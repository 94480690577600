export const BASIC_DETAIL_SUCCESS = "BASIC_DETAILS_SUCCESS"
export const BASIC_DETAILS_FAIL ="BASIC_DETAILS_FAIL"

export const CONSULTATION_TIME_SUCCESS = "CONSULTATION_TIME_SUCCESS"
export const CONSULTATION_TIME_FAILER = "CONSULTATION_TIME_FAILER"

export const CONSULTATION_FEE_SUCCESS = "CONSULTATION_FEE_SUCCESS"
export const CONSULTATION_FEE_FAILURE = "CONSULTATION_FEE_FAILURE"

export const DOCTOR_INPUT = 'DOCTOR_INPUT'

export const RESET_DOCTOR = 'RESET_DOCTOR'