import { Box, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import DataTables from '../../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBookingList } from '../../../Redux/actions/serviceAction';
import CustomSwitch from '../../../components/Widgets/CustomSwitch/CustomSwitch';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelBookingModal from './BookingCancelModal';
import { RESET_ERROR } from '../../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { Tooltip } from "@mui/material";

import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import BookingResheduleModal from './BookingResheduleModal';
import moment from 'moment';
const GetServiceBookingTable = ({ datas, filterbooking }) => {
    const dispatch = useDispatch()

    const Navigate = useNavigate()


    const { bookingList, bookingCanceled, error, reschedule } = useSelector(state => state.service);
    const [open, setOpen] = useState(false);
    const [openCalender, setOpenCalender] = useState(false);
    const [_id, set_id] = useState(null)
    const [serviceid, setServiceid] = useState(null)
    const [valuefrom, setvaluefrom] = useState("")
    const [valueto, setvalueto] = useState("")

    const NavigateToViewPage = (id) => {
        Navigate(`/bookingView/${id}`)
    }

    const NavigateToServiceAdd = (id) => {
        Navigate(`/addBooking/${id}`)


    }
    const handleOpen = (id) => {
        set_id(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleOpenCalender = (id) => {

        set_id(id)
        setOpenCalender(true);
    };

    const handleCloseCalender = () => {
        setOpenCalender(false);
    };
    useEffect(() => {
        if (bookingCanceled) {
            toast.success("Booking Cancelled successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
            dispatch(getBookingList())
        }

        if (reschedule) {
            toast.success("Booking Reschedule successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleCloseCalender()
            dispatch(getBookingList())

        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [bookingCanceled, error, reschedule])


    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    var columns = [

        {
            name: <b>{Capitalize("BOOKING ID")}</b>,
            header: <b>{Capitalize("BOOKING ID")}</b>,
            selector: (row) => row?.booking_id,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PATIENT ID")}</b>,
            header: <b>{Capitalize("PATIENT ID")}</b>,
            selector: (row) => `${row?.patient?.[0]?.user_id}`,
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("PATIENT NAME")}</b>,
        //     header: <b>{Capitalize("PATIENT NAME")}</b>,
        //     selector: (row) => row?.patient?.[0]?.name,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("PATIENT NAME")}</b>,
            header: <b>{Capitalize("PATIENT NAME")}</b>,
            selector: (row) => (
                <Tooltip title={row?.patient?.[0]?.name}>
                    <div style={{
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {Capitalize(row?.patient?.[0]?.name)}
                    </div>
                </Tooltip>
            ),
            style: {
                textAlign: "center",
            },
        },

        // {
        //     name: <b>{Capitalize("SERVICE")}</b>,
        //     header: <b>{Capitalize("SERVICE")}</b>,
        //     selector: (row) => row?.service_id?.map(res=>  (
        //         <span>
        //             {res?.name}
        //         </span>
        //     )),
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("SERVICE")}</b>,
            header: <b>{Capitalize("SERVICE")}</b>,
            selector: (row) => (
                <Tooltip title={row?.service_id?.map((res) => res?.name)?.join(', ')}>
                    <div style={{
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {row?.service_id?.map((res) => Capitalize(res?.name))?.join(', ')}
                    </div>
                </Tooltip>
            ),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("REFERRED BY")}</b>,
            header: <b>{Capitalize("REFERRED BY")}</b>,
            selector: (row) => row?.refferd_by,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("BOOKED ON")}</b>,
            header: <b>{Capitalize("BOOKED ON")}</b>,
            cell: (row) => moment(`${row?.date}  ${row?.time}`, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY hh:mm A"),
            minWidth: 250,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PAYMENT STATUS")}</b>,
            header: <b>{Capitalize("PAYMENT STATUS")}</b>,
            cell: (row) => row?.payment_status,

            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("AMOUNT")}</b>,
            header: <b>{Capitalize("AMOUNT")}</b>,
            selector: (row) => row?.amount,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("STATUS")}</b>,
            header: <b>{Capitalize("STATUS")}</b>,
            selector: (row) => row?.status,
            style: {
                textAlign: "center",
                // color:row?.status === "sheduled" ? "#FF8000" : "#19B12C"
            },
        },
        {
            name: <b>ACTIONS</b>,
            cell: (row, index) => (
                <>
                    <Stack spacing={.3}
                        direction="row">
                        <RemoveRedEyeIcon
                            onClick={() => NavigateToViewPage(row._id)}
                            color="blue"
                            style={{
                                cursor: "pointer",
                                color: "#047ac1",
                            }}
                        />{" "}
                        {row?.status != "cancelled" && <>
                            <BorderColorSharpIcon style={{ color: "#047ac1", cursor: "pointer" }}
                                onClick={() => Navigate(`/editBooking/${row?._id}`)} />
                            <CalendarMonthIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() => handleOpenCalender(row)} />
                            <CloseIcon style={{ color: "red", cursor: "pointer" }} onClick={() => handleOpen(row?._id)} />
                        </>}
                    </Stack>
                </>
            ),
        },

    ];


    useEffect(() => {
        dispatch(getBookingList())
    }, [filterbooking])

    return (
        <>
            <Paper>
                <Fragment>
                    <DataTables
                        columns={columns}
                        row={datas}
                        pagination
                    />
                </Fragment>
            </Paper>
            <CancelBookingModal handleClose={handleClose} handleOpen={open} id={_id} />
            <BookingResheduleModal handleClose={handleCloseCalender} handleOpen={openCalender} store={_id} service={'service'} />

        </>
    )
}

export default GetServiceBookingTable