import styled, { css } from "styled-components";
export const DrawerContainer = styled.div`
background: #ccdede;
      height:100%;
      padding:5px;
      border-radius:20px;
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    padding-left:10px;
  }
  li{
    cursor:pointer;
    h1{
        font-family:'Quicksand';
        font-size:12px !important;
        font-weight:bold;
        letter-spacing: 0.5px;
        color: #047ac3;
    }
  }

`;


export const LogoContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  .sub {
    padding-top: 2px;
    width: 16vw;
    border-radius: 15px;
    background: transparent linear-gradient(110deg, #0e9dab 0%, #047ac3 100%) 0%
      0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`;

export const LogoutContainer = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: center;
  .power {
    display: flex;
    justify-content: center;
    background: transparent linear-gradient(139deg, #0e9dab 0%, #047ac3 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #00000054;
    align-items: center;
    border-radius: 15px;
    opacity: 1;
    padding: 10px;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
    }
  }
`;
