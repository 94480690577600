
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Divider, Grid, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";
import CustomFormInput from "../../../components/Widgets/CustomFormInput";
import { createUser, editUser } from "../../../Redux/actions/userActions";
import { getAllRoles } from "../../../Redux/actions/roleAction";
import CustomSelectInput from "../../../components/Widgets/CustomSelect";
const AddUserModal = ({ handleClose, handleOpen, userData }) => {

    const { roleList } = useSelector(state => state.role);

    const { loading } = useSelector(state => state.auth)
    const [roles, setRole] = useState(null)


    const dispatch = useDispatch()
    const schema = object().shape({
        name: yup.string().max(50).required("Name is required"),
        designation: yup.string().required("Designation is required").nullable(true),
        mobile: yup.string().max(50).required("mobile is required"),
        email: yup.string().email("Invalid email format").required("Email is required"),
        //email: yup.string().email().required("email is required"),
        role: yup.string().max(50).required("role is required"),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),

    });

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        p: 2.5,
        width: "400px"
    };


    const handleRoleChange = (e) => {
        setValue("role", e.target.value)
        setRole(e.target.value)

    }

    const clearClose = () => {
        setValue("name", "")
        setValue("designation", "")
        setValue("mobile", "")
        setValue("email", "")
        handleClose()
    }

    useEffect(() => {
        if (userData) {
            setValue("name", userData?.name)
            setValue("designation", userData?.designation)
            setValue("mobile", userData?.mobile)
            setValue("email", userData?.email)
            setValue("role", userData?.role)
            setRole(userData?.role)
        }
    }, [userData])

    const submit = (data) => {
        if (userData) {
            let val = {
                name: data?.name,
                designation: data?.designation,
                mobile: data?.mobile,
                email: data?.email,
                id: userData?._id,
                status: userData?.status,
                role: data?.role
            }
            dispatch(editUser(val))
        } else {
            dispatch(createUser(data))
        }
    }

    useEffect(() => {
        dispatch(getAllRoles())
    }, [])

    return (
        <>
            <Modal
                open={handleOpen}
                onClose={clearClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack
                        direction={"row"}
                        spacing={5}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            style={{ color: "#047BC1" }}
                        >
                            {userData ? "EDIT USER" : "ADD USER"}
                        </Typography>
                        <ClearIcon
                            style={{
                                cursor: "pointer",
                                color: "black"
                            }}
                            onClick={() => clearClose()}
                        />
                    </Stack>
                    <Divider />
                    <Box paddingTop={"20px"}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} md={6} >
                                <CustomFormInput
                                    height="40px"
                                    fieldLabel="Name"
                                    control={control}
                                    error={errors.name}
                                    fieldName="name"
                                    type={"text"}
                                    background="#FFFFFF"
                                    boxshadow="5px 5px 10px #00000005"
                                />
                            </Grid>
                            <Grid item xs={5} md={6} >
                                <CustomFormInput
                                    height="40px"
                                    fieldLabel="Designation"
                                    control={control}

                                    error={errors.designation}
                                    fieldName="designation"
                                    type={"text"}
                                    background="#FFFFFF"
                                    boxshadow="5px 5px 10px #00000005"
                                />
                            </Grid>
                            <Grid item xs={5} md={6} >
                                <CustomFormInput
                                    height="40px"
                                    fieldLabel="Mobile Number"
                                    control={control}
                                    error={errors.mobile}
                                    fieldName="mobile"
                                    type={"text"}
                                    background="#FFFFFF"
                                    boxshadow="5px 5px 10px #00000005"
                                />
                            </Grid>
                            <Grid item xs={5} md={6} >
                                <CustomFormInput
                                    height="40px"
                                    fieldLabel="Email Address"
                                    control={control}
                                    error={errors.email}
                                    fieldName="email"
                                    type={"text"}
                                    background="#FFFFFF"
                                    boxshadow="5px 5px 10px #00000005"
                                />
                            </Grid>
                            <Grid item xs={5} md={6} >
                                <CustomSelectInput
                                    control={control}
                                    error={errors.role}
                                    fieldName="role"
                                    fieldLabel="Roles"
                                    background="#e7f5f7"
                                    height="40px"
                                    size="16px"
                                    value={roles}
                                    onChangeValue={handleRoleChange}
                                // options={categoryArray}
                                >
                                    <MenuItem value="" disabled >
                                        Select Roles
                                    </MenuItem>
                                    {roleList?.map((status) => (
                                        <MenuItem key={status?.slug} value={status?.slug} >
                                            {status?.name}
                                        </MenuItem>
                                    ))}

                                </CustomSelectInput>

                            </Grid>
                        </Grid>
                    </Box>
                    <Box paddingTop={"30px"} display={"flex"} justifyContent={"center"}>
                        <CustomButton variant={"contained"} text={loading ? "Loading ..." : "submit"} disabled={loading ? true : false} submit={handleSubmit(submit)} />

                    </Box>
                </Box>

            </Modal>
        </>
    )
}

export default AddUserModal