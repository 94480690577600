import React from 'react'
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const CustomTimePicker = ({ Fieldlabel, From, To, fromValue, onChanges, submit, onChangeTo, toValue, disabled,readOnly }) => {

    return (
        <Box>
           
            <Box display={"flex"} alignItems={"center"} gap={"5px"} paddingY={1} >
                <LocalizationProvider dateAdapter={AdapterMoment} >
                    <TimePicker
                        label={From}
                        value={fromValue}
                        onChange={onChanges}
                        // disabled={disabled}
                        readOnly={readOnly}
                        disableOpenPicker={disabled}
                        renderInput={(params) => <TextField {...params} />
                        }
                        InputProps={{
                            style:{borderRadius: "20px" ,height:"48px",background:"white"}

                           }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                       
                        label={To}
                        value={toValue}
                        onChange={onChangeTo}
                        readOnly={readOnly}
                        // disabled={disabled}
                        disableOpenPicker={disabled}
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                            style:{borderRadius: "20px" ,height:"48px",background:"white"}

                           }}
                    />
                </LocalizationProvider>
                {/* <CustomButton ComponentIcon={AddIcon} variant={"contained"} submit={submit}style={{paddingTop:"30px"}}/> */}
            </Box>
        </Box>
    )
}

export default CustomTimePicker