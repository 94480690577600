import axios from "../../customAxios"
import { CREATE_PATIENT_SUCCESS, CRETAE_PATIENT_FAIL, EDIT_PATIENTS_FAIL, EDIT_PATIENTS_SUCCESS, FILTER_PATIENT_FAIL, FILTER_PATIENT_SUCCESS, GET_ALL_PATIENTS_SUCCESS, GET_ALL_PATIENT_FAIL, GET_SINGLE_PATIENT_FAIL, GET_SINGLE_PATIENT_SUCCESS } from "../constants/patientConstant"

import {
    LOADING, LOGIN_FAILED, LOGIN_SUCCESS
} from "../constants/userConstants"

export const createPatient = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-patient`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_PATIENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: CRETAE_PATIENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const getPatient = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/patients`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_ALL_PATIENTS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_ALL_PATIENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const EditPatientsdata = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/edit-patient`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_PATIENTS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type:EDIT_PATIENTS_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const getSingleList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/patient/${id}`)
        .then(async response => {
            let data = response.data.data;

            dispatch({
                type: GET_SINGLE_PATIENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type:GET_SINGLE_PATIENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const filterPatients = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/patients/filter`,data)
        .then(async response => {
            let data = response.data.data;
         
            dispatch({
                type: FILTER_PATIENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type:FILTER_PATIENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}




