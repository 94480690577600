import { Backdrop, CircularProgress } from '@mui/material'
import React, { memo } from 'react'

const UiBlocker = memo(({show}) => {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
        //onClick={handleClose}
        >
        <CircularProgress color="inherit" />
    </Backdrop>
  )
})

export default UiBlocker