import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import { Stack, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import customAxios from '../../../customAxios';
import moment from 'moment';


const DocBlog = () => {
    const [img, setImg] = useState();
    const navigate = useNavigate();
    const [blogData, setBlogData] = useState([])
    const [newErrors, setNewErrors] = useState({});

    useEffect(() => {
        async function getBlog() {
            try {
                let res = await customAxios.get(
                    `/doctor/blogs`
                )
                if (res?.status === 201) {
                    let data = res?.data?.data;
                    setBlogData(data);


                } else {
                    throw new Error('Network error');
                }
            } catch (error) {
                setNewErrors({ status: { message: 'Error fetching blogs' } });
            }
        }

        getBlog();
    }, []);
    const datafetch = (data, k) => {
    }
    return (
        <Box sx={{ flexGrow: 1 }} py={5} height={300}>
             {blogData.length === 0 ? (
            <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh', 
                marginTop:'-200px'
            }}
        >
            <Typography
                variant='inherit'
           
            >
                No blogs found
            </Typography>
        </Box>
        ) : (
            <Grid container spacing={2} height={300}>
                {blogData?.map((res, i) => (

                    <Grid key={i} item lg={3} md={3} sm={4} xs={12} maxHeight={300}>
                        <Card sx={{ maxWidth: '100%', cursor: 'pointer' }} onClick={() => navigate(`/docBlogview/${res?._id}`)} >
                            <CardMedia
                                sx={{ height: 140 }}
                                image={`https://apimcare.diginestsolutions.in/public/${res?.attachment}`}

                                title="green iguana"
                            />
                            <Stack direction={'row'} style={{}} justifyContent={'space-between'} px={2}>
                                <Typography style={{
                                    fontFamily: "Quicksand", fontWeight: "bold", marginTop: "6px", fontSize: "20px", lineHeight: "25px", "overflow": "hidden",
                                    "textOverflow": "ellipsis",
                                    "display": "-webkit-box",
                                    "WebkitLineClamp": "1",
                                    "lineClamp": "1",
                                    "WebkitBoxOrient": "vertical"
                                }} variant='inherit'  >{res?.subject}</Typography>
                                <Typography style={{
                                    opacity: "0.4", color: "#444444", textAlign: "right",
                                    top: "10",
                                    fontSize: "x-small",
                                    marginTop: "revert"
                                }}>{moment(res?.created_at).format("DD/MM/YYYY hh:mm")}</Typography>
                            </Stack>
                            <CardContent style={{
                                "overflow": "hidden",
                                "textOverflow": "ellipsis",
                                "display": "-webkit-box",
                                "WebkitLineClamp": "2",
                                "height": "2",
                                "lineClamp": "2",
                                "WebkitBoxOrient": "vertical",

                            }} >
                                <Typography variant="body2" style={{ height: "3.5vh", textAlign: "left", fontFamily: "Quicksand", fontWeight: "bold", letterSpacing: "1.5px", color: "#000000", fontSize: "15px", lineHeight: "24px", opacity: "1", }}  >
                                    {res?.content}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>))}
            </Grid>
              )}
        </Box >
    )
}
export default DocBlog