export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS"
export const CREATE_DOCTOR_FAIL = "CREATE_DOCTOR_FAIL"
export const EDIT_DOCTOR_SUCCESS = "EDIT_DOCTOR_SUCCESS"
export const EDIT_DOCTOR_FAIL = "EDIT_DOCTOR_FAIL"

export const GET_DOCTOR_SUCCESS = "GET_DOCTOR_SUCCESS"
export const GET_DOCTOR_FAIL = "GET_DOCTOR_FAIL"

export const GET_DOCTOR_SINGLE_SUCCESS = "GET_DOCTORSINGLE_SUCCESS"
export const GET_DOCTOR_SINGLE_FAIL = "GET_DOCTORSINGLE_FAIL"
export const DOCTOR_STATUS_SUCCESS = "DOCTOR_STATUS_SUCCESS"
export const DOCTOR_STATUS_FAIL = "DOCTOR_STATUS_FAIL"
export const FILTER_DOCTER_SUCCESS = "FILTER_DOCTER_SUCCESS"
export const FILTER_DOCTER_FAIL = "FILTER_DOCTER_FAIL"

export const POST_DOCTOR_RESCHEDULE_LIST_SUCCESS = 'POST_DOCTOR_RESCHEDULE_LIST_SUCCESS'
export const POST_DOCTOR_RESCHEDULE_LIST_FAIL = 'POST_DOCTOR_RESCHEDULE_LIST_FAIL'

export const POST_DOCTOR_RESCHEDULE_LIST_SUCCESS_DOCTOR = 'POST_DOCTOR_RESCHEDULE_LIST_SUCCESS_DOCTOR'
export const POST_DOCTOR_RESCHEDULE_LIST_FAIL_DOCTOR = 'POST_DOCTOR_RESCHEDULE_LIST_FAIL_DOCTOR'

export const DOCTOR_LEAVE_LIST_FAIL = 'DOCTOR_LEAVE_LIST_FAIL'

export const DOCTOR_LEAVE_LIST_SUCCESS = 'DOCTOR_LEAVE_LIST_SUCCESS'

export const LEAVE_LIST = 'LEAVE_LIST'