import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Sidebardata, Sidebardataforadmin } from "../data";
import Paper from "@mui/material/Paper";
import { styled, Box, Stack } from "@mui/system";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RoleContext from "../../../helpers/Role";
import { DrawerContainer, LogoContainer, LogoutContainer } from "./style";
import logo from "../../../assets/images/hlogo.png";
import power from "../../../assets/images/power-button-svgrepo-com.png";
const ModalDrawer = ({ toggleDrawer, setIsDraweropen, isDraweropen }) => {
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState([])
  const [text, settext] = useState(null);
  let location = useLocation();
  const context = useContext(RoleContext)

  useEffect(() => {
    context?.getMenus()

  }, [context?.role])

  useEffect(() => {

    const role = JSON.parse(localStorage?.getItem("role"))
    if (role?.slug === "admin") {
      setSideMenu(Sidebardataforadmin)
    }
    else {

      let usersMenus = Sidebardata.filter(side => context?.menus?.includes(side?.title))
      setSideMenu(usersMenus)
    }

  }, [context?.menus])

  const [isActive, setActive] = useState();

  const toggleClass = (index) => {
    setActive(index);
  };

  const click = (index) => {
    navigate(index);
    setActive(index);
    setIsDraweropen(false)
  };
  useEffect(() => {

    if (location) {


      if (location?.pathname === "/" || location?.pathname === "/addAppointment") {
        settext("Consultation Appointment Management")
      } else if (location?.pathname === "/profile") {
        settext("Profile")
      } else if (location?.pathname === "/department") {
        settext("Department Management")
      } else if (location?.pathname === "/doctor" || location?.pathname === "/AddDoctor") {
        settext("Doctor Management")
      } else if (location?.pathname === "/patients" || location?.pathname === "/Addpatients") {
        settext("Patient Management")
      } else if (location?.pathname === "/service" || location?.pathname === "/addBooking") {
        settext("Service Management")
      } else if (location?.pathname === "/procedure" ||
        location?.pathname === "/procedureBooking"
        || location?.pathname === "/procedureList") {
        settext("Procedure Management")
      } else if (location?.pathname === "/settings") {
        settext("Settings")
      } else if (location?.pathname === "/roles") {
        settext("Role Management")
      } else if (location?.pathname === "/user") {
        settext("User Management")
      } else if (location?.pathname === "/blogs") {
        settext("Blog Management")
      } else if (location?.pathname === "/leaveManagement") {
        settext("Leave Management")
      }

      else if (location?.pathname === "/docBlog") {
        settext("Blog")
      }
    }
  }, [location])

  const LogOut = () => {
    localStorage?.clear();
    navigate(`/login`);
  }



  return (
    <>
      <Drawer
        anchor="left"
        open={isDraweropen}
        onClose={() => setIsDraweropen(false)}
      >
        <Box sx={{ width: "200px" }}>
          <Stack spacing={2} direction="column">
            <LogoContainer>
              <div className="sub">
                <img src={logo} alt="logo" style={{ width: 60, height: 60, paddingTop: "9px" }}></img>
              </div>
            </LogoContainer>
            <DrawerContainer>
              <ul>
                {sideMenu?.map((val, key) => {
                  return (
                    <>
                      <li
                        key={key}
                        onClick={() => click(val?.link)}
                        style={{ background: val?.link === isActive ? "white" : "", padding: val.link === isActive ? "2px 2px 2px 2px" : "", borderRadius: val?.link === isActive ? "10px" : "" }}
                      >
                        <h1>{val?.title} </h1>
                      </li>
                    </>
                  );
                })}

              </ul>
            </DrawerContainer>
            <LogoutContainer >
              <div className="power" onClick={() => LogOut()}>
                <img src={power} alt="power" height={50}></img>
              </div>
            </LogoutContainer>

          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default ModalDrawer;
