import React from 'react'
import { Avatar, Box, FormGroup, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { styled } from '@mui/material/styles';
import BackupIcon from '@mui/icons-material/Backup';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Padding, WidthFull } from '@mui/icons-material';
const CustomImageUpload = ({
    fieldName,
    control,
    fieldLabel,
    placeholder,
    error,
    type,
    max,
    onChangeValue,
    height,
    background,
    ICON,
    width,
    preview,
    previewEditimage,
    myid }) => {
    const Input = styled('input')({
        display: 'none',

    });

    return (
        <>
            <FormGroup>
                <Typography
                    htmlFor="recipient-name"
                    className="col-form-label"
                    sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: "bold"
                    }}
                >
                    {fieldLabel}
                </Typography>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) =>
                        <Box style={{
                            height: height,
                            border: "1px solid",
                            width: width ? width : "100%",
                            borderRadius: "15px",
                            display: "flex",

                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            background: background,
                            border: "none",
                            position: "relative"

                        }}>
                            <Avatar src={previewEditimage ? previewEditimage : preview ? URL.createObjectURL(preview) : ""} style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "15px",
                                border: "none",
                                objectFit: "container"

                            }} ></Avatar>
                            <label htmlFor={myid} >
                                <Input
                                    onBlur={onBlur}
                                    aria-invalid={error ? "true" : "false"}
                                    className="form-control"
                                    placeholder={placeholder}
                                    type={type ? type : "text"}
                                    maxLength={max}

                                    id={myid}
                                    onChange={onChangeValue ? (e) => onChangeValue(e.target.files[0]) : onChange}
                                />
                                <CloudUploadOutlinedIcon style={{
                                    color: "white",
                                    cursor: "pointer",
                                    background: "#0E9DAB",
                                    zIndex: "1000",
                                    position: "absolute",
                                    bottom: "0",
                                    right: "0",
                                    objectFit: "container",
                                    borderRadius: "3px",
                                    width: "40px"

                                }} fontSize={"large"} />
                            </label>
                        </Box>
                    }
                />
                {error && <p role="alert" style={{
                    color: "red",
                    display: "flex",
                    flexDirection: "start",
                    paddingLeft: "10px",
                    fontSize: "12px"
                }}>{error?.message}</p>}
            </FormGroup>
        </>
    )
}

export default CustomImageUpload