import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from "@mui/material/Avatar";
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import { IconButton, Stack, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useNavigate, useParams } from 'react-router-dom';
import Backbutton from "../../../components/Widgets/Backbutton";
import { useEffect } from 'react';
import customAxios from '../../../customAxios';
import user from '../../../assets/images/user.webp'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
const DrBlogview = () => {
    const [singleBlog, setsingleBlog] = useState(
    )
    const [newErrors, setNewErrors] = useState({});
    const { profiledata } = useSelector(state => state?.profile);
    const { id } = useParams();
    useEffect(() => {
        async function getSingelBlog() {
            try {
                let res = await customAxios.get(
                    `/doctor/get-blog/${id}`
                );
                if (res?.status === 201) {
                    let data = res?.data?.data;
                    setsingleBlog(data);

                } else {
                    throw new Error('Network error');
                }
            } catch (error) {
                setNewErrors({ status: { message: 'Error fetching blogs' } });
            }
        }

        getSingelBlog();
    }, []);


    return (
        <Box sx={{ flexGrow: 1 }} py={1}>
            <Card sx={{ maxWidth: '100%', height: '87vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ width: "100%", height: "40%" }}>
                    <img src={singleBlog?.attachment} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </div>
                <div style={{ height: '100%', overflowX: 'auto'}}> 
                    <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                        <Typography style={{ color: "#0680BE", fontFamily: "quicksand", fontSize: "49px", lineHeight: "61px", letterSpacing: "2.45px", opacity: "1" }} variant='inherit' fontSize={35} >{singleBlog?.subject}</Typography>
                        <Typography style={{ marginTop: "20px", fontFamily: "Quicksand", fontSize: "20px", lineHeight: "25px", letterSpacing: "1px", color: " #444444", opacity: " 0.4" }} letterSpacing={1}>{moment(singleBlog?.created_at).format("DD/MM/YYYY hh:mm")}</Typography>
                    </Stack>
                    <CardContent sx={{ maxHeight: '100%', paddingBottom: '40px' }}>
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {singleBlog?.content.split('\n').map((paragraph, index) => (
                                <Typography key={index} style={{ fontFamily: "Quicksand", fontWeight: "bold", letterSpacing: "2.5px", color: "#000000", opacity: 1, marginBottom: "10px" }} variant="body2" letterSpacing={1}>
                                    {paragraph}
                                </Typography>
                            ))}
                        </div>
                    </CardContent >
                    <CardContent sx={{ maxHeight: '100%', minHeight: "76px", paddingTop: "15px", display: "flex" }}>
                        <Typography style={{ color: "# 444444", lineHeight: "0px", letterSpacing: "2.45px", opacity: "1" }} variant='inherit' fontSize={20}  >{`Author:${singleBlog?.created}`}</Typography>
                    </CardContent>
                </div>
         

                <Box position={'absolute'} zIndex={100} top={95} >
                    <Backbutton />
                </Box>
            </Card>
        </Box >
    )
}

export default DrBlogview