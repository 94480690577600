import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormGroup, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const CustomFormInput = ({
  fieldName,
  control,
  fieldLabel,
  placeholder,
  error,
  type,
  maxrow,
  height,
  background,
  rows,
  multiline,
  ICON,
  size,
  boxshadow,
  onChange,
  readOnly,
  maxlength
}) => {

  const [show, setShow] = useState(false)

  return (
    <>
      <FormGroup>
        <Typography style={{ paddingBottom: "2px" }} sx={{
          fontSize: {
            lg: 15,
            md: 14,
            sm: 13,
            xs: 12,

          },
          fontFamily: 'Quicksand',
          fontWeight:"bold",
          paddingLeft:"5  px"
        }}>{fieldLabel}</Typography>
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <TextField
           
              {...field}
              aria-invalid={error ? "true" : "false"}
              className="form-control"
              placeholder={placeholder}
              id="exampleInputEmail1"
              type={type ? type : 'text'}
              maxRows={maxrow}
              multiline={multiline}
              InputProps={{
                
                readOnly: readOnly === true ? true : false,
                style: { borderRadius: "10px", height: height, background: background, boxShadow: boxshadow ,color:"black", },
                startAdornment: (
                  <InputAdornment position="end">
                    {ICON ? <ICON /> : ""}
                  </InputAdornment>
                ),
                endAdornment: type === "password" ? show ? <VisibilityOffIcon style={{ pointer: 'cursor' }} onClick={() => setShow(false)} /> : <VisibilityIcon style={{ pointer: 'cursor' }}  onClick={() => setShow(true)} /> : null
              }}
            />
          )}
        />
        {error && (
          <p
            role="alert"
            style={{
              color: "red",
              display: "flex",
              flexDirection: "start",
              paddingLeft: "10px",
              fontSize: "12px",
            }}
          >
            {error?.message}
          </p>
        )}
      </FormGroup>
    </>
  );
};

export default CustomFormInput;
