import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const SidebarContainer = styled.aside`
  position: fixed;
  top: 0;
  width: 16vw;
  height: 100vh;
  transition: 0.5s;
  @media (max-width: 980px) {
    display: none;
  },
  background: transparent linear-gradient(110deg, #0e9dab 0%, #047ac3 100%) 0% 0% no-repeat padding-box;
`;

export const LogoContainer = styled.div`
  width: 16vw;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  .sub {
    padding-top: 2px;
    width: 16vw;
    border-radius: 15px;
    background: transparent linear-gradient(110deg, #0e9dab 0%, #047ac3 100%) 0%
      0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      // width:75px;
      // height:75px;
      // display:block;
    }
  }
`;

export const NavlinkContainer = styled.div`
  margin-top:5px;
  height:100vh;
  background: transparent linear-gradient(166deg, rgba(14, 157, 171, 0.1) 0%, rgba(4, 122, 195, 0.1) 100%) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  
  border-radius: 10px 10px 0px 0px;
    div{
      overflow-y: scroll;
      -ms-overflow-style: none;  
      scrollbar-width: none; 
      ::-webkit-scrollbar {
        display: none;
    }

    }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top:15px;
    padding-left:20px;
   
     li {
      width:80%;
       cursor: pointer;
       text-align: start;
       border-radius: 10px;
 
      
       h1 {
        width:80%;
         font-family: Quicksand;
         padding: 2;
         margin: auto;
         font: normal normal 600 20px/25px Quicksand;
         letter-spacing: 1px;
         color: #047ac3;
         font-size: 20px;
       }
     }
   }
`;

export const LogoutContainer = styled.div`
  padding-top: 60px;
  display: flex;
  justify-content: center;
  .power {
    display: flex;
    justify-content: center;
    background: transparent linear-gradient(139deg, #0e9dab 0%, #047ac3 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #00000054;
    align-items: center;
    border-radius: 15px;
    opacity: 1;
    padding: 10px;
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
    }
  }
`;
