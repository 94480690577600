import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import CustomSearch from "../../components/Widgets/CustomSearch";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import data from "../../components/common/data";
import DataTables from "../../components/Widgets/dataTable";
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filterPatients, getPatient, getSingleList } from "../../Redux/actions/patientAction";
import moment from "moment";
import { toast } from 'react-toastify';
import CustomFilter from "../../components/Widgets/CustomFilter";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import Button from '@mui/material/Button';
import { Tooltip } from "@mui/material";
const Patients = () => {
  const dispatch = useDispatch()

  const { patientList, error } = useSelector(state => state.patient);

  const [datas, setDatas] = useState([])

  const [editValue, seteditValue] = useState(null)
  const [valuefrom, setvaluefrom] = useState("")
  const [valueto, setvalueto] = useState("")
  const [openFilter, setOpenFilter] = useState(false);
  const [filterKey, setFilterKey] = useState(0);
  const [switchChecked, setSwitchChecked] = useState(null);
  const [row, setRow] = useState(null)
  const [checked, setChecked] = useState(null)
  const [openDialog, setOpenDialog] = useState(false);


  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  // const handleCloseFilter = () => {
  //   if (valuefrom && valueto) {
  //     dispatch(getPatient())
  //   }
  //   setvaluefrom("")
  //   setvalueto("")
  //   setOpenFilter(false);
  // };
  const clearFilter = () => {
    setvaluefrom("");
    setvalueto("");
    setFilterKey(prevKey => prevKey + 1);
  };

  const handleCloseFilter = () => {
    clearFilter();
    setOpenFilter(false);
  };


  const ChangeStatus = (checked, row) => {
    setSwitchChecked(checked);
    setRow(row);
    handleClickOpen();
  };


  const handleClickOpen = () => {
    setOpenDialog(true);
  };


  const Submitfilter = () => {

    let val = {
      from_date: moment(valuefrom).format('DD-MM-YYYY'),
      to_date: moment(valueto).format('DD-MM-YYYY'),
    };


    if (valuefrom && valueto) {
      dispatch(filterPatients(val));
      setOpenFilter(false);
    } else {
      toast.warning('fromDate and toDate are required');
    }
  };


  const navigate = useNavigate()

  const NavigateToaddpage = () => {
    navigate("/Addpatients")
  }

  useEffect(() => {

    if (patientList) {
      setDatas(patientList)
    }

  }, [patientList])

  // const searchPatient = (value) => {

  //   let datas =
  //     patientList?.filter(pat => pat?.user_id?.toLowerCase().includes(value.toLowerCase())
  //       || pat?.name?.toLowerCase().includes(value.toLowerCase()) || pat?.mobile.toLowerCase().includes(value.toLowerCase()))
  //   setDatas(datas)
  // }
  const searchPatient = (value) => {

    let datas = patientList?.filter(pat =>
      pat?.user_id?.toLowerCase().includes(value.toLowerCase()) ||
      pat?.name?.toLowerCase().includes(value.toLowerCase()) ||
      pat?.email?.toLowerCase().includes(value.toLowerCase()) ||
      (typeof pat?.mobile === 'string' ?
        pat?.mobile.toLowerCase().includes(value.toLowerCase()) :
        pat?.mobile.toString().includes(value))
    );
    setDatas(datas);
  }

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };


  const EditPatient = (id) => {
    navigate(`/EditPatient/${id}`)
  }

  const PatientView = (id) => {
    navigate(`/PatientView/${id}`)
  }

  var columns = [

    {
      name: <b>{Capitalize("PATIENT ID")}</b>,
      header: <b>{Capitalize("PATIENT ID")}</b>,
      selector: (row) => row?.user_id,
      style: {
        textAlign: "center",
      },
    },
    // {
    //   name: <b>{Capitalize("NAME")}</b>,
    //   header: <b>{Capitalize("NAME")}</b>,
    //   selector: (row) => row?.name,
    //   style: {
    //     textAlign: "center",
    //   },
    // },
    {
      name: <b>{Capitalize("NAME")}</b>,
      header: <b>{Capitalize("NAME")}</b>,
      selector: (row) => (
        <Tooltip title={Capitalize(row?.name)}>
          <div style={{
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
            {row?.name}
          </div>
        </Tooltip>
      ),
    },
    
    {
      name: <b>{Capitalize("MOBILE NUMBER")}</b>,
      header: <b>{Capitalize("MOBILE NUMBER")}</b>,
      selector: (row) => row?.mobile,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("EMAIL")}</b>,
      header: <b>{Capitalize("EMAIL")}</b>,
      selector: (row) => (
        <Tooltip title={Capitalize(row?.email)}>
          <div style={{
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
            {row?.email}
          </div>
        </Tooltip>
      ),
    },
    {
      name: <b>{Capitalize("DOB")}</b>,
      header: <b>{Capitalize("DOB")}</b>,
      selector: (row) => row?.dob,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("GENDER")}</b>,
      header: <b>{Capitalize("GENDER")}</b>,
      selector: (row) => row?.gender,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("DEPARTMENT")}</b>,
      header: <b>{Capitalize("DEPARTMENT")}</b>,
      selector: (row) => <>
        {row?.category?.map(res => (
          <div style={{ display: 'flex' }}>{res?.name},</div>
        ))}
      </>,
      style: {

        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("ADDRESS")}</b>,
      header: <b>{Capitalize("ADDRESS")}</b>,
      selector: (row) => row?.address,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("BLOOD GROUP")}</b>,
      header: <b>{Capitalize("BLOOD GROUP")}</b>,
      selector: (row) => row?.bloodgroup,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("CREATED ON")}</b>,
      header: <b>{Capitalize("CREATED ON")}</b>,
      selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
      style: {
        textAlign: "center",
      },
    },

    {
      name: <b>ACTIONS</b>,
      id: "delete",
      accessor: (str) => "delete",
      cell: (row, index) => (
        <>
          <Stack spacing={1} direction="row">
            <RemoveRedEyeIcon
              onClick={() => PatientView(row?._id)}
              color="blue"
              style={{
                cursor: "pointer",
                color: "#047ac1",
              }}
            />{" "}
            <BorderColorSharpIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() => EditPatient(row._id)} />{" "}
          </Stack>
        </>
      ),
    },
 
  ];

  useEffect(() => {
    dispatch(getPatient())
  }, [])


  return (
    <>
      <Box
        sx={{
          width: "99%",
          height: "60px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexDirection: { lg: "row", md: "column", xs: "column" },
          gap: { md: "10px", xs: "10px" },
          marginBottom: "40px",
        }}
      >
        <Stack spacing={2.6} direction="row" style={{
          background: "#d8dee1",
          padding: "3px 3px 3px .1px",
          display: "flex",
          alignItems: "center",

        }} borderRadius={2}>
          <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={(value) => searchPatient(value)} />

          <CustomButton
            variant={"contained"}
            text={"Filter"}
            active={openFilter ? true : false}
            Icon={FilterListIcon}
            submit={() => setOpenFilter(!openFilter)}
          />
          <CustomButton
            submit={NavigateToaddpage}
            ComponentIcon={AddIcon}
            variant={"contained"}

          />
        </Stack>
      </Box>
      {/* {openFilter && <CustomFilter
          handlechangefrom={(value) => setvaluefrom(value)}
          valuefrom={valuefrom}
          valueto={valueto}
          handlechangeto={(value) => setvalueto(value)}
          submit={Submitfilter}
          clear={handleCloseFilter}
        />} */}
      {openFilter &&
        <CustomFilter
          key={filterKey}
          handlechangefrom={(value) => setvaluefrom(value)}
          valuefrom={valuefrom}
          valueto={valueto}
          handlechangeto={(value) => setvalueto(value)}
          submit={Submitfilter}
          clear={clearFilter}
        />
      }
      <Paper>
        <Fragment>
          <DataTables
            columns={columns}
            row={datas}
            pagination={true} />
        </Fragment>
      </Paper>
      {/* <Dialog
        open={openDialog}
        onClose={handleCloseDialoge}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure ?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialoge}>No</Button>
          <Button onClick={() => handleCloseDialoge('yes')} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* <Dialog
        open={openDialog}
        onClose={handleCloseDialoge}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure ?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialoge}>No</Button>
          <Button onClick={() => handleCloseDialoge('yes')} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
}

export default Patients