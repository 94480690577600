import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import CustomSearch from "../../components/Widgets/CustomSearch";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DataTables from "../../components/Widgets/dataTable";
import { CircularProgress, Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { DocterStatusChange, GetDocterList } from "../../Redux/actions/doctorAction";
import CustomSwitch from '../../components/Widgets/CustomSwitch/CustomSwitch';
import moment from "moment/moment";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BASIC_DETAIL_SUCCESS, CONSULTATION_FEE_SUCCESS, CONSULTATION_TIME_SUCCESS } from "../../Redux/constants/AddDoctorConstant";
import { GET_DOCTOR_SINGLE_SUCCESS } from "../../Redux/constants/doctorConstant";
import { Tooltip } from "@mui/material";
const Docter = () => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const location = useLocation()
    const { loading } = useSelector(state => state.auth);
    const { doctorList, error, statusChanged } = useSelector(state => state.doct);
    const [datas, setDatas] = useState([])
    const [switchChecked, setSwitchChecked] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = useState(null)
    const [row, setRow] = useState(null)

    const navigateToAddPage = () => {
        Navigate("/AddDoctor")
    }

    const navigateToDetailPage = (id) => {
        Navigate(`/DoctorDetailPage/${id}`)
    }

    const navigateToEditPage = (id) => {
        Navigate(`/editDoctor/${id}`)
    }


    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    useEffect(() => {
        if (doctorList) {
            setDatas(doctorList)
        }
    }, [doctorList])
    useEffect(() => {
        dispatch(GetDocterList())
    }, [])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (mode) => {
        if (mode === 'yes') {
            let val = {
                id: row,
                status: checked === true ? "active" : "inactive"
            }
            dispatch(DocterStatusChange(val))

        };
        setOpen(false);
    }


    const ChangeStatus = (checked, row) => {
        setSwitchChecked(checked)
        setChecked(checked)
        setRow(row)
        handleClickOpen()
    }






    var columns = [
        {
            name: <b>{Capitalize("DOCTOR ID")}</b>,
            header: <b>{Capitalize("DOCTOR ID")}</b>,
            selector: (row) => <>DOC{row?.doctor_id}</>,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("CREATED ON")}</b>,
            header: <b>{Capitalize("CREATED ON")}</b>,
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("NAME")}</b>,
        //     header: <b>{Capitalize("NAME")}</b>,
        //     selector: (row) => row?.name,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("NAME")}</b>,
            header: <b>{Capitalize("NAME")}</b>,
            selector: (row) => (
                <Tooltip title={Capitalize(row?.name)}>
                    <div style={{
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {row?.name}
                    </div>
                </Tooltip>
            ),
        },
        {
            name: <b>{Capitalize("MOBILE")}</b>,
            header: <b>{Capitalize("MOBILE")}</b>,
            selector: (row) => row?.mobile,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("DEPARTMENT")}</b>,
            header: <b>{Capitalize("DEPARTMENT")}</b>,
            selector: (row) => row?.department_details?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("DESIGNATION")}</b>,
            header: <b>{Capitalize("DESIGNATION")}</b>,
            selector: (row) => row?.designation,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("OP")}</b>,
            header: <b>{Capitalize("OP")}</b>,
            selector: (row) => row?.op_fee,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("ONLINE")}</b>,
            header: <b>{Capitalize("ONLINE")}</b>,
            selector: (row) => row?.online_fee,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("REPORT")}</b>,
            header: <b>{Capitalize("REPORT")}</b>,
            selector: (row) => row?.review_fee,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>ACTIONS</b>,
            cell: (row, index) => (
                <>
                    <Stack spacing={2} direction="row">
                        <RemoveRedEyeIcon
                            onClick={() => navigateToDetailPage(row?._id)}
                            color="blue"
                            style={{
                                cursor: "pointer",
                                color: "#047ac1",
                            }}
                        />

                        <>
                            {row.status === 'active' && ( // Check if row status is active
                                <BorderColorSharpIcon
                                    style={{ color: "#047ac1", cursor: "pointer" }}
                                    onClick={() => navigateToEditPage(row?._id)}
                                />
                            )}
                        </>

                    </Stack>
                </>
            ),
        },
        {
            name: <b>STATUS</b>,
            cell: (row, index) => (
                <>
                    <Stack spacing={1} direction="row">
                        <CustomSwitch checked={row?.status === "active" ? true : false}
                            onClick={(e) => ChangeStatus(e.target.checked, row?._id)}
                        />
                    </Stack>
                </>
            ),
        },
    ];

    const searchDoctor = (value) => {
        let doctors = doctorList?.filter(doc => doc?.doctor_id.toString().toLowerCase().includes(value.toLowerCase()) || doc?.name.toLowerCase().includes(value.toLowerCase()) || doc?.designation.toLowerCase().includes(value.toLowerCase()) || doc?.mobile.toLowerCase().includes(value.toLowerCase()) || doc?.department_details?.name.toLowerCase().includes(value.toLowerCase()))
        setDatas(doctors)
    }



    //FOR RESETING THE DOCTOR DETAILS ADD/EDIT.............................   
    useEffect(() => {
        if (location.pathname === '/doctor') {
            dispatch({
                type: BASIC_DETAIL_SUCCESS,
                payload: null
            })
            dispatch({
                type: GET_DOCTOR_SINGLE_SUCCESS,
                payload: null
            })
            dispatch({
                type: CONSULTATION_TIME_SUCCESS,
                payload: null
            })
            dispatch({
                type: CONSULTATION_FEE_SUCCESS,
                payload: null
            })
        }
    }, [location])



    useEffect(() => {
        if (statusChanged) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
        }
        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, statusChanged])

    // if (loading) { //loading spinner
    //     return <Box sx={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         height: '81vh',
    //     }}>
    //         <CircularProgress />
    //     </Box>

    // }

    return (
        <>
            <Box
                sx={{
                    width: "99%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "10px", xs: "10px" },
                    marginBottom: "40px",
                }}
            >
                <Stack spacing={2.6} direction="row" style={{
                    background: "#d8dee1",
                    padding: "3px 3px 3px .1px",
                    display: "flex",
                    alignItems: "center",

                }} borderRadius={2}>
                    <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={(value) => searchDoctor(value)} />
                    {/* <CustomButton
                        variant={"contained"}
                        text={"Filter"}
                        Icon={FilterListIcon}
                    /> */}
                    <CustomButton
                        submit={navigateToAddPage}
                        ComponentIcon={AddIcon}
                        variant={"contained"}

                    />
                </Stack>
            </Box>
            <Paper>
                <Fragment>

                    <DataTables
                        columns={columns}
                        row={datas}
                        pagination={true}
                    />
                </Fragment>
            </Paper>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Are you sure ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={() => handleClose('yes')} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Docter