import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Divider, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import CustomFormInput from "../../../../components/Widgets/CustomFormInput";
import CustomButton from "../../../../components/Widgets/CustomButton/CustomButton";
import { Cancelbooking } from "../../../../Redux/actions/serviceAction";
import { CancelbookingProcedure } from "../../../../Redux/actions/procedureAction";
import { cancelAppointment } from "../../../../Redux/actions/appointmentAction";

const CancelBookingModal = ({ handleClose, handleOpen, id, procedure, service, mode }) => {


  const dispatch = useDispatch()

  const schema = object().shape({
    remark:yup.string().max(100).required("Remarks is required"),

  });




  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),

    // defaultValues: {

    //     localImage: false
    // }
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "none",
    p: 2.5,
  };

  const clearClose = () => {
    setValue("remark", "")
    handleClose()
  }

  const SubmitDate = (data) => {

    const val = {
      booking_id: id,
      remark:data?.remark
    }

    if (procedure === "procedure") {
      dispatch(CancelbookingProcedure(val))
    } else if (mode === "appointment" || mode === 'leave') {
      dispatch(cancelAppointment(val))
    } else {
      dispatch(Cancelbooking(val))
    }
  }


  return (
    <Modal
      open={handleOpen}
      onClose={clearClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          spacing={5}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ color: "#047BC1" }}
          >
            CANCEL
          </Typography>
          <ClearIcon
            style={{
              cursor: "pointer",
              color: "black"
            }}
            onClick={() => clearClose()}
          />
        </Stack>
        <Divider />
        <Box display={"flex"} gap={"10px"} flexDirection={"column"} paddingTop={"20px"}>
          <CustomFormInput
           
            maxrow="4"
            multiline
            fieldLabel="Remarks"
            control={control}
            error={errors.remark}
            fieldName="remark"
            type={"textarea"}
            background="#FFFFFF"
          />

          <Box display={"flex"} paddingTop={"10px"} justifyContent={"center"}>
            <CustomButton text={"Submit"} variant={"contained"} submit={handleSubmit(SubmitDate)} />
          </Box>

        </Box>
      </Box>
    </Modal>
  )
}

export default CancelBookingModal