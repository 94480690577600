export const CREATE_SETTINGS_SUCCESS = "CREATE_SETTINGS_SUCCES"
export const CREATE_SETTINGS_FAIL = "CREATE_SETTINGS_FAIL"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL"
export const EDIT_SETTINGS_SUCCESS = "EDIT_SETTINGS_SUCCESS"
export const EDIT_SETTINGS_FAIL = "EDIT_SETTINGS_FAIL"

export const CREATE_SLIDER_SUCCESS = "CREATE_SLIDER_SUCCES"
export const CREATE_SLIDER_FAIL = "CREATE_SLIDER_FAIL"
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS"
export const GET_SLIDER_FAIL = "GET_SLIDER_FAIL"
export const DEL_SLIDER_SUCCESS = "DEL_SLIDER_SUCCESS"
export const DEL_SLIDER_FAIL = "DEL_SLIDER_FAIL"
export const EDIT_SLIDER_SUCCESS = "EDIT_SLIDER_SUCCESS"
export const EDIT_SLIDER_FAIL = "EDIT_SLIDER_FAIL"
export const STATUS_SLIDER_SUCCESS = "STATUS_SLIDER_SUCCESS"
export const STATUS_SLIDER_FAIL = "STATUS_SLIDER_FAIL"
export const CREATE_DOC_GROUP_SUCCESS = 'CREATE_DOC_GROUP_SUCCESS'
export const CREATE_DOC_GROUP_FAIL = 'CREATE_DOC_GROUP_FAIL'
export const GET_GROUP_SUCCESS ='GET_GROUP_SUCCESS'
export const GET_GROUP_FAIL='GET_GROUP_FAIL'
export const DEL_GROUP_SUCCESS = 'DEL_GROUP_SUCCESS'
export const DEL_GROUP_FAIL = 'DEL_GROUP_FAIL'