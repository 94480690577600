
import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Container } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from "../../components/Widgets/Backbutton";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import CustomFormInput from "../../components/Widgets/CustomFormInput";
import CustomSelectInput from "../../components/Widgets/CustomSelect";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import CustomCalender from "../../components/Widgets/CustomCalender";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { createPatient, EditPatientsdata, getSingleList } from "../../Redux/actions/patientAction";
import moment from 'moment';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getDepartmentListByStatus, getDeptCategory } from "../../Redux/actions/departmentAction";
import CustomMultipleSelectInput from "../../components/Widgets/CustomMultipleSelect";
const EditPatient = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams();
    const { CategoryList, deptListBySatatus } = useSelector(state => state.department);

    const { loading } = useSelector(state => state.auth)
    const { patientedited, error, patientSingleList } = useSelector(state => state.patient);

    const schema = object().shape({
        name: yup.string().max(50).required("Name is required"),
        mobile: yup.number()
            .typeError("That doesn't look like a phone number")
            .positive("A phone number can't start with a minus")
            .integer("A phone number can't include a decimal point")
            .min(8)
            .required('A phone number is required'),
        dob: yup.string().required("DOB is Required"),
        address: yup.string().required("Address is Required"),
        email: yup.string().email().required("Email is Required"),
        gender: yup.string().required("gender is Required"),
        height: yup.string().required("height is Required"),
        weight: yup.string().required("Weight is Required"),
        bloodgroup: yup.string().required("blood Group is Required"),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues: { role: "patient", id: id, category: null },
        resolver: yupResolver(schema)
    });


    const [postArray, setPostArray] = React.useState([]);
    const [Date, setDate] = React.useState(null);
    const [valuegender, setvaluegender] = React.useState("");
    const [valuebloodgroup, setvaluebloodgroup] = React.useState("");
    const [multpleArray, setMultipleArray] = React.useState([]);

    useEffect(() => {
        if (patientSingleList) {
            setDate(moment(patientSingleList?.dob, "DD-MM-YYYY"))
        }
    }, [patientSingleList])


    console.log({ patientSingleList });

    const handleChange = (newValue) => {
        let date = moment(newValue).format('DD-MM-YYYY');
        setValue("dob", date);
        setDate(newValue);
    };

    const handlechangegender = (event) => {
        setvaluegender(event.target.value)
        setValue("gender", event.target.value)

    }
    const handlechanBloodgroup = (event) => {
        setValue("bloodgroup", event.target.value)
        setvaluebloodgroup(event.target.value)

    }
    const submit = (data) => {
        // Create the category payload with the required structure
        console.log(postArray);
        const categoryPayload = postArray.map(category => ({
            id: category?.id,
            name: category?.name
        }));
        console.log({ categoryPayload });
        // Update the 'category' property in the 'data' object
        data.category = categoryPayload;

        // Dispatch the action with the updated 'data'
        dispatch(EditPatientsdata(data));
    };


    // const submit = (data) => {
    //     console.log({ data });
    //     dispatch(EditPatientsdata(data));
    // };

    const onChangeMultiple = (event) => {

        const {
            target: { value },
        } = event;

        const values = event.target.value


        let find = deptListBySatatus?.filter((res, I) => event.target.value.includes(res?._id))
        let data = find?.map((res) => ({
            id: res?._id,
            name: res?.name

        }))
        if (data) {
            setValue('category', data)
        }

        setPostArray(data)
        setMultipleArray(
            values
        );

    }

    useEffect(() => {
        if (patientSingleList) {
            // Assuming the patientSingleList contains an array of categories with 'name' and 'id'

            const categories = patientSingleList?.category || [];

            // Map the categories to the format you need in postArray
            const updatedPostArray = categories?.map(category => ({
                id: category?.id,
                name: category?.name
            }));
            // Set the updated postArray
            setPostArray(updatedPostArray);

            // Set other form values if needed
            setValue("name", patientSingleList?.name);
            setValue("id", patientSingleList?._id);
            // Set other form values as required

            // Set Date field if needed
            setDate(moment(patientSingleList?.dob, "DD-MM-YYYY"));
        }
    }, [patientSingleList]);

    useEffect(() => {
        if (id) {
            dispatch(getSingleList(id))
            // setMultipleArray([initialSelectedCategories])
        }
    }, [id])



    useEffect(() => {
        if (patientedited) {
            toast.success("Patient Updated Successfully");
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
            reset()
        }

        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })

        }
    }, [patientedited, error])


    useEffect(() => {
        if (patientSingleList) {
            let category = patientSingleList?.category?.map(cat => cat?.id) || []; // Use an empty array if category is not defined

            setMultipleArray(category);

            reset({
                ...patientSingleList,
                category
            });
            setValue("id", patientSingleList?._id);
            setValue("category", category);
            setValue('height', patientSingleList?.height ? patientSingleList?.height : "")
            setValue('weight', patientSingleList?.weight ? patientSingleList?.weight : "")
            setValue('address', patientSingleList?.address ? patientSingleList?.address : "")
        }
    }, [patientSingleList]);

    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList?.find((a) => a?.name === "patients");
            dispatch(getDepartmentListByStatus(find?._id))
        }
    }, [CategoryList])



    useEffect(() => {
        dispatch(getDeptCategory())
    }, [])

    return (
        <>
            <Box style={{ paddingBottom: "30px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >Edit Patient
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={2} px={3}>
                <Grid item xs={12} md={3} >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Patient Name "
                        control={control}
                        error={errors.name}
                        fieldName="name"
                        type={"text"}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Mobile Number "
                        control={control}
                        error={errors.mobile}
                        fieldName="mobile"
                        type={"text"}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Email Address"
                        control={control}
                        error={errors.email}
                        fieldName="email"
                        type={"text"}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <CustomCalender
                        control={control}
                        error={errors.dob}
                        fieldName="dob"
                        fieldLabel="DOB"
                        value={Date}
                        onChangeValue={handleChange}
                        inputFormat={"DD-MM-YYYY"}
                        background="#e7f5f7"
                        height="40px"
                    />

                </Grid>
                <Grid item xs={12} md={3} >
                    <CustomSelectInput
                        control={control}
                        error={errors.gender}
                        fieldName="gender"
                        fieldLabel="Select gender"
                        background="#e7f5f7"
                        height="40px"
                        size="16px"
                        value={valuegender}
                        onChangeValue={handlechangegender}
                    // options={categoryArray}
                    >
                        <MenuItem value="" disabled>
                            <em>{patientSingleList?.gender}</em>
                        </MenuItem>
                        {["Male", "Female", "Others"].map((status) => (
                            <MenuItem key={status} value={status} >
                                {status}
                            </MenuItem>
                        ))}

                    </CustomSelectInput>
                </Grid>
                <Grid item xs={12} md={3} >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Height "
                        control={control}
                        error={errors.height}
                        fieldName="height"
                        type={"text"}
                        defaultValue={''}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Weight"
                        control={control}
                        error={errors.weight}
                        fieldName="weight"
                        type={"text"}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={12} md={3} >
                    <CustomSelectInput
                        control={control}
                        error={errors.bloodgroup}
                        fieldName="bloodgroup"
                        fieldLabel="Blood Group"
                        background="#e7f5f7"
                        height="40px"
                        size="16px"
                        defaultValue={""}
                        value={valuebloodgroup}

                        onChangeValue={handlechanBloodgroup}
                    // options={categoryArray}
                    >
                        <MenuItem value="" disabled>
                            <em>{patientSingleList?.bloodgroup}</em>
                        </MenuItem>
                        {["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"].map((status) => (
                            <MenuItem key={status} value={status} >
                                {status}
                            </MenuItem>
                        ))}

                    </CustomSelectInput>
                </Grid>

                <Grid item xs={12} md={3} >
                    <CustomMultipleSelectInput
                        multiple={true}
                        control={control}
                        error={errors.category}
                        fieldName="category"
                        fieldLabel="Select Category"
                        background="#FFFFFF"
                        multiline
                        height="40px"
                        value={multpleArray}
                        size="15px"
                        onChangeValue={onChangeMultiple}
                    >
                        <MenuItem value="" disabled>
                            <em>Select Category</em>
                        </MenuItem>
                        {deptListBySatatus?.map((cat) => (
                            <MenuItem key={cat?._id} value={cat?._id} >
                                {cat?.name}
                            </MenuItem>
                        ))}
                    </CustomMultipleSelectInput>
                </Grid>
                <Grid item xs={6} md={6}   >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Address "
                        control={control}
                        error={errors.address}
                        fieldName="address"
                        type={"text"}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
            </Grid>
            <Box style={{ display: "flex", justifyContent: "center", paddingTop: "100px" }}>
                <CustomButton disabled={loading} variant={"contained"} text={loading ? <CircularProgress /> : "Submit"} submit={handleSubmit(submit)} />
            </Box>

        </>
    )
}

export default EditPatient