import { Grid, Typography, Box } from '@mui/material'
import React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CunsultationType = ({ label, onchange, onclick }) => {
    return (
        <>

            <FormGroup


            >
                <Box px={1} py={1} display={'flex'} alignItems={'center'} bgcolor={'#e6f4f7'} justifyContent={'space-between'} borderRadius={5}>
                    <Box></Box>
                    <Typography>{label}</Typography>
                    <FormControlLabel control={<Checkbox onChange={onchange} name={label} />} />
                </Box>
            </FormGroup>


        </>

    )
}

export default CunsultationType 