import {
    CREATE_DEPT_FAILED,
    CREATE_DEPT_SUCCESS,
    FILTER_DEPT_FAIL,
    FILTER_DEPT_SUCCESS,
    GET_CATEGORY_FAILED,
    GET_CATEGORY_SUCCESS,
    GET_DEPT_DETAIL_FAIL,
    GET_DEPT_DETAIL_SUCCESS,
    GET_DEPT_FAIL,
    GET_DEPT_SUCCESS,
    GET_LIST_STATUS_FAIL,
    GET_LIST_STATUS_SUCCESS,
    UPDATE_DEPT_FAIL,
    UPDATE_DEPT_SUCCESS
} from "../constants/departmentConstants";

import { LOADING, RESET_ERROR } from "../constants/userConstants";


export const departmentReducer = (state = { error: null }, action) => {

    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                deptCreated: null,
                DeptEdited: null
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                CategoryList: action?.payload,

            }

        case GET_CATEGORY_FAILED:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_DEPT_SUCCESS:
        
            return {
                ...state,
                deptList: state?.deptList ?  [...state?.deptList,action.payload]: action.payload,
                deptCreated: true,
            }

        case CREATE_DEPT_FAILED:
            return {
                ...state,
                error: action.payload
            }

        case GET_DEPT_SUCCESS:
            return {
                ...state,
                deptList: action.payload,

            }

        case GET_DEPT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_DEPT_DETAIL_SUCCESS:
            return {
                ...state,
                deptSingleList: action.payload
            }

        case GET_DEPT_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case UPDATE_DEPT_SUCCESS:
            return {
                ...state,
                deptList: state?.deptList.map(Dept => Dept._id === action.payload._id ? action.payload : Dept),
                DeptEdited: true
            }
        case UPDATE_DEPT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_LIST_STATUS_SUCCESS:
            return {
                ...state,
                deptListBySatatus: action.payload
            }
        case GET_LIST_STATUS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case FILTER_DEPT_SUCCESS:
            return{
                ...state,
                deptList:action.payload,

            }  
        case FILTER_DEPT_FAIL:
            return{
                ...state,
                error:action.payload
            }      
        default:
            return state;

    }

}