import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import customAxios from '../customAxios';
import { API_URL } from './constants';
import { CleaningServices } from '@mui/icons-material';


const firebaseConfig = {
  apiKey: "AIzaSyDvMTs4oDLvxP6D63-Qx60brwTjQMWCrt8",
  authDomain: "mcare-d985d.firebaseapp.com",
  projectId: "mcare-d985d",
  storageBucket: "mcare-d985d.appspot.com",
  messagingSenderId: "1015851660184",
  appId: "1:1015851660184:web:62fb1a99d7f9e98ccef1ed",
  measurementId: "G-5Y4B0B0YD8"
};


initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: "BAdU1ZFoRzNonFBDIA1nCUPMgy6QTA0IqlCU_1C2SSByqUaKEk268N76MKMsHWJCKGwbRuicu7E3xtpfg7BoPcA" })
    .then(async (currentToken) => {
      let user = JSON.parse(localStorage.getItem("user"))
      if (currentToken && user?._id) {

        console.log('current token for client: ', currentToken, user);
        let data = {
          id: user?._id,
          token: currentToken
        }
        localStorage.setItem("webtocken", currentToken)
        await customAxios.post(`auth/addwebtoken`, data)
          .then(async response => {
          })
          .catch(async error => {
          })
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });