export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS'
export const GET_NOTIFICATION_LIST_FAIL = 'GET_NOTIFICATION_LIST_FAIL'

export const GET_DOCTOR_NOTIFICATION_LIST_SUCCESS = 'GET_DOCTOR_NOTIFICATION_LIST_SUCCESS'
export const GET_DOCTOR_NOTIFICATION_LIST_FAIL = 'GET_DOCTOR_NOTIFICATION_LIST_FAIL'

export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL'

export const NOTY_TYPES = 'NOTY_TYPES'
export const NOTY_TYPESS = 'NOTY_TYPESS'




