import { Box, Grid, MenuItem, Stack, Typography, TextField, IconButton, Select } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import Backbutton from '../../../components/Widgets/Backbutton'
import { useForm } from "react-hook-form";
import moment from 'moment'
import { PDFURL } from '../../../config/constants';

const ViewModal = () => {
    const location = useLocation();
    const consultationData = location?.state?.consultation;
    console.log({ consultationData }, "haiii")
    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues: {},

    });
    ;
    return (
        <Box>
            <Stack
                direction={"row"}
                spacing={1}
                style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}
            >
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    > Patient ID: {consultationData?.patient?.user_id}
                    </Typography>


                </Box>

            </Stack>

            <Grid container spacing={2} px={5} mt={2}>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Name</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.name}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Mobile Number</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.mobile}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>DOB</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.dob}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Gender</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.gender}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Appointment Type</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.appointmenttype}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Booked Date & Time</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{moment(`${consultationData?.date} ${consultationData?.time}`, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY hh:mm A")}</Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Amount</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.amount_payable}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Payment Status</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.payment_status}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Consultation Summary</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "133px",
                            background: "#f6f6f6",
                            color: "black",
                            overflow: 'auto',
                            paddingLeft: "10px",

                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationData?.consultation_summary}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Attachment</Typography>
                    <div style={{ overflowY: "auto", maxHeight: "133px" }}>
                    <div style={{ marginTop: "3px", borderRadius: "10px", background: "#f6f6f6", color: "black", padding: "10px", display: "flex", minHeight: "130px"}}>
                        <div style={{ flex: 1 }}>
                            <Typography sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,
                                },
                                marginBottom: "5px",
                                fontWeight: "bold",
                            }}>Procedures</Typography>
                             
                            {consultationData?.procedure_reports?.map((procedure) => (
                                <div key={procedure?._id}>
                                    {procedure?.procedures_id?.map((item) => {
                                        const pdfURL = procedure?.attachment;

                                        return (
                                            <div key={item?.id}>
                                                <a
                                                    href={`${PDFURL}${pdfURL}`}
                                                    target="_blank"
                                                    style={{ textDecoration: "underline", display: "block" }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                md: 14,
                                                                sm: 13,
                                                                xs: 12,
                                                            },
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        {item?.name}
                                                    </Typography>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                            {consultationData?.procedure_booking?.map((booking) => (
                                <div key={booking?.booking_id}>
                                    {booking?.procedure_id?.map((procedure) => {
                                        const hasReport = consultationData?.procedure_reports?.some((report) =>
                                            report?.procedures_id?.some((reportProcedure) =>
                                                reportProcedure?.id === procedure?.id
                                            )
                                        );

                                        if (!hasReport) {
                                            return (
                                                <div key={procedure?.id}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                md: 14,
                                                                sm: 13,
                                                                xs: 12,
                                                            },
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        {procedure?.name}
                                                    </Typography>
                                                </div>
                                            );
                                        }

                                        return null;
                                    })}
                                </div>
                            ))}
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography
                                sx={{
                                    fontSize: {
                                        md: 14,
                                        sm: 13,
                                        xs: 12,
                                    },
                                    marginBottom: "5px",
                                    fontWeight: "bold",
                                }}
                            >
                                Services
                            </Typography>
                        
                            {consultationData?.service_booking?.map((service) => {
                                return (
                                    <div key={service?._id}>
                                        {service?.service_id?.map((item) => {
                                            const reportWithAttachment = consultationData?.service_reports.filter(
                                                (report) =>
                                                    report?.booking_id === service?._id &&
                                                    report?.service_id?.some((serviceInReport) => serviceInReport?.id === item?.id)
                                            );


                                            if (reportWithAttachment?.length === 0) {
                                                return (
                                                    <div key={item?.id}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: {
                                                                    md: 14,
                                                                    sm: 13,
                                                                    xs: 12,
                                                                },
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            {item?.name}
                                                        </Typography>
                                                    </div>
                                                );
                                            }

                                            return (
                                                <div key={item?.id}>
                                                    {reportWithAttachment?.map((report) => {
                                                        const pdfURL = report?.attachment;
                                                        return (
                                                            <a
                                                                key={report?._id}
                                                                href={`${PDFURL}${pdfURL}`}
                                                                target="_blank"
                                                                style={{ textDecoration: "underline", display: "block" }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: {
                                                                            md: 14,
                                                                            sm: 13,
                                                                            xs: 12,
                                                                        },
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    {item?.name}
                                                                </Typography>
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    </div>
                </Grid>
            </Grid>
            <Box display={"flex"} paddingTop={"60px"} gap={"10px"} px={5}>
                <Typography sx={{
                    fontSize: {
                        md: 18,
                        sm: 14,
                        xs: 14,
                    },
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                    letterSpacing: "1px"

                }}>Status</Typography> :  <Typography sx={{
                    fontSize: {
                        md: 18,
                        sm: 14,
                        xs: 14,
                    },
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                    letterSpacing: "1px"
                }}>{consultationData?.status}</Typography>
            </Box>
        </Box>

    )
}

export default ViewModal