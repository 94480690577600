import styled from "styled-components";

export const DIV = styled.div`
  display: flex;
  height:100vh;
  .nav {
    display: flex;
    flex-direction: column;
  }
  .outlet{
    margin-left: 16.4vw;
    margin-top:80px;
   
    width: 81.5vw;

    @media (max-width: 980px) {
        margin-left: unset;
        width: 100vw;
      }
  }
`;
