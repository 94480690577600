import axios from "../../customAxios"
import { PROFILE_FAILED, PROFILE_SUCCESS, PROFILE_UPDATE_FAILED, PROFILE_UPDATE_SUCCESS } from "../constants/profileConstants"
import {
    LOADING, LOGIN_FAILED, LOGIN_SUCCESS
} from "../constants/userConstants"

export const getProfile = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/auth/admin-profile/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: PROFILE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: PROFILE_FAILED,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const updateProfile = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/auth/update-admin`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type:PROFILE_UPDATE_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,  
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: PROFILE_UPDATE_FAILED,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
