import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from "../../../components/Widgets/Backbutton";
import { Typography } from "@mui/material";
import CustomSelectInput from "../../../components/Widgets/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomFormInput from "../../../components/Widgets/CustomFormInput";
import CustomSelectSearch from "../../../components/Widgets/CustomSelectSearch/CustomSelectSearch";
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";
import moment from "moment/moment";
import { LOADING, RESET_ERROR } from "../../../Redux/constants/userConstants";
import { toast } from 'react-toastify';
import CustomCalender from "../../../components/Widgets/CustomCalender";
import { useNavigate, useParams } from 'react-router-dom';
import { getDepartmentListByStatus, getDeptCategory } from "../../../Redux/actions/departmentAction";
import { getprocedureSingleListView, postEditProcedureBooking, postProcedureBooking, postProcedureReport, postProcedureTime, SearchServiceByDeptProcedure } from "../../../Redux/actions/procedureAction";
import { PostPatientSearch, postServiceTime } from "../../../Redux/actions/serviceAction";
import CustomMultipleSelectInput from "../../../components/Widgets/CustomMultipleSelect";
import CustomCheckBox from "../../../components/Widgets/CustomCheckBox";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
const ProcedureBooking = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const navigate = useNavigate()

    const { CategoryList, deptListBySatatus, } = useSelector(state => state.department);
    const { procedureServiceList, procedureTimeList, procedurebookingCreated, procedureSingleList, error, editprocedureBooking, procedureReport } = useSelector(state => state.procedure);
    const { patientSearchList } = useSelector(state => state.service);
    const { loading } = useSelector(state => state.auth);


    const schema = object().shape({
        // patient: yup.string().max(100).required("Search Patient is required"),
        department: yup.string().max(100).required("Department is required"),
        // service: yup.string().max(100).required("service is required"),
        refferdby: yup.string().max(100).required('Referred By is required'),
        time: yup.string().required('Time is required').nullable(),
        //date: yup.string().required('Date is required'),
        paymentmethod: yup.string().max(100).required('Payment Method is required').typeError("Payment Method is required"),
        paymentstatus: yup.string().max(100).required('Payment Status is required'),
        // transactionref: yup.string().when('paymentmethod', {
        //     is: (paymentMethod) => paymentMethod !== "Cash",
        //     then: yup.string().max(100).required('Transaction Id is required').typeError('Transaction Id is required'),
        //     otherwise: yup.string().max(100).nullable(), // Allow null for "Cash" payment method.
        // }),

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError,
    } = useForm({
        resolver: yupResolver(schema)
    });


    const [patientArray, setPatientArray] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [datas, setData] = useState('')
    const [selectDepartment, setSelectDepartment] = useState(null)
    const [selectService, setselectService] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')
    const [dateTime, setDateTime] = useState('')
    const [time, setTime] = useState(null)
    const [bookingstatus, setbookingstatus] = useState(null)
    const [multpleArray, setMultipleArray] = React.useState([]);
    const [ServiceArray, setServiceArray] = React.useState([]);
    const [postArray, setPostArray] = React.useState([]);
    const [groupable, setGroupable] = useState(true)
    const [procedureArray, setSetProcedureArray] = useState([])
    const [pdf, setPdf] = useState(null)



    const PatientSearch = (value, newvalue) => {
        setData(newvalue)

    }

    const ChangeDepartment = (event) => {
        setTime(null)
        setValue('time', null)
        setDateTime(null)


        const value = {
            department_id: event.target.value
        }
        dispatch(SearchServiceByDeptProcedure(value))
        setSelectDepartment(event.target.value)
        setValue("department", event.target.value)
        setError('department', { message: "" })
    }

    const onChangeService = (e) => {

        setselectService(e.target.value)
        setValue("service", e.target.value)

        let find = procedureServiceList?.filter((res) => res?._id === e.target.value)
        setValue("amount", find?.[0]?.price)
    }



    const onChangeTime = (e) => {
        setTime(e.target.value)
        setValue('time', e.target.value)
        setError('time', { message: "" })

    }

    const onChangeDateNtime = (value) => {
        setTime(null)
        setValue('time', null)
        setDateTime(value)
        // setError('date', "")
        let procedureid = postArray?.[0]
        if (value) {
            const val = {
                date: moment(value).format('DD-MM-YYYY'),
                procedure_id: procedureid
            }
            dispatch(postProcedureTime(val))
        }
    }

    const PatientOnchangeInput = (event, newInputValue) => {
        setInputValue(newInputValue)
        if (inputValue.length >= 1) {
            const val = {
                key: inputValue
            }
            dispatch(PostPatientSearch(val))
        }
    }


    //grouping function....................................
    const groupCheck = (e) => {

        const { checked } = e.target;
        setValue("amount", '')
        setTime(null)
        setValue('time', null)
        setDateTime(null)
        if (checked) {
            setGroupable(true)
            let groups = procedureServiceList.filter(res => res?.groupable === true)
            setServiceArray(groups)

        } else {
            setGroupable(false)
            let groups = procedureServiceList.filter(res => res?.groupable === false)
            setServiceArray(groups)
        }
        setPostArray([])
        setMultipleArray([])

    }


    //MULTI SELECT FEILD.................................................................
    const onChangeMultiple = (event) => {
        const {
            target: { value },
        } = event;

        const values = event.target.value

        if (groupable === false) {
            let amount = procedureServiceList?.filter((res) => res?._id === event.target.value)
            setValue("amount", amount[0]?.price)

        } else {
            let sum = procedureServiceList?.filter((res, I) => event.target.value.includes(res._id))
            let amount = sum?.reduce((p, s) => parseInt(p) + parseInt(s?.price), 0)
            setValue("amount", amount)


        }

        let find = procedureServiceList?.filter((res, I) => event.target.value.includes(res._id))
        let data = find?.map((res) => (

            res?._id

        ))
        setPostArray(data)
        setMultipleArray(
            values
        );

    }



    //******************************************CHECK SERVICE LIST******************************************************** */
    const onChangeCheckbox = (e, index) => {
        const { value, checked } = e.target;

        if (checked) {
            let find = procedureSingleList?.procedure_id.filter((res) => res?.id.includes(value))?.map((res) => (
                res?.id
            ))
            setSetProcedureArray([...procedureArray, ...find])
        } else {
            let array = procedureArray
            let filter = array.filter((res) => res !== value)
            setSetProcedureArray([])
            setSetProcedureArray([...filter])

        }

    }

    //******************************************PDF UPLODER******************************************************* */
    const onChangePdf = (e) => {
        setPdf(e.target.files[0])

    }


    //****************************************** SUBMITFORM SERVICE REPORT*******************************************************/
    const SubmitformAccordin = () => {
        const formData = new FormData();
        formData.append('booking_id', id);
        formData.append('department_id', procedureSingleList?.department_id);
        formData.append('procedure_id', JSON.stringify(procedureArray));
        formData.append('attachment', pdf)
        dispatch(postProcedureReport(formData))
    }



    //*****************************************FILTER GROUPABLE***************************************************** */

    useEffect(() => {
        if (procedureServiceList && groupable) {
            let groups = procedureServiceList.filter(res => res?.groupable === groupable)
            setServiceArray(groups)

        }
    }, [procedureServiceList])



    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList?.find((a) => a?.name === "Procedures");
            dispatch(getDepartmentListByStatus(find?._id))
        }
    }, [CategoryList])

    useEffect(() => {
        if (!id) {
            setValue('name', "")
            setValue('gender', "")
            setValue('mobile', "")
            setValue('dob', "")
            setValue('user_id', "")
            setValue('amount', "")
            setValue("payment_method", "")
            setValue("payment_status", "")
            setSelectDepartment("")
            setselectService("")
            setPaymentMethod("")
            setPaymentStatus("")
            setValue('transactionref', "")
            setTime(null)
            setValue('time', "")
            setValue('date', "")
            setDateTime("")
            setValue('refferdby', "")
        }
    }, [])



    useEffect(() => {
        if (patientSearchList) {
            setPatientArray([...patientSearchList])
        }
        if (datas) {
            setValue('name', datas?.name)
            setValue('user_id', `#MRN${datas?.user_id}`)
            setValue('gender', datas?.gender)
            setValue('mobile', datas?.mobile)
            setValue('dob', datas?.dob)
        }

        if (inputValue.length === 0) {
            setValue('name', "")
            setValue('gender', "")
            setValue('mobile', "")
            setValue('dob', "")
            setValue('user_id', "")
        }

    }, [patientSearchList, datas, inputValue])




    /*******************************EDIT RESPONSE********************************* */
    useEffect(() => {


        if (id && procedureSingleList) {
            setValue('name', procedureSingleList?.patient?.[0]?.name)
            setValue('gender', procedureSingleList?.patient?.[0]?.gender)
            setValue('mobile', procedureSingleList?.patient?.[0]?.mobile)
            setValue('dob', procedureSingleList?.patient?.[0]?.dob)
            setValue('user_id', procedureSingleList?.patient?.[0]?.user_id)
            setValue('amount', procedureSingleList?.amount)
            setValue("paymentmethod", procedureSingleList?.payment_method)
            setValue("paymentstatus", procedureSingleList?.payment_status)
            setValue("department", procedureSingleList?.department_id)
            setSelectDepartment(procedureSingleList?.department_id)
            // setValue('service', procedureSingleList?.procedure_id)
            // Uncomment this code to set the 'service' field.
            setValue("service", procedureSingleList?.procedure_id);

            setselectService(procedureSingleList?.procedure_id);
            setPaymentMethod(procedureSingleList?.payment_method)
            setPaymentStatus(procedureSingleList?.payment_status)
            setbookingstatus(procedureSingleList?.status)
            setValue('transactionref', procedureSingleList?.transactionref)
            setValue('refferdby', procedureSingleList?.refferd_by)
            setValue('time', procedureSingleList?.time)

            const value = {
                department_id: procedureSingleList?.department_id
            }
            dispatch(SearchServiceByDeptProcedure(value))

        }

    }, [procedureSingleList])

    const onChangepaymentMethod = (e) => {
        setValue("paymentmethod", e.target.value);
        setPaymentMethod(e.target.value)
        setError('paymentmethod', { message: "" })
    }


    const onChangepaymentStatus = (e) => {
        setPaymentStatus(e.target.value)
        setValue("paymentstatus", e.target.value);
        setError('paymentstatus', { message: "" })
    }
    const onChangebookingStatus = (e) => {
        setbookingstatus(e.target.value)

        // setValue("paymentstatus", e.target.value);
    }
    const Submit = (DATA) => {
        if (id && procedureSingleList) {
            let val = {
                id: id,
                patient_id: procedureSingleList?.patient_id,
                department_id: selectDepartment,
                // procedure_id: DATA?.service,
                amount: DATA?.amount,
                payment_method: paymentMethod,
                payment_status: paymentStatus,
                refferd_by: DATA?.refferdby,
                transactionref: DATA?.transactionref,

                status: bookingstatus
            }
            dispatch(postEditProcedureBooking(val))
        } else {
            let val = {
                patient_id: datas?._id,
                department_id: selectDepartment,
                procedure_id: postArray,
                // type:
                amount: DATA?.amount,
                date: moment(dateTime).format("YYYY-MM-DD"),
                time: DATA?.time,
                payment_method: paymentMethod,
                payment_status: paymentStatus,
                refferd_by: DATA?.refferdby,
                transactionref: DATA?.transactionref
            }


            dispatch(postProcedureBooking(val))

        }

    }

    useEffect(() => {
        if (procedurebookingCreated) {
            toast.success(" Booking Created Successfully");
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }
        if (procedureReport) {
            toast.success("Report Created Successfully");

            dispatch({
                type: RESET_ERROR
            })
        }

        if (editprocedureBooking) {
            toast.success(" Booking Updated Successfully");
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [procedurebookingCreated, error, editprocedureBooking, procedureReport])
    useEffect(() => {
        if (id) {
            dispatch(getprocedureSingleListView(id))
        }
        dispatch(getDeptCategory())
    }, [])


    console.log({ procedureTimeList });

    return (
        <>
            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        {id ? "Edit Booking" : "Add Booking"}
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={2} px={5}>
                {!id ? <Grid item xs={12} md={4} lg={3}>
                    <CustomSelectSearch
                        control={control}
                        error={errors.Search_Patient}
                        fieldName="patient"
                        fieldLabel="Search Patient"
                        background="#FFFFFF"
                        height="40px"
                        size="16px"
                        options={patientArray}
                        getOptionLabel={({ name, user_id, mobile }) => `${name} ${user_id} ${mobile}`}
                        onChangeValue={PatientSearch}
                        inputValue={inputValue}
                        onInputChange={PatientOnchangeInput}
                    />

                </Grid> : ""}


                {id && (
                    <Grid item xs={12} lg={3}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"
                        }}>Booking ID</Typography>

                        <Typography style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }}>
                            {procedureSingleList?.booking_id}
                        </Typography>
                    </Grid>
                )}

                <Grid item xs={12} md={4} lg={3}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Name"
                        control={control}
                        error={errors.name}
                        fieldName="name"
                        type={"text"}
                        background='#e7f5f7'
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Mobile Number"
                        control={control}
                        error={errors.mobile}
                        fieldName="mobile"
                        type={"text"}
                        background="#e7f5f7"
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="DOB"
                        control={control}
                        error={errors.dob}
                        fieldName="dob"
                        type={"text"}
                        background="#e7f5f7"
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={id ? 3 : 3}>

                    <CustomFormInput
                        height="40px"
                        fieldLabel="Gender"
                        control={control}
                        error={errors.gender}
                        fieldName="gender"
                        type={"text"}
                        readOnly={true}
                        background="#e7f5f7"
                    />
                </Grid>


                <Grid item xs={12} md={4} lg={3}>

                    {id ?
                        <>
                            <Typography sx={{
                                fontSize: {
                                    md: 16,
                                    sm: 14,
                                    xs: 14,
                                },
                                fontFamily: "Quicksand",
                                fontWeight: "bold",
                                letterSpacing: "1px"

                            }}>Department</Typography>

                            <Typography style={{
                                marginTop: "3px",
                                borderRadius: "10px",
                                height: "40px",
                                background: "#e7f5f7",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "10px"
                            }} >{procedureSingleList?.department?.[0]?.name
                                }</Typography>
                        </>
                        :
                        <CustomSelectInput
                            control={control}
                            error={errors.department}
                            fieldName="department"
                            fieldLabel="Procedure Department"
                            background="#FFFFFF"
                            height="40px"
                            size="16px"
                            defaultValue={""}
                            value={selectDepartment}
                            onChangeValue={ChangeDepartment}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Department</em>
                            </MenuItem>
                            {deptListBySatatus?.map((doc) => (
                                <MenuItem key={doc?.name} value={doc?._id} >
                                    {doc?.name}
                                </MenuItem>
                            ))}
                        </CustomSelectInput>}

                </Grid>
                {!id ?
                    <Grid item xs={12} md={4} lg={3}>


                        <Box display={'flex'} alignItems={'flex-end'} justifyContent={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Typography>Groupable</Typography>
                                <CustomCheckBox changeRole={(e) => groupCheck(e)} checked={groupable ? true : false} />
                            </Box>
                        </Box>
                    </Grid> : ""}
                {!id ?
                    <Grid item xs={12} md={4} lg={3}>
                        <CustomMultipleSelectInput
                            readOnly={!id ? false : true}
                            control={control}
                            error={errors.service}
                            fieldName="service"
                            fieldLabel="Select Procedure"
                            background="#FFFFFF"
                            multiline
                            height="40px"
                            size="15px"
                            multiple={groupable ? true : false}
                            defaultValue={""}
                            value={multpleArray}
                            onChangeValue={onChangeMultiple}
                        >
                            <MenuItem value="" disabled >
                                <em>Select  Procedure</em>
                            </MenuItem>
                            {ServiceArray?.map((doc) => (
                                <MenuItem key={doc?.name} value={doc?._id} >
                                    {doc?.name}
                                </MenuItem>
                            ))}

                        </CustomMultipleSelectInput>

                    </Grid> : ""}
                {id ?
                    <Grid item xs={12} md={4} lg={3}>
                        {procedureSingleList?.procedure_id && Array.isArray(procedureSingleList?.procedure_id) ? (

                            <>
                                <Typography sx={{
                                    fontSize: {
                                        md: 16,
                                        sm: 14,
                                        xs: 14,
                                    },
                                    fontFamily: "Quicksand",
                                    fontWeight: "bold",
                                    letterSpacing: "1px"

                                }}>Procedure</Typography>

                                <Typography style={{
                                    marginTop: "3px",
                                    borderRadius: "10px",
                                    height: "40px",
                                    background: "#e7f5f7",
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }} >{procedureSingleList?.procedure_id?.map((res) => (
                                    <div style={{ display: "flex" }}>
                                        <span>{res?.name},</span>

                                    </div>
                                ))}</Typography>
                            </>

                        ) : (
                            ""
                        )}
                    </Grid> : ""}
                <Grid item xs={12} md={4} lg={3}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Referred By"
                        control={control}
                        error={errors.refferdby}
                        fieldName="refferdby"
                        type={"text"}
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Amount"
                        control={control}
                        error={errors.amount}
                        fieldName="amount"
                        type={"text"}
                        readOnly={true}
                        background="#e7f5f7"
                    />
                </Grid>
                {!id ? <Grid item xs={12} md={4} lg={3}>

                    <CustomCalender
                        height="40px"
                        minDateres={new Date()}
                        fieldLabel="Choose Date"
                        control={control}
                        error={errors.date}
                        fieldName="date"
                        type={"text"}
                        readOnly={true}
                        background="#FFFFFF"
                        value={dateTime}
                        onChangeValue={onChangeDateNtime}
                    />
                </Grid> : ""}
                {!id ? <Grid item xs={12} md={4} lg={3}>

                    <CustomSelectInput
                        control={control}
                        error={errors.time}
                        fieldName="time"
                        fieldLabel="Time"
                        background="#FFFFFF"
                        height="40px"
                        size="16px"
                        defaultValue={""}
                        onChangeValue={onChangeTime}
                        value={time}

                    >
                        <MenuItem value="" disabled >
                            <em>Select Time</em>
                        </MenuItem>
                        {procedureTimeList?.time?.filter(tm => tm?.patient_id === null).map((doc) => (
                            <MenuItem key={doc?.time} value={doc?.time} >
                                {moment(doc?.time, 'HH:mm').format('hh:mm A')}
                            </MenuItem>
                        ))}
                    </CustomSelectInput>
                </Grid> : ""}


                <Grid item xs={12} md={4} lg={3}>
                    {paymentMethod === "online" ? <CustomFormInput
                        height="40px"
                        fieldLabel="Payment Status"
                        control={control}
                        error={errors.paymentstatus}
                        fieldName="paymentstatus"
                        type={"text"}
                        readOnly={true}
                        background="#e7f5f7"
                    /> :
                        <CustomSelectInput
                            control={control}
                            error={errors.paymentstatus}
                            fieldName="paymentstatus"
                            fieldLabel="Payment Status"
                            background="#FFFFFF"
                            height="40px"
                            size="16px"
                            marginLeft="25px"
                            defaultValue={""}
                            value={paymentStatus}
                            onChangeValue={onChangepaymentStatus}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Payment Status</em>
                            </MenuItem>
                            {['Paid', "Pending"]?.map((doc) => (
                                <MenuItem key={doc} value={doc} >
                                    {doc}
                                </MenuItem>
                            ))}
                        </CustomSelectInput>}
                </Grid>


                <Grid item xs={12} md={4} lg={3}>
                    {paymentMethod === "online" ? <CustomFormInput
                        height="40px"
                        fieldLabel=" Payment Method"
                        control={control}
                        error={errors.paymentmethod}
                        fieldName="paymentmethod"
                        type={"text"}
                        readOnly={true}
                        background="#e7f5f7"
                    /> :
                        <CustomSelectInput
                            control={control}
                            error={errors.paymentmethod}
                            fieldName="paymentmethod"
                            fieldLabel="Payment Method"
                            background="#FFFFFF"
                            height="40px"
                            size="16px"
                            defaultValue={""}
                            value={paymentMethod}
                            onChangeValue={onChangepaymentMethod}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Payment Method</em>
                            </MenuItem>
                            {['UPI', 'Card', 'Cash']?.map((doc) => (
                                <MenuItem key={doc} value={doc} >
                                    {doc}
                                </MenuItem>
                            ))}
                        </CustomSelectInput>}
                </Grid>


                {paymentMethod === 'UPI' || paymentMethod === 'Card' ?
                    <Grid item xs={12} md={4} lg={3}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Transaction ID"
                            control={control}
                            error={errors.transactionref}
                            fieldName="transactionref"
                            type={"text"}
                            background="#FFFFFF"
                        />    </Grid> : ''}

                {id ? <Grid item xs={12} md={4} lg={3}>
                    <CustomSelectInput
                        control={control}
                        error={errors.bookingstatus}
                        fieldName="bookingstatus"
                        fieldLabel="Booking Status"
                        background="#FFFFFF"
                        height="40px"
                        size="16px"
                        defaultValue={""}
                        value={bookingstatus}
                        onChangeValue={onChangebookingStatus}
                    >
                        <MenuItem value="" disabled >
                            <em>Select Booking Search_Patienttatus</em>
                        </MenuItem>
                        {["booked", "Completed"]?.map((doc) => (
                            <MenuItem key={doc} value={doc} >
                                {doc}
                            </MenuItem>
                        ))}
                    </CustomSelectInput>
                </Grid> : ""}



            </Grid>




            {id && procedureSingleList ?
                <Box paddingTop={5} >
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<AddBoxRoundedIcon style={{ color: '#0681bf' }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography style={{ fontFamily: 'Quicksand', fontWeight: 'bold' }}>ADD</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'}>
                                <Box display={'flex'}>
                                    {Array.isArray(procedureSingleList?.procedure_id) && procedureSingleList?.procedure_id.map((res, index) => (
                                        <Box
                                            style={{ background: 'white', display: 'flex', alignItems: 'center', height: '40px', justifyContent: 'space-between', gap: "30px" }}
                                            borderRadius={3}
                                        >
                                            <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>{res?.name}</Typography>
                                            <CustomCheckBox changeRole={(e) => onChangeCheckbox(e, index)} value={res?.id} />
                                        </Box>
                                    ))}
                                </Box>
                                <Box width={'40%'} paddingY={5}>
                                    <Typography fontSize={'18px'} paddingBottom={1}>Upload Files</Typography>
                                    <TextField type={"file"} inputProps={{ accept: ".pdf,.png,.jpg,.jpeg" }} onChange={(file) =>
                                        onChangePdf(file)} />
                                </Box>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <CustomButton variant={'contained'} text={'SAVE'} submit={() => SubmitformAccordin()} />
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box > : ''
            }


            <Box display={"flex"} justifyContent={"center"} paddingTop={"60px"} paddingBottom={"30px"}>
                <CustomButton text={loading ? "Loading..." : "Submit"} disabled={loading ? true : false} variant={"contained"} submit={handleSubmit(Submit)} />
            </Box>

        </>
    )
}

export default ProcedureBooking