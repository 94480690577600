import axios from "../../customAxios"
import { CREATE_DOC_GROUP_FAIL, CREATE_DOC_GROUP_SUCCESS, CREATE_SETTINGS_FAIL, CREATE_SETTINGS_SUCCESS, CREATE_SLIDER_FAIL, CREATE_SLIDER_SUCCESS, DEL_GROUP_FAIL, DEL_GROUP_SUCCESS, DEL_SLIDER_FAIL, DEL_SLIDER_SUCCESS, EDIT_SETTINGS_FAIL, EDIT_SETTINGS_SUCCESS, EDIT_SLIDER_FAIL, EDIT_SLIDER_SUCCESS, GET_GROUP_FAIL, GET_GROUP_SUCCESS, GET_SETTINGS_FAIL, GET_SETTINGS_SUCCESS, GET_SLIDER_FAIL, GET_SLIDER_SUCCESS, STATUS_SLIDER_FAIL, STATUS_SLIDER_SUCCESS } from "../constants/settingsConstants"
import {
    LOADER,
    LOADING
} from "../constants/userConstants"

// export const postSettings = (data) => async (dispatch) => {
//     dispatch({
//         type: LOADING,
//         payload: true
//     })

//     await axios.post(`auth/login`, data)
//         .then(async response => {
//             console.log(response,"api response")
//             let data = response.data.user;

//             dispatch({
//                 type: LOGIN_SUCCESS,
//                 payload: data
//             });

//             dispatch({
//                 type: LOADING,
//                 payload: false
//             })


//         }).catch(async error => {
//             dispatch({
//                 type: LOGIN_FAILED,
//                 payload: error
//             })

//             dispatch({
//                 type: LOADING,
//                 payload: false
//             })
//         })
// }


export const postSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/create-slider`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: CREATE_SLIDER_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: CREATE_SLIDER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const getSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/sliders`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_SLIDER_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_SLIDER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const delSlider = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/slider/${id}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: DEL_SLIDER_SUCCESS,
                payload: id
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: DEL_SLIDER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const editSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-slider`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: EDIT_SLIDER_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: EDIT_SLIDER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const statusSlider = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/slider/${id}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: STATUS_SLIDER_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: STATUS_SLIDER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const postSettings = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/admin-setting`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: CREATE_SETTINGS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: CREATE_SETTINGS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const getSettings = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/admin-settings`)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: GET_SETTINGS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SETTINGS_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const editSettings = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-setting`, data)
        .then(async response => {

            let data = response.data.setting;
            console.log(response);
            console.log({ data });
            localStorage.setItem('changed_image', data?.logo)
            dispatch({
                type: EDIT_SETTINGS_SUCCESS,
                payload: data

            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: EDIT_SETTINGS_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const postGroups = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    // dispatch({
    //     type: LOADER,
    //     payload: true
    // })

    await axios.post(`admin/doctor/group/create`, data)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: CREATE_DOC_GROUP_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_DOC_GROUP_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getGroups = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/doctor/group/list`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_GROUP_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_GROUP_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const delGroups = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/doctor/group/delete/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: DEL_GROUP_SUCCESS,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: DEL_GROUP_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

