import React, { useEffect, useState } from 'react'
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import { Box, Paper, Stack } from '@mui/material';
import CustomSearch from '../../../components/Widgets/CustomSearch';
import AddIcon from "@mui/icons-material/Add";
import { Fragment } from 'react';
import DataTables from '../../../components/Widgets/dataTable';
import AddLeaveModal from './AddLeaveModal';
import customAxios from '../../../customAxios';

import { getAllDoctorLeave } from "../../../Redux/actions/doctorAction";
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomFilter from '../../../components/Widgets/CustomFilter';
import { LEAVE_LIST } from '../../../Redux/constants/doctorConstant';

const DrLeaveManagement = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [filterKey, setFilterKey] = useState(0);
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    const [Active, setActive] = useState(null);
    const [valuefrom, setvaluefrom] = useState("")
    const [valueto, setvalueto] = useState("")
    const { profiledata } = useSelector(state => state?.profile);
    const id = profiledata?.id
    const [leaveData, setLeaveData] = useState([])
    const [allleave, setallleave] = useState(leaveData)
    const dispatch = useDispatch();
    const [newErrors, setNewErrors] = useState({});
    const { leaveList, allleaveList } = useSelector(state => state?.doct)
    useEffect(() => {
        if (leaveList) {
            setLeaveData(leaveList)
        }
    }, [leaveList])


    const handleOpenFilter = () => {
        setOpenFilter(true);
    };
    const clearFilter = () => {
        setvaluefrom("");
        setvalueto("");
        setFilterKey(prevKey => prevKey + 1);
      };

    const handleCloseFilter = () => {
        if (valuefrom && valueto) {
            if (leaveList) {
                let find = leaveList?.find((a) => a?.name === "Doctors");
                dispatch(getAllDoctorLeave(Active === null ? find?._id : Active))
            }
        }
        setvaluefrom("")
        setvalueto("")
        setOpenFilter(false);
    };
  

    const Submitfilter = () => {
        if (valuefrom && valueto) {
            let filtered = allleaveList.filter((product) => {
                let productDate = moment(product?.leave_days, "DD-MM-YYYY");
                return (productDate >= valuefrom &&
                    productDate <= valueto);
            })
            dispatch({
                type: LEAVE_LIST,
                payload: filtered
            })


            setOpenFilter(false);
        } else {
            toast.warning(`From Date and To Date is required`);
        }
    }

    useEffect(() => {
        if (profiledata) {
            dispatch(getAllDoctorLeave(profiledata?._id))
        }

    }, [profiledata])

    const [open, setOpen] = useState(false);

    // const searchLeaves = (value) => {
    //     const datas = (allleaveList.filter(role => role?.status.toLowerCase().includes(value.toLowerCase())));
    //     dispatch({
    //         type: LEAVE_LIST,
    //         payload: datas
    //     })
    // }
    const searchLeaves = (value) => {
        const datas = allleaveList.filter((row) => {
            const appliedDate = moment(row?.leave_days, "DD-MM-YYYY").format("DD-MM-YYYY");
            const consultationTypes = row?.type.join(',').toLowerCase();
            const status = row?.status.toLowerCase();
    
            return (
                appliedDate.includes(value.toLowerCase()) ||
                consultationTypes.includes(value.toLowerCase()) ||
                status.includes(value.toLowerCase())
            );
        });
    
        dispatch({
            type: LEAVE_LIST,
            payload: datas,
        });
    };
    
    
    var columns = [

        {
            name: <b>{Capitalize("APPLIED DATE")}</b>,
            header: <b>{Capitalize("APPLIED DATE")}</b>,
            selector: (row) => row?.leave_days,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("CONSULTATIONS")}</b>,
            header: <b>{Capitalize("CONSULTATIONS")}</b>,
            selector: (row) => row?.type?.join(','),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("STATUS")}</b>,
            header: <b>{Capitalize("STATUS")}</b>,
            selector: (row) => row?.status,
            style: {
                textAlign: "center",

            },

        },



    ];

    const rows = leaveData



    return (
        <>
            <Box
                sx={{
                    width: "99%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "10px", xs: "10px" },
                    marginBottom: "40px",
                }}
            >
                <Stack spacing={2.6} direction="row" style={{
                    background: "#d8dee1",
                    padding: "3px 3px 3px .1px",
                    display: "flex",
                    alignItems: "center",

                }} borderRadius={2}>
                    <CustomSearch Icon={SearchIcon} variant={"contained"}
                        onSearch={searchLeaves}
                    />
                    <CustomButton
                        variant={"contained"}
                        text={"Filter"}
                        active={openFilter ? true : false}
                        Icon={FilterListIcon}
                        submit={() => setOpenFilter(!openFilter)}
                    />

                    <CustomButton
                        submit={() => setOpen(true)}
                        ComponentIcon={AddIcon}
                        variant={"contained"}
                    />
                </Stack>
            </Box>
            {openFilter && <CustomFilter
              key={filterKey}
                handlechangefrom={(value) => setvaluefrom(value)}
                valuefrom={valuefrom}
                valueto={valueto}
                handlechangeto={(value) => setvalueto(value)}
                submit={Submitfilter}
                // clear={handleCloseFilter}
                clear={clearFilter}
            />}
            <Box py={5}>
                <Paper>
                    <Fragment>
                        <DataTables
                            columns={columns}
                            row={rows}
                            pagination={true} />
                    </Fragment>
                </Paper>
                {open && <AddLeaveModal open={open} handleClose={() => setOpen(false)} />}
            </Box>
        </>
    )
}

export default DrLeaveManagement