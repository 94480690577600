import {
    EDIT_ROLE_FAIL,
    EDIT_ROLE_SUCCESS,
    GET_SINGLE_ROLE_FAIL,
    GET_SINGLE_ROLE_SUCCESS,
    NEW_ROLE_FAIL,
    NEW_ROLE_SUCCESS,
    ROLE_LIST_FAIL,
    ROLE_LIST_SUCCESS
} from "../constants/roleConstant";
import { RESET_ERROR } from "../constants/userConstants";

export const roleReducer = (state = { error: null }, action) => {
    switch (action.type) {

        case ROLE_LIST_SUCCESS:
            return {
                ...state,
                roleList: action.payload
            }

        case ROLE_LIST_FAIL:
            return {
                error: action.payload
            }
        case NEW_ROLE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESET_ERROR:
            return {
                ...state,
                error: null,
                roleSuccess: null,
                roleEdited: null
            }

        case NEW_ROLE_SUCCESS:
            return {
                ...state,
                roleSuccess: true,
                roleList: [action.payload, ...state.roleList]
            }
        case EDIT_ROLE_SUCCESS:
            return {
                ...state,
                // roleList: state?.roleList.map(rol => rol._id === action.payload._id ? action.payload : rol),
                roleEdited: true,
            }
        case EDIT_ROLE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SINGLE_ROLE_SUCCESS:
            return {
                ...state,
                singleRoleList: action.payload,

            }
        case GET_SINGLE_ROLE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}