import { Box, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import data from "../../components/common/data";
import CustomSearch from '../../components/Widgets/CustomSearch';
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import ProcedureBookingTable from './Table/ProcedureBookingTable';
import ProcedureListingTable from './Table/ProcedureListingTable';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import moment from "moment";
import CustomFilter from '../../components/Widgets/CustomFilter';
import { filterProcedure, filterProcedureBooking } from '../../Redux/actions/procedureAction';
import ProcedureReferalTable from './Table/ProcedureReferalTable';
const Procedure = () => {
	const dispatch = useDispatch()
	const Navigate = useNavigate()

	const [datas, setDatas] = useState([])
	const [filterbooking, setFilterBooking] = useState(false)
	const [filterListing, setFilterListing] = useState(false)

	const { procedureBookingList, procedureList } = useSelector(state => state.procedure);
	const [active, setActive] = useState('ProcedureBooking')
	const [activeColor, setActiveColor] = useState('contained')
	const [valuefrom, setvaluefrom] = useState("")
	const [valueto, setvalueto] = useState("")
	const [openFilter, setOpenFilter] = useState(false);
	const [filterKey, setFilterKey] = useState(0);


	const handleOpenFilter = () => {
		setOpenFilter(true);
	};

	// const handleCloseFilter = () => {

	// 	if (active === "ProcedureBooking") {
	// 		setFilterBooking(true)
	// 	} else if (active === 'ProcedureListing') {
	// 		setFilterListing(true)
	// 	}
	// 	setvaluefrom("")
	// 	setvalueto("")
	// 	setOpenFilter(false);
	// };
	const clearFilter = () => {
		setvaluefrom("");
		setvalueto("");
		setFilterKey(prevKey => prevKey + 1);
	};

	const handleCloseFilter = () => {
		clearFilter();
		setOpenFilter(false);
	};

	const Submitfilter = () => {
		let val = {
			from_date: moment(valuefrom).format("DD-MM-YYYY"),
			to_date: moment(valueto).format("DD-MM-YYYY")
		}
		if (valuefrom && valueto) {
			if (active === "ProcedureBooking") {
				dispatch(filterProcedureBooking(val))
			} else if (active === 'ProcedureListing') {
				dispatch(filterProcedure(val))
			}
			setOpenFilter(false);
		} else {
			toast.warning(`from Date and to Date is required`);
		}
	}


	const NavigateToServiceAdd = () => {
		if (active === 'ProcedureBooking') {
			Navigate(`/procedureBooking`)
		} else if (active === 'ProcedureListing') {
			Navigate(`/procedureList`)
		}

	}

	useEffect(() => {
		if (procedureBookingList && active === 'ProcedureBooking') {

			setDatas(procedureBookingList)
		}

		if (procedureList && active === 'ProcedureListing') {
			setDatas(procedureList ? procedureList : [])
		}

	}, [procedureBookingList, procedureList, active])


	useEffect(() => {
		let mode = localStorage.getItem("mode");

		if (mode === "ProcedureBooking") {

			setDatas(procedureBookingList ? procedureBookingList : [])
			setActive("ProcedureBooking")
		}
		else if (mode === "ProcedureListing") {
			setDatas(procedureList)
			setActive("ProcedureListing")
		}

	}, [])

	const filterValues = (value) => {
		value = value?.replace('#', '');
		if (active === 'ProcedureBooking') {
			let datas = procedureBookingList?.filter(proc => proc?.booking_id?.toLowerCase().includes(value.toLowerCase()) || `mrn${proc?.patient?.[0]?.user_id}`?.toString()?.toLowerCase().includes(value.toLowerCase()) || proc?.patient?.[0]?.name?.toLowerCase().includes(value.toLowerCase()) || proc?.procedure?.[0]?.name?.toLowerCase().includes(value.toLowerCase()) || proc?.status?.toLowerCase().includes(value.toLowerCase()))
			setDatas(datas)
		}
		else if (active === "ProcedureListing") {
			let datas = procedureList?.filter(proc => proc?.procedure_id?.toLowerCase().includes(value.toLowerCase()) || `${proc?.department?.[0]?.name}`?.toString()?.toLowerCase().includes(value.toLowerCase()) || proc?.name?.toLowerCase().includes(value.toLowerCase()))
			setDatas(datas)
		}
	}

	return (
		<>
			<Box
				sx={{
					width: "99%",
					height: "60px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					flexDirection: { lg: "row", md: "column", xs: "column" },
					gap: { md: "10px", xs: "10px" },
					marginBottom: "40px",
				}}
			>
				<Stack spacing={2} direction="row">
					<CustomButton active={active === "ProcedureBooking" ? true : false ? active === "ProcedureListing" : false}
						text={"Procedure Booking"} submit={() => {

							if (active !== "ProcedureBooking") {
								setDatas([])
								localStorage.setItem("mode", "ProcedureBooking")
								setActive('ProcedureBooking')
							}

						}} variant={activeColor} />
					<CustomButton active={active === "ProcedureListing" ? true : false ? active === "ProcedureBooking" : false}

						text={"Procedure Listing"} submit={() => {
							if (active !== "ProcedureListing") {
								setActive('ProcedureListing')
								localStorage.setItem("mode", "ProcedureListing")
								setDatas([])
							}
						}} variant={activeColor} />
					<CustomButton active={active === "referalList" ? true : false ? active === "referalList" : false}

						text={"Referral Listing"} submit={() => {
							setOpenFilter(false);
							if (active !== "referalList") {
								setActive('referalList')
								localStorage.setItem("mode", "referalList")
								setDatas([])
							}

						}} variant={activeColor} />

				</Stack>
				{active !== 'referalList' &&
					<Stack spacing={2.6} direction="row" style={{
						background: "#d8dee1",
						padding: "3px 3px 3px .1px",
						display: "flex",
						alignItems: "center",

					}} borderRadius={2}>
						<CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={(value) => filterValues(value)} />
						<CustomButton
							variant={"contained"}
							text={"Filter"}
							active={openFilter ? true : false}
							Icon={FilterListIcon}
							submit={() => setOpenFilter(!openFilter)}

						/>
						<CustomButton
							submit={NavigateToServiceAdd}
							ComponentIcon={AddIcon}
							variant={"contained"}

						/>
					</Stack>}
			</Box>
			{openFilter && <CustomFilter
				key={filterKey}
				handlechangefrom={(value) => setvaluefrom(value)}
				valuefrom={valuefrom}
				valueto={valueto}
				handlechangeto={(value) => setvalueto(value)}
				submit={Submitfilter}
				// clear={handleCloseFilter}
				clear={clearFilter}
			/>}
			{active === 'ProcedureBooking' && < ProcedureBookingTable datas={datas} filterbooking={filterbooking} />}
			{/* {active === "ProcedureBooking" ?  : <ProcedureListingTable datas={datas} filterListing={filterListing} />} */}
			{active === 'ProcedureListing' && <ProcedureListingTable datas={datas} filterListing={filterListing} />}
			{active === 'referalList' && <ProcedureReferalTable />}


		</>
	)
}

export default Procedure