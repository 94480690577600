import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import Grid from '@mui/material/Grid';
import CustomTimePicker from '../../components/Widgets/CustomTimePicker/CustomTimePicker';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomFormInput from '../../components/Widgets/CustomFormInput';
import { Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import { useParams } from 'react-router-dom';
import { CONSULTATION_TIME_SUCCESS } from '../../Redux/constants/AddDoctorConstant';
import { toast } from 'react-toastify';
import { logDOM } from '@testing-library/react';
const ConsultationTime = ({ setActive, Resdata }) => {
    let { id } = useParams();
    const dispatch = useDispatch()

    const [dates, setDates] = useState({
        monday: [
            { from: null, to: null },
        ],
        tuesday: [
            { from: null, to: null }
        ],
        wednesday: [
            { from: null, to: null }
        ],
        thursday: [
            { from: null, to: null }
        ],
        friday: [
            { from: null, to: null }
        ],
        saturday: [
            { from: null, to: null }
        ],
        sunday: [
            { from: null, to: null }
        ]
    })
    const { cosultationTime } = useSelector(state => state.doctState);


    const schema = object().shape({
        interval: yup.string().required("Interval field is required"),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            interval: cosultationTime?.interval
        }
    });
    useEffect(() => {
        if (cosultationTime?.time) {
            setDates(cosultationTime?.time);

        } else if (Resdata?.dateObject) {

            setDates(JSON.parse(Resdata?.dateObject));
            setValue("interval", Resdata?.interval);
        }
    }, [cosultationTime, Resdata]);
    const handleChange = (day, mode, index, value) => {
        dates[day][index][mode] = value;
        setDates({
            ...dates
        })

    }


    const handleAdd = (day) => {
        dates[day].push({ from: null, to: null })
        setDates({
            ...dates
        })
    }


    const handleRemove = (day, index) => {
        let filterdatas = dates[day].filter((da, i) => i !== index)
        dates[day] = filterdatas
        setDates({
            ...dates
        })
    }





    const PreviousButton = (data) => {

        // const day = {
        //     "monday": 1, "tuesday": 2, "wednesday": 3, "thursday": 4, "friday": 5, "saturday": 6, "sunday": 7
        // }
        // let sort = arrayList.sort((a, b) => {
        //     return day[a.day] - day[b.day]
        // })


        // let val = {
        //     time: sort,
        //     interval: data?.interval
        // }


        // dispatch(Cunsultation_Time(val))
        setActive("Basic_Details")

    }
    const NextButton = (data) => {
        // const day = {
        //     "monday": 1, "tuesday": 2, "wednesday": 3, "thursday": 4, "friday": 5, "saturday": 6, "sunday": 7
        // }
        // let sort = arrayList.sort((a, b) => {
        //     return day[a.day] - day[b.day]
        // })
        // let val = {
        //     time: sort,
        //     interval: data?.interval
        // }
        // let atLeastOneCompleteTime = Object.values(dates).some(date => date.some(time => time.from !== null && time.to !== null));
        // if (!atLeastOneCompleteTime) {
        //     toast.error('Please provide at least one service time, including the "From" and "To"');
        //     return;
        // }
        let atLeastOneCompleteTime = Object.values(dates).some(date => date?.some(time => time?.from !== null && time?.to !== null));
        if (!atLeastOneCompleteTime) {
            toast.error('Please provide at least one service time, including the "From" and "To"');
            return;
        }

        let validTimeEntries = true;
        for (const date of Object.values(dates)) {
            for (const time of date) {
                if ((time?.from === null && time?.to !== null) || (time?.from !== null && time?.to === null)) {
                    validTimeEntries = false;
                    break;
                }
            }
            if (!validTimeEntries) {
                break;
            }
        }

        if (!validTimeEntries) {
            toast.error('Each service time entry must include both "From" and "To" times');
            return;
        }



        let datas = {
            interval: data?.interval,
            time: dates
        }
        setValue('interval', data?.interval)
        if (ConsultationTime) {
            dispatch({
                type: CONSULTATION_TIME_SUCCESS,
                payload: datas
            })
        }
        else {
            toast.required("from time and to time is required")
        }
        //dispatch(Cunsultation_Time(val))
        setActive("Fees")

    }

    return (
        <>
            <Box paddingBottom={"10px"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Interval (Minutes)"
                            control={control}
                            error={errors.interval}
                            fieldName="interval"
                            type={"text"}
                            background="#FFFFFF"
                        />

                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography>{"Monday"}</Typography>
                    {dates.monday.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    Fieldlabel={"Monday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    onChanges={(value) => handleChange("monday", "from", index, value)}
                                    onChangeTo={(value) => handleChange("monday", "to", index, value)}
                                />
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('monday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("monday", index)} />}
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Tuesday"}</Typography>
                    {dates.tuesday.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    Fieldlabel={"Tuesday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    onChanges={(value) => handleChange("tuesday", "from", index, value)}
                                    onChangeTo={(value) => handleChange("tuesday", "to", index, value)}

                                />
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('tuesday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("tuesday", index)} />}
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Wednesday"}</Typography>
                    {dates.wednesday.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    Fieldlabel={"Wednesday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    onChanges={(value) => handleChange("wednesday", "from", index, value)}
                                    onChangeTo={(value) => handleChange("wednesday", "to", index, value)}

                                />
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('wednesday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("wednesday", index)} />}
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Thursday"}</Typography>
                    {dates.thursday.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    Fieldlabel={"Thursday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    onChanges={(value) => handleChange("thursday", "from", index, value)}
                                    onChangeTo={(value) => handleChange("thursday", "to", index, value)}

                                />
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('thursday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("thursday", index)} />}
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Friday"}</Typography>
                    {dates.friday.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    Fieldlabel={"Friday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    onChanges={(value) => handleChange("friday", "from", index, value)}
                                    onChangeTo={(value) => handleChange("friday", "to", index, value)}

                                />
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('friday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("friday", index)} />}
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Saturday"}</Typography>
                    {dates.saturday.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    Fieldlabel={"Saturday"}
                                    required
                                    From={"From"}
                                    To={"To"}
                                    toValue={day.to}
                                    fromValue={day.from}
                                    onChanges={(value) => handleChange("saturday", "from", index, value)}
                                    onChangeTo={(value) => handleChange("saturday", "to", index, value)}

                                />
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('saturday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("saturday", index)} />}
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Sunday"}</Typography>
                    {dates.sunday.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    Fieldlabel={"Sunday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    onChanges={(value) => handleChange("sunday", "from", index, value)}
                                    onChangeTo={(value) => handleChange("sunday", "to", index, value)}

                                />
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('sunday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("sunday", index)} />}
                            </Box>
                        </Box>
                    ))}
                </Grid>


            </Grid>

            <Box display={"flex"} justifyContent={"flex-end"} gap={"15px"}>
                <CustomButton variant={"contained"} text={"Previous"} submit={PreviousButton} />
                <CustomButton variant={"contained"} text={"Next"} submit={handleSubmit(NextButton)} />
            </Box>
        </>




    )
}

export default ConsultationTime