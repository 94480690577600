import { GET_NOTIFICATION_LIST_FAIL, GET_DOCTOR_NOTIFICATION_LIST_FAIL, GET_DOCTOR_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_SUCCESS, UPDATE_NOTIFICATION_FAIL, UPDATE_NOTIFICATION_SUCCESS, NOTY_TYPES, NOTY_TYPESS, } from "../constants/notificationConstant";
import { AUTH_INPUT, RESET_ERROR } from "../constants/userConstants";

export const notificationReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                notificationUpdated: null
            }
        case GET_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                notificationList: action.payload
            }
        case GET_NOTIFICATION_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_DOCTOR_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                notificationList: action.payload,
            }
        case GET_DOCTOR_NOTIFICATION_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationList: state?.notificationList.map(notifi => notifi._id === action.payload._id ? action.payload : notifi),
                notificationUpdated: state?.notificationList.map((res) => res?.status === 'unread' ? true : false)
            }
        case UPDATE_NOTIFICATION_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case NOTY_TYPES:
            return {
                ...state,
                types: action.payload
            }
        case NOTY_TYPESS:
            return {
                ...state,
                router_types: action.payload
            }
        default:
            return state;
    }
} 