import { isNull } from "lodash";
import axios from "../../customAxios"
import { CREATE_PROCEDURE_BOOKING_FAIL, CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_FAIL, CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_SUCCESS, CREATE_PROCEDURE_BOOKING_SUCCESS, CREATE_PROCEDURE_CANCEL_FAIL, CREATE_PROCEDURE_CANCEL_SUCCESS, CREATE_PROCEDURE_FAIL, CREATE_PROCEDURE_SUCCESS, CREATE_PROCEDURE_TIME_FAIL, CREATE_PROCEDURE_TIME_SUCCESS, EDIT_PROCEDURE_BOOKING_FAIL, EDIT_PROCEDURE_BOOKING_SUCCESS, EDIT_PROCEDURE_FAIL, EDIT_PROCEDURE_SUCCESS, FILTER_PROCEDURE_BOOKING_FAIL, FILTER_PROCEDURE_BOOKING_SUCCESS, FILTER_PROCEDURE_FAIL, FILTER_PROCEDURE_SUCCESS, GET_PROCEDURE_BOOKING_FAIL, GET_PROCEDURE_BOOKING_SUCCESS, GET_PROCEDURE_BOOKING_VIEW_FAIL, GET_PROCEDURE_BOOKING_VIEW_SUCCESS, GET_PROCEDURE_FAIL, GET_PROCEDURE_SINGLE_FAIL, GET_PROCEDURE_SINGLE_SUCCESS, GET_PROCEDURE_SUCCESS, POST_PROCEDURE_REFERAL_BOOKING_FAIL, POST_PROCEDURE_REFERAL_BOOKING_SUCCESS, PROCEDURE_REFERAL_SUCCESS, PROCEDURE_REPORT_BOOKING_FAIL, PROCEDURE_REPORT_BOOKING_SUCCESS, RESHEDULE_PROCEDURE_BOOKING_FAIL, RESHEDULE_PROCEDURE_BOOKING_SUCCESS, UPDATE_STATUS_PROCEDURE_LIST_FAIL, UPDATE_STATUS_PROCEDURE_LIST_SUCCESS } from "../constants/procedureConstant";
import { LOADING } from "../constants/userConstants";
import moment from "moment";

export const postProcedure = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/create`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_PROCEDURE_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_PROCEDURE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getProcedure = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/procedures`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_PROCEDURE_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_PROCEDURE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const editProcedure = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/edit`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_PROCEDURE_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: EDIT_PROCEDURE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getSingleProcedure = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/procedure/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_PROCEDURE_SINGLE_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_PROCEDURE_SINGLE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const postProcedureBooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_PROCEDURE_BOOKING_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_PROCEDURE_BOOKING_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const SearchServiceByDeptProcedure = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/search-booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const postProcedureTime = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/procedure-time`, data)
        .then(async response => {
            let data = response.data.data?.time;
            let procedureAvailableTimes = [];

            let date = moment(response?.data?.data?.date, "YYYY-MM-DD");
            console.log(date);
            console.log(data);
            if (moment().isSame(date, 'day')) {
                data?.map(time => {
                    if (moment() < moment(time?.time, "hh:mm A") && isNull(time?.patient_id)) {
                        procedureAvailableTimes.push(time)
                    }
                })
            }
            else {
                data?.map(time => {
                    if (isNull(time?.patient_id)) {
                        procedureAvailableTimes.push(time)
                    }
                })
            }
            let datas = {
                ...response.data.data,
                time: procedureAvailableTimes
            }



            dispatch({
                type: CREATE_PROCEDURE_TIME_SUCCESS,
                payload: datas
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_PROCEDURE_TIME_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const getProcedureBookingList = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/procedure/booking/list`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_PROCEDURE_BOOKING_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_PROCEDURE_BOOKING_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const CancelbookingProcedure = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/booking/cancel`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_PROCEDURE_CANCEL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_PROCEDURE_CANCEL_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const ReshedulebookingProcedure = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/booking/reschedule`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: RESHEDULE_PROCEDURE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: RESHEDULE_PROCEDURE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getprocedureSingleListView = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/procedure/booking/show/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_PROCEDURE_BOOKING_VIEW_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_PROCEDURE_BOOKING_VIEW_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const updateprocedureListStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/status`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch(getProcedure())
            dispatch({
                type: UPDATE_STATUS_PROCEDURE_LIST_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_STATUS_PROCEDURE_LIST_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const postEditProcedureBooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/procedure/schedule`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_PROCEDURE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: EDIT_PROCEDURE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const filterProcedure = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/filter`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: FILTER_PROCEDURE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: FILTER_PROCEDURE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const filterProcedureBooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/booking/filter`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: FILTER_PROCEDURE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: FILTER_PROCEDURE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


//procedure report............................................................................
export const postProcedureReport = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/procedure/addprocedurereport`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: PROCEDURE_REPORT_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: PROCEDURE_REPORT_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
//referal list.................................................................................

export const getProcedureReferal = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/procedure/referrel-list/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: PROCEDURE_REFERAL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: PROCEDURE_REPORT_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

//procedure booking .....................................................................

export const postProcedureReferalBooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/procedure/booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: POST_PROCEDURE_REFERAL_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: POST_PROCEDURE_REFERAL_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
