import { CREATE_DEPT_FAILED } from "../constants/departmentConstants";
import { PROFILE_FAILED, PROFILE_SUCCESS, PROFILE_UPDATE_FAILED, PROFILE_UPDATE_SUCCESS } from "../constants/profileConstants";
import {
    CANCEL_SERVICE_BOOKING_FAIL,
    CANCEL_SERVICE_BOOKING_SUCCESS,
    CREATE_SERVICE_BOOKING_FAIL,
    CREATE_SERVICE_BOOKING_SUCCESS,
    CREATE_SERVICE_LISTING_FAIL,
    CREATE_SERVICE_LISTING_SUCCESS,
    CREATE_SERVICE_SEARCH_BOOKING_FAIL,
    CREATE_SERVICE_SEARCH_BOOKING_SUCCESS,
    CREATE_SERVICE_TIME_FAIL,
    CREATE_SERVICE_TIME_SUCCESS,
    EDIT_SERVICE_BOOKING_FAIL,
    EDIT_SERVICE_BOOKING_SUCCESS,
    EDIT_SERVICE_LISTING_FAIL,
    EDIT_SERVICE_LISTING_SUCCESS,
    FILTER_SERVICE_BOOKING_FAIL,
    FILTER_SERVICE_BOOKING_SUCCESS,
    FILTER_SERVICE_FAIL,
    FILTER_SERVICE_SUCCESS,
    GET_BOOKING_LIST_FAIL,
    GET_BOOKING_LIST_SUCCESS,
    GET_BOOKING_VIEW_FAIL,
    GET_BOOKING_VIEW_SUCCESS,
    GET_SERVICE_BY_DEPT_SERVICE_FAIL,
    GET_SERVICE_BY_DEPT_SERVICE_SUCCESS,
    GET_SERVICE_LISTING_FAIL,
    GET_SERVICE_LISTING_SUCCESS,
    GET_SINGLE_LIST_FAIL,
    GET_SINGLE_LIST_SUCCESS,
    POST_REFERAL_BOOKING_FAIL,
    POST_REFERAL_BOOKING_SUCCESS,
    REFERAL_SERVICE_FAIL,
    REFERAL_SERVICE_SUCCESS,
    RESHEDULE_SERVICE_BOOKING_FAIL,
    RESHEDULE_SERVICE_BOOKING_SUCCESS,
    SERVICE_REPORT_FAIL,
    SERVICE_REPORT_SUCCESS,
    UPDATE_SERVICE_STATUS_FAIL,
    UPDATE_SERVICE_STATUS_SUCCESS
} from "../constants/serviceConstant";
import { LOADING, RESET_ERROR } from "../constants/userConstants";


export const serviceReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                createdServiceList: null,
                serviceListUpdated: null,
                bookingCreated: null,
                bookingEdited: null,
                bookingCanceled: null,
                reschedule: null,
                serviceStatusChanged: null,
                reportcreated: null,
                referalbooked: null

            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case GET_SERVICE_LISTING_SUCCESS:
            return {
                ...state,
                serviceList: action.payload
            }

        case GET_SERVICE_LISTING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_SERVICE_LISTING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_SERVICE_LISTING_SUCCESS:
            return {
                ...state,
                // serviceList: state?.serviceList ? [...state?.serviceList, action?.payload] : action.payload,
                createdServiceList: true
            }



        case GET_SINGLE_LIST_SUCCESS:
            return {
                ...state,
                serviceSingleList: action.payload
            }

        case GET_SINGLE_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }


        case EDIT_SERVICE_LISTING_SUCCESS:
            return {
                ...state,
                serviceListUpdated: true,

            }

        case EDIT_SERVICE_LISTING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_SERVICE_SEARCH_BOOKING_SUCCESS:
            return {
                ...state,
                patientSearchList: action.payload
            }

        case CREATE_SERVICE_SEARCH_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_SERVICE_BY_DEPT_SERVICE_SUCCESS:
            return {
                ...state,
                serviceListByDept: action.payload
            }

        case GET_SERVICE_BY_DEPT_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload
            }


        case GET_BOOKING_LIST_SUCCESS:
            return {
                ...state,
                bookingList: action.payload
            }

        case GET_BOOKING_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_SERVICE_BOOKING_SUCCESS:
            return {
                ...state,
                // bookingList: state?.bookingList ? [...state?.bookingList, action.payload] : action.payload,
                bookingCreated: true,
            }

        case CREATE_SERVICE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_SERVICE_TIME_SUCCESS:
            return {
                ...state,
                serviceTimelist: action.payload
            }

        case CREATE_SERVICE_TIME_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_BOOKING_VIEW_SUCCESS:
            return {
                ...state,
                bookingListview: action.payload
            }

        case GET_BOOKING_VIEW_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case EDIT_SERVICE_BOOKING_SUCCESS:
            return {
                ...state,
                bookingList: state.bookingList?.map(booking => booking._id === action.payload._id ? action.payload : booking),
                bookingEdited: true,
            }

        case EDIT_SERVICE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CANCEL_SERVICE_BOOKING_SUCCESS:
            return {
                ...state,
                bookingCanceled: true
            }
        case CANCEL_SERVICE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case RESHEDULE_SERVICE_BOOKING_SUCCESS:
            return {
                ...state,
                reschedule: true,
            }
        case RESHEDULE_SERVICE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case UPDATE_SERVICE_STATUS_SUCCESS:
            return {
                ...state,
                serviceStatusChanged: true
            }
        case UPDATE_SERVICE_STATUS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case FILTER_SERVICE_SUCCESS:
            return {
                ...state,
                serviceList: action.payload
            }

        case FILTER_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case FILTER_SERVICE_BOOKING_SUCCESS:
            return {
                ...state,
                bookingList: action.payload
            }
        case FILTER_SERVICE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case SERVICE_REPORT_SUCCESS:
            return {
                ...state,
                reportcreated: true
            }
        case SERVICE_REPORT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case REFERAL_SERVICE_SUCCESS:
            return {
                ...state,
                referalList: action.payload
            }
        case REFERAL_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case POST_REFERAL_BOOKING_SUCCESS:
            return {
                ...state,
                referalList: state?.referalList.filter(ref => ref._id !== action.payload._id),
                referalbooked: true,
            }
        case POST_REFERAL_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }


        default:
            return state;

    }

}