import React, { useState, useEffect, useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import CustomSelectInput from '../../../components/Widgets/CustomSelect';
import CustomCheckBox from '../../../components/Widgets/CustomCheckBox';
import customAxios from '../../../customAxios';
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";

const ReviewModal = ({ open, handleClose, procedureId, serviceId, setSelectedProcedureNames, selectedProcedureNames, setSelectedServiceNames, selectedServiceNames, selectedDepartment, setSelectedDepartment, selectedProcedures, setSelectedProcedures, selectedServices, setSelectedServices, savedProcedures, savedServices }) => {
  const schema = object().shape({});

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newErrors, setNewErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState('');
  const [categoryID, setCategoryID] = useState('');
  const [departments, setDepartments] = useState([]);
  //const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [procedures, setProcedures] = useState([]);
  //const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([{ isChecked: false }]);
  const [dept, setDept] = useState('');
  const [selectedData, setSelectedData] = useState([]);


  useEffect(() => {
    async function fetchDepartmentProcedure() {
      try {
        let departmentId =
          open?.type === 'procedure'
            ? procedureId
            : serviceId;
        let res = await customAxios.get(
          `/doctor/category/departments/${departmentId}`
        );
        if (res?.status === 201) {
          let data = res?.data?.data;
          setDepartments(data);
          setSelectedDepartment('');
        } else {
          throw new Error('Network error');
        }
      } catch (error) {
        setNewErrors({ status: { message: 'Error fetching departments' } });
      }
    }

    fetchDepartmentProcedure();
  }, [open?.type, procedureId, serviceId]);

  const getProceduresByDepartment = useCallback(async (departmentId) => {
    try {
      let res = await customAxios.get(
        `/doctor/department/procedures/${departmentId}`
      );
      if (res?.status === 201) {

        let procedures = res?.data?.data.filter(proc => !savedProcedures?.includes(proc?._id))

        let data = procedures?.map((procedure) => {
          // Compare procedures from already selected

          const alreadySelectedProcedure = selectedProcedures?.find(
            (selectedProcedure) => selectedProcedure?._id === procedure?._id
          );
          return {
            ...procedure,
            isChecked: alreadySelectedProcedure ? true : false,
          };
        });
        setProcedures(data);
      } else {
        throw new Error('Network error');
      }
    } catch (error) {
      setNewErrors({ status: { message: 'Error fetching procedures' } });
    }
  }, [selectedProcedures]);

  const getServicesByDepartment = useCallback(async (selectedDepartment) => {
    try {
      let res = await customAxios.get(
        `/doctor/department/services/${selectedDepartment}`
      );
      if (res?.status === 201) {
        let services = res?.data?.data.filter(proc => !savedServices?.includes(proc?._id))
        let data = services?.map((service) => {
          // Compare services from already selected
          const alreadySelectedService = selectedServices?.find(
            (selectedService) => selectedService?._id === service?._id
          );

          return {
            ...service,
            isChecked: alreadySelectedService ? true : false,
          };
        });
        setServices(data);
      } else {
        throw new Error('Network error');
      }
    } catch (error) {
      setNewErrors({ status: { message: 'Error fetching services' } });
    }
  }, [selectedServices]);

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
    if (open?.type === "procedure") {
      getProceduresByDepartment(event.target.value);
    } else if (open?.type === "service") {
      getServicesByDepartment(event.target.value);
    }
  };



  const handleChangeRole = (e) => { };

  const handleCheckboxClick = (procedure, index) => {

    setProcedures((prevProcedures) => {
      const updatedProcedures = [...prevProcedures];
      if (updatedProcedures[index].isChecked) {
        //remove procedure from 
      }
      else {
        //add procedure name
      }
      updatedProcedures[index].isChecked = !updatedProcedures[index].isChecked;

      return updatedProcedures;
    });
  };

  const handleCheckboxClickService = (service, index) => {
    setServices((prevServices) => {
      const updatedServices = [...prevServices];
      if (updatedServices[index].isChecked) {

      }
      else {

      }
      updatedServices[index].isChecked = !updatedServices[index].isChecked;

      return updatedServices;
    });
  };

  const handleSelection = () => {
    if (open?.type == "procedure") {
      let existingItems = selectedProcedures.filter(proc => proc?.department_id !== selectedDepartment);
      let newItems = procedures.filter((procedure) => procedure?.isChecked);
      setSelectedProcedures([...existingItems, ...newItems]);
    }
    else if (open?.type == "service") {
      let existingItems = selectedServices.filter(serv => serv?.department_id !== selectedDepartment);
      let newItems = services.filter((service) => service?.isChecked);
      setSelectedServices([...existingItems, ...newItems]);
    }
    toast.success('Added Successfully');
    handleClose();
  };

  useEffect(() => {

  }, [selectedProcedureNames]);

  return (
    <Dialog open={open?.open} maxWidth={'sm'} onClose={handleClose}>
      <DialogTitle>
        <Stack justifyContent={'space-between'} direction={'row'} gap={5} px={2} py={2} alignItems='center'>
          <Box></Box>
          <Typography
            sx={{
              fontFamily: 'Quicksand',
              fontWeight: 'bold',
              fontSize: 26,
              color: '#047AC3',
              letterSpacing: 1,
            }}
          >
            {open?.type === 'service' ? 'Add service' : 'Add procedure'}
          </Typography>
          <Button>
            <Close
              onClick={() => handleClose()}
              sx={{
                color: '#000',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            />
          </Button>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant='outlined' error={!!errors.status}>
              <Typography variant='h6'>Choose Department</Typography>
              <Select
                labelId='status-label'
                id='status'
                value={selectedDepartment}
                //value={selectedStatus}
                onChange={handleDepartmentChange}
                control={control}
                error={errors.status}
                fieldName='status'
                background='#E7F5F7'
                height='50px'
                size='16px'
              >
                <MenuItem value=''>
                  <em>Select Department</em>
                </MenuItem>
                {departments?.map((department) => (
                  <MenuItem key={department?._id} value={department?._id}>
                    {department?.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.status && (
                <FormHelperText>{errors.status.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {selectedDepartment && (
            <Box
              sx={{
                background: '#E6F4F7',
                height: 'fit-content',
                width: '600px',
                borderRadius: '8px',
                marginTop: '20px',
                padding: '10px',
              }}
            >
              <Typography variant='body1'>
                {open?.type === 'procedure' &&
                  procedures
                    .filter((procedure) => procedure?.department_id === selectedDepartment)
                    .map((procedure, index) => (
                      <div key={procedure?._id}>
                        <CustomCheckBox
                          onClick={() => handleCheckboxClick(procedure, index)}
                          checked={procedure?.isChecked}
                          disabled={procedure?.isDisabled}
                        />
                        <span>{procedure?.name}</span>
                      </div>
                    ))}
                {open.type === 'service' &&
                  services
                    .filter((service) => service?.department_id === selectedDepartment)
                    .map((service, index) => (
                      <div key={service?._id}>
                        <CustomCheckBox
                          onClick={() => handleCheckboxClickService(service, index)}
                          checked={service?.isChecked}
                          disabled={service?.isDisabled}
                        />


                        <span>{service?.name}</span>
                      </div>
                    ))}
              </Typography>
            </Box>
          )}
        </Grid>
      </DialogContent>
      <Box display='flex' justifyContent='flex-end' p={2}>
        <Button onClick={handleSelection}>ADD</Button>
      </Box>
      <ToastContainer />
    </Dialog>
  );
};

export default ReviewModal;