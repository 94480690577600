
import { Box, Grid, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import Backbutton from "../../components/Widgets/Backbutton";
import CustomSelectInput from '../../components/Widgets/CustomSelect';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed, array } from "yup";
import * as yup from "yup";
import CustomFormInput from '../../components/Widgets/CustomFormInput';
import CustomImageUpload from '../../components/Widgets/CustomImageUploade';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList, getDeptCategory } from '../../Redux/actions/departmentAction';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import CustomCheckboxTitile from '../../components/Widgets/CustomCheckBoxAndTitle/CustomCheckboxTitile';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import format from 'date-fns/format'
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment/moment';
import { getDeptByDocName } from '../../Redux/actions/appointmentAction';
import { DataArray, HistoryEduSharp } from '@mui/icons-material';
import { postLeave } from '../../Redux/actions/leaveAction';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const AddLeaveManagement = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()



    const [type, setType] = useState([])
    const [department, setDepartment] = useState(null)
    const [docName, setDocName] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const { CategoryList, deptList } = useSelector(state => state.department);
    const { doctorNameList } = useSelector(state => state.appointment);
    const { leaveCreated, error } = useSelector(state => state.leave);

    const { loading } = useSelector(state => state.auth);
    const schema = object().shape({
        department: yup.string().required('Department is Required'),
        doctor: yup.string().required('Doctor is Required'),

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {

        },
        resolver: yupResolver(schema)
    });



    const onChangeCheckbox = (e, index) => {
        const { value, checked } = e.target;
        if (checked) {
            setType([...type, value])
        } else {
            let array = type
            array.splice(value, 1)
            setType([...array])
        }
    }





    // date state
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ])



    // open close
    const [open, setOpen] = useState(false)

    // get the target element to toggle 
    const refOne = useRef(null)




    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])


    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }


    const onChangeDepartment = (e) => {
        setValue("department", e.target.value);
        setDepartment(e.target.value)
        let value = {
            department_id: e.target.value
        }
        dispatch(getDeptByDocName(value))
    }
    const onChangeDoctorName = (e) => {
        setDocName(e.target.value);
        setValue("doctor", e.target.value)

    }

    const submitForm = (DATA) => {
        var dateArray = [];
        var startDate = moment(range[0]?.startDate);
        var endDate = moment(range[0]?.endDate);
        while (startDate <= endDate) {
            dateArray.push(moment(startDate).format('DD-MM-YYYY'))
            startDate = moment(startDate).add(1, 'days');
        }
        // return dateArray;
        let value = {
            id: DATA?.doctor,
            type: type,
            leave_days: dateArray,
            department: DATA?.department
        }

        if (type.length === 0) {
            toast.error("Consultation Type is required")

        } else {
            dispatch(postLeave(value))
        }


    }




    useEffect(() => {
        if (leaveCreated) {
            toast.success(`Leave Created Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }

        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, leaveCreated])

    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList?.find((a) => a?.name === "Doctors");
            dispatch(getDepartmentList(find?._id))
        }
    }, [CategoryList])

    useEffect(() => {
        dispatch(getDeptCategory())
    }, [])


    const changeRange = (data) => {
        setRange([data?.selection])
        setStartDate(data?.selection?.startDate)
        setEndDate(data?.selection?.endDate)
    }


    return (
        <>
            <Box style={{ paddingBottom: "30px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        {id ? 'Edit Leave' : 'Add Leave'}
                    </Typography>
                </Stack>
            </Box>
            <Grid container spacing={3} padding={2}>
                <Grid item xs={5} md={3} lg={3} >
                    <CustomSelectInput
                        control={control}
                        error={errors.department}
                        fieldName="department"
                        fieldLabel="Department"
                        background="#ffff"
                        height="40px"
                        size="16px"
                        value={department}
                        onChangeValue={(value) => {
                            onChangeDepartment(value)
                            setError('department', '')
                        }}
                    // options={categoryArray}
                    >
                        <MenuItem value="" disabled >
                            Select Department
                        </MenuItem>
                        {deptList?.map((res) => (
                            <MenuItem key={res?._id} value={res?._id} >
                                {res?.name}
                            </MenuItem>
                        ))}

                    </CustomSelectInput>
                </Grid>
                <Grid item xs={5} md={3} lg={3} >
                    <CustomSelectInput
                        control={control}
                        error={errors.doctor}
                        fieldName="doctor"
                        fieldLabel="Doctor"
                        background="#ffff"
                        height="40px"
                        size="16px"
                        value={docName}
                        onChangeValue={(value) => {
                            onChangeDoctorName(value)
                            setError('doctor', '')
                        }}
                    // options={categoryArray}
                    >
                        <MenuItem value="" disabled >
                            Select Doctor
                        </MenuItem>
                        {doctorNameList?.map((res) => (
                            <MenuItem key={res?._id} value={res?._id} >
                                {res?.name}
                            </MenuItem>
                        ))}

                    </CustomSelectInput>
                </Grid>
                <Grid item xs={5} md={3} lg={3} >
                    <Box>
                        <Typography>Consultation Type</Typography>
                        <Box display={'flex'} justifyContent={'space-between'} gap={1.5}>
                            {['Op', 'Online', 'Report']?.map((res, index) => (
                                <CustomCheckboxTitile label={res} value={res} changeRole={(e) => onChangeCheckbox(e, index)}
                                />
                            )
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={5} md={4} lg={4} >

                    {/* <input
                        value={`${format(range[0].startDate, "dd/MM/yyyy")} to ${format(range[0].endDate, "dd/MM/yyyy")}`}
                        readOnly
                        className="inputBox"
                        onClick={() => setOpen(open => !open)}
                    /> */}
                    <Typography sx={{
                        paddingLeft: '5px',
                        fontSize: {
                            md: 14,
                            sm: 13,
                            xs: 12,
                        },
                        fontFamily: 'Quicksand',
                        fontWeight: "bold"
                    }}>Choose Date</Typography>
                    <TextField
                        className="inputBox"
                        value={startDate ? `${format(startDate, "dd/MM/yyyy")} to ${format(endDate, "dd/MM/yyyy")}` : 'Choose Date'}
                        sx={{ m: 1, width: '30ch', backgroundColor: '#fff' }}
                        InputProps={{
                            style: { borderRadius: '15px', height: '48px' },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CalendarMonthIcon onClick={() => setOpen(!open)} style={{
                                        color: "#047ac1"
                                    }} />
                                </InputAdornment>
                            ),
                        }}

                    />

                    <Box ref={refOne}>
                        {open &&
                            <DateRange
                                onChange={item => changeRange(item)}
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                ranges={range}
                                months={1}
                                direction="horizontal"
                                className="calendarElement"
                            />
                        }
                    </Box>
                </Grid>
            </Grid>
            <Box display={'flex'} justifyContent={'center'} paddingTop={'50px'} paddingBottom={'30px'}>
                <CustomButton text={loading ? 'Loading...' : 'SUBMIT'} disabled={loading ? true : false} variant={'contained'} submit={handleSubmit(submitForm)} />
            </Box>
        </>
    )
}

export default AddLeaveManagement