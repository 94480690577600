import React, { useEffect, useState } from "react";
import { BUTTONIMG, DIV } from "./style";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../../assets/images/hlogoLogin.png";
import loginbutton from "../../assets/images/login-svgrepo-com.svg";
import { InputAdornment, Stack, styled } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import Forget from "./forget";
import CustomInput from "../../components/Widgets/CustomInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from "../../Redux/constants/userConstants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { forgetpasswordUpdate, loginUser } from "../../Redux/actions/userActions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ImagePath from "../../assets/images/mcarebackground.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { toast } from "react-toastify";
const ForgetPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const [queryParameters] = useSearchParams()

	const { passwordUpdated, error } = useSelector(state => state.auth);
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 450,
				md: 600,
				lg: 900,
				xl: 1200,
				tablet: 1024
			}
		}
	});
	const schema = object().shape({
		password: yup.string().required('Password is required'),
		passwordConfirmation: yup.string()
			.oneOf([yup.ref('password'), null], 'Passwords must match')
	});

	const [eye, setEye] = useState(false)
	const {
		register,
		handleSubmit,
		setFieldValue,
		control,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),

	});

	const submit = (data) => {
		let val = {
			vrftoken: queryParameters.get('vrftoken'),
			password: data?.password
		}

		dispatch(forgetpasswordUpdate(val))

	};

	useEffect(() => {
		if (passwordUpdated) {
			toast.success("Password Updated successfully");
			dispatch({
				type: RESET_ERROR
			})
			navigate("/login/admin")
		}

		if (error) {
			toast.error(`${error.message}`);
			dispatch({
				type: RESET_ERROR
			})

		}
	}, [passwordUpdated, error])
	return (
		<DIV img={ImagePath}>
			<Container >
				<Box sx={{
					paddingRight: "30px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					height: "100vh",
					justifyContent: "center"

				}}>
					<Avatar src={logo} style={{ width: "140px", height: "140px" }}>
					</Avatar>
					<Typography component="h1" variant="h5" color="white" fontSize="50px" fontFamily={'Quicksand ,sans-serif'} fontWeight={"bold"}>
						CHANGE PASSWORD
					</Typography>

					<Grid container >

						<Grid
							item
							xs={12}
							display="flex"
							alignItems="center"
							justifyContent="center"
							sx={{ mt: 3 }}
						>
							<Box width={"30%"}>
								<CustomInput
									control={control}
									error={errors.password}
									fieldName="password"
									position={"start"}
									Icon={HttpsIcon}
									placeholder={"Password"}
									type={eye ? "text" : "password"}
									onClick={() => setEye(!eye)}
									EndAdornments={eye ? VisibilityIcon : VisibilityOffIcon}
								/>
							</Box>

						</Grid>
						<Grid
							item
							xs={12}
							display="flex"
							alignItems="center"
							justifyContent="center"
							sx={{ mt: 3 }}
						>
							<Box width={"30%"}>
								<CustomInput
									control={control}
									error={errors.passwordConfirmation}
									fieldName="passwordConfirmation"
									position={"start"}
									Icon={HttpsIcon}
									placeholder={"Confirm Password"}
									type={eye ? "text" : "password"}
									onClick={() => setEye(!eye)}
									EndAdornments={eye ? VisibilityIcon : VisibilityOffIcon}
								/>
							</Box>
						</Grid>
					</Grid>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							mt: 2,
						}}
					>
						<BUTTONIMG onClick={handleSubmit(submit)}>
							<img src={loginbutton} alt="image"></img>
						</BUTTONIMG>
					</Box>
				</Box>
			</Container>

		</DIV>
	)
}

export default ForgetPassword