import axios from "../../customAxios"
import { GET_NOTIFICATION_LIST_FAIL, GET_DOCTOR_NOTIFICATION_LIST_FAIL, GET_DOCTOR_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_SUCCESS, UPDATE_NOTIFICATION_SUCCESS, UPDATE_NOTIFICATION_FAIL } from "../constants/notificationConstant";
import { LOADING } from "../constants/userConstants";


export const getNotification = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/notifications`)
        .then(async response => {

            let data = response.data.data;
            console.log(response.data.notification, "data");

            dispatch({
                type: GET_NOTIFICATION_LIST_SUCCESS,
                payload: data,
                count: response.data.notification
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_NOTIFICATION_LIST_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
export const getDoctorNotification = (id) => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`doctor/notifications`, { doctor_id: id })
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_DOCTOR_NOTIFICATION_LIST_SUCCESS,
                payload: data,
                count: response.data.notification
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_DOCTOR_NOTIFICATION_LIST_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const updateNotification = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/update-notification`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_NOTIFICATION_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: UPDATE_NOTIFICATION_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}