import React, { useState, useEffect, useContext } from "react";
import {
    GridBox,
    HeaderContainer,
    SeparateContainer,
    SeparateContainer1,
    SeparateContainer2,
} from "./style";
import person from "../../assets/images/person.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DehazeIcon from "@mui/icons-material/Dehaze";
import PersonIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import ModalDrawer from "../sidebar/Drawer";
import useWindowDimensions from "../../components/Widgets/WindowDimentions/WindowDimention";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../Redux/actions/profileAction";
import UserContext from "../../helpers/User";
import { Fragment } from "react";
import Menu from '@mui/material/Menu';
import { getNotification, getDoctorNotification, updateNotification } from "../../Redux/actions/notificationAction";
import { Button, Card, Paper, Tooltip } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import customAxios from "../../customAxios";
import { IMGEURL } from "../../config/constants";
import { setActiveSidebar } from '../../Redux/reducers/activeSidebarReducer'
import { NOTY_TYPES } from "../../Redux/constants/notificationConstant";

const Navbar = () => {
    const dispatch = useDispatch()
    let location = useLocation();
    const navigate = useNavigate();

    const { profiledata, error, profileList, profileupdatedsuccess } = useSelector(state => state?.profile);
    const { settingsList, updatedSettingsList } = useSelector(state => state.settings);


    const { notificationUpdated, notificationList } = useSelector(state => state?.notifi);


    const { doctorDetails } = useSelector(state => state?.auth)

    const context = useContext(UserContext)
    const unreadNotificationsCount = notificationList ? notificationList.filter(notif => notif?.status === 'unread').length : 0;

    const theme = createTheme();
    const [isDraweropen, setIsDraweropen] = React.useState(false);
    const { height, width } = useWindowDimensions();
    const [hide, sethide] = useState(false);
    const [text, settext] = useState(null);
    const role = JSON.parse(localStorage?.getItem("user"))
    const rolee = JSON.parse(localStorage?.getItem("role"))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [noti, setnoti] = useState('')
    const [newNotification, setNewNotification] = useState(null);
    const img = localStorage.getItem("changed_image")
    const imagePath = role?.logo;
    const baseUrl = IMGEURL
    const imageUrl = `${baseUrl}${imagePath}`;
    const fixedUrl = imageUrl.replace(/\/\//g, '/');

    let user = JSON.parse(localStorage?.getItem("user"))
    const AppointmentDetail = async (bookingid) => {
        let id = bookingid;

        const response = await customAxios.get(`/doctor/consultationdetail/${bookingid}`);

        navigate(`/docViewModal/${id}`, { state: { consultation: response?.data?.data } });
    }



    const updateNotifications = (id, appointmenttype, booking_id, types, zoomDetails) => {
        let val = {
            id: id
        }
        dispatch({
            type: NOTY_TYPES,
            payload: types

        })
        dispatch(updateNotification(val))
        setnoti(notificationUpdated.filter(Boolean).length);
        handleClose();

        if (appointmenttype === "service" && booking_id) {
            navigate(`/bookingView/${booking_id}`);
            settext("Service Management");
        } else if (appointmenttype === "procedure" && booking_id) {
            navigate(`/procedureBookingView/${booking_id}`);
            settext("Procedure Management");
        } else if (appointmenttype === "Report" && booking_id) {
            navigate(`/viewAppointment/${booking_id}`);
            settext("Consultation Appointment Management");
        } else if (appointmenttype === "Online" && booking_id) {
            navigate(`/viewAppointment/${booking_id}`);
            settext("Consultation Appointment Management");
        } else if (appointmenttype === "Op" && booking_id) {
            navigate(`/viewAppointment/${booking_id}`);
            settext("Consultation Appointment Management");
        } else if (appointmenttype === "leave") {
            navigate(`/leaveManagement`);
            settext("Leave Management");
        } else if (types === "Online" && booking_id) {
            console.log('Online')
            AppointmentDetail(booking_id)
            settext("Online Appointments");
            setActiveSidebar('/docOnline')


        } else if (types === "Op" && booking_id) {
            AppointmentDetail(booking_id)
            setActiveSidebar('/consultations')
            settext("Op Consultations");
        } else if (types === "Report" && booking_id) {
            AppointmentDetail(booking_id)
            setActiveSidebar('/docReportReview')
            settext("Report Review Appointments");
        } else if (types === "procedure" && booking_id) {
            AppointmentDetail(booking_id)
        } else if (types === "service" && booking_id) {
            AppointmentDetail(booking_id)
        } else if (types === "leave") {
            navigate(`/docleavemanagement`);
            settext("Leave Management");
        } else if (appointmenttype === "video consultation") {
            // Parse the zoomDetails JSON string

            const sessionName = zoomDetails?.sessionName;
            const password = zoomDetails?.password;

            const roletype = 0;
            startZoomVideoCall(sessionName, password, user?.name, roletype);

        }


    }




    const handleModalClose = () => {
        //setIsModalOpen(false);
    };

    const startZoomVideoCall = async (session, password, name, roletype) => {
        var win = window.open(`/onlineVideo/${session}/${password}/${name}/${roletype}`, '_blank');
        win.focus();
    }


    useEffect(() => {
        if (location) {
            console.log(location?.pathname, "k");
            if (location?.pathname === "/" || location?.pathname === "/addAppointment") {
                settext("Consultation Appointment Management");
            } else if (location?.pathname === "/profile") {
                settext("Profile");
            } else if (location?.pathname === "/department") {
                settext("Department Management");
            } else if (location?.pathname === "/doctor" || location?.pathname === "/AddDoctor") {
                settext("Doctor Management");
            } else if (location?.pathname === "/patients" || location?.pathname === "/Addpatients") {
                settext("Patient Management");
            } else if (location?.pathname === "/service" || location?.pathname === "/addBooking" || location?.pathname.startsWith("/bookingView/")) {
                settext("Service Management");
            } else if (location?.pathname === "/procedure" ||
                location.pathname === "/procedureBooking" ||
                location?.pathname === "/procedureList") {
                settext("Procedure Management");
            } else if (location?.pathname === "/settings") {
                settext("Settings");
            } else if (location?.pathname === "/roles") {
                settext("Role Management");
            } else if (location?.pathname === "/user") {
                settext("User Management");
            } else if (location?.pathname === "/blogs") {
                settext("Blog Management");
            } else if (location?.pathname === "/leaveManagement") {
                settext("Leave Management")
            }
            else if (location.pathname === "/consultations") {
                settext("Op Appointments")

            }
            else if (location.pathname === "/docOnline" || location?.pathname.startsWith("/docViewModal/")) {
                settext("Online Appointments");
            } else if (location.pathname === "/docReportReview") {
                settext("Report Review Appointments");
            } else if (location?.pathname === "/docSettings") {
                settext("Settings");
            } else if (location?.pathname === "/docleavemanagement") {
                settext("Leave Management");
            } else if (location?.pathname === "/docTimeManagement") {
                settext("Time Management");
            } else if (location?.pathname === "/docBlog") {
                settext("Blogs");
            } else if (location?.pathname === "/patient") {
                settext("Patient Managment");
            } else if (location?.pathname.startsWith("/procedureBookingView/")) {
                settext("Procedure Management");
            }
            else if (location.pathname === "/docReportReview") {
                settext("Report Review Appointments")
            }
            else if (location?.pathname === "/docSettings") {
                settext("Settings")
            }
            else if (location?.pathname === "/docleavemanagement") {
                settext("Leave Management")
            }
            else if (location?.pathname === "/docTimeManagement") {
                settext("Time Management")
            }
            else if (location?.pathname === "/docBlog") {
                settext("Blogs")
            }

            else if (location?.pathname === "/patient") {
                settext("Patient Managment")
            }
            else if (location?.pathname === "/docViewModal" || location?.pathname.startsWith("/viewAppointment/")) {
                settext("Consultation Appointment Management");
            }
        }
    }, [location]);




    useEffect(() => {
        if (context?.user?.user?._id) {
            dispatch(getProfile(context?.user?.user?._id))
        }

    }, [context])



    // useEffect(() => {
    //     if (role.name === "admin") {
    //         console.log("hh", role.name);
    //     dispatch(getNotification())
    //     }
    //     else if (role.name === "doctor") {
    //         console.log("doc");
    //         dispatch(getDoctorNotification())
    //     }

    // }, [])

    // useEffect(() => {
    //     if (context?.user?.role === 'doctor') {
    //         const id = profiledata?._id;

    //         if (id) {
    //             dispatch(getDoctorNotification(id));
    //         }
    //     } else {
    //         dispatch(getNotification());
    //     }
    // }, [context, profiledata?._id]);
    useEffect(() => {
        console.log('User Context:', context);
        console.log('User Role:', context?.user?.role);

        if (context?.user?.role === 'doctor') {
            const id = profiledata?._id;
            console.log('Doctor Profile ID:', id);

            if (id) {
                dispatch(getDoctorNotification(id));
                console.log('Calling getDoctorNotification API');
            }
        } else {
            dispatch(getNotification());
            console.log('Calling getNotification API');
        }
    }, [context, profiledata?._id, context?.user?.role]);







    const ProfileNavigate = () => {
        if (context?.user?.role === 'doctor') {
            navigate(`/docSettings`);
        } else if (context?.user?.role === 'admin') {
            navigate(`/settings`);
        }
        else {
            navigate(`/settings`)
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <GridBox>
            {width > 780 ? (
                <ThemeProvider theme={theme}>
                    <Grid
                        container
                        //   spacing={2}
                        className="grid"
                        style={{
                            display: "flex",
                            height: "80px",
                            // marginTop: "2px",
                            alignItems: "center",
                            zIndex: "1000",
                            background: "#f9fbfd"
                        }}
                    >
                        <Grid
                            className="gridcolor"
                            item
                            xs={10}
                            md={9.7}
                            style={{
                                height: "60px",

                                borderRadius: "15px 15px 15px 15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <DehazeIcon className="hamburger"
                                onClick={() => setIsDraweropen(true)}

                            />

                            <Typography
                                variant="h3"
                                color={"white"}
                                marginLeft={"10px"}
                                sx={{
                                    fontSize: {
                                        lg: 30,
                                        md: 20,
                                        sm: 18,
                                        xs: 15,
                                    },
                                    letterSpacing: "1px",
                                }}
                            >   {text}

                            </Typography>
                        </Grid>
                        <Grid
                            // className="admin"
                            item
                            lg={1.9}
                            md={1.8}
                            className="gridcolor"
                            style={{
                                height: "60px",
                                borderRadius: "15px 15px 15px 15px",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "2px",
                            }}
                        >
                            <Box
                                style={{
                                    height: "60px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    fontSize={25}
                                    color={"white"}
                                    marginLeft={2
                                    }
                                    sx={{
                                        fontSize: {
                                            lg: 25,
                                            md: 20,
                                            sm: 15,
                                            xs: 10,
                                        },
                                    }}
                                >
                                    {role?.name.split(' ')[0]}
                                </Typography>
                                {rolee?.name === "doctor" ?
                                    <Avatar
                                        variant="square"
                                        onClick={() => ProfileNavigate()}
                                        alt="Remy Sharp"
                                        src={profiledata?.image}
                                        className="personimg"
                                        sx={{
                                            width: 56,
                                            height: 60,
                                            cursor: "pointer",
                                            borderRadius: "15px 15px 15px 15px"
                                        }}
                                    />



                                    : <Avatar
                                        variant="square"
                                        onClick={() => ProfileNavigate()}
                                        alt="Remy Sharp"

                                        src={settingsList?.logo ? settingsList?.logo : img ? img : fixedUrl}

                                        className="personimg"


                                        sx={{
                                            width: 56,
                                            height: 60,
                                            cursor: "pointer",
                                            borderRadius: "15px 15px 15px 15px"
                                        }}
                                    />




                                }
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={0.3}
                            md={0.2}
                            style={{
                                height: "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "3px",
                            }}
                        >
                            {/* <Badge badgeContent={notificationList ? notificationList?.length + 1 : 0} color="error"> */}
                            <Badge badgeContent={unreadNotificationsCount} color="error">
                                <NotificationsIcon
                                    style={{ cursor: 'pointer' }}
                                    className="notification"
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                />
                            </Badge>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            ) : (
                <ThemeProvider theme={theme}>
                    <Grid
                        container
                        //   spacing={2}
                        className="grid"
                        style={{
                            display: "flex",
                            height: "100px",
                            marginTop: "2px",
                            alignItems: "center",
                            zIndex: "1000",
                        }}
                    >
                        <Grid
                            className="gridcolor"
                            item
                            xs={12}
                            style={{
                                height: "55px",

                                borderRadius: "15px 15px 15px 15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <DehazeIcon
                                className="hamburger"
                                onClick={() => setIsDraweropen(true)}
                            />
                            <Box style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-around"
                            }}>
                                <Box>
                                    <Typography
                                        variant="h3"
                                        color={"white"}
                                        marginLeft={"10px"}
                                        sx={{
                                            fontSize: {
                                                lg: 30,
                                                md: 20,
                                                sm: 18,
                                                xs: 15,
                                            },
                                        }}
                                    >
                                        Consultation Appoinment Management
                                    </Typography>
                                </Box>

                                <Box
                                    style={{
                                        display: "flex",
                                        gap: 20,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <PersonIcon className="man" style={{ color: "white" }} />
                                    <NotificationsIcon className="bell" style={{ color: "white" }} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            )
            }

            <ModalDrawer

                isDraweropen={isDraweropen}
                setIsDraweropen={setIsDraweropen}
            />
            <Fragment>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    style={{ height: "50%" }}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >

                    {notificationList?.map((res => (


                        <Box display={'flex'} gap={1} alignItems={'center'}>

                            <Box
                                padding={.5}
                                boxShadow={1}
                                borderRadius={1}
                                sx={{ width: { xs: 300, sm: 300, lg: 500, xl: 600 } }}
                                // onClick={() => {

                                //     updateNotifications(res?._id);
                                // }}
                                onClick={() => {
                                    // updateNotifications(res?._id);

                                    updateNotifications(res?._id, res?.appointmenttype, res?.booking_id, res?.types, res?.zoomDetails);

                                    // if (res?.message.includes('Leave management')) {
                                    //     navigate('/docleavemanagement');
                                    // }
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    fontFamily={'Quicksand'}
                                    fontSize={'13px'}
                                    display={'block'}
                                    fontWeight={'bold'}
                                    style={{ padding: '10px' }}
                                >
                                    Date:{moment(res?.created_at).format('DD-MM-YYYY HH:mm')}

                                </Typography>
                                <Typography
                                    variant="caption"
                                    //noWrap
                                    fontFamily={'Quicksand'}
                                    fontSize={'13px'}
                                    display={'block'}
                                    fontWeight={'bold'}
                                    style={{ padding: '10px' }}
                                >
                                    {res?.message}
                                </Typography>

                            </Box>

                            <Box
                            >
                                {res?.status === 'unread' ?
                                    <CircleIcon fontSize='2px' style={{ color: '#f44336' }} /> : ''}

                            </Box>
                        </Box>

                    )))}
                </Menu>
            </Fragment>
        </GridBox >
    );
};

export default Navbar;
