import React, { useContext, useEffect, useState } from "react";
import { BUTTONIMG } from "./style";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import mail from "../../assets/images/mail.png";
import lock from "../../assets/images/lock.png";
import logo from "../../assets/images/hlogoLogin.png";
import loginbutton from "../../assets/images/login-svgrepo-com.png";
import CustomInput from "../../components/Widgets/CustomInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from "../../Redux/constants/userConstants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { loginUsers } from "../../Redux/actions/userActions";
import { loginPhone } from "../../Redux/actions/userActions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import UserContext from "../../helpers/User";
import RoleContext from "../../helpers/Role";
import OtpInput from 'react-otp-input';
import { toast } from "react-toastify";
const Loginpatientdetails = ({ setforgetpage }) => {
    const context = useContext(UserContext)
    const roleC = useContext(RoleContext)
    console.log({roleC})
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [first, setfirst] = useState()
    const { loginSuccess, loading, error, userData, role, mobile } = useSelector(state => state?.auth)
    const id = localStorage.getItem("id");
    console.log("id ", id);
    
    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {

            handleSubmits({ otp });
        }
    };
    useEffect(() => {

        if (mobile === undefined) {

            navigate(`/login/patient`)
        }
    }, [mobile])
    useEffect(() => {
        if (loginSuccess) {

            // context.setUser(userData)
            // roleC.setRole(role?.permissions)
            // roleC.getRoutes()
            // roleC.getMenus()
            //navigate to dashboard
            navigate(`/patientaccounts`);
            dispatch({
                type: RESET_ERROR
            })
        }
        if (error) {
            //using toast display error message


            setOtp('')
            toast.error(error?.message)
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [loginSuccess, error])


    const dispatch = useDispatch();
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 450,
                md: 600,
                lg: 900,
                xl: 1200,
                tablet: 1024
            }
        }
    });
    const handleSubmits = () => {
        const data = { otp: otp, role: "patient",id:id}; 
        console.log({data},'ooo');
        dispatch(loginUsers(mobile, data));
    };

    
 
      
    
    return (
        <>
            <Container >
                <Box sx={{
                    paddingRight: "184px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100vh",
                    justifyContent: "center"

                }}>
                    <Avatar src={logo} style={{ width: "140px", height: "140px" }}>
                    </Avatar>
                    <Typography component="h2" variant="h5" color="white" fontSize="50px" fontFamily={'Quicksand'} fontWeight={"bold"} letterSpacing="2px">
                        LOGIN
                    </Typography>
                    <Grid  >
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ mt: 3 }}
                        >
                            <Box width={"100%"}>
                                {/* <CustomInput
                                    control={control}
                                    error={errors.mobile}
                                    fieldName="mobile"
                                    position={"start"}
                                    Icon={mail}
                                    placeholder={"Enter Phone Number"}
                                /> */}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ mt: 3 }}
                        >
                            <Box width={"100%"}>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    separator={<span style={{ width: "8px" }}></span>}
                                    // renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} onKeyDown={handleEnterPress} />}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle={{
                                        width: "50px !important",
                                        fontSize: "32px",
                                        padding: "10px",
                                        textAlign: "center",
                                        borderRadius: "18px",
                                        borderRadious: "5px",
                                        margin: "2px",
                                        border: "2px solid #55525c",
                                        background: "transparent",
                                        fontWeight: "bold",
                                        color: "#fff",
                                        outline: "none",
                                        transition: "all 0.1s",
                                    }}
                                    focusStyle={{
                                        boxShadow: " 0 0 2px 2px #8cc1e2",
                                        margin: '4em',
                                        padding: '1em',
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5,
                        }}
                    >
                        <BUTTONIMG onClick={handleSubmits}>
                            <img src={loginbutton} alt="image"></img>
                        </BUTTONIMG>
                    </Box>
                    {/* <Box >
                        <Box
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection={"column"}
                            mt={8}
                        >
                            <span
                                style={{
                                    color: "white",
                                    fontSize: "18px",
                                    fontFamily: "Quicksand",
                                }}
                            >
                                Can't Login?
                            </span>
                            <Link
                                style={{
                                    color: "white",
                                    fontSize: "22px",
                                    fontFamily: "Quicksand",
                                    fontWeight: "bold",
                                    marginTop: 2,
                                    textDecoration: 'none'
                                }}
                                to="/forgetpass"
                            >
                                CLICK HERE
                            </Link>
                        </Box>
                    </Box> */}

                </Box>
            </Container>
        </>
    );
};

export default Loginpatientdetails;
