import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { Avatar, Container, Divider, Grid } from "@mui/material";

const DeptDetailedPage = ({ handleClose, handleOpen, data }) => {
    const dispatch = useDispatch()
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        p: 2.5,
        width: "28%"
    };

    return (
        <Modal
            open={handleOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack
                    direction={"row"}
                    spacing={5}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ color: "#047BC1" }}
                    >
                        DEPARTMENT
                    </Typography>
                    <ClearIcon
                        style={{ cursor: "pointer", color: "black" }}
                        onClick={() => handleClose()}
                    />
                </Stack>
                <Divider />
                <Container >
                    <Box style={{ display: "flex", justifyContent: "space-around", gap: "20px", marginTop: "10px" }} >
                        <Grid container spacing={2} display="flex" justifyContent="center" >
                            <Grid item xs={6} md={12}>
                                <Typography sx={{
                                    fontSize: {
                                        lg: 15,
                                        md: 14,
                                        sm: 13,
                                        xs: 12,

                                    },
                                }}>Category</Typography>
                                <Typography
                                    style={{
                                        marginTop: "3px",
                                        borderRadius: "10px",
                                        height: "40px",
                                        background: "#e7f5f7",
                                        color: "black",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "5px"
                                    }} sx={{
                                        fontSize: {
                                            md: 14,
                                            sm: 13,
                                            xs: 12,

                                        },
                                    }}> {data?.category?.map((res, index) =>
                                        <span key={index}>{res?.name}</span>
                                    )}  </Typography>

                            </Grid>
                            <Grid item xs={6} md={12}>
                                <Typography sx={{
                                    fontSize: {
                                        md: 14,
                                        sm: 13,
                                        xs: 12,

                                    },
                                }}>Department Name</Typography>
                                <Typography
                                    style={{
                                        marginTop: "3px",
                                        borderRadius: "10px",
                                        height: "40px",
                                        background: "#e7f5f7",
                                        color: "black",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "5px"
                                    }} sx={{
                                        fontSize: {
                                            md: 14,
                                            sm: 13,
                                            xs: 12,

                                        },
                                    }}>{data?.name}</Typography>

                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={6} md={12}>
                                <Typography sx={{
                                    fontSize: {
                                        md: 14,
                                        sm: 13,
                                        xs: 12,

                                    },
                                }}>Department Image</Typography>
                                <Avatar style={{
                                    marginTop: "3px",
                                    borderRadius: "10px",
                                    height: "120px",
                                    width: "100%",
                                    objectFit: "contain",
                                    background: "#e7f5f7",
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "5px"
                                }} src={data?.image} ></Avatar>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={{ marginTop: "10px" }}>
                        <Typography sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>Description</Typography>
                        <Typography
                            style={{
                                marginTop: "3px",
                                borderRadius: "20px",

                                overflow: 'hidden',
                                padding: 8,
                                minHeight: '100px',
                                background: "#e7f5f7",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "5px"
                            }} sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                            }}>{data?.description}</Typography>
                    </Box>
                </Container>
            </Box>
        </Modal>
    )
}

export default DeptDetailedPage