import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const GridBox = styled.div`
  .grid {
    position: fixed;
    margin-left: 16.3vw;
    width: 83.5vw;
    z-index:1000;
    @media (max-width: 980px) {
      margin-left: unset;
      width: 100vw;
    }
  }
.gridcolor{
  background: transparent linear-gradient(110deg, #0e9dab 0%, #047ac3 100%) 0%
  0% no-repeat padding-box;
}
  .hamburger {
    display: none;
    color: white;
    padding-left: 12px;
    padding-right: 5px;
    @media (max-width: 980px) {
      display: block;
    }
  }

  .admin {
    @media (max-width: 680px) {
      display: none;
    }
  }
  
  .noti {
    @media (max-width: 680px) {
      display: none;
    }
  }
`;
