
import { Box, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import DataTables from '../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import data from '../../components/common/data';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomSearch from '../../components/Widgets/CustomSearch';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { getLeave } from '../../Redux/actions/leaveAction';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RecheduleModal from './ResheduleModal/RecheduleModal';

function LeaveManagement() {
  const dispatch = useDispatch()
  const Navigate = useNavigate()

  const { leaveList } = useSelector(state => state.leave);



  const [searchValue, setSearchValue] = useState(null)
  const [datas, setDatas] = useState(null)



  const NavigateToAddPage = () => {
    Navigate('/addLeave')
  }

  const NavigatetoRechedulePage = (row) => {
    Navigate('/rescheduleLeave',
      {
        state: {
          item: row
        }
      }
    )

  }





  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const filterResults = (value) => {
    setSearchValue(value)
    let datas = leaveList?.filter(res => res?.departmentinfo?.name?.toLowerCase().includes(value.toLowerCase()) || res?.doctor?.name.toLowerCase().includes(value.toLowerCase()))
    setDatas(datas)
  }


  useEffect(() => {
    if (leaveList) {
      setDatas(leaveList)
    }
  }, [leaveList])


  var columns = [
    {
      name: <b>{Capitalize("REQUEST DATE")}</b>,
      header: <b>{Capitalize("REQUEST DATE")}</b>,
      selector: (row) => row?.leave_days,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("DEPARTMENT")}</b>,
      header: <b>{Capitalize("DEPARTMENT")}</b>,
      selector: (row) => row?.departmentinfo?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("DOCTOR NAME")}</b>,
      header: <b>{Capitalize("DOCTOR NAME")}</b>,
      selector: (row) => row?.doctor?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("CONSULTATION TYPE")}</b>,
      header: <b>{Capitalize("CONSULTATION TYPE")}</b>,
      selector: (row) =>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'baseline' }}>
          <span >OP - {row?.type.includes('Op') ?
            <span style={{ color: "red" }}>Inactive</span> : <span style={{ color: "green" }}>Active</span>} </span>
          <span>Online - {row?.type.includes('Online') ? <span style={{ color: "red" }}>Inactive</span> : <span style={{ color: "green" }}>Active</span>} </span>
          <span>Report - {row?.type.includes('Report') ? <span style={{ color: "red" }}>Inactive</span> : <span style={{ color: "green" }}>Active</span>} </span>
        </div>
      ,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("STATUS")}</b>,
      header: <b>{Capitalize("STATUS")}</b>,
      selector: (row) => row?.status,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>ACTIONS</b>,
      cell: (row, index) => (
        <>
          <Stack spacing={2} direction="row">
            {row?.status === "approved" ? <CheckCircleIcon

              style={{

                color: "grey",
              }}
            /> :
              <CheckCircleIcon
                onClick={() => NavigatetoRechedulePage(row)}
                color="blue"
                style={{
                  cursor: "pointer",
                  color: "#047ac1",
                }}
              />}

          </Stack>
        </>
      ),
    },

  ];



  useEffect(() => {
    dispatch(getLeave())
  }, [])

  return (
    <>
      <Box
        sx={{
          width: "99%",
          height: "60px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexDirection: { lg: "row", md: "column", xs: "column" },
          gap: { md: "10px", xs: "10px" },
          marginBottom: "40px",
        }}
      >
        <Stack spacing={2.6} direction="row" style={{
          background: "#d8dee1",
          padding: "3px 3px 3px .1px",
          display: "flex",
          alignItems: "center",

        }} borderRadius={2}>
          <CustomSearch Icon={SearchIcon} variant={"contained"}
            value={searchValue} onSearch={(value) => filterResults(value)}
          />
          {/* <CustomButton
                        variant={"contained"}
                        text={"Filter"}
                        Icon={FilterListIcon}
                    />  */}
          <CustomButton
            submit={NavigateToAddPage}
            ComponentIcon={AddIcon}
            variant={"contained"}

          />
        </Stack>
      </Box>
      <Paper>
        <Fragment>

          <DataTables
            columns={columns}
            row={datas ? datas : []}
            pagination
          />
        </Fragment>
      </Paper>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}


      </Box>

    </>
  )
}

export default LeaveManagement