export const CREATE_APPOINMENT_SUCCESS = "CREATE_APPOINMENT_SUCCESS"
export const CREATE_APPOINMENT_FAIL = "CREATE_APPOINMENT_FAIL"
export const GET_APPOINTMENT_SUCCESS ="GET_APPOINTMENT_SUCCESS"
export const GET_APPOINTMENT_FAIL = "GET_APPOINTMENT_FAIL"
export const EDIT_APPOINTMENT_SUCCES = "EDIT_APPOINMENT_SUCCESS"
export const EDIT_APPOINTMENT_FAIL = "EDIT_APPOINTMENT_FAIL"
export const View_APPOINTMENT_SuCCESS = "VIEW_APPOINTMENT_SUCCESS"
export const VIEW_APPOINTMENT_FAIL = "VIEW_APPOINTMENT_FAIL"
export const RESCHEDULE_APPOINTMENT_SuCCESS = "RESCHEDULE_APPOINTMENT_SUCCESS"
export const RESCHEDULE_APPOINTMENT_FAIL = "RESCHEDULE_APPOINTMENT_FAIL"
export const CANCEL_APPOINTMENT_SuCCESS = "CANCEL_APPOINTMENT_SUCCESS"
export const CANCEL_APPOINTMENT_FAIL = "CANCEL_APPOINTMENT_FAIL"
export const GET_SINGLE_APPOINTMENT_SUCCESS ="GET_SINGLE_APPOINTMENT_SUCCESS"
export const GET_SINGLE_APPOINTMENT_FAIL = "GET_SINGLE_APPOINTMENT_FAIL"
export const FILTER_APPOINTMENT_SUCCESS = "FILTER_APPOINTMENT_SUCCESS"
export const FILTER_APPOINTMENT_FAIL = "FILTER_APPOINTMENT_FAIL"

export const GET_DEPT_BY_DOC_NAME_SUCCESS = "GET_DEPT_BY_DOC_NAME_SUCCESS"
export const GET_DEPT_BY_DOC_NAME_FAIL = "GET_DEPT_BY_DOC_NAME_FAIL"
export const GET_DOC_TIME_SUCCESS = "GET_DOCTOR_TIME_SUCCESS"
export const GET_DOC_TIME_FAIL = "GET_DOCTOR_TIME_FAIL"

export const CREATE_REPORT_APPOINTMENT_SUCCESS =" CREATE_REPORT_APPOINTMENT_SUCCESS"
export const CREATE_REPORT_APPOINTMENT_FAIL = "CREATE_REPORT_APPOINTMENT_FAIL"


