
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Container, Divider, Grid, } from "@mui/material";
import { Stack } from "@mui/system";
import { getSingleList } from '../../../Redux/actions/patientAction';
import Backbutton from '../../../components/Widgets/Backbutton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmailIcon from '@mui/icons-material/Email';
import blood from '../../../assets/images/blood.png'
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MaleIcon from '@mui/icons-material/Male';
import HeightIcon from '@mui/icons-material/Height';
import ScaleSharpIcon from '@mui/icons-material/ScaleSharp';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CustomSearch from '../../../components/Widgets/CustomSearch';
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import SearchIcon from "@mui/icons-material/Search";
import customAxios from '../../../customAxios';
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import moment from 'moment';
import { IMGEURL } from '../../../config/constants';
import { PDFURL } from '../../../config/constants';
import { toast } from 'react-toastify';


const DrPatienthistory = () => {
    const { patient_id } = useParams();
    const [historyDetails, setHistoryDetails] = useState(null);
    const [history, setHistory] = useState([]);
    const [datas, setDatas] = useState([])
    const dispatch = useDispatch()
    const { id } = useParams();


    useEffect(() => {
        async function getHistoryDetails() {
            try {
                let res = await customAxios.get(`/doctor/consultationhistory/${patient_id}`);
                if (res?.status === 201) {
                    const data = res?.data?.data;
                    setHistoryDetails(data?.patient);
                    setHistory(data?.history);
                } else {
                    throw new Error("Network error");
                }
            } catch (error) {
                toast.error(error?.message || error)
            }
        }
        getHistoryDetails();
    }, [patient_id]);


    return (
        <>
            <Box
                px={2}
                sx={{
                    height: "60px",
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "5px", xs: "5px" },
                    marginBottom: "30px",
                }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >Patient ID : {historyDetails?.user_id}
                    </Typography>
                </Stack>

                <Typography
                    variant="h4"
                    component="h2"
                    style={{ fontSize: "25px", color: "#047AC3" }}
                > DOB: {historyDetails?.dob}
                </Typography>

                <Typography
                    variant="h4"
                    component="h2"
                    style={{ fontSize: "25px", color: "#047AC3" }}
                >GENDER : {historyDetails?.gender}
                </Typography>
            </Box>
            <Box paddingX={10} >
                <Accordion style={{ borderRadius: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownCircleIcon style={{ color: "#047AC3", fontSize: '30px' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontFamily={'Quicksand'} fontSize={'20px'} fontWeight={'bold'}>
                            Patient Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box paddingY={2} px={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <EmailIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography variant='subtitle1' fontFamily={'Quicksand'} fontWeight={'bold'} display={'flex'}

                                            fontSize={'16px'} letterSpacing={'1px'}
                                        >    {historyDetails?.email}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <CalendarMonthIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}> {historyDetails?.dob}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <MaleIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}>{historyDetails?.gender}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <HeightIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}>{historyDetails?.height}</Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <ScaleSharpIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}>{historyDetails?.weight}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <BloodtypeIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}>{historyDetails?.bloodgroup}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <LocationOnIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}>{historyDetails?.address}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Grid container spacing={3}>

                    <Box
                        paddingX={2}
                        marginY={4}
                        style={{
                            background: '#F5FAFD',
                            borderRadius: '20px',
                            width: '1009%',
                            margin: '43px 3px 0 26px',
                            height: '836px',
                            boxShadow: '1',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            overflow: 'auto',
                        }}
                    >
                        {history?.map((item, index) => (
                            <Grid container>
                                <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        height={100}
                                    >
                                        <CalendarMonthIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography>{moment(item?.date).format('DD/MM/YYYY')}</Typography>
                                        {item?.time && (
                                        <Typography> {moment(item?.time, "HH:mm").format("hh:mm A")}</Typography>
                                        )}
                                    </Box>

                                </Grid>
                                <Grid item xs={12} sm={12} md={10} lg={11} xl={11}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                justifyContent={'center'}
                                                alignItems={'center'}>

                                                <Avatar

                                                    variant="square"
                                                    src={`${IMGEURL}${item?.doctor_details?.image}`}
                                                    style={{ width: '90px', height: '94px', borderRadius: '38px', marginTop: '9px' }}
                                                />


                                                <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>
                                                    {item?.doctor_details?.name}
                                                </Typography>

                                                <Typography fontFamily={'Quicksand'} fontSize={'14px'} letterSpacing={1}>
                                                    {item?.doctor_details?.designation}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Box>
                                                <Typography
                                                    fontFamily={'Quicksand'}
                                                    fontSize={'16px'}
                                                    fontWeight={'bold'}
                                                >Consultation Summary</Typography>
                                                <Typography
                                                    style={{
                                                        background: '#E4E4E4',
                                                        height: '100px',
                                                        //width: '250px',
                                                        borderRadius: '20px',
                                                        overflow: 'auto', 
                                                        paddingLeft: '10px'
                                                    }}>
                                                    {item?.consultation_summary}

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Box>
                                                <Typography fontFamily={'Quicksand'}
                                                    fontSize={'16px'}
                                                    fontWeight={'bold'}>Procedure</Typography>
                                                <Box
                                                    style={{
                                                        background: '#E4E4E4',
                                                        height: '100px',
                                                        //width: '250px',
                                                        borderRadius: '20px',
                                                        paddingLeft: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexWrap: 'wrap',
                                                        overflowY: 'auto'
                                                    }}>


                                                    {item?.procedure_booking?.map((procedure) => {
                                                        console.log(item)
                                                        return (

                                                            <div key={procedure._id}>
                                                                {procedure?.procedure_reports?.map((report) => (
                                                                    <div key={report?._id}>
                                                                        {report?.procedures_id?.map((item) => {
                                                                            const pdfURL = report?.attachment;

                                                                            return (
                                                                                <div key={item?.id}>
                                                                                    <a
                                                                                        href={`${PDFURL}${pdfURL}`}
                                                                                        target="_blank"
                                                                                        style={{ textDecoration: "underline", display: "block" }}
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: {
                                                                                                    md: 14,
                                                                                                    sm: 13,
                                                                                                    xs: 12,
                                                                                                },
                                                                                                marginBottom: "5px",
                                                                                            }}
                                                                                        >
                                                                                            {item?.name}
                                                                                        </Typography>
                                                                                    </a>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                ))}


                                                            </div>
                                                        );
                                                    })}


                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Box>
                                                <Typography paddingX={1} fontFamily={'Quicksand'}
                                                    fontSize={'16px'}
                                                    fontWeight={'bold'}>Services</Typography>
                                                <Box

                                                    style={{
                                                        background: '#E4E4E4',
                                                        height: '100px',
                                                        //width: '250px',
                                                        borderRadius: '20px',
                                                        paddingLeft: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexWrap: 'wrap',
                                                        overflowY: "auto"
                                                    }}>
                                                    {item?.service_booking?.map((service) => {
                                                        return (
                                                            <div key={service?._id}>
                                                                {service?.service_id?.map((item) => {
                                                                    const reportWithAttachment = service?.service_reports?.find(
                                                                        (report) => report?.service_id?.some(serviceInReport => serviceInReport?.id === item?.id)
                                                                    );
                                                                    const pdfURL = reportWithAttachment?.attachment;

                                                                    return (
                                                                        <div key={item?.id}>
                                                                            {pdfURL ? (
                                                                                <a
                                                                                    href={`${PDFURL}${pdfURL}`}
                                                                                    target="_blank"
                                                                                    style={{ textDecoration: "underline", display: "block" }}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: {
                                                                                                md: 14,
                                                                                                sm: 13,
                                                                                                xs: 12,
                                                                                            },
                                                                                            marginBottom: "5px",
                                                                                        }}
                                                                                    >
                                                                                        {item?.name}
                                                                                    </Typography>
                                                                                </a>
                                                                            ) : (
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: {
                                                                                            md: 14,
                                                                                            sm: 13,
                                                                                            xs: 12,
                                                                                        },
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                >
                                                                                    {item?.name}
                                                                                </Typography>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                    </Box>

                </Grid>



            </Box>
        </>
    )
}
export default DrPatienthistory
