import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import DataTables from '../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import data from '../../components/common/data';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import AddGroups from './GroupModal';
import { delGroups, getGroups } from '../../Redux/actions/settingsAction';
import { display } from '@mui/system';
import { RESET_ERROR } from '../../Redux/constants/userConstants';

const DoctorGroups = () => {
    const dispatch = useDispatch()
    const { groupList, error, groupDeleted } = useSelector(state => state.settings);
    const [id, setid] = useState('')
    const [open, setOpen] = useState(false);
    const [groupData, setGroupData] = useState(null)
    const [openn, setOpenn] = React.useState(false);

    const Navigate = useNavigate()

    const handleClickOpen = (id) => {
        setid(id)
        setOpenn(true);
    };

    const handleClose = () => {
        setOpenn(false);
    };
    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const editGroups = (id) => {
        // setGroupData(row)
        // setOpen(true);
        Navigate(`/editgroup/${id?._id}`)


    }


    const delGroupss = () => {

        dispatch(delGroups(id))


        if (error) {
            toast.error(error)
        }
        else { setOpenn(false) }
    }




    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    var columns = [

        {
            name: <b>{Capitalize("GROUP NAME")}</b>,
            header: <b>{Capitalize("GROUP NAME")}</b>,
            selector: (row, index) => <span style={{ padding: '2px', textAlign: 'center', fontFamily: 'Quicksand', fontWeight: 'bold' }} >{row?.name}
            </span>,
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("DOCTORS")}</b>,
            header: <b>{Capitalize("DOCTORS")}</b>,
            selector: (row) =>
                <Box paddingY={1} style={{ fontSize: "14px", display: 'flex', flexDirection: 'column', alignItems: 'baseline', height: '100px', overflowY: 'auto', width: '200px' }}>
                    {row?.doctors?.map((res, i) => (
                        <span style={{ padding: '2px', textAlign: 'center', fontFamily: 'Quicksand', fontWeight: 'bold' }} >{i + 1}. {res?.name}</span>

                    ))}</Box>,
            style: {
                textAlign: "center",

            },
        },
        {
            name: <b>ACTIONS</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (
                <>
                    <Stack spacing={2} direction="row">
                        {/* <BorderColorSharpIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() => editGroups(row)} /> */}
                        <DeleteIcon style={{ color: "#FF0000", cursor: "pointer" }} onClick={() => handleClickOpen(row?._id)

                        }
                        />
                    </Stack>
                </>

            ),
        },

    ];



    useEffect(() => {

        if (groupDeleted) {
            toast.success("Group Deleted successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, groupDeleted])


    useEffect(() => {
        dispatch(getGroups())
    }, [])

    return (
        <>
            <Paper>
                <Fragment>

                    <DataTables
                        columns={columns}
                        row={groupList ? groupList : []}
                    />
                </Fragment>
            </Paper>
            <Dialog
                open={openn}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you wish to delete this group?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={delGroupss} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <AddGroups />
            {/* {open && <AddGroups handleClose={handleClose} handleOpen={open} groupData={groupData} />} */}
        </>
    )
}

export default DoctorGroups