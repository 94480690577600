import { CREATE_BLOG_FAIL, DEL_BLOG_SUCCESS, DEL_BLOG_FAIL, CREATE_BLOG_SUCCESS, EDIT_BLOG_FAIL, EDIT_BLOG_SUCCESS, GET_BLOG_FAIL, GET_BLOG_SUCCESS, GET_SINGLE_BLOG_FAIL, GET_SINGLE_BLOG_SUCCESS } from "../constants/blogConstants";
import { LOADING } from "../constants/userConstants";
import axios from "../../customAxios"


export const createBlogs = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-blog`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_BLOG_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_BLOG_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}



export const getBlogs = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/blogs`)
        .then(async response => {
            let data = response?.data?.data;
            dispatch({
                type: GET_BLOG_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_BLOG_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const getSingleBlogs = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/get-blog/${id}`)
        .then(async response => {
            let data = response?.data?.data;
            dispatch({
                type: GET_SINGLE_BLOG_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SINGLE_BLOG_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const EditBlogs = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-blog`, data)
        .then(async response => {
            let data = response?.data?.data;
            dispatch({
                type: EDIT_BLOG_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: EDIT_BLOG_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const deleteBlog = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/delete-blog/${id}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: DEL_BLOG_SUCCESS,
                payload: id
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: DEL_BLOG_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}




