import { Avatar, Box, Button, Dialog, DialogActions, DialogTitle, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import DataTables from '../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import data from '../../components/common/data';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomSwitch from '../../components/Widgets/CustomSwitch/CustomSwitch';
import { delSlider, getSlider, statusSlider } from '../../Redux/actions/settingsAction';
import AddSlider from './SliderModal';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { useContext } from "react";
import RoleContext from "../../helpers/Role";

const Sliders = () => {
    const dispatch = useDispatch()


    const Navigate = useNavigate()

    const { error, sliderList, sliderUpdated, sliderDeleted, statusChanged } = useSelector(state => state.settings);
    const [open, setOpen] = useState(false);
    const [sliderData, setSliderData] = useState(null)
    const [openn, setOpenn] = React.useState(false);
    const context = useContext(RoleContext)
    const [id, setid] = useState('')
    const departmentsEntry = context?.role?.find(item => item?.title === 'Settings');
    const user = JSON.parse(localStorage.getItem("user"))
    let departmentsedit = false
    if (user?.role === "admin") {

        let departmentsedit = true;

    }
    else {
        departmentsedit = departmentsEntry?.edit?.selected;
    }




    const handleOpen = () => {

        setOpen(true);
    };
    const delGroupss = () => {

        dispatch(delSlider(id))


        if (error) {
            toast.error(error)
        }
        else { setOpenn(false) }
    }
    const handleClose_deletealrm = () => {
        setOpenn(false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const EditSlider = (data) => {
        setSliderData(data)
        setOpen(true);
    }

    const ChangeStatus = (checked, row) => {

        let val = {
            id: row,
            status: checked === true ? "active" : "inactive"
        }
        dispatch(statusSlider(row))
    }


    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var columns = [

        {
            name: <b>{Capitalize("SL NO")}</b>,
            header: <b>{Capitalize("SL NO")}</b>,
            selector: (row, index) => index + 1,
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("IMAGE")}</b>,
            header: <b>{Capitalize("IMAGE")}</b>,
            cell: (row, index) => (
                <>
                    <Stack direction="row" style={{ justifyContent: "center", display: "flex", padding: "5px" }} spacing={1}>
                        <Avatar
                            style={{
                                borderRadius: '10px'
                            }}
                            variant="square"
                            alt="slider iMage"
                            src={row?.image}
                            sx={{ width: 150, height: 80 }}

                        />
                    </Stack>

                </>
            ),
        },
        // {
        //     name: <b>{Capitalize("NAME")}</b>,
        //     header: <b>{Capitalize("NAME")}</b>,
        //     selector: (row) => row?.name,
        //     style: {
        //         textAlign: "center",

        //     },
        // },
        {
            name: <b>{Capitalize("NAME")}</b>,
            header: <b>{Capitalize("NAME")}</b>,
            selector: (row) => (
                <Tooltip title={Capitalize(row?.name)}>
                    <div style={{
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {row?.name}
                    </div>
                </Tooltip>
            ),
        },

        {
            name: <b>{Capitalize("ORDER")}</b>,
            header: <b>{Capitalize("ORDER")}</b>,
            selector: (row) => row?.order,


            style: {
                textAlign: "center",

            },
        },
        {
            name: <b>ACTIONS</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (
                <>
                    <Stack spacing={2} direction="row">
                        {user?.role === 'admin' ? (
                            <BorderColorSharpIcon
                                style={{ color: "#047ac1", cursor: "pointer" }}
                                onClick={() => EditSlider(row)}
                            />
                        ) : (
                            departmentsedit && (
                                <BorderColorSharpIcon
                                    style={{ color: "#047ac1", cursor: "pointer" }}
                                    onClick={() => EditSlider(row)}
                                />
                            )
                        )}


                        <DeleteIcon style={{ color: "#FF0000", cursor: "pointer" }} onClick={() => handleDelete(row._id)} />
                    </Stack>
                </>
            ),
        },
        // {
        //     name: <b>STATUS</b>,
        //     id: "delete",
        //     accessor: (str) => "delete",
        //     cell: (row, index) => (
        //         <>
        //             <Stack spacing={1} direction="row">
        //                 <CustomSwitch defaultChecked={row?.status === "active" ? true : false}
        //                     onClick={(e) => ChangeStatus(e.target.checked, row._id)}
        //                 />
        //             </Stack>
        //         </>
        //     ),
        // },
    ];


    const handleDelete = (id) => {

        setid(id)
        setOpenn(true);
    }



    useEffect(() => {
        dispatch(getSlider())
        if (sliderUpdated) {
            toast.success("Slider Updated successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
        }
        if (sliderDeleted) {
            toast.success("Slider Deleted successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        if (statusChanged) {
            toast.success("Status Changed successfully");
            dispatch({
                type: RESET_ERROR
            })
        }


        // if (error) {
        //     toast.error(`${error.message}`);
        //     dispatch({
        //         type: RESET_ERROR
        //     })
        // }
    }, [sliderUpdated, sliderDeleted, statusChanged, error])


    return (
        <>
            <Paper>
                <Fragment>

                    <DataTables
                        columns={columns}
                        row={sliderList ? sliderList : []}
                    />
                </Fragment>
            </Paper>
            <Box
                sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                <Dialog
                    open={openn}
                    onClose={handleClose_deletealrm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure you wish to delete this slider?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose_deletealrm}>No</Button>
                        <Button onClick={delGroupss} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <AddSlider handleClose={handleClose} handleOpen={open} sliderData={sliderData} />
            </Box></>
    )
}

export default Sliders
