import { PROFILE_FAILED, PROFILE_SUCCESS, PROFILE_UPDATE_FAILED, PROFILE_UPDATE_SUCCESS, ZOOM_DATA } from "../constants/profileConstants";
import { LOADING, RESET_ERROR } from "../constants/userConstants";


export const ProfileReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                profileList: null,
                profileupdatedsuccess   : null,
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case PROFILE_SUCCESS:
            return {
                ...state,
                profiledata: action?.payload,
                profileList: true
            }

        case PROFILE_FAILED:
            return {
                ...state,
                error: action.payload
            }

        case PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                profiledata: action?.payload,
                profileupdatedsuccess: true,
            }

        case PROFILE_UPDATE_FAILED:
            return {
                ...state,
                error: action?.payload
            }

        case ZOOM_DATA:
            return {
                ...state,
                zoomdata: action?.payload
            }

        default:
            return state;

    }

}