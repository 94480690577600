import { Action } from "@remix-run/router";
import { CANCEL_APPOINTMENT_FAIL, CANCEL_APPOINTMENT_SuCCESS, CREATE_APPOINMENT_FAIL, CREATE_APPOINMENT_SUCCESS, CREATE_REPORT_APPOINTMENT_FAIL, CREATE_REPORT_APPOINTMENT_SUCCESS, EDIT_APPOINTMENT_FAIL, EDIT_APPOINTMENT_SUCCES, FILTER_APPOINTMENT_FAIL, FILTER_APPOINTMENT_SUCCESS, GET_APPOINTMENT_FAIL, GET_APPOINTMENT_SUCCESS, GET_DEPT_BY_DOC_NAME_FAIL, GET_DEPT_BY_DOC_NAME_SUCCESS, GET_DOC_TIME_FAIL, GET_DOC_TIME_SUCCESS, GET_SINGLE_APPOINTMENT_FAIL, GET_SINGLE_APPOINTMENT_SUCCESS, RESCHEDULE_APPOINTMENT_FAIL, RESCHEDULE_APPOINTMENT_SuCCESS, VIEW_APPOINTMENT_FAIL, View_APPOINTMENT_SuCCESS } from "../constants/appointmentConst";
import { RESET_ERROR } from "../constants/userConstants";


export const appointmentReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                resettime: null,
                avabilityTime: null,
                appointmentCreated: null,
                appointmentEdited: null,
                appointmentRescheduled: null,
                appoim: null,
                appointmentCancel: null,
                appointmentReportCreated: null
            }

        case GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointmentList: action.payload
            }
        case GET_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_APPOINMENT_SUCCESS:
            return {
                ...state,
                // appointmentList: state?.appointmentList ? [...state?.appointmentList, action.payload] : action.payload,
                appointmentCreated: true
            }

        case CREATE_APPOINMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_DEPT_BY_DOC_NAME_SUCCESS:
            return {
                ...state,
                doctorNameList: action.payload
            }
        case GET_DEPT_BY_DOC_NAME_FAIL:
            return {

                ...state,
                error: action.payload,
                appoim: true,
                doctorNameList: []
            }

        case GET_DOC_TIME_SUCCESS:
            return {
                ...state,
                avabilityTime: action.payload,

            }
        case GET_DOC_TIME_FAIL:
            return {
                ...state,
                error: action.payload,
                avabilityTime: null
            }
        case EDIT_APPOINTMENT_SUCCES:
            return {
                ...state,
                appointmentList: state.appointmentList?.map(app => app._id === action.payload._id ? action.payload : app),
                appointmentEdited: true,
            }
        case EDIT_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case View_APPOINTMENT_SuCCESS:
            return {
                ...state,
                viewAppointmentList: action.payload
            }
        case VIEW_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CANCEL_APPOINTMENT_SuCCESS:
            return {
                ...state,
                appointmentCancel: true
            }
        case CANCEL_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case RESCHEDULE_APPOINTMENT_SuCCESS:
            return {
                ...state,
                appointmentList: state.appointmentList?.map(app => app._id === action.payload._id ? action.payload : app),
                appointmentRescheduled: true
            }
        case RESCHEDULE_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_SINGLE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                sigleAppointmentList: action.payload
            }
        case GET_SINGLE_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case FILTER_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointmentList: action.payload,

            }
        case FILTER_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_REPORT_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointmentReportCreated: true,
            }
        case CREATE_REPORT_APPOINTMENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;

    }

}