import { Circle } from '@mui/icons-material'
import { Box, Grid, MenuItem, Stack, Typography, TextField, IconButton, Select } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Backbutton from '../../../components/Widgets/Backbutton'
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton'
import CustomFormInput from "../../../components/Widgets/CustomFormInput";
import AddIcon from '@mui/icons-material/Add';
import CustomSelectInput from '../../../components/Widgets/CustomSelect';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import ReviewModal from './ReviewModal'
import customAxios from '../../../customAxios';
import moment from 'moment'
import { toast } from 'react-toastify';
import CustomLabel from '../../../components/EditConsultation/CustomLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { PDFURL } from '../../../config/constants';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ZOOM_DATA } from '../../../Redux/constants/profileConstants';

const DrEditMyConsultation = () => {

    const navigate = useNavigate();
    const [open, setopen] = useState(false);
    const schema = yup.object().shape({
        remarks: yup.string().required("Consultation Summary is required"),
        status: yup.string().required("Appointment Status is required"),
    });

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const { id } = useParams();
    const { patient_id } = useParams();
    const [consultationDetails, setConsultationDetails] = useState(null)
    console.log({ consultationDetails })
    const [selectedProcedures, setSelectedProcedures] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedProcedureNames, setSelectedProcedureNames] = useState([]);
    const [selectedServiceNames, setSelectedServiceNames] = useState([]);
    const [status, setStatus] = useState('');
    const [procedureId, setProcedureId] = useState(null)
    const [serviceId, setserviceId] = useState(null)
    const [groupValue, setGroupValue] = useState([]);
    const [dept, setDept] = useState("");

    const [loading, setLoading] = useState(false);

    const [savedProcedures, setSavedProcedures] = useState(null)
    const [savedServices, setSavedServices] = useState(null)

    const { profiledata } = useSelector((state) => state?.profile);



    const handleViewHistory = () => {
        if (consultationDetails?.patient_id) {
            navigate(`/docPatientHistory/${consultationDetails?.patient_id}`);
        }
    };
    const handleDeleteProcedure = (nameToDelete) => {
        setSelectedProcedures(prevProcedures => {
            const updatedProcedures = prevProcedures.filter(proc => proc?.name !== nameToDelete);
            return updatedProcedures;
        });
    };
    const consultationType = consultationDetails?.type;


    const handleDeleteService = (nameToDelete) => {
        setSelectedServices(prevServices => {
            const updatedServices = prevServices.filter(service => service?.name !== nameToDelete);
            return updatedServices;
        });
    };



    useEffect(() => {

    }, [selectedProcedures]);

    useEffect(() => {

    }, [selectedServices]);


    useEffect(() => {

        async function getConsultationDetails() {
            console.log({ getConsultationDetails })
            try {
                let res = await customAxios.get(
                    `/doctor/consultationdetail/${id}`
                );
                if (res?.status === 201) {
                    let data = res?.data?.data;
                    setConsultationDetails(data);
                    setValue("remarks", data?.consultation_summary)


                    let procedures = []
                    let services = [];

                    data?.procedure_booking?.map(proc => {
                        proc?.procedure_id?.map(pro => {
                            procedures?.push(pro?.id)
                        })
                    })
                    data?.service_booking?.map(proc => {
                        proc?.service_id?.map(pro => {
                            services?.push(pro?.id)
                        })
                    })
                    setSavedProcedures(procedures)
                    setSavedServices(services)

                } else {
                    throw new Error("Network error");
                }
            } catch (error) {
                toast.error(error?.message || error)
            }
        }

        getConsultationDetails();
    }, [id]);
    console.log({ consultationDetails })
    useEffect(() => {
        async function fetchCategories() {
            try {
                const response = await customAxios.get('/doctor/category');
                if (response?.status === 201) {
                    let procedure = response?.data?.data?.find(cat => cat?.name === "Procedures");
                    if (procedure) {
                        setProcedureId(procedure?._id)
                    }

                    let service = response?.data?.data?.find(cat => cat?.name === "Services");
                    if (service) {
                        setserviceId(service?._id)
                    }
                    //setCategories(response.data.data);
                } else {
                    throw new Error("Network error");
                }
            } catch (error) {
                toast.error(error?.message || error)
            }
        }
        fetchCategories();
    }, []);

    const submit = async (data) => {
        let procedures = [];

        selectedProcedures?.map(proc => {
            if (!proc?.groupable) {
                procedures?.push({ department: proc?.department_id, procedures: [proc?._id], groupable: false });
            } else {
                let existing = procedures?.find(pro => pro?.department === proc?.department_id && pro?.groupable === true);
                if (existing) {
                    existing?.procedures?.push(proc?._id);
                } else {
                    procedures?.push({ department: proc?.department_id, procedures: [proc?._id], groupable: true });
                }
            }
        });

        let services = [];

        selectedServices?.map(serv => {
            if (!serv?.groupable) {
                services?.push({ department: serv?.department_id, services: [serv?._id], groupable: false });
            } else {
                let existing = services?.find(ser => ser?.department === serv?.department_id && ser?.groupable === true);
                if (existing) {
                    existing?.services?.push(serv?._id);
                } else {
                    services?.push({ department: serv?.department_id, services: [serv?._id], groupable: true });
                }
            }
        });

        try {
            setLoading(true);
            const requestData = {
                booking_id: consultationDetails?._id,
                consultation_summary: data?.remarks,
                status: status,
                procedures: procedures,
                services: services,
                patient_id: consultationDetails?.patient_id,
                doctor_id: consultationDetails?.doctor_id,
            };
            const res = await customAxios.post("/doctor/updateconsultation", requestData);

            if (res?.status === 201) {
                toast.success("Updated successfully");
                navigate(-1);
            } else {
                throw new Error("Failed to update");
            }
        } catch (error) {
            toast.error(error?.message || error)
        } finally {
            setLoading(false);
        }
    };


    // //Start Zoom Video Call
    // const startZoomVideoCall = async () => {
    //     var win = window.open(`/onlineVideo/${consultationDetails?.zoomDetails?.sessionName}/${consultationDetails?.zoomDetails?.password}/${profiledata?.name}`, '_blank');
    //     win.focus();

    //     //console.log({UIToolkitConfig})

    //     // let UIToolkit = document.createElement('app-uitoolkit')

    //     // UIToolkit.setAttribute("uitoolkitconfig", UIToolkitConfig)

    //     // document.getElementById('UIToolkit').append(UIToolkit)


    // }



    return (
        <Box>
            <Stack
                direction={"row"}
                spacing={1}
                style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}
            >
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    > Patient ID: {consultationDetails?.patient?.user_id}
                    </Typography>

                </Box>
                <Box>
                    <CustomButton text={'View History'} variant={'contained'} submit={handleViewHistory} />
                </Box>
            </Stack>

            <Grid container spacing={2} px={5} mt={2}>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Name</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationDetails?.patient?.name}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Booked Date & Time</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#f6f6f6",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{moment(`${consultationDetails?.date} ${consultationDetails?.time}`, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY hh:mm A")}</Typography>
                </Grid>
                {consultationDetails?.token && (
                    <Grid item xs={12} md={3}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"

                        }}>Token No</Typography>
                        <Typography
                            style={{
                                marginTop: "3px",
                                borderRadius: "10px",
                                height: "40px",
                                background: "#f6f6f6",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "10px"
                            }} sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                            }}>{consultationDetails?.token}</Typography>
                    </Grid>
                )}
                <Grid item xs={12} md={8}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Remarks</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "130px",
                            background: "#f6f6f6",
                            color: "black",
                            paddingLeft: "10px",
                            overflowY: "auto"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{consultationDetails?.remarks}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Reports</Typography>
                    <div style={{ marginTop: "3px", borderRadius: "10px", background: "#f6f6f6", color: "black", padding: "10px", display: "flex", minHeight: "130px", overflowY: "auto" }}>
                        <div style={{ flex: 1 }}>
                            <Typography sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,
                                },
                                marginBottom: "5px",
                                fontWeight: "bold",
                            }}>Procedures</Typography>
                            <div style={{ maxHeight: "100px", maxWidth: "500px" }}>
                                {consultationDetails?.procedure_booking?.map((procedure) => (
                                    // console.log(consultationDetails),
                                    console.log(procedure),
                                    <div key={procedure._id}>
                                        {procedure?.procedure_reports?.map((report) => (
                                            <div key={report._id}>
                                                {report?.procedures_id?.map((item) => {
                                                    const pdfURL = report?.attachment;

                                                    return (
                                                        <div key={item?.id}>
                                                            <a
                                                                href={`${PDFURL}${pdfURL}`}
                                                                target="_blank"
                                                                style={{ textDecoration: "underline", display: "block" }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: {
                                                                            md: 14,
                                                                            sm: 13,
                                                                            xs: 12,
                                                                        },
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    {item?.name}
                                                                </Typography>
                                                            </a>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ))}


                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,
                                },
                                marginBottom: "5px",
                                fontWeight: "bold",
                            }}>Services</Typography>
                            <div style={{ maxHeight: "300px" }}>
                                {consultationDetails?.service_booking?.map((service) => {
                                    return (
                                        <div key={service?._id}>
                                            {service?.service_id?.map((item) => {
                                                const reportWithAttachment = service?.service_reports?.find(
                                                    (report) => report?.service_id?.some(serviceInReport => serviceInReport?.id === item?.id)
                                                );
                                                const pdfURL = reportWithAttachment?.attachment;

                                                return (
                                                    <div key={item?.id}>
                                                        {pdfURL ? (
                                                            <a
                                                                href={`${PDFURL}${pdfURL}`}
                                                                target="_blank"
                                                                style={{ textDecoration: "underline", display: "block" }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: {
                                                                            md: 14,
                                                                            sm: 13,
                                                                            xs: 12,
                                                                        },
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    {item?.name}
                                                                </Typography>
                                                            </a>
                                                        ) : (
                                                            <Typography
                                                                sx={{
                                                                    fontSize: {
                                                                        md: 14,
                                                                        sm: 13,
                                                                        xs: 12,
                                                                    },
                                                                    marginBottom: "5px",
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}



                            </div>


                        </div>


                    </div>
                </Grid>
                {consultationDetails && (
                    <Grid item xs={12} md={8}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"
                        }}>Attachment</Typography>
                        <div
                            style={{
                                marginTop: "3px",
                                background: "#f6f6f6",
                                color: "black",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                padding: "10px",
                                overflowY: "auto",
                                minHeight: "130px",
                                borderRadius: "10px"
                            }}
                            sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,
                                },
                            }}
                        >
                            {consultationDetails?.attachment && consultationDetails?.attachment?.map((attachment) => (
                                <div key={attachment?.id}>
                                    <a
                                        href={`${consultationDetails?.basepath}${attachment?.image}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "underline", display: "block", marginBottom: "10px" }}
                                    >
                                        {attachment?.name}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </Grid>
                )}


            </Grid>
            <Box display={'flex'} alignItems={'center'} gap={2} my={5}>
                {/* {"firstPart" === 'docEditMyConsultation' && (
                    <Box display={'flex'} alignItems={'center'} gap={2} my={5}>
                        <CustomButton variant={"contained"} text={"Start Video Call"}
                            submit={startZoomVideoCall}
                        />
                    </Box>
                )} */}
            </Box>


            <Box display={'flex'} alignItems={'center'} gap={2} my={5}>
                <Box width={'100%'} border={'1px solid #328bb8'} />
                <Box> <Circle style={{ color: '#328bb8' }} /></Box>
                <Box width={'100%'} border={'1px solid #328bb8'} />
            </Box>


            <Grid container spacing={3} px={5}>
                <Grid item xs={12} md={4} lg={3}>
                    <CustomFormInput
                        marginTop="3px"
                        borderRadius="10px"
                        height="150px"
                        background="#ffff"
                        color="black"
                        display="flex"
                        alignItems="center"
                        paddingLeft="80px"
                        fieldLabel="Consultation Summary"
                        control={control}
                        maxrow={4}
                        //value={consultationDetails?.consultation_summary}
                        // readOnly={id ? true : false}

                        multiline
                        error={errors.remarks}
                        fieldName="remarks"
                        type="text"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box display="flex" flexDirection="row" alignItems="center" gap={3} marginTop={-2}>
                        <Typography variant="subtitle2" sx={{ fontSize: '14px', marginTop: 2 }}>Refer a Procedure</Typography>
                        <button
                            type="button"
                            onClick={() => setopen({ type: 'procedure', open: true })}
                            style={{
                                width: '25px',
                                height: '25px',
                                backgroundColor: 'rgb(4, 122, 195)',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '2px',
                                marginLeft: '2px',
                            }}
                        >
                            <AddIcon style={{ color: 'white' }} />
                        </button>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                        gap={3}
                        marginTop="3px"
                        borderRadius="10px"
                        height="150px"
                        backgroundColor="white"
                        color="black"
                        border="1px solid black"
                        paddingLeft="10px"
                        overflowY="auto" 
                     
                    >
                        <Grid container spacing={2}>
                            <Grid item margin={0.5} display={"flex"} flexDirection={"row"} flexWrap={"wrap"} >
                                {selectedProcedures?.map((proc, index) => (

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        border: '1px solid gray',
                                        padding: 4,
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 0.5,
                                        marginLeft: 1,
                                     
                                    }}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                whiteSpace: 'nowrap',
                                                marginRight: '5px',
                                                
                                            }}
                                        >
                                            {proc?.name}
                                        </Typography>
                                        <DeleteIcon
                                            onClick={() => handleDeleteProcedure(proc?.name)}
                                            sx={{
                                                fontSize: '18px',
                                                border: '1px solid red',
                                                padding: 0.5,
                                                marginLeft: 1,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                ))}

                              
                                {consultationDetails?.referral && consultationDetails?.referral?.map((referral, index) => (
                                    <div key={index}>
                                        {Object.keys(referral).map((key, innerIndex) => (
                                            key.startsWith('procedure_id') && Array.isArray(referral[key]) ? (
                                                referral[key].map((procedure, procedureIndex) => (
                                                    procedure?.name ? (
                                                        <div
                                                            key={procedureIndex}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                padding: 4,
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontSize: '12px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    whiteSpace: 'nowrap',
                                                                    margin: '2px 0',
                                                                }}
                                                            >
                                                                {procedure?.name}
                                                            </Typography>
                                                        </div>
                                                    ) : null
                                                ))
                                            ) : null
                                        ))}
                                    </div>
                                ))}




                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box display="flex" flexDirection="row" alignItems="center" gap={3} marginTop={-2}>
                        <Typography variant="subtitle2" sx={{ fontSize: '14px', marginTop: 2 }}>Refer a Service</Typography>
                        <button
                            type="button"
                            onClick={() => setopen({ type: 'service', open: true })}
                            style={{
                                width: '25px',
                                height: '25px',
                                backgroundColor: 'rgb(4, 122, 195)',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '2px',
                                marginLeft: '2px',
                            }}
                        >
                            <AddIcon style={{ color: 'white' }} />
                        </button>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-start"
                        gap={3}
                        marginTop="3px"
                        borderRadius="10px"
                        height="150px"
                        backgroundColor="white"
                        color="black"
                        border="1px solid black"
                        paddingLeft="10px"

                    >
                        <Grid container spacing={2}>
                            <Grid item margin={0.5} display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                                {selectedServices?.map((proc, index) => (

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        border: '1px solid gray',
                                        padding: 4,
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 0.5,
                                        marginLeft: 1
                                    }}>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                whiteSpace: 'nowrap',
                                                marginRight: '5px',
                                            }}
                                        >
                                            {proc?.name}
                                        </Typography>
                                        <DeleteIcon
                                            onClick={() => handleDeleteService(proc?.name)}
                                            sx={{
                                                fontSize: '18px',
                                                border: '1px solid red',
                                                padding: 0.5,
                                                marginLeft: 1,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                ))}
                                
                                {consultationDetails?.referral && consultationDetails?.referral?.map((referral, index) => (
                                    <div key={index}>
                                        {Object.keys(referral).map((key, innerIndex) => (
                                            key.startsWith('service_id') && Array.isArray(referral[key]) ? (
                                                referral[key].map((service, serviceIndex) => (
                                                    service?.name ? (
                                                        <div
                                                            key={`${innerIndex}-${serviceIndex}`}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                padding: 4,
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontSize: '12px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    whiteSpace: 'nowrap',
                                                                    margin: '2px 0',
                                                                }}
                                                            >
                                                                {service?.name}
                                                            </Typography>
                                                        </div>
                                                    ) : null
                                                ))
                                            ) : null
                                        ))}
                                    </div>
                                ))}

                            </Grid>
                        </Grid>
                    </Box>

                </Grid>

                <Grid container style={{ maxWidth: '771px', margin: '0 0 0 27px' }}>
                    <Grid item xs={12} md={3} style={{ textAlign: 'left' }}  >
                        <Typography>Appointment Status</Typography>
                        <Select
                            {...register("status")}
                            fieldName="status"
                            control={control}

                            background="#e7f5f7"
                            style={{ height: '45px', fontSize: '18px', width: '100%', background: '#FFFFFF' }}
                            onChange={(e) => {
                                setStatus(e.target.value);

                            }}
                        >
                            <MenuItem value="" disabled>
                                Select status
                            </MenuItem>
                            {["Pending Review", "Completed"].map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.status && (
                            <Typography variant="body2" color="error">
                                {errors.status.message}
                            </Typography>
                        )}
                    </Grid>

                </Grid>

            </Grid>

            <Box py={5} display={'flex'} justifyContent={'center'}>
                <CustomButton variant={"contained"} text={loading ? "Loading ..." : "Submit"}
                    disabled={loading ? true : false} submit={handleSubmit(submit)}
                />
            </Box>

            {
                open.open && (
                    <ReviewModal
                        open={open}
                        handleClose={() => setopen({ open: false })}
                        serviceId={serviceId}
                        procedureId={procedureId}
                        selectedDepartment={selectedDepartment}
                        setSelectedDepartment={setSelectedDepartment}
                        selectedProcedures={selectedProcedures}
                        setSelectedProcedures={setSelectedProcedures}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                        selectedProcedureNames={selectedProcedureNames}
                        setSelectedProcedureNames={setSelectedProcedureNames}
                        selectedServiceNames={selectedServiceNames}
                        setSelectedServiceNames={setSelectedServiceNames}
                        savedProcedures={savedProcedures}
                        savedServices={savedServices}

                    />


                )
            }



        </Box >
    )
}

export default DrEditMyConsultation