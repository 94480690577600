import { LOADING } from "../constants/userConstants";
import axios from "../../customAxios"
import { 
    EDIT_ROLE_FAIL,
    EDIT_ROLE_SUCCESS,
    GET_SINGLE_ROLE_FAIL,
    GET_SINGLE_ROLE_SUCCESS,
    NEW_ROLE_FAIL,
    NEW_ROLE_SUCCESS, 
    ROLE_LIST_FAIL, 
    ROLE_LIST_SUCCESS
} from "../constants/roleConstant";
import { GET_SINGLE_LIST_SUCCESS } from "../constants/serviceConstant";

//Get all roles
export const getAllRoles = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/roles`)
    .then(async response => {
        dispatch({
            type: ROLE_LIST_SUCCESS,
            payload: response?.data?.data
        });

        dispatch({
            type: LOADING,
            payload: false
        })


    }).catch(async error => {
        dispatch({
            type: ROLE_LIST_FAIL,
            payload: error
        })

        dispatch({
            type: LOADING,
            payload: false
        })
    })
}

//Save New Role
export const saveNewRole = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/role-create`, data)
    .then(async response => {
        dispatch({
            type: NEW_ROLE_SUCCESS,
            payload: response.data?.data
        });

        dispatch({
            type: LOADING,
            payload: false
        })


    }).catch(async error => {
        dispatch({
            type: NEW_ROLE_FAIL,
            payload: error
        })

        dispatch({
            type: LOADING,
            payload: false
        })
    })
}

export const updateNewRole = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/role-update`, data)
    .then(async response => {
        dispatch({
            type: EDIT_ROLE_SUCCESS,
            payload: response.data?.data
        });

        dispatch({
            type: LOADING,
            payload: false
        })


    }).catch(async error => {
        dispatch({
            type:EDIT_ROLE_FAIL,
            payload: error
        })

        dispatch({
            type: LOADING,
            payload: false
        })
    })
}

export const getSinglerole = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/roles/show/${id}`)
    .then(async response => {
        dispatch({
            type:GET_SINGLE_ROLE_SUCCESS,
            payload: response?.data?.data
        });

        dispatch({
            type: LOADING,
            payload: false
        })


    }).catch(async error => {
        dispatch({
            type:GET_SINGLE_ROLE_FAIL,
            payload: error
        })

        dispatch({
            type: LOADING,
            payload: false
        })
    })
}