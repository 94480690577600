import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import CustomFormInput from "../../../../components/Widgets/CustomFormInput";
import CustomButton from "../../../../components/Widgets/CustomButton/CustomButton";
import { Cancelbooking, postReferalbooking, postServiceTime, Reshedulebooking } from "../../../../Redux/actions/serviceAction";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Divider, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import CustomCalender from '../../../../components/Widgets/CustomCalender';
import CustomSelectInput from '../../../../components/Widgets/CustomSelect';
import { postProcedureReferalBooking, postProcedureTime, ReshedulebookingProcedure } from '../../../../Redux/actions/procedureAction';
import CustomCheckBox from '../../../../components/Widgets/CustomCheckBox';
const BookingResheduleModal = ({ handleClose, handleOpen, store, procedure, service, referal, referalP }) => {


    const dispatch = useDispatch()

    const { serviceTimelist } = useSelector(state => state.service);

    const { procedureTimeList } = useSelector(state => state.procedure);

    const schema = object().shape({
        date: yup.string().max(100).required("Date is required"),
        time: yup.string().max(100).required("Time is required"),
    });

    const [dateTime, setDateTime] = useState(null)
    const [time, setTime] = useState(null)
    const [procedureArray, setSetProcedureArray] = useState([])
    const [serviceArray, setServiceArray] = useState([])
    const [amount, setamount] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentStatus, setPaymentStatus] = useState(null)


    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),

    });

    const style = {
        position: "absolute",
        top: "38%",
        left: "51%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        p: 2.5,
    };

    const onChangeTime = (e) => {
        setTime(e.target.value)
        setValue('time', e.target.value)
    }

    const onChangeDateNtime = (value) => {
        setDateTime(value)
        setValue("date", moment(value).format('DD-MM-YYYY'))
        if (value && store) {

            if (procedure === "procedure") {
                const val = {
                    date: moment(value).format('DD-MM-YYYY'),
                    procedure_id: store?.procedure_id?.[0]?.id
                }
                dispatch(postProcedureTime(val))
            } else if (service === 'service') {
                const val = {
                    date: moment(value).format('DD-MM-YYYY'),
                    service_id: store?.service_id?.[0]?.id
                }
                dispatch(postServiceTime(val))
            } else if (referal === 'referal') {
                const val = {
                    date: moment(value).format('DD-MM-YYYY'),
                    service_id: procedureArray[0]?.id
                }
                dispatch(postServiceTime(val))

            } else if (referalP === 'referalP') {
                const val = {
                    date: moment(value).format('DD-MM-YYYY'),
                    procedure_id: procedureArray[0]?.id
                }
                dispatch(postProcedureTime(val))

            }

        }

    }


    const clearClose = () => {
        setDateTime(null)
        setTime(null)
        setValue("date", "")
        setValue("time", "")
        handleClose()
    }

    const SubmitDate = (data) => {
        let val = {
            id: store?._id,
            date: data?.date,
            time: data?.time
        }

        if (procedure === "procedure") {
            dispatch(ReshedulebookingProcedure(val))
        } else if (service === 'service') {
            dispatch(Reshedulebooking(val))

        } else if (referal) {
            let val = {
                patient_id: store?.patient_id,
                department_id: store?.department_id,
                service_id: procedureArray?.map((res) => res?.id),
                amount: amount,
                date: data?.date,
                time: data?.time,
                payment_method: data?.paymentmethod,
                payment_status: data?.paymentstatus,
                transactionref: data?.transactionref,
                doctor_id: store?.doctor_id,
                referral_id: store?._id,
                refferd_by: store?.doctor?.name,
                booking_id: store?.reference_booking

            }

            dispatch(postReferalbooking(val))
        } else if (referalP) {
            let val = {
                patient_id: store?.patient_id,
                department_id: store?.department_id,
                procedure_id: procedureArray?.map((res) => res?.id),
                amount: amount,
                date: data?.date,
                time: data?.time,
                payment_method: data?.paymentmethod,
                payment_status: data?.paymentstatus,
                transactionref: data?.transactionref,
                doctor_id: store?.doctor_id,
                referral_id: store?._id,
                refferd_by: store?.doctor?.name,
                booking_id: store?.reference_booking

            }

            dispatch(postProcedureReferalBooking(val))

        }
        setDateTime(null)
        setTime(null)
        setValue("date", "")
        setValue("time", "")
    }


    const onChangeCheckbox = (e, index) => {
        const { value, checked } = e.target;
        if (checked) {
            if (referal) {
                let find = store?.service_id.filter((res) => res?.id.includes(value))?.map((res) => (
                    res
                ))
                setSetProcedureArray([...procedureArray, ...find])

            } else if (referalP) {
                let find = store?.procedure_id.filter((res) => res?.id.includes(value))?.map((res) => (
                    res
                ))
                setSetProcedureArray([...procedureArray, ...find])
            }

        } else {
            let array = procedureArray
            let filter = array.filter((res) => res?.id !== value)
            setSetProcedureArray([])
            setSetProcedureArray([...filter])

        }

    }


    const onChangepaymentMethod = (e) => {
        setValue("paymentmethod", e.target.value);
        setPaymentMethod(e.target.value)
    }


    const onChangepaymentStatus = (e) => {
        setPaymentStatus(e.target.value)
        setValue("paymentstatus", e.target.value);
    }


    //calculate amount..........................................................................

    useEffect(() => {
        if (procedureArray) {
            let amount = procedureArray?.reduce((p, s) => parseInt(p) + parseInt(s?.price), 0)

            setamount(amount)

        }

    }, [procedureArray])



    return (
        <Modal
            open={handleOpen}
            onClose={clearClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>



                <Stack
                    direction={"row"}
                    spacing={5}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ color: "#047BC1" }}
                    >
                        {referal || referalP ? 'REFERAL BOOKING' : 'RESCHEDULE'}

                    </Typography>
                    <ClearIcon
                        style={{
                            cursor: "pointer",
                            color: "black"
                        }}
                        onClick={() => clearClose()}
                    />
                </Stack>
                <Divider />
                <Box display={"flex"} gap={"10px"} flexDirection={"column"} paddingTop={"20px"}>
                    {referal ?
                        <Box>
                            {store?.service_id?.map((res, index) =>
                                <Box
                                    style={{ background: 'white', display: 'flex', alignItems: 'center', height: '40px', justifyContent: 'space-between', gap: "30px" }}
                                    borderRadius={3}
                                >
                                    <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>{res?.name}</Typography>
                                    <CustomCheckBox changeRole={(e) => onChangeCheckbox(e, index)} value={res?.id} />
                                </Box>)}
                        </Box> : ''}
                    {referalP ?
                        <Box>
                            {store?.procedure_id?.map((res, index) =>
                                <Box
                                    style={{ background: 'white', display: 'flex', alignItems: 'center', height: '40px', justifyContent: 'space-between', gap: "30px" }}
                                    borderRadius={3}
                                >
                                    <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>{res?.name}</Typography>
                                    <CustomCheckBox changeRole={(e) => onChangeCheckbox(e, index)} value={res?.id} />
                                </Box>)}
                        </Box> : ''}
                    {referal || referalP ?
                        <Box>
                            <Typography sx={{
                                fontSize: {
                                    md: 16,
                                    sm: 14,
                                    xs: 14,
                                },
                                fontFamily: "Quicksand",
                                fontWeight: "bold",
                                letterSpacing: "1px",
                                paddingLeft: '5px'
                            }}>Amount</Typography>
                            <Typography
                                style={{
                                    marginTop: "3px",
                                    borderRadius: "10px",
                                    height: "40px",
                                    background: "#e7f5f7",
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }} sx={{
                                    fontSize: {
                                        md: 14,
                                        sm: 13,
                                        xs: 12,

                                    },
                                }}>{amount}</Typography>
                        </Box> : ''}
                    <Box display={'flex'} gap={1}>
                        <Grid container spacing={2}>
                     
                            <Grid item xs={6}>
                                <CustomCalender
                                    height="35px"
                                    minDateres={new Date()}
                                    fieldLabel="Choose Date"
                                    control={control}
                                    error={errors.date}
                                    fieldName="date"
                                    type={"text"}
                                    readOnly={true}
                                    background="#FFFFFF"
                                    value={dateTime}
                                    onChangeValue={onChangeDateNtime}
                                />

                            </Grid>
                            {procedure === "procedure" || referalP ?
                                <Grid item xs={6}>
                                    <CustomSelectInput
                                        control={control}
                                        error={errors.time}
                                        fieldName="time"
                                        fieldLabel="Time"
                                        background="#FFFFFF"
                                        height="40px"
                                        size="16px"
                                        defaultValue={""}
                                        onChangeValue={onChangeTime}
                                        value={time}

                                    >
                                        <MenuItem value="" disabled >
                                            <em>Select Time</em>
                                        </MenuItem>
                                        {procedureTimeList?.time?.filter(pro => pro?.patient_id === null).map((doc) => (
                                            <MenuItem key={doc?.time} value={doc?.time} >
                                                {doc?.time}
                                            </MenuItem>
                                        ))}
                                    </CustomSelectInput>

                                </Grid> : <Grid item xs={6}>
                                    <CustomSelectInput
                                        control={control}
                                        error={errors.time}
                                        fieldName="time"
                                        fieldLabel="Time"
                                        background="#FFFFFF"
                                        height="40px"
                                        size="16px"
                                        defaultValue={""}
                                        onChangeValue={onChangeTime}
                                        value={time}
                                    >
                                        <MenuItem value="" disabled >
                                            <em>Select Time</em>
                                        </MenuItem>
                                        {serviceTimelist?.time?.filter(ser => ser?.patient_id === null).map((doc) => (
                                            <MenuItem key={doc?.time} value={doc?.time} >
                                                {doc?.time}
                                            </MenuItem>
                                        ))}
                                    </CustomSelectInput>

                                </Grid>}

                        </Grid>

                        {/* 
                        {procedure === "procedure" || referalP ?

                            <CustomSelectInput
                                control={control}
                                error={errors.time}
                                fieldName="time"
                                fieldLabel="Time"
                                background="#FFFFFF"
                                height="40px"
                                size="16px"
                                defaultValue={""}
                                onChangeValue={onChangeTime}
                                value={time}

                            >
                                <MenuItem value="" disabled >
                                    <em>Select Time</em>
                                </MenuItem>
                                {procedureTimeList?.time?.filter(pro => pro.patient_id === null).map((doc) => (
                                    <MenuItem key={doc.time} value={doc.time} >
                                        {doc.time}
                                    </MenuItem>
                                ))}
                            </CustomSelectInput>
                            :
                            <CustomSelectInput
                                control={control}
                                error={errors.time}
                                fieldName="time"
                                fieldLabel="Time"
                                background="#FFFFFF"
                                height="40px"
                                size="16px"
                                defaultValue={""}
                                onChangeValue={onChangeTime}
                                value={time}
                            >
                                <MenuItem value="" disabled >
                                    <em>Select Time</em>
                                </MenuItem>
                                {serviceTimelist?.time?.filter(ser => ser?.patient_id === null).map((doc) => (
                                    <MenuItem key={doc.time} value={doc.time} >
                                        {doc.time}
                                    </MenuItem>
                                ))}
                            </CustomSelectInput>

                        } */}
                    </Box>
                    {referal || referalP ?
                        <Box >
                            <CustomSelectInput
                                control={control}
                                error={errors.paymentstatus}
                                fieldName="paymentstatus"
                                fieldLabel="Payment Status"
                                background="#FFFFFF"
                                height="40px"
                                size="16px"
                                defaultValue={""}
                                value={paymentStatus}
                                onChangeValue={onChangepaymentStatus}
                            >
                                <MenuItem value="" disabled >
                                    <em>Select Status</em>
                                </MenuItem>
                                {['Paid', "Pending"]?.map((doc) => (
                                    <MenuItem key={doc} value={doc} >
                                        {doc}
                                    </MenuItem>
                                ))}
                            </CustomSelectInput>
                            {paymentStatus === 'Paid' ?
                                <CustomSelectInput
                                    control={control}
                                    error={errors.paymentmethod}
                                    fieldName="paymentmethod"
                                    fieldLabel="Payment Method"
                                    background="#FFFFFF"
                                    height="40px"
                                    size="16px"
                                    defaultValue={""}
                                    value={paymentMethod}
                                    onChangeValue={onChangepaymentMethod}
                                >
                                    <MenuItem value="" disabled >
                                        <em>Select PaymentMethod    </em>
                                    </MenuItem>
                                    {['UPI', 'Card', 'Cash']?.map((doc) => (
                                        <MenuItem key={doc} value={doc} >
                                            {doc}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput> : ''}

                            {paymentMethod === 'UPI' || paymentMethod === 'Card' ?
                                <CustomFormInput
                                    height="40px"
                                    fieldLabel="Transaction ID"
                                    control={control}
                                    error={errors.transactionref}
                                    fieldName="transactionref"
                                    type={"text"}
                                    background="#FFFFFF"
                                /> : ''}


                        </Box> : ''}

                    <Box display={"flex"} paddingTop={"10px"} justifyContent={"center"}>
                        <CustomButton text={"Submit"} variant={"contained"} submit={handleSubmit(SubmitDate)} />
                    </Box>

                </Box>
            </Box>
        </Modal>
    )
}

export default BookingResheduleModal