import { Box, Pagination, Typography } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import DataTables from '../../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import data from "../../../components/common/data";
import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDebounce } from "use-debounce";
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { RESET_ERROR } from '../../../Redux/constants/userConstants';
import { toast } from 'react-toastify';

import moment from 'moment';
import CustomSelectSearch from '../../../components/Widgets/CustomSelectSearch/CustomSelectSearch';
import { getReferal, PostPatientSearch } from '../../../Redux/actions/serviceAction';
import { REFERAL_SERVICE_SUCCESS } from '../../../Redux/constants/serviceConstant';

import BookingResheduleModal from './BookingResheduleModal';
const GetReferalListTable = () => {


    const dispatch = useDispatch()

    const Navigate = useNavigate()

    const { patientSearchList,
        referalList, referalbooked,
        error } = useSelector(state => state.service);





    const schema = object().shape({


    });
    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema)
    });


    const handleOpenRe = (row) => {
        setStore(row)
        setOpenRe(true);
    };

    const handleCloseRe = () => {
        setOpenRe(false);
    };


    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const [openRe, setOpenRe] = useState(false);
    const [patientArray, setPatientArray] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [data, setData] = useState([])
    const [store, setStore] = useState(null)

    const [newValue, setNewValue] = useState(null)
    const [debouncedText] = useDebounce(inputValue, 500);
    const PatientSearch = (value, newvalue) => {
        setNewValue(newvalue)
        if (newvalue === null) {
            setData([])
            dispatch({
                type: REFERAL_SERVICE_SUCCESS,
                payload: []
            });
        } else {
            setData([newvalue])
            dispatch(getReferal(newvalue?._id))
        }
    }

    const PatientOnchangeInput = (event, newInputValue) => {
        setInputValue(newInputValue)

    }
    useEffect(() => {
        if (debouncedText.length > 1) {
            const val = {
                key: debouncedText
            }
            dispatch(PostPatientSearch(val))
        }
    }, [debouncedText])



    var columns = [
        {
            name: <b>{Capitalize("PATIENT ID")}</b>,
            header: <b>{Capitalize("PATIENT ID")}</b>,
            selector: (row) => row?.user_id,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PATIENT NAME")}</b>,
            header: <b>{Capitalize("PATIENT NAME")}</b>,
            selector: (row) => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("EMAIL")}</b>,
            header: <b>{Capitalize("EMAIL")}</b>,
            selector: (row) => row?.email,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("MOBILE")}</b>,
            header: <b>{Capitalize("MOBILE")}</b>,
            selector: (row) => row?.mobile,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("HEIGHT")}</b>,
            header: <b>{Capitalize("HEIGHT")}</b>,
            selector: (row) => row?.height,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("GENDER")}</b>,
            header: <b>{Capitalize("GENDER")}</b>,
            selector: (row) => row?.gender,

            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("STATUS")}</b>,
            header: <b>{Capitalize("STATUS")}</b>,
            selector: (row) => row?.status,
            style: {
                textAlign: "center",
                // color:row?.status === "sheduled" ? "#FF8000" : "#19B12C"
            },
        },
    ];

    var column = [
        {
            name: <b>{Capitalize("CREATED ON")}</b>,
            header: <b>{Capitalize("CREATED ON")}</b>,
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
            style: {
                textAlign: "center",
            },
        },


        {
            name: <b>{Capitalize("DOCTOR ID")}</b>,
            header: <b>{Capitalize("DOCTOR ID")}</b>,
            selector: (row) => `DOC${row?.doctor?.doctor_id}`,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("DOCTOR NAME")}</b>,
            header: <b>{Capitalize("DOCTOR NAME")}</b>,
            selector: (row) => row?.doctor?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("DEPARTMENT")}</b>,
            header: <b>{Capitalize("DEPARTMENT")}</b>,
            selector: (row) => row?.department?.name
            ,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("SERVICE")}</b>,
            header: <b>{Capitalize("SERVICE")}</b>,
            selector: (row) => row?.service_id?.map((res) => (
                `${res?.name},`
            )),
            style: {
                textAlign: "center",
            },
        },


        {
            name: <b>{Capitalize("STATUS")}</b>,
            header: <b>{Capitalize("STATUS")}</b>,
            selector: (row) => row?.status,
            style: {
                textAlign: "center",
                // color:row?.status === "sheduled" ? "#FF8000" : "#19B12C"
            },
        },
        {
            name: <b>ACTIONS</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (
                <>
                    <Stack spacing={1} direction="row">
                        <CalendarMonthIcon
                            onClick={() => handleOpenRe(row)}
                            style={{
                                cursor: "pointer",
                                color: "#047ac1",
                            }}
                        />

                    </Stack>
                </>
            ),
        },
    ];



    useEffect(() => {
        if (referalbooked) {
            toast.success("Referal Booking Created successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleCloseRe()
            dispatch(getReferal(newValue?._id))

        }


        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [referalbooked, error])

    useEffect(() => {
        if (patientSearchList) {
            setPatientArray([...patientSearchList])
        }
    }, [patientSearchList])


    return (
        <>

            <Box paddingBottom={3}>
                <CustomSelectSearch
                    control={control}
                    error={errors.patient}
                    fieldName="patient"
                    fieldLabel="Search Patients"
                    background="#FFFFFF"
                    height="40px"
                    size="16px"
                    options={patientArray}
                    getOptionLabel={({ name, user_id, mobile }) => `${name} ${user_id} ${mobile}`}
                    onChangeValue={PatientSearch}
                    inputValue={inputValue}
                    onInputChange={PatientOnchangeInput}
                />
            </Box>
            {data?.length !== 0 ?
                <Paper>
                    <Fragment>

                        <DataTables
                            columns={columns}
                            row={data ? data : []}
                        />
                    </Fragment>
                </Paper> : ''}

            {data?.length !== 0 ?
                <Box paddingY={2}>
                    <Paper>
                        <Fragment>

                            <DataTables
                                columns={column}
                                row={referalList ? referalList : []}
                                pagination
                            />
                        </Fragment>
                    </Paper>
                </Box> : ''}

            {openRe && <BookingResheduleModal handleClose={handleCloseRe} handleOpen={handleOpenRe} store={store} referal={'referal'} />}
        </>
    )
}

export default GetReferalListTable