import React, { useContext, useEffect, useState } from "react";

import ImagePath from "../../../assets/images/mcarebackground.png";
import LoginPageindex from "../../../Screens/auth";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from "../../../Redux/constants/userConstants";
import { Outlet, useNavigate } from "react-router-dom";
import { DIV } from "./style";
import { Box, Container } from "@mui/system";
import UserContext from "../../../helpers/User";
import RoleContext from "../../../helpers/Role";
const LoginLayout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(UserContext)
  const roleC = useContext(RoleContext)

  const { loginSuccess, loading, error, userData, role } = useSelector(state => state.auth)

  const [authLoading, setAuthLoading] = useState(false)


  const checkLogin = async () => {
    let token = await localStorage.getItem("token");

    if (token) {
      history(`/home`);
    }
    else {
      setAuthLoading(false)
    }
  }
  // useEffect(() => {
  //   //checkLogin()
  // }, [])

  if (authLoading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <DIV img={ImagePath}>
      <Box>
      <Outlet />
      </Box>

    </DIV>
  );
};

export default LoginLayout;
