import axios from 'axios';
import { API_URL } from './config/constants'
import { RESET_USER } from './Redux/constants/userConstants';
import store from './Redux/store'

// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
    baseURL: API_URL,
    timeout: 50000
});

const usr = localStorage.getItem("user")
console.log({ usr }, 'ERROR HANDLER');
// Step-2: Create request, response & error handlers
const requestHandler = request => {
    const location = window.location.href
    console.log({ location });

    let token = localStorage.getItem("token");


    // // Token will be dynamic so we can use any app-specific way to always   
    // // fetch the new token before making the call
    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
};

const responseHandler = response => {
    if (response.status === 403) {
        localStorage.removeItem("user");
        localStorage.removeItem("profileData");
        //window.location.href = 'http://localhost:3000/login';
        window.location.replace(
            "/login",
        );
        store.dispatch({
            type: RESET_USER
        })
        localStorage.clear()
    }


    return response;
};

const errorHandler = async error => {
    let err = {
        message: '',
        status: error.response.status
    }
    if (error.response) {
        console.log(error.response, "kk");


        err.message = JSON.stringify(error.response.data)
        if (error.response.status === 403) {
            console.log({ usr }, 'ERROR HANDLER');
            //if (error.response.data.error === 'Unauthenticated.') {
                const usr = await localStorage.getItem("user")
                const usrdata = JSON.parse(usr)
            //if (error.response.data.error === 'Unauthenticated.') {

                
                console.log(error.response, "uer error");
                if (usrdata?.role === "doctor") {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    store.dispatch({
                        type: RESET_USER
                    })
                    window.location.replace(
                        "/dr",
                    )
                }
                else {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    store.dispatch({
                        type: RESET_USER
                    })
                    window.location.replace(
                        "/login",
                    )
                }
                store.dispatch({
                    type: RESET_USER
                })

                localStorage.clear()
            //}

        }

        if (error.response.data.message) {

            err.message = error.response.data.message
        }
        return Promise.reject(err);

    } else if (error.request) {


        err.message = JSON.stringify(error.request);
    }
    else {

        // Something happened in setting up the request that triggered an Error
        err.message = error.message;
    }
    return Promise.reject(err);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;