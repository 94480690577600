export const CREATE_SERVICE_LISTING_SUCCESS = "CREATE_SERVICE_LISTING_SUCCESS"
export const CREATE_SERVICE_LISTING_FAIL = "CREATE_SERVICE_LISTING_FAIL"
export const GET_SERVICE_LISTING_SUCCESS = "GET_SERVICE_LISTING_SUCCESS"
export const GET_SERVICE_LISTING_FAIL = "GET_SERVICE_LISTING_FAIL"
export const GET_SINGLE_LIST_SUCCESS = "GET_SINGLE_LIST_SUCCESS"
export const GET_SINGLE_LIST_FAIL = "GET_SINGLE_LIST_SUCCESS"
export const EDIT_SERVICE_LISTING_SUCCESS = "EDIT_SERVICE_LISTING_SUCCESS"
export const EDIT_SERVICE_LISTING_FAIL = "EDIT_SERVICE_LISTING_FAIL"
export const CREATE_SERVICE_BOOKING_SUCCESS = "CREATE_SERVICE_BOOKING_SUCCESS"
export const CREATE_SERVICE_BOOKING_FAIL = "CREATE_SERVICE_BOOKING_FAIL"
export const CREATE_SERVICE_SEARCH_BOOKING_SUCCESS = "CREATE_SERVICE_SEARCH_BOOKING_SUCCESS"
export const CREATE_SERVICE_SEARCH_BOOKING_FAIL = "CREATE_SERVICE_SEARCH_BOOKING_FAIL"
export const GET_SERVICE_BY_DEPT_SERVICE_SUCCESS = "GET_SERVICE_BY_DEPT_SERVICE_SUCCESS"
export const GET_SERVICE_BY_DEPT_SERVICE_FAIL = "GET_SERVICE_BY_DEPT_SERVICE_FAIL"
export const CREATE_SERVICE_TIME_SUCCESS = "CREATE_SERVICE_TIME_SUCCESS"
export const CREATE_SERVICE_TIME_FAIL = "CREATE_SERVICE_TIME_FAIL"
export const GET_BOOKING_LIST_SUCCESS = "GET_BOOKING_LIST_SUCCESS"
export const GET_BOOKING_LIST_FAIL = "GET_BOOKING_LIST_FAIL"
export const GET_BOOKING_VIEW_SUCCESS = "GET_BOOKING_VIEW_SUCCESS"
export const GET_BOOKING_VIEW_FAIL = "GET_BOOKING_VIEW_FAIL"
export const EDIT_SERVICE_BOOKING_SUCCESS = "EDIT_SERVICE_BOOKING_SUCCESS"
export const EDIT_SERVICE_BOOKING_FAIL = "EDIT_SERVICE_BOOKING_FAIL"
export const CANCEL_SERVICE_BOOKING_SUCCESS = "CANCEL_SERVICE_BOOKING_SUCCESS"
export const CANCEL_SERVICE_BOOKING_FAIL = "CANCEL_SERVICE_BOOKING_FAIL"
export const RESHEDULE_SERVICE_BOOKING_SUCCESS = "RESHEDULE_SERVICE_BOOKING_SUCCESS"
export const RESHEDULE_SERVICE_BOOKING_FAIL = "RESHEDULE_SERVICE_BOOKING_FAIL"
export const UPDATE_SERVICE_STATUS_SUCCESS = "UPDATE_SERVICE_STATUS_SUCCESS"
export const UPDATE_SERVICE_STATUS_FAIL = "UPDATE_SERVICE_STATUS_FAIL"
export const FILTER_SERVICE_SUCCESS = "FILTER_SERVICE_SUCCESS"
export const FILTER_SERVICE_FAIL = "FILTER_SERVICE_FAIL"
export const FILTER_SERVICE_BOOKING_SUCCESS = "FILTER_SERVICE_BOOKING_SUCCESS"
export const FILTER_SERVICE_BOOKING_FAIL = "FILTER_SERVICE_BOOKING_FAIL"
export const SERVICE_REPORT_SUCCESS = "SERVICE_REPORT_SUCCESS"
export const SERVICE_REPORT_FAIL = 'SERVICE_REPORT_FAIL'
export const REFERAL_SERVICE_SUCCESS='REFERAL_SERVICE_SUCCESS'
export const REFERAL_SERVICE_FAIL='REFERAL_SERVICE_FAIL'
export const POST_REFERAL_BOOKING_SUCCESS = 'POST_REFERAL_BOOKING_SUCCESS'
export const POST_REFERAL_BOOKING_FAIL='POST_REFERAL_BOOKING_FAIL'


