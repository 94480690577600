import { Box, Pagination } from '@mui/material';
import React, { Fragment, useState } from 'react'
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import DataTables from '../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import data from "../../components/common/data";
import CustomSearch from '../../components/Widgets/CustomSearch';
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import GetServiceListTable from './components/getServiceListTable';
import GetServiceBookingTable from './components/getServiceBookingTable';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from "moment";
import CustomFilter from '../../components/Widgets/CustomFilter';
import { filterProcedure, filterProcedureBooking } from '../../Redux/actions/procedureAction';
import { filterServiceBooking, filterServiceListing } from '../../Redux/actions/serviceAction';
import GetReferalListTable from './components/getReferalListTable';
const Service = () => {
  const dispatch = useDispatch()
  const Navigate = useNavigate()

  const { bookingList, serviceList } = useSelector(state => state.service);

  const [active, setActive] = useState('serviceBooking')
  const [activeColor, setActiveColor] = useState('contained')
  const [datas, setDatas] = useState([])
  const [valuefrom, setvaluefrom] = useState("")
  const [valueto, setvalueto] = useState("")
  const [openFilter, setOpenFilter] = useState(false);
  const [filterbooking, setFilterBooking] = useState(false)
  const [filterListing, setFilterListing] = useState(false)
  const [filterKey, setFilterKey] = useState(0);

  const NavigateToServiceAdd = (id) => {

    if (active === 'serviceBooking') {
      Navigate(`/addBooking`)
    } else if (active === 'serviceListing') {
      Navigate('/addListing')
    }

  }


  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  // const handleCloseFilter = () => {
  //   if (active === "serviceBooking") {
  //     setFilterBooking(true)
  //   } else if (active === 'serviceListing') {
  //     setFilterListing(true)
  //   }
  //   setvaluefrom("")
  //   setvalueto("")
  //   setOpenFilter(false);
  // };
  const clearFilter = () => {
    setvaluefrom("");
    setvalueto("");
    setFilterKey(prevKey => prevKey + 1);
  };

  const handleCloseFilter = () => {
    clearFilter();
    setOpenFilter(false);
  };

  const Submitfilter = () => {
    let val = {
      from_date: moment(valuefrom).format("DD-MM-YYYY"),
      to_date: moment(valueto).format("DD-MM-YYYY")
    }

    if (valuefrom && valueto) {
      if (active === "serviceBooking") {
        dispatch(filterServiceBooking(val))
      } else if (active === 'serviceListing') {
        dispatch(filterServiceListing(val))
      }
      setOpenFilter(false);
    } else {
      toast.warning(`from Date and to Date is required`);
    }
  }

  useEffect(() => {
    if (bookingList && active === 'serviceBooking') {
      setDatas(bookingList)
    }

    if (serviceList && active === 'serviceListing') {
      setDatas(serviceList ? serviceList : [])
    }

  }, [bookingList, serviceList, active])


  useEffect(() => {
    let mode = localStorage.getItem("mode")
    if (mode === "serviceBooking") {
      setDatas(bookingList)
      setActive("serviceBooking")
    }
    else if (mode === "serviceListing") {
      setDatas(serviceList ? serviceList : [])
      setActive("serviceListing")
    }
  }, [])



  const searchData = (value) => {
    if (active === 'serviceBooking') {
      let datas = bookingList?.filter(list => list?.booking_id?.toLowerCase().includes(value.toLowerCase()) || list?.patient?.[0]?.user_id.toLowerCase().includes(value.toLowerCase()) || list?.patient?.[0]?.name.toLowerCase().includes(value.toLowerCase()) || list?.service?.[0]?.name.toLowerCase().includes(value.toLowerCase()) || list?.status?.toLowerCase().includes(value.toLowerCase()));

      setDatas(datas)
    }
    else if (active === 'serviceListing') {
      let datas = serviceList?.filter(list => list?.service_id?.toLowerCase().includes(value.toLowerCase()) || list?.department?.[0]?.name.toLowerCase().includes(value.toLowerCase()) || list?.name?.toLowerCase().includes(value.toLowerCase()));
      setDatas(datas)
    }
  }

  return (
    <>
      <Box
        sx={{
          width: "99%",
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { lg: "row", md: "column", xs: "column" },
          gap: { md: "10px", xs: "10px" },
          marginBottom: "40px",
        }}
      >
        <Stack spacing={2} direction="row">
          <CustomButton active={active === "serviceBooking" ? true : false ? active === "serviceListing" : false}
            text={"Service Booking"} submit={() => {
              setDatas(bookingList)
              setActive('serviceBooking')
              localStorage.setItem("mode", 'serviceBooking');
            }} variant={activeColor} />
          <CustomButton active={active === "serviceListing" ? true : false ? active === "serviceBooking" : false}

            text={"Service Listing"} submit={() => {
              setDatas(serviceList)
              setActive('serviceListing')
              localStorage.setItem("mode", 'serviceListing');
            }} variant={activeColor} />


          <CustomButton active={active === "referalList" ? true : false ? active === "referalList" : false}

            text={"Referral Listing"} submit={() => {
              setOpenFilter(false);
              if (active !== "referalList") {
                setActive('referalList')
                localStorage.setItem("mode", "referalList")
                setDatas([])
              }
            }} variant={activeColor} />
        </Stack>
        {active !== 'referalList' &&
          <Stack spacing={2.6} direction="row" style={{
            background: "#d8dee1",
            padding: "3px 3px 3px .1px",
            display: "flex",
            alignItems: "center",

          }} borderRadius={2}>
            <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={(value) => searchData(value)} />
            <CustomButton
              variant={"contained"}
              text={"Filter"}
              active={openFilter ? true : false}
              Icon={FilterListIcon}
              submit={() => setOpenFilter(!openFilter)}

            />

            <CustomButton
              submit={NavigateToServiceAdd}
              ComponentIcon={AddIcon}
              variant={"contained"}
            />
          </Stack>}
      </Box>
      {openFilter && <CustomFilter
        key={filterKey}
        handlechangefrom={(value) => setvaluefrom(value)}
        valuefrom={valuefrom}
        valueto={valueto}
        handlechangeto={(value) => setvalueto(value)}
        submit={Submitfilter}
        //clear={handleCloseFilter}
        clear={clearFilter}
      />}
      {active === "serviceBooking" && <GetServiceBookingTable datas={datas} filterbooking={filterbooking} />}
      {active === "serviceListing" && <GetServiceListTable datas={datas} filterListing={filterListing} />}
      {active === 'referalList' && <GetReferalListTable />}
      {/* {active ? active === "serviceBooking" ?
        <GetServiceBookingTable datas={datas} filterbooking={filterbooking} />
        : <GetServiceListTable datas={datas} filterListing={filterListing} /> : null} */}
    </>
  )
}

export default Service