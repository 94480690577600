import React from 'react'
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Controller } from "react-hook-form";
import { FormGroup, Typography } from "@mui/material";
import { format } from 'date-fns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
const CustomCalender = ({
    fieldName,
    control,
    fieldLabel,
    error,
    children,
    max,
    isMultiple,
    selectvalue,
    onChangeValue,
    background,
    options,
    value,
    disabled,
    minDateres
}) => {
    const onKeyDown = (e) => {
        e.preventDefault();
     };


    return (
        <>
            <FormGroup>
                <Typography style={{ paddingBottom: "2px" }} sx={{
                    fontSize: {
                        lg: 15,
                        md: 14,
                        sm: 13,
                        xs: 12,

                    },
                    fontFamily: 'Quicksand',
                    fontWeight: "bold"
                }}>{fieldLabel}</Typography>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                
                                minDate={minDateres}
                                disabled={disabled}
                                renderInput={(props) => <TextField  onKeyDown={onKeyDown}   {...props} />}
                                value={value}
                                onChange={onChangeValue}
                                inputFormat="DD-MM-YYYY"
                                InputProps={{
                                    style: { borderRadius: "10px", height: "40px", background: background ? background : "white" }

                                }}
                            />
                        </LocalizationProvider>
                    )}
                />
                {error && (
                    <p
                        role="alert"
                        style={{
                            color: "red",
                            display: "flex",
                            flexDirection: "start",
                            paddingLeft: "10px",
                            fontSize: "12px",
                        }}
                    >
                        {error?.message}
                    </p>
                )}
            </FormGroup>

        </>
    )
}

export default CustomCalender