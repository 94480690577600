import { CREATE_DOC_GROUP_FAIL, CREATE_DOC_GROUP_SUCCESS, CREATE_SETTINGS_FAIL, CREATE_SETTINGS_SUCCESS, CREATE_SLIDER_FAIL, CREATE_SLIDER_SUCCESS, DEL_GROUP_FAIL, DEL_GROUP_SUCCESS, DEL_SLIDER_FAIL, DEL_SLIDER_SUCCESS, EDIT_SETTINGS_FAIL, EDIT_SETTINGS_SUCCESS, EDIT_SLIDER_FAIL, EDIT_SLIDER_SUCCESS, GET_GROUP_FAIL, GET_GROUP_SUCCESS, GET_SETTINGS_FAIL, GET_SETTINGS_SUCCESS, GET_SLIDER_FAIL, GET_SLIDER_SUCCESS, STATUS_SLIDER_FAIL, STATUS_SLIDER_SUCCESS } from "../constants/settingsConstants";
import { RESET_ERROR } from "../constants/userConstants";


export const settingsReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                sliderCreated: null,
                sliderUpdated: null,
                settingCreated: null,
                settingUpdated: null,
                sliderDeleted: null,
                statusChanged: null,
                groupCreted: null,
                groupDeleted: null,

            }

        case GET_SLIDER_SUCCESS:
            return {
                ...state,
                sliderList: action.payload
            }

        case GET_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_SLIDER_SUCCESS:
            return {
                ...state,
                sliderList: state?.sliderList ? [...state?.sliderList, action.payload] : action.payload,
                sliderCreated: true
            }

        case CREATE_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case EDIT_SLIDER_SUCCESS:
            return {
                ...state,
                sliderList: state.sliderList?.map(slider => slider._id === action.payload._id ? action.payload : slider),
                sliderUpdated: true
            }
        case EDIT_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DEL_SLIDER_SUCCESS:
            return {
                ...state,
                sliderList: state?.sliderList.filter(slider => slider._id !== action.payload),
                sliderDeleted: true
            }
        case DEL_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case STATUS_SLIDER_SUCCESS:
            return {
                ...state,
                statusChanged: true
            }
        case STATUS_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsList: state?.settingsList ? [...state?.settingsList, action.payload] : action.payload,
                settingCreated: true
            }
        case CREATE_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsList: action.payload
            }

        case GET_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case EDIT_SETTINGS_SUCCESS:
            return {
                ...state,
                settingUpdated: true,
                settingsList: action.payload
            }
        case EDIT_SETTINGS_FAIL:
            return {
                error: action.payload
            }

        case GET_GROUP_SUCCESS:
            return {
                ...state,
                groupList: action.payload
            }
        case GET_GROUP_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_DOC_GROUP_SUCCESS:
            return {
                ...state,
                groupCreted: true,
                groupList: state?.groupList ? [...state?.groupList, action.payload] : action.payload,

            }
        case CREATE_DOC_GROUP_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DEL_GROUP_SUCCESS:
            return {
                ...state,
                groupList: state?.groupList.filter(grp => grp._id !== action.payload),
                groupDeleted: true
            }
        case DEL_GROUP_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;

    }

}