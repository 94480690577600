

import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Switch, Typography } from '@mui/material'
import { Box, Container } from '@mui/system';
import { toast } from 'react-toastify';
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
const ViewFees = ({ doctorSingleList, setActive }) => {


    return (
        <>


            <Grid container spacing={2} px={5}>
                <Grid item xs={12} md={3}>
                    <Typography>OP Consultation</Typography>

                    <Typography
                        style={{
                            marginTop: "20px",

                        }} >Fees</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.op_fee}</Typography>

                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography>Online Consultation</Typography>

                    <Typography style={{
                        marginTop: "20px",

                    }} >Fees</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.online_fee}</Typography>

                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography>Report Review</Typography>

                    <Typography style={{
                        marginTop: "20px",

                    }}>Fees</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.review_fee}</Typography>

                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography style={{
                        marginTop: "42px",
                    }} >Reviewed Within (Days)</Typography>
                    <Typography
                        style={{
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,
                            },
                        }}>{doctorSingleList?.review_day}</Typography>
                </Grid>
            </Grid>

            {/* <Box paddingTop={"100px"} display={"flex"} justifyContent={"flex-end"} gap={"20px"}>
                    <CustomButton variant={"contained"} text={"Previous"} submit={() => setActive("Consultation_Time")} />
                </Box> */}

        </>
    )
}

export default ViewFees