export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED'

export const CREATE_DEPT_SUCCESS = "CREATE_DEPT_SUCCESS"
export const CREATE_DEPT_FAILED = "CREATE_DEPT_FAILED"
export const GET_DEPT_SUCCESS = "GET_DEPT_SUCCESS"
export const GET_DEPT_FAIL = "GET_DEPT_FAILED"
export const GET_DEPT_LIST_SUCCESS = "GET_DEPT_LIST_SUCCESS"
export const GET_DEPT_LIST_FAIL = "GET_DEPT_LIST_FAIL"
export const GET_DEPT_DETAIL_SUCCESS = " GET_DEPT_DETAIL_SUCCESS"
export const GET_DEPT_DETAIL_FAIL = "GET_DEPT_DETAIL_FAIL"
export const UPDATE_DEPT_SUCCESS = "UPDATE_DEPT_SUCCESS"
export const UPDATE_DEPT_FAIL = "UPDATE_DEPT_FAIL"
export const FILTER_DEPT_SUCCESS = "FILTER_DEPT_SUCCESS"
export const FILTER_DEPT_FAIL = "FILTER_DEPT_FAIL"


export const GET_LIST_STATUS_SUCCESS="GET_LIST_STATUS_SUCCESS"
export const GET_LIST_STATUS_FAIL="GET_LIST_STATUS_FAIL"
