
import React, { Fragment } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import DataTables from '../../../../components/Widgets/dataTable';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function Completed({ mode, consultations }) {
    const navigate = useNavigate();
    const handleViewConsultation = (id, consultationData) => {
        navigate(`/docViewModal/${id}`, { state: { consultation: consultationData } });
    };
    const navigatePage = (id) => {
        switch (mode) {
            case 'Op':
                navigate(`/docEditMyConsultation/${id}`);
                break;
            case 'Online':
                navigate(`/docEditMyConsultation/${id}`);
                break;
            case 'Report':
                navigate(`/docEditMyConsultation/${id}`);
                break;
            default:
                break;
        }
    };
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const renderActions = (row) => {
        if (row.status === 'Completed') {
            return (
                <Stack spacing={2} direction="row">
                    <RemoveRedEyeIcon
                        style={{
                            cursor: 'pointer',
                            color: '#047ac1',
                        }}
                        onClick={() => handleViewConsultation(row?._id, row)}
                    />
                </Stack>
            );
        }
        return (
            <Stack spacing={2} direction="row">
                {mode === 'Online' && (
                    <CallIcon
                        style={{
                            cursor: "pointer",
                            color: "green",
                        }}
                    />
                )}
                <BorderColorSharpIcon
                    onClick={() => navigatePage(row?._id ? row?._id : '')}
                    color="blue"
                    style={{
                        cursor: "pointer",
                        color: "#047ac1",
                    }}
                />
                <RemoveRedEyeIcon
                    style={{
                        cursor: "pointer",
                        color: "#047ac1",
                    }}
                    onClick={() => handleViewConsultation(row?._id, row)}
                />
            </Stack>
        );
    };

    const columns = [
        {
            name: <b>{Capitalize("BOOKING ID")}</b>,
            header: <b>{Capitalize("BOOKING ID")}</b>,
            selector: (row) => row?.booking_id,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("BOOKED ON")}</b>,
            header: <b>{Capitalize("BOOKED ON")}</b>,
            selector: (row) => moment(row?.date).format("DD/MM/YYYY"),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PATIENT ID")}</b>,
            header: <b>{Capitalize("PATIENT ID")}</b>,
            selector: (row) => `${row?.patient?.user_id}`,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PATIENT NAME")}</b>,
            header: <b>{Capitalize("PATIENT NAME")}</b>,
            selector: (row) => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("STATUS")}</b>,
            header: <b>{Capitalize("STATUS")}</b>,
            selector: (row) => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>ACTIONS</b>,
            cell: (row, index) => (
                <>{renderActions(row)}</>
            ),
        },
    ];

    return (
        <Box py={2}>
            <Paper>
                <Fragment>
                    <DataTables
                        columns={columns}
                        row={consultations}
                        pagination
                    />
                </Fragment>
            </Paper>
        </Box>
    )
}
