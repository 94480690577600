import { CREATE_DEPT_FAILED } from "../constants/departmentConstants";
import { CREATE_DOCTOR_FAIL, CREATE_DOCTOR_SUCCESS, DOCTOR_LEAVE_LIST_SUCCESS, DOCTOR_LEAVE_LIST_FAIL, DOCTOR_STATUS_FAIL, DOCTOR_STATUS_SUCCESS, EDIT_DOCTOR_FAIL, EDIT_DOCTOR_SUCCESS, GET_DOCTOR_FAIL, GET_DOCTOR_SINGLE_FAIL, GET_DOCTOR_SINGLE_SUCCESS, GET_DOCTOR_SUCCESS, LEAVE_LIST } from "../constants/doctorConstant";
import { LOADING, RESET_ERROR } from "../constants/userConstants";

export const doctorReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                docterCreated: null,
                statusChanged: null,
                docterEdited: null
            }
        case GET_DOCTOR_SUCCESS:
            return {
                ...state,
                doctorList: action.payload
            }
        case GET_DOCTOR_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_DOCTOR_SUCCESS:
            return {
                ...state,
                // doctorList: state?.doctorList ? [...state.doctorList, action.payload] : action.payload,
                docterCreated: true
            }
        case CREATE_DOCTOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_DEPT_FAILED:
            return {
                ...state,
                error: action.payload
            }

        case GET_DOCTOR_SINGLE_SUCCESS:
            return {
                ...state,
                doctorSingleList: action.payload
            }
        case GET_DOCTOR_SINGLE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DOCTOR_STATUS_SUCCESS:
            return {
                ...state,
                statusChanged: true,
            }
        case DOCTOR_STATUS_FAIL:
            return {
                ...state,
                error: action.payload
            }


        case EDIT_DOCTOR_SUCCESS:
            return {
                ...state,
                //doctorList: state?.doctorList.map((doc) => doc._id === action.payload._id ? action.payload : doc),
                docterEdited: true
            }

        case EDIT_DOCTOR_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DOCTOR_LEAVE_LIST_SUCCESS:
            return {
                ...state,
                leaveList: action.payload,
                allleaveList: action.payload

            }

        case LEAVE_LIST:
            return {
                ...state,
                leaveList: action.payload
            }

        case DOCTOR_LEAVE_LIST_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;

    }

}