import { Box, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import DataTables from '../../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { getServiceList, updateServiceStatus } from '../../../Redux/actions/serviceAction';
import { LOADING, RESET_ERROR } from '../../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import CustomSwitch from '../../../components/Widgets/CustomSwitch/CustomSwitch';
import moment from 'moment';
import CustomFilter from '../../../components/Widgets/CustomFilter';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import CustomCheckBox from '../../../components/Widgets/CustomCheckBox';
const GetServiceListTable = ({ datas, filterListing }) => {
  const dispatch = useDispatch()

  const Navigate = useNavigate()

  const { serviceList, serviceStatusChanged, error } = useSelector(state => state.service);
  const [valuefrom, setvaluefrom] = useState("")
  const [valueto, setvalueto] = useState("")


  const NavigatetoViewPage = (id) => {
    Navigate(`/serviceViewListing/${id}`)
  }
  const NavigatetoEditPage = (id) => {
    Navigate(`/editListing/${id}`)
  }


  const ChangeStatus = (checked, row) => {
    let val = {
      id: row,
      status: checked === true ? "active" : "inactive"
    }
    dispatch(updateServiceStatus(val))
  }

  useEffect(() => {
    if (serviceStatusChanged) {
      toast.success("Status Changed successfully");
      dispatch({
        type: RESET_ERROR
      })
    }


    if (error) {
      toast.error(`${error?.message}`);
      dispatch({
        type: RESET_ERROR
      })
    }

  }, [serviceStatusChanged, error])
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  var columns = [
    {
      name: <b>{Capitalize("SERVICE ID")}</b>,
      header: <b>{Capitalize("SERVICE ID")}</b>,
      selector: (row) => row?.service_id,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("CREATED ON")}</b>,
      header: <b>{Capitalize("CREATED ON")}</b>,
      selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("SERVICE DEPARTMENT")}</b>,
      header: <b>{Capitalize("SERVICE DEPARTMENT")}</b>,
      selector: (row) => row?.department?.[0]?.name,
    },
    {
      name: <b>{Capitalize("NAME")}</b>,
      header: <b>{Capitalize("NAME")}</b>,
      selector: (row) => row?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("PRICE")}</b>,
      header: <b>{Capitalize("PRICE")}</b>,
      selector: (row) => row?.price,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("GROUPABLE")}</b>,
      header: <b>{Capitalize("GROUPABLE")}</b>,
      cell: (row) => (
        <>
          <CustomCheckBox checked={row?.groupable ? true : false} />
        </>
      )
    },
    {
      name: <b>ACTIONS</b>,
      cell: (row, index) => (
        <>
          <Stack spacing={2} direction="row">
            <RemoveRedEyeIcon

              color="blue"
              style={{
                cursor: "pointer",
                color: "#047ac1",
              }}
              onClick={() => NavigatetoViewPage(row?._id)}
            />{" "}
            {row?.status == "active" && <BorderColorSharpIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() => NavigatetoEditPage(row?._id)} />}
          </Stack>
        </>
      ),
    },
    {
      name: <b>STATUS</b>,
      id: "status",
      cell: (row, index) => (
        <>
          <Stack spacing={1} direction="row">
            <CustomSwitch checked={row?.status == "active" ? true : false}
              onClick={(e) => ChangeStatus(e.target.checked, row?._id)}
            />
          </Stack>
        </>
      ),
    },
  ];




  useEffect(() => {
    dispatch(getServiceList())
  }, [filterListing])




  return (
    <>
      <Paper>
        <Fragment>
          <DataTables
            columns={columns}
            row={datas}
            pagination
          />
        </Fragment>
      </Paper>
    </>
  )
}

export default GetServiceListTable;