import React, { useContext, useEffect, useState } from "react";
import { BUTTONIMG } from "./style";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Phone from "../../assets/images/phone.png";
import lock from "../../assets/images/lock.png";
import logo from "../../assets/images/hlogoLogin.png";
import loginbutton from "../../assets/images/login-svgrepo-com.png";
import CustomInput from "../../components/Widgets/CustomInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_INPUT, RESET_ERROR } from "../../Redux/constants/userConstants";
import { Link, useNavigate } from "react-router-dom";
import { loginPhone } from "../../Redux/actions/userActions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import UserContext from "../../helpers/User";
import RoleContext from "../../helpers/Role";
import { toast } from "react-toastify";
const LoginPatient = ({ setforgetpage }) => {
    const history = useNavigate();
    const navigate = useNavigate();
    const context = useContext(UserContext)
    const roleC = useContext(RoleContext)
    const [mobile, setmobile] = useState()
    const { otpsuccess, loading, error, userData, mobilee, role } = useSelector(state => state?.auth)
    const [authLoading, setAuthLoading] = useState(false)


    useEffect(() => {
        if (otpsuccess) {

            // context.setUser(userData)
            // roleC.setRole(role?.permissions)
            // roleC.getRoutes()
            // roleC.getMenus()
            //navigate to dashboard
            history(`/otp-patientlogin`);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            //using toast display error message
            toast.error(error?.message)
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [otpsuccess, error])


    const dispatch = useDispatch();
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 450,
                md: 600,
                lg: 900,
                xl: 1200,
                tablet: 1024
            }
        }
    });
    const schema = object().shape({
        // mobile: yup.string().mobile().required('Phone Number is required'),
        // otp: yup.string()
        //     .required('No OTP provided.')
        // .min(6, 'Password is too short')
    });


    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            role: "patient",
        },
    });

    const [eye, setEye] = useState(false)
    const handleSubmits = (event) => {

        dispatch({
            type: AUTH_INPUT,
            payload: {
                prop: 'mobile',
                value: event.mobile
            }
        })
   
        dispatch(loginPhone(event))


        // navigate("/otp-login")
        // localStorage.setItem("mobile", event.mobile)
    };
    return (
        <>
            <Container >
                <Box sx={{
                    paddingRight: "184px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100vh",
                    justifyContent: "center"

                }}>
                    <Avatar src={logo} style={{ width: "140px", height: "140px" }}>
                    </Avatar>
                    <Typography component="h2" variant="h5" color="white" fontSize="50px" fontFamily={'Quicksand'} fontWeight={"bold"} letterSpacing="2px">
                        LOGIN
                    </Typography>
                    <Grid  >
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ mt: 3 }}
                        >
                            <Box width={"100%"}>
                                <CustomInput
                                    control={control}
                                    error={errors.mobile}
                                    fieldName="mobile"
                                    position={"start"}
                                    Icon={Phone}
                                    placeholder={"Enter Phone Number"}
                                    //onClick={handleSubmit(handleSubmits)}

                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ mt: 3 }}
                        >
                            <Box width={"60%"}>

                            </Box>

                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5,
                        }}
                    >

                        <BUTTONIMG
                            onClick={handleSubmit(handleSubmits)}

                        >
                            <img src={loginbutton} alt="image"></img>
                        </BUTTONIMG>

                    </Box>
                    {/* <Box >
                        <Box
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection={"column"}
                            mt={8}
                        >
                            <span
                                style={{
                                    color: "white",
                                    fontSize: "18px",
                                    fontFamily: "Quicksand",
                                }}
                            >
                                Can't Login?
                            </span>
                            <Link
                                style={{
                                    color: "white",
                                    fontSize: "22px",
                                    fontFamily: "Quicksand",
                                    fontWeight: "bold",
                                    marginTop: 2,
                                    textDecoration: 'none'
                                }}
                                to="/forgetpass"
                            >
                                CLICK HERE
                            </Link>
                        </Box>
                    </Box> */}

                </Box>
            </Container>
        </>
    );
};

export default LoginPatient;
