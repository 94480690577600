import { Box, Stack } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton'
import DrConsultation from './Consultation'
import DrProfile from './Profile'

const DrSettings = () => {
    const [active, setActive] = useState('Profile')
    const [activeColor, setActiveColor] = useState('contained')
    return (
        <Box py={2}>
            <Stack spacing={2} direction="row">
                <CustomButton
                    active={active === "Profile" ? true : false ? active === "Consultation" : false}
                    text={"Profile"}
                    submit={() => setActive('Profile')}
                    variant={activeColor} />

                <CustomButton active={active === "Consultation" ? true : false ? active === "Profile" : false}
                    text={"Consultation"}
                    submit={() => setActive('Consultation')}
                    variant={activeColor} />
            </Stack>
            {active === 'Profile' && <DrProfile />}
            {active === 'Consultation' && <DrConsultation />}
        </Box>
    )
}

export default DrSettings