import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography, Grid, Stack, Paper, Card, CardMedia } from "@mui/material";
import Backbutton from '../../components/Widgets/Backbutton';
import { getSingleBlogs } from '../../Redux/actions/blogActions';

const ViewBlog = () => {
    const { id } = useParams();
    const dispatch = useDispatch()

    // const { error, sigleAppointmentList } = useSelector(state => state.appointment);
    const { error, singleBlogList } = useSelector(state => state.blog);
    console.log({ singleBlogList })


    useEffect(() => {
        dispatch(getSingleBlogs(id))
    }, [])

    return (
        <>
            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        Blog ID : {singleBlogList?.blog_id}
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={2} px={5}>
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>USER TYPE</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{singleBlogList?.usertype}</Typography>
                </Grid>
                {singleBlogList?.usertype !== "all" && (
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"

                        }}>CATEGORY</Typography>
                        <Typography
                            style={{
                                marginTop: "3px",
                                borderRadius: "10px",
                                height: "40px",
                                background: "#e7f5f7",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "10px"
                            }} sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                            }}>{singleBlogList?.category}</Typography>
                    </Grid>
                )}
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>SUBJECT</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px",
                            overflowY: "auto"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{singleBlogList?.subject}</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>CREATED BY</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{singleBlogList?.created}</Typography>
                </Grid>
                {/* <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>CONTENT</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "114px",
                            background: "#e7f5f7",
                            color: "black",
                            // display: "flex",
                            alignItems: "center",
                            overflowY: "auto",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{singleBlogList?.content}</Typography>
                </Grid> */}
                <Grid item xs={12} md={4} lg={3}>
                    <Typography
                        sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px",
                        }}
                    >
                        CONTENT
                    </Typography>
                    <div
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "114px",
                            background: "#e7f5f7",
                            color: "black",
                            overflowY: "auto",
                            paddingLeft: "10px",
                        }}
                    >
                     
                        {singleBlogList?.content.split('\n').map((paragraph, index) => (
                            <p key={index} style={{ margin: 0 }}>
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </Grid>

                <Grid item xs={12} md={4} lg={3} >
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px",


                    }}>IMAGE</Typography>
                    <Card sx={{ maxWidth: '100%', height: "15vh" }}  >
                        <CardMedia
                            // sx={{ height: 286 }}
                            sx={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            image={singleBlogList?.attachment}
                            title="green iguana"
                        />
                    </Card></Grid>
            </Grid>


        </>
    )
}

export default ViewBlog