export const CREATE_PATIENT_SUCCESS= "CREATE_PATIENT_SUCCESS"
export const CRETAE_PATIENT_FAIL ="CREATE_PATIENT_FAIL"

export const GET_ALL_PATIENTS_SUCCESS = "GET_ALL_PATIENT_SUCCESS"
export const GET_ALL_PATIENT_FAIL ="GET_ALL_PATIENT_FAIL"

export const EDIT_PATIENTS_SUCCESS = "EDIT_PATIENTS_SUCCESS"
export const EDIT_PATIENTS_FAIL ="EDIT_PATIENTS_FAIL"
export const FILTER_PATIENT_SUCCESS = "FILTER_PATIENTS_SUCCESS"
export const FILTER_PATIENT_FAIL = "FILTER_PATIENTS_FAIL"

export const GET_SINGLE_PATIENT_SUCCESS = "GET_SINGLE_PATIENT_SUCCESS"
export const GET_SINGLE_PATIENT_FAIL ="GET_SINGLE_PATIENT_FAIL"