import { Typography, Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { ToggleOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleAppointment } from '../../../Redux/actions/appointmentAction';

const DrConsultation = () => {
  const { profiledata } = useSelector(state => state?.profile);


  const { doctorSingleList } = useSelector(state => state?.doct);

  return (
    <Grid container display={'flex'} spacing={2} py={5} px={5}>
      {[{ name: 'Op Consultation' }, { name: 'Online Consultation' }, { name: 'Report Review' }]?.map((res) => (
        <Grid item xs={12} md={4} lg={4}>
          <Typography fontWeight={'bold'} fontFamily={'Quicksand'} fontSize={20}>{res?.name}</Typography>
          {profiledata?.available_online && res?.name === "Online Consultation" ? <ToggleOnIcon style={{ color: "green", fontSize: "xx-large" }} /> : profiledata?.available_op && res?.name === "Op Consultation" ? <ToggleOnIcon style={{ color: "green", fontSize: "xx-large" }} /> : profiledata?.available_report && res?.name === "Report Review" ? <ToggleOnIcon style={{ color: "green", fontSize: "xx-large" }} /> : <ToggleOff style={{ color: "red", fontSize: "xx-large" }} />}

        </Grid>


      ))}


    </Grid>
  )
}

export default DrConsultation