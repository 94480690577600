import React from 'react'


export const Sidebardata = [
    {
        title: "Appointment",
        roleTitle: "Appointment",
        link: "/"
    },
    {
        title: "Procedures",
        roleTitle: "Procedures",
        link: "/procedure"
    },
    {
        roleTitle: "Services",
        title: "Services",
        link: "/service"
    },
    {
        roleTitle: "Departments",
        title: "Departments",
        link: "/department"
    },
    {
        title: "Doctors",
        roleTitle: "Doctors",
        link: "/doctor"
    },

    {
        title: "Patients",
        roleTitle: "Patients",
        link: "/patients"
    },
    {
        title: "Roles",
        roleTitle: "Roles",
        link: "/roles"
    },
    {
        title: "Users",
        roleTitle: "Users",
        link: "/user"
    },
    {
        title: "Leave",
        roleTitle: "Leave",
        link: "/leaveManagement"
    },
    {
        title: "Blogs",
        roleTitle: "Blogs",
        link: "/blogs"
    },
    // {
    //     title: "Settings",
    //     roleTitle: "Settings",
    //     link: "/settings"
    // },
    {
        title: "Op Consultation",
        roleTitle: "Doctor OP Consultations",
        link: "/consultations"
    },
    {
        title: "Online Consultation",
        roleTitle: "Doctor Online Consultations",
        link: "/docOnline"
    },
    {
        title: "Report Review",
        roleTitle: "Doctor Report Review Consultations",
        link: "/docReportReview"
    },
    {
        title: "Time Management",
        roleTitle: "Doctor Time Management",
        link: "/docTimeManagement"
    },
    {
        title: "Leave Management",
        roleTitle: "Doctor Leave Management",
        link: "/docleavemanagement"
    },
    {
        title: "Blogs",
        roleTitle: "Doctor Blogs",
        link: "/docBlog"
    },

    {
        title: "Settings",
        roleTitle: "Doctor Settings",
        link: "/docSettings"
    },

]
export const Sidebardoctor = [

    // {
    //     title: "Settings",
    //     roleTitle: "Settings",
    //     link: "/settings"
    // },
    {
        title: "Op Consultation",
        roleTitle: "Doctor OP Consultations",
        link: "/consultations"
    },
    {
        title: "Online Consultation",
        roleTitle: "Doctor Online Consultations",
        link: "/docOnline"
    },
    {
        title: "Report Review",
        roleTitle: "Doctor Report Review Consultations",
        link: "/docReportReview"
    },
    {
        title: "Time Management",
        roleTitle: "Doctor Time Management",
        link: "/docTimeManagement"
    },
    {
        title: "Leave Management",
        roleTitle: "Doctor Leave Management",
        link: "/docleavemanagement"
    },
    {
        title: "Blogs",
        roleTitle: "Doctor Blogs",
        link: "/docBlog"
    },

    {
        title: "Settings",
        roleTitle: "Doctor Settings",
        link: "/docSettings"
    },

]


export const Sidebarpatient = [
    {
        title: "Appointment",
        roleTitle: "Appointment",
        link: "/"
    },


]

export const Sidebardataforadmin = [
    {
        title: "Appointment",
        roleTitle: "Appointment",
        link: "/"
    },
    {
        title: "Procedures",
        roleTitle: "Procedures",
        link: "/procedure"
    },
    {
        roleTitle: "Services",
        title: "Services",
        link: "/service"
    },
    {
        roleTitle: "Departments",
        title: "Departments",
        link: "/department"
    },
    {
        title: "Doctors",
        roleTitle: "Doctors",
        link: "/doctor"
    },

    {
        title: "Patients",
        roleTitle: "Patients",
        link: "/patients"
    },
    {
        title: "Roles",
        roleTitle: "Roles",
        link: "/roles"
    },
    {
        title: "Users",
        roleTitle: "Users",
        link: "/user"
    },
    {
        title: "Leave",
        roleTitle: "Leave",
        link: "/leaveManagement"
    },
    {
        title: "Blogs",
        roleTitle: "Blogs",
        link: "/blogs"
    },
    {
        title: "Settings",
        roleTitle: "Settings",
        link: "/settings"
    },


]
