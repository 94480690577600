import { CREATE_PROCEDURE_BOOKING_FAIL, CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_FAIL, CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_SUCCESS, CREATE_PROCEDURE_BOOKING_SUCCESS, CREATE_PROCEDURE_CANCEL_FAIL, CREATE_PROCEDURE_CANCEL_SUCCESS, CREATE_PROCEDURE_FAIL, CREATE_PROCEDURE_SUCCESS, CREATE_PROCEDURE_TIME_FAIL, CREATE_PROCEDURE_TIME_SUCCESS, EDIT_PROCEDURE_BOOKING_FAIL, EDIT_PROCEDURE_BOOKING_SUCCESS, EDIT_PROCEDURE_FAIL, EDIT_PROCEDURE_SUCCESS, FILTER_PROCEDURE_BOOKING_FAIL, FILTER_PROCEDURE_BOOKING_SUCCESS, FILTER_PROCEDURE_FAIL, FILTER_PROCEDURE_SUCCESS, GET_PROCEDURE_BOOKING_FAIL, GET_PROCEDURE_BOOKING_SUCCESS, GET_PROCEDURE_BOOKING_VIEW_FAIL, GET_PROCEDURE_BOOKING_VIEW_SUCCESS, GET_PROCEDURE_FAIL, GET_PROCEDURE_SINGLE_FAIL, GET_PROCEDURE_SINGLE_SUCCESS, GET_PROCEDURE_SUCCESS, POST_PROCEDURE_REFERAL_BOOKING_FAIL, POST_PROCEDURE_REFERAL_BOOKING_SUCCESS, PROCEDURE_REFERAL_FAIL, PROCEDURE_REFERAL_SUCCESS, PROCEDURE_REPORT_BOOKING_FAIL, PROCEDURE_REPORT_BOOKING_SUCCESS, RESHEDULE_PROCEDURE_BOOKING_FAIL, RESHEDULE_PROCEDURE_BOOKING_SUCCESS, UPDATE_STATUS_PROCEDURE_LIST_FAIL, UPDATE_STATUS_PROCEDURE_LIST_SUCCESS } from "../constants/procedureConstant";
import { RESET_ERROR } from "../constants/userConstants";


export const procedureReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                procedureCreated: null,
                procedureEdited: null,
                procedurebookingCreated: null,
                procedureCanceled: null,
                procedureResheduled: null,
                procedureStatus: null,
                editprocedureBooking: null,
                procedureReport: null,
                procedureReferralBooking: null,
                procedureTimeList: null

            }

        case GET_PROCEDURE_SUCCESS:
            return {
                ...state,
                procedureList: action.payload,

            }
        case GET_PROCEDURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_PROCEDURE_SUCCESS:
            return {
                ...state,
                // procedureList: state?.procedureList ? [...state?.procedureList, action.payload] : action.payload,
                procedureCreated: true
            }
        case CREATE_PROCEDURE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PROCEDURE_SINGLE_SUCCESS:
            return {
                ...state,
                procedureSingleList: action.payload,

            }

        case GET_PROCEDURE_SINGLE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case EDIT_PROCEDURE_SUCCESS:
            return {
                ...state,
                procedureEdited: true,
            }
        case EDIT_PROCEDURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_PROCEDURE_BOOKING_SUCCESS:
            return {
                ...state,
                bookingList: action.payload,
                procedurebookingCreated: true
            }
        case CREATE_PROCEDURE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_SUCCESS:
            return {
                ...state,
                procedureServiceList: action.payload
            }
        case CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_PROCEDURE_TIME_SUCCESS:
            return {
                ...state,
                procedureTimeList: action.payload
            }
        case CREATE_PROCEDURE_TIME_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PROCEDURE_BOOKING_SUCCESS:
            return {
                ...state,
                procedureBookingList: action.payload
            }
        case GET_PROCEDURE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_PROCEDURE_CANCEL_SUCCESS:
            return {
                ...state,
                procedureCanceled: true
            }

        case CREATE_PROCEDURE_CANCEL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case RESHEDULE_PROCEDURE_BOOKING_SUCCESS:
            return {
                ...state,
                procedureResheduled: true
            }
        case RESHEDULE_PROCEDURE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PROCEDURE_BOOKING_VIEW_SUCCESS:
            return {
                ...state,
                procedureSingleList: action.payload
            }
        case GET_PROCEDURE_BOOKING_VIEW_FAIL:
            return {
                ...state,
                error: action.payloade
            }
        case UPDATE_STATUS_PROCEDURE_LIST_SUCCESS:
            return {
                ...state,
                procedureStatus: true
            }

        case UPDATE_STATUS_PROCEDURE_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case EDIT_PROCEDURE_BOOKING_SUCCESS:
            return {
                ...state,
                procedureBookingList: state?.procedureBookingList.map(cate => cate._id === action.payload._id ? action.payload : cate),
                editprocedureBooking: true
            }
        case EDIT_PROCEDURE_BOOKING_FAIL:
            return {
                error: action.payload
            }
        case FILTER_PROCEDURE_SUCCESS:
            return {
                ...state,
                procedureList: action.payload
            }
        case FILTER_PROCEDURE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case FILTER_PROCEDURE_BOOKING_SUCCESS:
            return {
                ...state,
                procedureBookingList: action.payload

            }
        case FILTER_PROCEDURE_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case PROCEDURE_REPORT_BOOKING_SUCCESS:
            return {
                ...state,
                procedureReport: true
            }
        case PROCEDURE_REPORT_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case PROCEDURE_REFERAL_SUCCESS:
            return {
                ...state,
                referalProcdureList: action.payload
            }
        case PROCEDURE_REFERAL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case POST_PROCEDURE_REFERAL_BOOKING_SUCCESS:
            return {
                ...state,
                procedureReferralBooking: true
            }
        case POST_PROCEDURE_REFERAL_BOOKING_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;

    }

}