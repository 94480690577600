import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { Label } from "@mui/icons-material";
const CustomButton = ({ active,variant, text, Icon, ComponentIcon ,submit,uid,width,paddingTop, data, heights, widths, fontSizes,disabled}) => {



  return (
    <Box boxShadow={ active ? 3 : 0} style={{ backgroundColor: 'transparent', borderRadius: 10 , 
    // border: active ? "2px solid #047AC3" : "none"
    }}>
    <Button
      variant={active ? 'outlined' : variant}
      startIcon={Icon === undefined ? "" : <Icon />}
      disabled={disabled}
      style={{
        paddingTop:paddingTop,
        borderRadius: 8,
        boxShadow: active ? 4 : 0,
        height: heights,
        width: widths,
        background:
        active
            ? "#fff"
            : "linear-gradient(106deg, #0E9DAB 0%, #047AC3 100%)",
            fontFamily: 'Quicksand',
          fontWeight:"bold"
      }}

      onClick={()=>submit()}
    >
      <span style={{ fontSize: fontSizes }} >{text}</span>
      {ComponentIcon === undefined ? "" : <ComponentIcon />}{" "}
    </Button>
    </Box>
  );
};

export default CustomButton;
