import React from 'react'
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const CustomCheckBox = ({ onClick, checked, changeRole, value }) => {
    console.log(checked === true);
    return (
        <>
            <Checkbox
                {...label}
                value={value}
                checked={checked}
                onChange={changeRole ? (e) => changeRole(e) : null}
                // defaultChecked
                // sx={{
                //     color: pink[800],
                //     '&.Mui-checked': {
                //         color: pink[600],
                //     },
                // }}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                onClick={onClick}
            />
        </>
    )
}

export default CustomCheckBox