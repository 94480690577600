
import { Box, Button, Dialog, DialogActions, DialogTitle, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import DataTables from '../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import data from '../../components/common/data';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomSearch from '../../components/Widgets/CustomSearch';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { getBlogs, getSingleBlogs, deleteBlog } from '../../Redux/actions/blogActions';
import { Tooltip } from "@mui/material";
import { RESET_ERROR } from '../../Redux/constants/userConstants'
const Blogs = () => {
  const dispatch = useDispatch()
  const Navigate = useNavigate()

  const { blogList, blogDeleted, error } = useSelector(state => state.blog);
  const [id, setid] = useState('')
  const [searchValue, setSearchValue] = useState(null)
  const [datas, setDatas] = useState(null)
  const [openn, setOpenn] = React.useState(false);


  const handleClickOpen = (id) => {
    setid(id)
    setOpenn(true);
  };

  const handleClose = () => {
    setOpenn(false);
  };
  const NavigateToAddPage = () => {
    Navigate('/addBlogs')
  }

  const NavigatetoEditpage = (id) => {
    Navigate(`/editBlogs/${id}`)

  }
  const ViewBlogPage = (id) => {
    Navigate(`/viewBlog/${id}`)
  }

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const deleteBlogg = () => {

    dispatch(deleteBlog(id))
    if (error) {
      toast.error(error)
    }
    else { setOpenn(false) }
  }

  useEffect(() => {
    if (blogDeleted) {
      toast.success("Blog Deleted successfully");
      dispatch({
        type: RESET_ERROR
      })
    }
    if (error) {
      toast.error(`${error?.message}`);
      dispatch({
        type: RESET_ERROR
      })
    }

  }, [error, blogDeleted])


  var columns = [
    {
      name: <b>{Capitalize("CREATED DATE & TIME")}</b>,
      header: <b>{Capitalize("CREATED DATE & TIME")}</b>,
      selector: (row) => moment(row?.created_at).format("DD-MM-YYYY hh:mm A"),
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("POST ID")}</b>,
      header: <b>{Capitalize("POST ID")}</b>,
      selector: (row) => <span>#{row?.blog_id}</span>,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("USER TYPE")}</b>,
      header: <b>{Capitalize("USER TYPE")}</b>,
      selector: (row) => row?.usertype,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("CATEGORY")}</b>,
      header: <b>{Capitalize("CATEGORY")}</b>,
      selector: (row) => row?.category === "null" ? ' ' : row?.category,
      style: {
        textAlign: "center",

      },
    },
    // {
    //   name: <b>{Capitalize("BLOG SUBJECT")}</b>,
    //   header: <b>{Capitalize("BLOG SUBJECT")}</b>,
    //   selector: (row) => row?.subject,
    //   style: {
    //     textAlign: "center",
    //   },
    // },
    {
      name: <b>{Capitalize("BLOG SUBJECT")}</b>,
      header: <b>{Capitalize("BLOG SUBJECT")}</b>,
      selector: (row) => (
        <Tooltip title={Capitalize(row?.subject)}>
          <div style={{
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100px"
          }}>
            {row?.subject}
          </div>
        </Tooltip>
      ),
    },

    {
      name: <b>ACTIONS</b>,
      cell: (row, index) => (
        <>
          <Stack spacing={2} direction="row">	<RemoveRedEyeIcon
            color="blue"
            style={{
              cursor: "pointer",
              color: "#047ac1",
            }}
            onClick={() => ViewBlogPage(row?._id)}

          />{" "}
            <BorderColorSharpIcon
              onClick={() => NavigatetoEditpage(row?._id)}
              color="blue"
              style={{
                cursor: "pointer",
                color: "#047ac1",
              }}
            />{" "}

            <DeleteIcon style={{ color: "#FF0000", cursor: "pointer" }}
              onClick={() => handleClickOpen(row?._id)
              }
            />
          </Stack>
        </>
      ),
    },

  ];


  const filterResults = (value) => {
    setSearchValue(value)
    let datas = blogList?.filter(op => op?.usertype?.toLowerCase().includes(value.toLowerCase()) || op?.category.toLowerCase().includes(value.toLowerCase()) || op?.subject.toLowerCase().includes(value.toLowerCase()))
    setDatas(datas)
  }

  useEffect(() => {
    if (blogList) {
      setDatas(blogList)
    }
  }, [blogList])

  useEffect(() => {

    dispatch(getBlogs())
  }, [])


  return (
    <>
      <Box
        sx={{
          width: "99%",
          height: "60px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexDirection: { lg: "row", md: "column", xs: "column" },
          gap: { md: "10px", xs: "10px" },
          marginBottom: "40px",
        }}
      >
        <Stack spacing={2.6} direction="row" style={{
          background: "#d8dee1",
          padding: "3px 3px 3px .1px",
          display: "flex",
          alignItems: "center",

        }} borderRadius={2}>
          <CustomSearch Icon={SearchIcon} variant={"contained"} value={searchValue} onSearch={(value) => filterResults(value)} />
          {/* <CustomButton
                        variant={"contained"}
                        text={"Filter"}
                        Icon={FilterListIcon}
                    /> */}
          <CustomButton
            submit={NavigateToAddPage}
            ComponentIcon={AddIcon}
            variant={"contained"}

          />
        </Stack>
      </Box>
      <Paper>
        <Fragment>

          <DataTables
            columns={columns}
            row={datas ? datas : []}
            pagination
          />
        </Fragment>
      </Paper>
      <Dialog
        open={openn}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you wish to delete this item?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={deleteBlogg} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}


      </Box></>
  )
}

export default Blogs

