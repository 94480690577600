import React from "react";
import { Controller } from "react-hook-form";
import { Avatar, FormGroup, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { InputAdornment, styled } from "@mui/material";
import { Box } from "@mui/system";

const CustomInput = ({
  fieldName,
  control,
  fieldLabel,
  placeholder,
  error,
  type,
  max,
  position,
  Icon,
  EndAdornments,
  onClick
}) => {
  const WhiteBorderTextField = styled(TextField)`
    & label.Mui-focused {
      color: grey;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: grey;
      }
    }
    
  `;
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  }
  return (
    <>
      <FormGroup >
        <Typography>{fieldLabel}</Typography>
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <TextField
            //autoComplete="off"
              {...field}
              aria-invalid={error ? "true" : "false"}
              className="form-control"
              placeholder={placeholder}
              onKeyPress={handleKeyPress}
              id="exampleInputEmail1"
              type={type ? type : "text"}
              maxLength={max}
              fullWidth
              InputProps={{
                style: { borderRadius: "25px", boxShadow: "0px 3px 6px #00000029", opacity: "1", background: "#FFFFFF40", height: "48px", fontFamily: "Quicksand", fontWeight: "bold", letterSpacing: "1px" ,color: 'black'},
                startAdornment: (
                  <InputAdornment position={position}>
                    {/* <Icon style={{ color: "white" }} /> */}
                    <Box>
                      <Avatar src={Icon} variant="square" style={{ width: "16px", height: "20px", display: "flex" }} />
                    </Box>


                  </InputAdornment>
                ),

                endAdornment: EndAdornments === undefined ? "" : <EndAdornments style={{ color: "white", cursor: "pointer" }} onClick={onClick} />
              }}
            />
          )}
        />
        {error && (
          <p
            role="alert"
            style={{
              color: "#FF0000",
              display: "flex",
              flexDirection: "start",
              paddingLeft: "10px",
              fontSize: "12px",
              letterSpacing: "1px",
              fontWeight: "bold",
              fontFamily: "Quicksand"
            }}
          >
            {error?.message}
          </p>
        )}
      </FormGroup>
    </>
  );
};

export default CustomInput;
