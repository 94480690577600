import React, { useState, useEffect, useRef } from 'react'
import { Avatar, Box, Button, CircularProgress, InputAdornment, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Modal, Stack, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import CunsultationType from './CunsultationType';
import CustomCalender from '../../../components/Widgets/CustomCalender';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import customAxios from '../../../customAxios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { set } from 'lodash';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import format from 'date-fns/format'
import { getAllDoctorLeave } from "../../../Redux/actions/doctorAction";
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
const AddLeaveModal = ({ open, handleClose, res }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { profiledata } = useSelector(state => state?.profile);
    const [startDate, setStartDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [endDate, setEndDate] = useState(null)
    const [dateArray, setDateArray] = useState([]);
    const [showLoading, setshowLoading] = useState(false)
    const [type, settype] = useState([
        {
            name: 'Op',
            checked: false
        },
        {
            name: 'Online',
            checked: false
        },
        {
            name: 'Report Review',
            checked: false
        }
    ]);
    const [leaveType, setLeaveType] = useState([])
    const schema = object().shape({
        date: yup.array().required("Date is required").nullable(false),
        consultationType: yup.array().required("Consultation type is required").min(1, "Consultation type is required"),
    });

    //date
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ])

    const [openn, setOpenn] = useState(false)

    // get the target element to toggle 
    const refOne = useRef(null)

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setOpenn(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne?.current && !refOne?.current?.contains(e.target)) {
            setOpenn(false)
        }
    }
    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])
    const onChange = (e, i) => {
        setError("consultationType", null)

        if (e.target.checked) {
            setLeaveType([...leaveType, i])
            setValue("consultationType", [...leaveType, i])
        }
        else {
            setLeaveType(leaveType.filter(leave => leave !== i));
            setValue("consultationType", leaveType ? (leaveType.filter(leave => leave !== i)) : null)
        }
    }
    // const updateTable = async (id) => {
    //     console.log(profiledata?.id)
    //     handleClose()
    //     props.leaveDetails()

    // }
    const applyLeave = async (data) => {
        setLoading(true)
        let datas = {
            id: profiledata?._id,
            type: leaveType,
            leave_days: data?.date,
            department: profiledata?.department
        }
        try {
            const res = await customAxios.post('/doctor/apply-leave', datas);
            if (res?.status === 201) {
                toast.success('Added  successfully');
                dispatch(getAllDoctorLeave(profiledata?._id))
                handleClose()
                // navigate('/docleavemanagement');
                reset()
                setshowLoading(false)
            }
            else {
                throw new Error('Failed to update');
            }

        } catch (error) {
            toast.error(error.message || error)
        }
        finally {
            setLoading(false)
        }


    }

    //date
    const changeRange = (data) => {


        setRange([data?.selection])

        setStartDate(data?.selection?.startDate)

        setEndDate(data?.selection?.endDate)

        let date1 = moment(data?.selection?.startDate)
        let date2 = moment(data?.selection?.endDate)


        let startDate = new Date(date1);
        let endDate = new Date(date2);
        let dates = [];


        let tempDate = new Date(startDate?.getTime());
        while (tempDate <= endDate) {
            dates?.push(moment(tempDate).format("YYYY-MM-DD"));
            tempDate.setDate(tempDate.getDate() + 1);

        }
        setValue("date", dates)

        //setDateArray(dates.map((d) => moment(d).format('YYYY-MM-DD')));

    }

    return (
        <Dialog
            id='first'
            open={open}
            // fullWidth
            maxWidth={"xs"}
            onClose={handleClose}>
            <DialogTitle>
                <Stack justifyContent={'space-between'} direction={'row'} gap={5} px={2} py={2} >
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        paddingLeft: '70px',
                        fontSize: 22,
                        color: '#047AC3',
                        letterSpacing: 1
                    }}>{'Apply Leave'}</Typography>
                    <Button>
                        <Close
                            onClick={() => handleClose()}
                            sx={{
                                color: '#000',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }} />
                    </Button>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} py={.5} >
                        <Box py={1} px={1}>
                            <Typography fontSize={20} fontFamily={'Quicksand'} color={'#000'} fontWeight={'bold'} style={{ fontSize: 'initial' }} letterSpacing={1}>Choose Date</Typography>
                        </Box>
                        <TextField
                            fullWidth
                            className="inputBox"
                            value={startDate ? `${format(startDate, "dd/MM/yyyy")} to ${format(endDate, "dd/MM/yyyy")}` : moment(Date()).format('YYYY-MM-DD')}
                            // sx={{ backgroundColor: '#E6F4F7' }}
                            InputProps={{
                                style: { borderRadius: '15px', height: '48px', backgroundColor: '#E6F4F7' },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarMonthIcon onClick={() => setOpenn(!openn)} style={{
                                            color: "#047ac1"
                                        }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box ref={refOne}>
                            {openn &&
                                <DateRange
                                    onChange={item => changeRange(item)}
                                    setValue={Date()}
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={false}
                                    ranges={range}
                                    months={1}
                                    direction="horizontal"
                                    className="calendarElement"
                                />
                            }
                        </Box>
                        <Grid container xs={10} style={{

                            justifyContent: "space-between"
                        }}>
                            <Grid>

                                {errors?.date && <Typography style={{
                                    color: "red", display: "flex", paddingLeft: "10",
                                    fontSize: "small"
                                }} >{errors?.date?.message}</Typography>}
                            </Grid>
                            <Grid >
                                {openn && <Button border variant="outlined" href="#outlined-buttons" onClick={() => setOpenn(false)}>
                                    OK
                                </Button>}
                            </Grid>
                        </Grid>

                    </Grid>

                    <Box px={2}>
                        <Typography fontSize={20} fontFamily={'Quicksand'} color={'#000'} style={{ fontSize: 'initial' }} fontWeight={'bold'} letterSpacing={1}>Consultation Type</Typography>
                    </Box>
                    {[{ name: 'OP', value: 'Op' }, { name: 'Online', value: 'Online' }, { name: 'Report Review', value: 'Report' }]?.map((res, i) => (
                        <Grid item xs={12}>
                            <CunsultationType
                                error={errors?.consultationType}

                                label={res?.name}
                                onchange={(e) => onChange(e, res?.value)}
                            />
                        </Grid>))}
                    <Grid item xs={12}>

                        <Typography style={{
                            color: "red", display: "flex", paddingLeft: "10",
                            fontSize: "small"

                        }}>{errors?.consultationType?.message}</Typography>
                    </Grid>
                    <Grid marginTop={"10px"} display={"flex"} justifyContent={"center"} width={"100%"}>

                        <Button disabled={loading} variant="contained" onClick={handleSubmit(applyLeave)}>
                            {loading ? <CircularProgress /> : "Confirm"}
                        </Button>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog >
    )
}

export default AddLeaveModal