import React, { useState } from "react";
import { FormGroup, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import Select from '@mui/material/Select';
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from "@mui/system";
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
const CustomSelectInput = ({
  fieldName,
  control,
  fieldLabel,
  error,
  children,
  max,
  isMultiple,
  selectvalue,
  onChangeValue,
  options,
  background,
  height,
  size,
  value,
  label,
  widthSize,
  defaultValue
}) => {

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  return (
    <>
      <FormGroup>
        <Typography sx={{
          paddingLeft: '5px',
          fontSize: {
            lg: size,
            md: 14,
            sm: 13,
            xs: 12,
          },
          fontFamily: 'Quicksand',
          fontWeight: "bold"
        }}>{fieldLabel}</Typography>
        <Controller
          name={fieldName}
          control={control}

          render={({ field: { onBlur, onChange } }) => (

            <Select
              // sx={{

              //   "& .MuiSvgIcon-root": {
              //     color: "white",

              //     width: '30px',
              //     borderRadius: '5px',
              //     background: '#0681bf',


              //     padding: 'px;'
              //   },
              // }}
              fullWidth
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              displayEmpty

              onChange={onChangeValue}
              onBlur={onBlur}
              value={value}
              label={label}
              IconComponent={() => (

                <Box
                  sx={{
                    cursor: 'pointer'
                  }}

                  onClick={handleOpen}
                  // width={60}
                  height={height}
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  bgcolor="#047AC3"
                  color={"#fff"}
                  borderRadius={"10px"}>
                  <KeyboardArrowDownSharpIcon style={{ fontSize: 30, fontWeight: 'bold' }} />
                </Box>
              )}
              style={{
                //  width: widthSize,
                borderRadius: "10px",
                background: background,
                height: height,
              }}

            >
              {children}
            </Select>
          )}
        />
        {error && (
          <p
            role="alert"
            style={{
              color: "red",
              display: "flex",
              flexDirection: "start",
              paddingLeft: "10px",
              fontSize: "12px",
            }}
          >
            {error?.message}
          </p>
        )}
      </FormGroup>
    </>
  );
};

export default CustomSelectInput;
