import React, { Fragment, useCallback, useContext, useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import UiBlocker from '../../../components/Widgets/UiBlocker';
import customAxios from '../../../customAxios';
import UserContext from '../../../helpers/User';
import { useSelector } from "react-redux";
import Completed from '../Report Review/Table/Completed';
import Today from '../Report Review/Table/Today';
import Upcoming from '../Report Review/Table/Upcoming';
import { toast } from 'react-toastify';

const DrReportReview = () => {
  const [active, setActive] = useState('Today');
  const [consultations, setConsultations] = useState([]);
  const [activeColor, setActiveColor] = useState('contained');
  const [show, setShow] = useState(true);
  const userContext = useContext(UserContext);
  const { profiledata } = useSelector(state => state?.profile);





  const focus = localStorage.getItem("focus")
  useEffect(() => {
    if (focus === "Today") {

      activateTodayTrip()
    }
    else if (focus === "Upcoming") {

      activateUpcomingTrip()
    }
    else if (focus === "Completed") {
      activateCompleted()
    }
  }, [profiledata?._id]);




  const activateTodayTrip = useCallback(async () => {
    if (profiledata?._id) {
      setShow(true);
      setConsultations([]);
      setActive('Today');
      let data = {
        doctor_id: profiledata?._id,
        type: "Report"
      };
      try {
        const response = await customAxios.post(`/doctor/todaysconsultations`, data);
        if (response?.data?.message === "Success") {
          setConsultations(response.data.data);
        }
        else {
          throw "Internal server error"
        }
      } catch (error) {
        toast.error(error?.message || error)
      } finally {
        setShow(false);
      }
    }
  }, [profiledata?._id]);




  const activateUpcomingTrip = useCallback(async () => {
    if (profiledata?._id) {
      setShow(true);
      setConsultations([]);
      setActive('Upcoming');
      let data = {
        doctor_id: profiledata?._id,
        type: "Report"
      };
      try {
        const response = await customAxios.post(`/doctor/upcomingconsultations`, data);
        if (response?.data?.message === "Success") {
          setConsultations(response.data.data);
        }
        else {
          throw "Internal server error"
        }
      } catch (error) {
        toast.error(error?.message || error)
      } finally {
        setShow(false);
      }
    }
  }, [profiledata?._id]);

  const activateCompleted = useCallback(async () => {
    if (profiledata?._id) {
      setShow(true);
      setConsultations([]);
      setActive('Completed');
      let data = {
        doctor_id: profiledata?._id,
        type: "Report"
      };
      try {
        const response = await customAxios.post(`/doctor/oldconsultations`, data);
        if (response?.data?.message === "Success") {
          setConsultations(response.data.data);
        }
        else {
          throw "Internal server error"
        }
      } catch (error) {
        toast.error(error?.message || error)
      } finally {
        setShow(false);
      }
    }
  }, [profiledata?._id]);
  // useEffect(() => {
  //   if (focus === "Today") {

  //     activateTodayTrip()
  //   }
  //   else if (focus === "Upcoming") {

  //     activateUpcomingTrip()
  //   }
  //   else if (focus === "Completed") {
  //     activateCompleted()
  //   }

  // }, [focus])
  return (
    <Box py={2} px={3}>
      <Stack spacing={2} direction="row">
        <CustomButton
          active={focus === "Today"}
          text={"Today"}
          submit={() => {
            activateTodayTrip()
            localStorage.setItem("focus", "Today")
          }}
          variant={activeColor}
        />
        <CustomButton
          active={focus === "Upcoming"}
          text={"Upcoming"}
          submit={() => {
            activateUpcomingTrip()
            localStorage.setItem("focus", "Upcoming")
          }}
          variant={activeColor}
        />
        <CustomButton
          active={focus === "Completed"}
          text={"Completed"}
          submit={() => {
            activateCompleted()
            localStorage.setItem("focus", "Completed")
          }}
          variant={activeColor}
        />
      </Stack>
      <Box>

        {active === 'Today' && <Today mode={'Report'} consultations={consultations} />}
        {active === 'Upcoming' && <Upcoming mode={'Report'} consultations={consultations} />}
        {active === 'Completed' && <Completed mode={'Report'} consultations={consultations} />}
      </Box>
      <UiBlocker show={show} />
    </Box>
  );
};

export default DrReportReview;
