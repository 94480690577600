import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomCheckBox from '../CustomCheckBox'

const CustomCheckboxTitile = ({ label, changeRole, checked, value }) => {
  return (


    <Box
      style={{ background: 'white', display: 'flex', alignItems: 'center', height: '40px' }}
      minWidth={180}
      justifyContent={'space-around'}
      borderRadius={3}

    >
      <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>{label}</Typography>
      <CustomCheckBox changeRole={changeRole} checked={checked} value={value} />
    </Box>


  )
}

export default CustomCheckboxTitile