import React, { useEffect } from "react";
import { BUTTONIMG } from "./style";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../../assets/images/hlogoLogin.png";
import mail from "../../assets/images/mail.png";
import loginbutton from "../../assets/images/checkmark-done-sharp.png";
import gobackbutton from "../../assets/images/backarrow.png";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomInput from "../../components/Widgets/CustomInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../Redux/constants/userConstants";
import { forgetPassword } from "../../Redux/actions/userActions";
import { Link } from "react-router-dom";
const Forget = ({ setforgetpage }) => {
  const dispatch = useDispatch()
  const { emailSend, error, forgetList } = useSelector(state => state.auth);
  const schema = object().shape({
    email: yup.string().email().required('Email is required'),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),

  });

  const submit = (data) => {
    dispatch(forgetPassword(data))

  };

  useEffect(() => {
    if (emailSend) {
      toast.success("Email Send successfully");
      dispatch({
        type: RESET_ERROR
      })
    }

    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR
      })

    }
  }, [emailSend, error])



  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          paddingRight: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center"
        }}
      >
        <Avatar
          alt="Example Alt"
          src={logo}
          sx={{ width: 159, height: 149, mb: 3 }}
        />
        <Typography variant="h5" color="white" fontSize="40px" fontFamily={'Quicksand'} fontWeight={"bold"} letterSpacing={"2px"}>
          FORGOT PASSWORD
        </Typography>
        <Typography component="h1" variant="h5" color="white" fontSize="16px" fontFamily={'Quicksand ,sans-serif'}>
          please enter your
        </Typography>
        <Typography component="h1" variant="h5" color="white" fontSize="16px" fontFamily={'Quicksand ,sans-serif'}>
          registered email address
        </Typography>


        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 3 }}

          >
            <Box width={"80%"}>
              <CustomInput
                control={control}
                error={errors.email}
                fieldName="email"
                position={"start"}
                Icon={mail}
                placeholder={"Email"}
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            md: 3,
            mt: 2,
          }}
        >
          <BUTTONIMG onClick={handleSubmit(submit)}>
            <img src={loginbutton} alt="image" style={{ width: "50px", height: "30px" }} ></img>
          </BUTTONIMG>
        </Box>

        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            sx={{ mt: 8 }}
            gap='10px'
          >
              <Avatar src={gobackbutton} style={{width:'25px'}}></Avatar> 
            <Link
              style={{
                color: "white",
                fontSize: "22px",
                textDecoration: 'none',
                letterSpacing:'1px'
              }}
              startIcon={gobackbutton}
              to="/login"
            >
              LOGIN
            </Link>
          </Grid>
        </Grid>
      </Box>

    </Container>
  );
};

export default Forget;
