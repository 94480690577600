import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Backbutton = () => {
  const navigate = useNavigate();

  return (
    <Button
      style={{
        borderRadius: 18,
        width: "50px",
        //boxSizing: "content-box !important"
      }}
    >
      <ArrowBackIosIcon
        onClick={() => navigate(-1)}
        sx={{
          boxSizing: "unset !important"
        }}
        style={{
          color: "white",
          background: "linear-gradient(106deg, #0E9DAB 0%, #047AC3 100%)",
          width: "12px",
          padding: "6px 16px",
          height: 30,
          borderRadius: "35px",
        }}
      />
    </Button>
  );
};

export default Backbutton;
