import React, { Fragment } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import DataTables from '../../../../components/Widgets/dataTable';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import customAxios from '../../../../customAxios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
function Today({ mode, consultations }) {

	const { doctorDetails } = useSelector(state => state.auth)
	const navigate = useNavigate();

	const handleViewConsultation = (id, consultationData) => {
		navigate(`/docViewModal/${id}`, { state: { consultation: consultationData } });
	};
	const navigatePage = (id) => {
		switch (mode) {
			case 'Op':
				navigate(`/docEditMyConsultation/${id}`);
				break;
			case 'Online':
				navigate(`/docEditMyConsultation/${id}`);
				break;
			case 'Report':
				navigate(`/docEditMyConsultation/${id}`);
				break;
			default:
				break;
		}
	};

	const Capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	//Start Zoom Video Call
	const startZoomVideoCall = async (session, password, name, roletype) => {
		var win = window.open(`/onlineVideo/${session}/${password}/${name}/${roletype}`, '_blank');
		win.focus();

		//console.log({UIToolkitConfig})

		// let UIToolkit = document.createElement('app-uitoolkit')

		// UIToolkit.setAttribute("uitoolkitconfig", UIToolkitConfig)

		// document.getElementById('UIToolkit').append(UIToolkit)


	}
	const videoCall = async (id, row) => {

		let data = {
			booking_id: id
		};
		console.log(id);
		try {
			if(row?.doctor_id === doctorDetails?._id){
				const response = await customAxios.post(`/doctor/videocall`, data);
				if (response?.data?.message === 'Notification send') {
					const roletype = 1;
					toast(response?.data?.message)
					console.log(row?.zoomDetails);
					startZoomVideoCall(row?.zoomDetails?.sessionName, row?.zoomDetails?.password, row?.doctor_details?.name, roletype)
				}
			}
			else{
				startZoomVideoCall(row?.zoomDetails?.sessionName, row?.zoomDetails?.password, row?.doctor_details?.name, 0)
			}
			
			// console.log({ response });
			// if (response?.data?.message === 'Notification send') {
			// 	const roletype = 1;
			// 	toast(response?.data?.message)
			// 	console.log(row?.zoomDetails);
			// 	startZoomVideoCall(row?.zoomDetails?.sessionName, row?.zoomDetails?.password, row?.doctor_details?.name, roletype)

			// }

			// else {
			// 	// toast(response?.data?.message)
			// }

		} catch (error) {
			toast.error(error?.message || error)

		} finally {
			// setShow(false);
		}
	}
	const renderActions = (row) => {
		if (row.status === 'Completed') {
			return (
				<Stack spacing={2} direction="row">
					<RemoveRedEyeIcon
						style={{
							cursor: 'pointer',
							color: '#047ac1',
						}}
						onClick={() => handleViewConsultation(row?._id, row)}
					/>
				</Stack>
			);
		}

		return (
			<Stack spacing={2} direction="row">
				{(mode === 'Online') && (
					<CallIcon
						style={{
							cursor: "pointer",
							color: "green",
						}}
						onClick={() => videoCall(row?._id, row)}

					/>
				)}
				<BorderColorSharpIcon
					onClick={() => navigatePage(row?._id ? row?._id : '')}
					color="blue"
					style={{
						cursor: "pointer",
						color: "#047ac1",
					}}
				/>
				<RemoveRedEyeIcon
					style={{
						cursor: "pointer",
						color: "#047ac1",
					}}
					onClick={() => handleViewConsultation(row?._id, row)}
				/>
			</Stack>
		);
	};

	const columns = [
		{
			name: <b>{Capitalize("BOOKING ID")}</b>,
			header: <b>{Capitalize("BOOKING ID")}</b>,
			selector: (row) => row?.booking_id,
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>{Capitalize("BOOKED ON")}</b>,
			header: <b>{Capitalize("BOOKED ON")}</b>,
			selector: (row) => moment(row?.date).format("DD/MM/YYYY"),
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>{Capitalize("PATIENT ID")}</b>,
			header: <b>{Capitalize("PATIENT ID")}</b>,
			selector: (row) => `${row?.patient?.user_id}`,
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>{Capitalize("PATIENT NAME")}</b>,
			header: <b>{Capitalize("PATIENT NAME")}</b>,
			selector: (row) => row?.name,
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>{Capitalize("STATUS")}</b>,
			header: <b>{Capitalize("STATUS")}</b>,
			selector: (row) => row?.status,
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>ACTIONS</b>,
			cell: (row, index) => (
				<>{renderActions(row)}</>
			),
		},
	];

	return (
		<Box py={2}>
			<Paper>
				<Fragment>
					<DataTables columns={columns} row={consultations} pagination />
				</Fragment>
			</Paper>
		</Box>
	);
};


export default Today;
