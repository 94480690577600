import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography, Grid, Stack, } from "@mui/material";
import { getBookingList, getBookingListView } from '../../Redux/actions/serviceAction';
import Backbutton from '../../components/Widgets/Backbutton';
import moment from 'moment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const BookingView = () => {
    const { id } = useParams();
    const dispatch = useDispatch()


    const { bookingListview } = useSelector(state => state.service);

    useEffect(() => {
        dispatch(getBookingListView(id))
    }, [])


    return (
        <>

            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        Booking ID:{bookingListview?.booking_id}
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={2} px={5}>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Patient ID</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.patient?.[0]?.user_id}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Name</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.patient?.[0]?.name}</Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Mobile Number</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.patient?.[0]?.mobile}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>DOB</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.patient.map((res) => <>{res?.dob}</>)}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Gender</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.patient.map((res) => <>{res?.gender}</>)}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Service Department</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.department.map((res) => <>{res?.name}</>)}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Service</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.service_id?.map((res) => <>{res?.name},</>)}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Referred By</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.refferd_by}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Amount</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.amount}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Date & Time</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{`${bookingListview?.date} ${moment(bookingListview?.time, 'HH:mm').format('hh:mm A')}`}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Payment Method</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.payment_method}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Payment Status</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.payment_status}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Transaction Ref</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{bookingListview?.transactionref}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Attachments</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            paddingY: '20px',
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>
                        <Box display={"flex"} flexDirection="column" gap={1} paddingY={2}>
                            {bookingListview?.service_reports?.map((res) => (
                                <a href={`${bookingListview?.baseurl}${res?.attachment}`} target="_blank">{res?.service_id.map(ser => `${ser?.name}, `)}</a>
                            ))}


                        </Box>
                    </Typography>
                </Grid>


            </Grid>



        </>
    )
}

export default BookingView