import React from 'react'
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
const CustomIconButton = ({ active,variant, text, Icon, ComponentIcon ,submit,uid,width,paddingTop, data}) => {
  return (
    <Box boxShadow={ active ? 3 : 0} style={{ backgroundColor: 'transparent', borderRadius: 10 }}>
    <Button
      variant={active ? 'outlined' : variant}
      startIcon={Icon === undefined ? "" : <Icon />}
      style={{
        paddingTop:paddingTop,
        borderRadius: 20,
        boxShadow: active ? 4 : 0,
        height:"48px",
        background:
        active
            ? "#fff"
            : "linear-gradient(106deg, #0E9DAB 0%, #047AC3 100%)",
            fontFamily: 'Quicksand',
          fontWeight:"bold"
      }}

      onClick={()=>submit()}
    >
      {text}
      {ComponentIcon === undefined ? "" : <ComponentIcon />}{" "}
    </Button>
    </Box>
  )
}

export default CustomIconButton
