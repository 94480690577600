
//export const API_URL = 'https://pal.diginestsolutions.in/public/api';
//export const API_URL = 'https://192.168.1.54/public/api/';

// 1- corporate, 2-ta, 3-customer, 4 -supplier

export const env = "live"

const URLS = {
    live: "https://api.mcareadmin.com/public/api",
    dev: "http://18.220.19.226:3010/"
}
const url = {
    dev: "https://apimcare.diginestsolutions.in/public/api/",
    // dev:  "http://localhost/mcare/public/api/",
    live: "https://api.mcareadmin.com/public/api/"
}

const key = {
    live: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA",
    dev: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA"
}
const IMGURL = {
    dev: "https://apimcare.diginestsolutions.in/public/",
    live: "https://api.mcareadmin.com/public/"
}
export const PDFFURL = {
    dev: "https://apimcare.diginestsolutions.in/public",
    live: "https://api.mcareadmin.com/public"
}

//'https://apidev.myfutton.com' //Dev mode= http://apidev.myfutton.com //Live Mode='http://api.myfutton.com';
//Dev mode= http://apidev.myfutton.com/api/v1/student //Live Mode=http://api.myfutton.com/api/v1/student';

export const BASE_URL = URLS[env]

export const IMGEURL = IMGURL[env]

export const PDFURL = PDFFURL[env]


export const API_URL = `${url[env]}`
export const API_KEY = `${key[env]}`
