import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography, Grid, Stack, Paper, Card } from "@mui/material";
import Backbutton from '../../components/Widgets/Backbutton';
import { getSingleAppointment } from '../../Redux/actions/appointmentAction';
import moment from "moment";
import { PDFURL } from '../../config/constants';
const ViewAppointment = () => {
    const { id } = useParams();
    const dispatch = useDispatch()




    const { error, sigleAppointmentList } = useSelector(state => state.appointment);
    console.log({ sigleAppointmentList })
    const focus = localStorage?.getItem('focus')

    useEffect(() => {
        dispatch(getSingleAppointment(id))
    }, [])


    return (
        <>
            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        Booking ID : {sigleAppointmentList?.booking_id}
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={2} px={5}>
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Name</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.name}</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Mobile Number</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.mobile}</Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>DOB</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.dob}</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Gender</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.gender}</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Appointment Type</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.appointmenttype}</Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Department</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.department_details?.name}</Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Doctor</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.doctor_details?.name}</Typography>
                </Grid>

                {focus !== "Report" && (
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"
                        }}>Date & Time</Typography>
                        <Typography
                            style={{
                                marginTop: "3px",
                                borderRadius: "10px",
                                height: "40px",
                                background: "#e7f5f7",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "10px"
                            }} sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,
                                },
                            }}>
                            {`${moment(sigleAppointmentList?.date).format('MM-DD-YYYY')} ${moment(sigleAppointmentList?.time, "HH:mm").format('hh:mm A')}`}
                        </Typography>
                    </Grid>
                )}


                {/* <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Amount</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.amount_payable}</Typography>
                </Grid> */}
                <Grid item xs={12} md={4} lg={3}>
                    <Typography
                        sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"
                        }}
                    >
                        Amount
                    </Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }}
                        sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,
                            },
                        }}
                    >
                        {sigleAppointmentList?.amount_payable || sigleAppointmentList?.paymentResponse?.amount}
                    </Typography>
                </Grid>

                {sigleAppointmentList?.payment_mode !== "undefined" &&
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"

                        }}>Payment Method</Typography>
                        <Typography
                            style={{
                                marginTop: "3px",
                                borderRadius: "10px",
                                height: "40px",
                                background: "#e7f5f7",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "10px"
                            }} sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                            }}>{sigleAppointmentList?.payment_mode || sigleAppointmentList?.payment_method}</Typography>
                    </Grid>}
                {sigleAppointmentList?.payment_status !== "null" &&
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"

                        }}>Payment Status</Typography>
                        <Typography
                            style={{
                                marginTop: "3px",
                                borderRadius: "10px",
                                height: "40px",
                                background: "#e7f5f7",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "10px"
                            }} sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                            }}>{sigleAppointmentList?.payment_status}</Typography>
                    </Grid>}

                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Attachment</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "120px",
                            background: "#e7f5f7",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            color: "black",
                            overflowY: "auto",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>
                        {/* {sigleAppointmentList?.attachment?.map((attachment) => attachment.name)} */}
                        {sigleAppointmentList?.attachment?.map((attachment) => {
                            // Check if attachment and attachment.attachment_id are defined
                            if (attachment && attachment?.id) {

                                const pdfURL = attachment?.image;

                                return (
                                    <div key={attachment?.id}>
                                        {pdfURL ? (
                                            <a
                                                href={`${PDFURL}${pdfURL}`}
                                                target="_blank"
                                                style={{ textDecoration: "underline", display: "block" }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 14,
                                                            sm: 13,
                                                            xs: 12,
                                                        },
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    {attachment?.name}
                                                </Typography>
                                            </a>
                                        ) : (
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        md: 14,
                                                        sm: 13,
                                                        xs: 12,
                                                    },
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                {attachment?.name}
                                            </Typography>
                                        )}
                                    </div>
                                );

                            } else {
                                return null; // or some default content if needed
                            }
                        })}
                    </Typography>
                </Grid>
                {sigleAppointmentList?.appointmenttype === 'Online' ?
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography sx={{
                            fontSize: {
                                md: 16,
                                sm: 14,
                                xs: 14,
                            },
                            fontFamily: "Quicksand",
                            fontWeight: "bold",
                            letterSpacing: "1px"

                        }}>{sigleAppointmentList?.doctors?.length > 0 ? 'Individual List' : 'Group List'}</Typography>
                        <Typography
                            style={{
                                marginTop: "3px",
                                borderRadius: "10px",
                                height: "80px",

                                color: "black",
                                // display: "flex",
                                // alignItems: "center",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                paddingLeft: "10px",
                                background: "#e7f5f7",
                                overflowY: "auto"
                            }} sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                            }}>
                            {
                                sigleAppointmentList?.doctors_grouparray?.name


                            }




                            {sigleAppointmentList?.doctors?.map((res) =>
                                <Box display={'flex'} marginRight={1} padding={'5px'} borderRadius={2}>
                                    {res?.name}
                                </Box>
                            )}
                        </Typography>
                    </Grid> : ""}
                <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Remarks</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "80px",
                            background: "#e7f5f7",
                            color: "black",
                            // display: "flex",
                            // alignItems: "center",
                            paddingLeft: "10px",
                            overflowY: "auto"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{sigleAppointmentList?.remarks}</Typography>
                </Grid>

            </Grid>
            <Box display={"flex"} paddingTop={"60px"} gap={"10px"} px={5}>
                <Typography sx={{
                    fontSize: {
                        md: 18,
                        sm: 14,
                        xs: 14,
                    },
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                    letterSpacing: "1px"

                }}>Status</Typography> :  <Typography sx={{
                    fontSize: {
                        md: 18,
                        sm: 14,
                        xs: 14,
                    },
                    fontFamily: "Quicksand",
                    fontWeight: "bold",
                    letterSpacing: "1px"
                }}>{sigleAppointmentList?.status}</Typography>
            </Box>

        </>
    )
}

export default ViewAppointment