import moment from "moment"
import axios from "../../customAxios"
import { CANCEL_APPOINTMENT_FAIL, CANCEL_APPOINTMENT_SuCCESS, CREATE_APPOINMENT_FAIL, CREATE_APPOINMENT_SUCCESS, CREATE_REPORT_APPOINTMENT_FAIL, CREATE_REPORT_APPOINTMENT_SUCCESS, EDIT_APPOINTMENT_FAIL, EDIT_APPOINTMENT_SUCCES, FILTER_APPOINTMENT_FAIL, FILTER_APPOINTMENT_SUCCESS, GET_APPOINTMENT_FAIL, GET_APPOINTMENT_SUCCESS, GET_DEPT_BY_DOC_NAME_FAIL, GET_DEPT_BY_DOC_NAME_SUCCESS, GET_DOC_TIME_FAIL, GET_DOC_TIME_SUCCESS, GET_SINGLE_APPOINTMENT_FAIL, GET_SINGLE_APPOINTMENT_SUCCESS, RESCHEDULE_APPOINTMENT_FAIL, RESCHEDULE_APPOINTMENT_SuCCESS, VIEW_APPOINTMENT_FAIL, View_APPOINTMENT_SuCCESS } from "../constants/appointmentConst"
import {
    LOADING, LOGIN_FAILED, LOGIN_SUCCESS
} from "../constants/userConstants"
import { useSelector } from "react-redux";
import { isNull } from "lodash";


export const postAppointment = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/consultation-booking`, data)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: CREATE_APPOINMENT_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: CREATE_APPOINMENT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getDeptByDocName = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/department/doctor-search`, data)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: GET_DEPT_BY_DOC_NAME_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_DEPT_BY_DOC_NAME_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getAppointmentTime = (data) => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/check-doctor`, data)
        .then(async response => {

            let data = response.data.data?.time;
            let availableTimes = [];
            let date = moment(response?.data?.data?.date, "YYYY-MM-DD");
            console.log(date);
            console.log(data);
            //check given date is today
            if (moment().isSame(date, 'day')) {
                data?.map(time => {
                    if (moment() < moment(time?.time, "hh:mm A") && isNull(time?.patient_id)) {
                        availableTimes.push(time)
                    }
                })
            }
            else {
                data?.map(time => {
                    if (isNull(time?.patient_id)) {
                        availableTimes.push(time)
                    }
                })
            }

            let datas = {
                ...response?.data?.data,
                time: availableTimes
            }

            dispatch({
                type: GET_DOC_TIME_SUCCESS,
                payload: datas
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_DOC_TIME_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getAppointment = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/consultations/${data}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_APPOINTMENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const editAppointment = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/edit-consultation`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_APPOINTMENT_SUCCES,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: EDIT_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const viewAppointment = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/consultation/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: View_APPOINTMENT_SuCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: VIEW_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const cancelAppointment = (val) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/cancel-booking`, val)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CANCEL_APPOINTMENT_SuCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: CANCEL_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const resheduleAppointment = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/reschedule-consultation`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: RESCHEDULE_APPOINTMENT_SuCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: RESCHEDULE_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({

                type: LOADING,
                payload: false
            })
        })
}

export const getSingleAppointment = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/consultation/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SINGLE_APPOINTMENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_SINGLE_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const filterAppointment = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/consultation/booking/filter`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: FILTER_APPOINTMENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: FILTER_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const createReportBooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/consultation-report-booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_REPORT_APPOINTMENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: CREATE_REPORT_APPOINTMENT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}



