import axios from "../../customAxios"
import { POST_DOCTOR_RESCHEDULE_LIST_FAIL, POST_DOCTOR_RESCHEDULE_LIST_SUCCESS, POST_DOCTOR_RESCHEDULE_LIST_FAIL_DOCTOR, POST_DOCTOR_RESCHEDULE_LIST_SUCCESS_DOCTOR } from "../constants/doctorConstant";
import { CREATE_LEAVE_FAIL, CREATE_LEAVE_SUCCESS, GET_LEAVE_FAIL, GET_LEAVE_SUCCESS, REJECT_OR_APPROVE_FAIL, REJECT_OR_APPROVE_SUCCESS } from "../constants/leaveConstant";
import { LOADING } from "../constants/userConstants";

export const postLeave = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/add-leave`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_LEAVE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_LEAVE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getLeave = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/leave-list`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_LEAVE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_LEAVE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const postResheduleList = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/consultation/list`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: POST_DOCTOR_RESCHEDULE_LIST_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: POST_DOCTOR_RESCHEDULE_LIST_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const postResheduleListbasedondoctor = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/leave/consultation/list`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: POST_DOCTOR_RESCHEDULE_LIST_SUCCESS_DOCTOR,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: POST_DOCTOR_RESCHEDULE_LIST_FAIL_DOCTOR,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const postApproveReject = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/edit-leave`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: REJECT_OR_APPROVE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: REJECT_OR_APPROVE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}