import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Container, Divider, Grid } from "@mui/material";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import CustomSelectInput from "../../components/Widgets/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from "../../components/Widgets/CustomFormInput";
import Button from '@mui/material/Button';
import CustomImageUpload from "../../components/Widgets/CustomImageUploade";
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import { postDepartment, updateDepartment } from "../../Redux/actions/departmentAction";
import { toast } from 'react-toastify';

const DepartmentAdd = ({ handleClose, handleOpen, CategoryList, DeptEditdata, title, active }) => {



	const dispatch = useDispatch()

	const { loading } = useSelector(state => state.auth);
	const [selectvalue, setselectValue] = useState('');
	const [images, setimages] = useState(null)
	const [previewEdit, setPreviewEdit] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const schema = object().shape({
		description: yup.string().max(300, 'Maximum Character exceeded').nullable(),
		name: yup.string().max(50).required("Name is required"),
		category_id: yup.string().required("category id Required"),
		localImage: yup.boolean(),
		image: yup.mixed()
			.nullable()
			.when("localImage", {
				is: false,
				then: yup.mixed().required("Image  is Required")

			})
	});

	const {
		register,
		handleSubmit,
		setFieldValue,
		control,
		formState: { errors },
		reset,
		setValue,
		setError
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			description: '',
			name: '',
			category_id: '',
			localImage: false
		}
	});


	const setimagefile = (e) => {
		if (e.size > 500000) {
			toast.warning("Image Size Should be less than 500KB");
			setPreviewEdit(null)
			setimages(null)
		} else {
			setPreviewEdit(null)
			setimages(e)
		}



	}

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		boxShadow: 24,
		border: "none",
		width: '30%',
		p: 2.5,
	};



	// const submit = (data) => {

	// 	if (DeptEditdata) {
	// 		const formData = new FormData();
	// 		formData.append('id', DeptEditdata._id);
	// 		formData.append('status', DeptEditdata.status);
	// 		formData.append('name', data.name === "" ? DeptEditdata.name : data.name);
	// 		formData.append('category_id', data.category_id === "" ? DeptEditdata.category_id : data.category_id);
	// 		if (data?.image) {
	// 			formData.append('image', data.image);
	// 		}
	// 		formData.append('description', data.description === "" ? DeptEditdata.description : data.description)
	// 		dispatch(updateDepartment(formData))

	// 	} else {
	// 		const formData = new FormData();
	// 		formData.append('name', data.name);
	// 		formData.append('category_id', data.category_id);
	// 		formData.append('image', data.image);
	// 		formData.append('description', data.description)
	// 		dispatch(postDepartment(formData))
	// 	}
	// }
	const submit = async (data) => {
		if (submitting) {
			return;
		}

		setSubmitting(true);

		try {
			if (DeptEditdata) {
				const formData = new FormData();
				formData.append('id', DeptEditdata?._id);
				formData.append('status', DeptEditdata?.status);
				formData.append('name', data?.name === "" ? DeptEditdata?.name : data?.name);
				formData.append('category_id', data?.category_id === "" ? DeptEditdata?.category_id : data?.category_id);
				if (data?.image) {
					formData.append('image', data?.image);
				}
				formData.append('description', data?.description === "" ? "" : data?.description)
				dispatch(updateDepartment(formData))
			} else {
				const formData = new FormData();
				formData.append('name', data?.name);
				formData.append('category_id', data?.category_id);
				formData.append('image', data?.image);
				formData.append('description', data?.description)
				dispatch(postDepartment(formData))
			}


		} catch (error) {
			// Handle errors here if necessary
		} finally {
			setSubmitting(false);
		}
	};
	const handleCreateNewItem = (event, object) => {
		setselectValue(event.target.value)
		setValue("category_id", event.target.value)
		setError("category_id", '')
	};

	const clearclose = () => {
		setimages(null)
		setselectValue(null)
		setPreviewEdit(null)
		reset()
		handleClose()
	}


	useEffect(() => {

		if (DeptEditdata) {
			setValue("name", DeptEditdata?.name)
			setValue("description", DeptEditdata?.description === null || DeptEditdata?.description === "null" ? "" : DeptEditdata?.description)
			setValue("category_id", DeptEditdata?.category_id)
			setselectValue(DeptEditdata?.category_id)
			setPreviewEdit(DeptEditdata?.image)
			setValue("localImage", `${DeptEditdata?.image}` ? true : false)
		}
	}, [DeptEditdata])

	return (
		<Modal
			open={handleOpen}
			onClose={clearclose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Stack
					direction={"row"}
					spacing={2}
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						style={{ color: "#047BC1" }}
					>
						{title}
					</Typography>
					<ClearIcon
						style={{
							cursor: "pointer",
							color: "black"
						}}
						onClick={() => clearclose()}
					/>
				</Stack>

				<Divider />



				<Grid container spacing={2} mt={5}  >
					<Grid item xs={4} md={6} >

						<CustomSelectInput
							control={control}
							error={errors.category_id}
							fieldName="category_id"
							fieldLabel="Select Category"
							background="#e7f5f7"
							height="40px"
							size="16px"
							defaultValue={""}
							value={selectvalue}
							onChangeValue={handleCreateNewItem}
						// options={categoryArray}
						>
							<MenuItem value="" disabled >
								<em>Select Category</em>
							</MenuItem>
							{CategoryList?.filter((res) => res?._id === active).map((status, index) => (
								<MenuItem key={status?.name} value={status?._id} >
									{status?.name}
								</MenuItem>
							))}

						</CustomSelectInput>


					</Grid>
					<Grid item xs={4} md={6} >
						<CustomFormInput
							height="40px"
							background="#e7f5f7"
							fieldLabel="Department Name "
							control={control}
							error={errors.name}
							fieldName="name"
							type={"Department_Name"}
							placeholder={"Department Name"}
						/>
					</Grid>
					<Grid item xs={4} md={6} >

					</Grid>
					<Grid item xs={4} md={6} >
						<CustomImageUpload
							fieldLabel='Department Image'
							control={control}
							error={errors.image}
							fieldName='image'
							type={"file"}

							height="120px"
							background="#e7f5f7"
							id="contained-button-file"
							preview={images}
							previewEditimage={previewEdit}
							onChangeValue={(file) => {
								setimagefile(file)
								setValue("image", file)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomFormInput
							fieldLabel="Description"
							control={control}
							error={errors.description}
							fieldName="description"
							type={"textarea"}
							placeholder={"Description"}
							height='120px'
							multiline={true}
							background="#e7f5f7"
							maxrow={4}
						/>

					</Grid>

				</Grid>
				<Box
					display={"flex"}
					justifyContent={"center"}
					marginTop={5}>
					<CustomButton disabled={loading} variant={"contained"} text={loading ? <CircularProgress
					/> : "Submit"} submit={handleSubmit(submit)} />
				</Box>

			</Box>
		</Modal>
	)
}

export default DepartmentAdd