import { Avatar, Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton'

const ViewBasic = ({ doctorSingleList, setActive }) => {

    return (
        <>


            <Grid container spacing={3} px={5}>
                <Grid item xs={6} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Department</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.department_details?.name}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Name</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.name}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>

                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Email Address</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.email}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Phone Number</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.mobile}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>

                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Designation</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "60px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px",
                            overflow: 'auto'
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.designation}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>

                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Specialization</Typography>
                    <Typography
                        py={2}
                        px={2}
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "60px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px",
                            overflow: 'auto'
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.specialization}</Typography>
                </Grid>



                <Grid item xs={6} md={3} >
                    <Typography sx={{
                        fontSize: {
                            md: 15,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",

                    }}>Department Image</Typography>
                    <Avatar style={{
                        marginTop: "3px",
                        borderRadius: "10px",
                        height: "120px",
                        width: "100%",
                        background: "#e7f5f7",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px"
                    }} src={`${doctorSingleList?.basepath}${doctorSingleList?.image}`} ></Avatar>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Qualifications</Typography>
                    <Typography
                        style={{

                            borderRadius: "10px",
                            background: "#e7f5f7",
                            color: "black",
                            padding: 5,

                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,
                            },
                        }}>{doctorSingleList?.qualifications}</Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Area of Expertise</Typography>
                    <Typography
                        py={3}
                        px={2}
                        style={{
                            borderRadius: "10px",
                            background: "#e7f5f7",
                            color: "black",
                        }}
                        sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.expertise}</Typography>

                </Grid>
            </Grid>
            {/* <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} width={"30%"}>
                        <Grid item xs={12} >
                            <CustomButton variant={"contained"} text={"Next"} submit={() => setActive("Consultation_Time")} />
                        </Grid>

                    </Box> */}



        </>
    )
}

export default ViewBasic