import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import UserContext from '../helpers/User';

export { PrivateRoute };

function PrivateRoute({ children }) {
  const context = useContext(UserContext)




  if (!context?.user) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />
  }

  // authorized so return child components
  return children;
}