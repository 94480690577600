export const CREATE_PROCEDURE_SUCCESS = "CREATE_PROCEDURE_SUCCESS"
export const CREATE_PROCEDURE_FAIL = "CREATE_PROCEDURE_FAIL"
export const GET_PROCEDURE_SUCCESS = "GET_PROCEDURE_SUCCESS"
export const GET_PROCEDURE_FAIL = "GET_PROCEDURE_FAIL"
export const GET_PROCEDURE_SINGLE_SUCCESS = "GET_PROCEDURE_SINGLE_SUCCESS"
export const GET_PROCEDURE_SINGLE_FAIL = " GET_PROCEDURE_SINGLE_FAIL"
export const EDIT_PROCEDURE_SUCCESS = "EDIT_PROCEDURE_SUCCESS"
export const EDIT_PROCEDURE_FAIL = "EDIT_PROCEDURE_FAIL"
export const CREATE_PROCEDURE_BOOKING_SUCCESS = "CREATE_PROCEDURE_BOOKING_SUCCESS"
export const CREATE_PROCEDURE_BOOKING_FAIL = "CREATE_PROCEDURE_BOOKING_FAIL"
export const CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_SUCCESS = "CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_SUCCESS"
export const CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_FAIL = "CREATE_PROCEDURE_BOOKING_SERVICE_BY_DEPT_FAIL"
export const CREATE_PROCEDURE_TIME_SUCCESS = "CREATE_PROCEDURE_TIME_SUCCESS"
export const CREATE_PROCEDURE_TIME_FAIL = "CREATE_PROCEDURE_TIME_FAIL"
export const CREATE_PROCEDURE_LIST_SUCCESS = "CREATE_PROCEDURE_LIST_SUCCESS"
export const CREATE_PROCEDURE_LIST_FAIL = "CREATE_PROCEDURE_LIST_FAIL"
export const UPDATE_STATUS_PROCEDURE_LIST_SUCCESS = "UPDATE_STATUS_PROCEDURE_LIST_SUCCESS"
export const UPDATE_STATUS_PROCEDURE_LIST_FAIL = "UPDATE_STATUS_PROCEDURE_LIST_FAIL"
export const GET_PROCEDURE_BOOKING_SUCCESS = "GET_PROCEDURE_BOOKING_SUCCESS"
export const GET_PROCEDURE_BOOKING_FAIL = "GET_PROCEDURE_BOOKING_FAIL"
export const CREATE_PROCEDURE_CANCEL_SUCCESS = "CREATE_PROCEDURE_CANCEL_SUCCESS"
export const CREATE_PROCEDURE_CANCEL_FAIL = "CREATE_PROCEDURE_CANCEL_FAIL"
export const RESHEDULE_PROCEDURE_BOOKING_SUCCESS = "RESHEDULE_PROCEDURE_BOOKING_SUCCESS"
export const RESHEDULE_PROCEDURE_BOOKING_FAIL = "RESHEDULE_PROCEDURE_BOOKING_FAIL"
export const GET_PROCEDURE_BOOKING_VIEW_SUCCESS = "GET_PROCEDURE_BOOKING_VIEW_SUCCESS"
export const GET_PROCEDURE_BOOKING_VIEW_FAIL = "GET_PROCEDURE_BOOKING_VIEW_FAIL"
export const EDIT_PROCEDURE_BOOKING_SUCCESS = "EDIT_PROCEDURE_BOOKING_SUCCESS"
export const EDIT_PROCEDURE_BOOKING_FAIL = "EDIT_PROCEDURE_BOOKING_FAIL"
export const FILTER_PROCEDURE_SUCCESS = "FILTER_PROCEDURE_SUCCESS"
export const FILTER_PROCEDURE_FAIL = "FILTER_PROCEDURE_FAIL"
export const FILTER_PROCEDURE_BOOKING_SUCCESS = "FILTER_PROCEDURE_BOOKING_SUCCESS"
export const FILTER_PROCEDURE_BOOKING_FAIL = "FILTER_PROCEDURE_BOOKING_FAIL"
export const PROCEDURE_REPORT_BOOKING_SUCCESS = 'PROCEDURE_REPORT_BOOKING_SUCCESS'
export const PROCEDURE_REPORT_BOOKING_FAIL = 'PROCEDURE_REPORT_BOOKING_FAIL'
export const PROCEDURE_REFERAL_SUCCESS='PROCEDURE_REFERAL_SUCCESS'
export const PROCEDURE_REFERAL_FAIL='PROCEDURE_REFERAL_FAIL'
export const POST_PROCEDURE_REFERAL_BOOKING_SUCCESS = 'POST_PROCEDURE_REFERAL_BOOKING_SUCCESS'
export const POST_PROCEDURE_REFERAL_BOOKING_FAIL='POST_PROCEDURE_REFERAL_BOOKING_FAIL'
