import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Input } from "@mui/material";
const CustomSearch = ({ Icon, variant, onSearch, value ,placeholder}) => {
	const Search = styled("div")(({ theme }) => ({
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: "white",
		"&:hover": {
			backgroundColor: "white",
		},
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto",
		},
	}));

	const SearchIconWrapper = styled("div")(({ theme }) => ({
		padding: theme.spacing(0, 2),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	}));

	const StyledInputBase = styled(InputBase)(({ theme }) => ({
		color: "inherit",
		"& .MuiInputBase-input": {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)})`,
			transition: theme.transitions.create("width"),
			width: "100%",

			[theme.breakpoints.up("sm")]: {
				width: "12ch",
				"&:focus": {
					width: "20ch",
				},
			},
		},
	}));
	// return (
	// 	<Box sx={{

	// 		display: "flex",

	// 		alignItems: "center",
	// 	}}>
	// 		<Search>
	// 			<StyledInputBase
	// 				placeholder="Search…"
	// 				inputProps={{ "aria-label": "search" }}
	// 				onChange={(e)=> setValue(e.target.value)}
	// 				value={value}
	// 			/>
	// 		</Search>
	// 		<Button variant={variant} style={{ background: "linear-gradient(106deg, #0E9DAB 0%, #047AC3 100%)" }} >
	// 			<Icon />
	// 		</Button>
	// 	</Box>
	// );

	return(
		<Input 
			placeholder={placeholder ? placeholder : "Search...."}
			inputProps={{ "aria-label": "search" }}
			onChange={(e)=> onSearch(e.target.value)}
			value={value}
			disableUnderline={true}
			style={{ backgroundColor: "#fff", borderRadius: 10, marginLeft: 5, paddingLeft: 5, width: 250 }}
		/>
	)
};

export default CustomSearch;
