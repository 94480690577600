import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import customAxios from '../../../customAxios';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Patientmanagement = () => {
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const id = localStorage.getItem("id");
    console.log({id},'patientid');
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

   
    const handleLogout = async () => {
        try {
            const response = await customAxios.delete(
                `/patient/remove-user/${id}`
            );
    
            if (response?.status === 201) {
               
				toast.success(`Patient Account Deletion Successfull`);
                navigate('/login/patient')
            } else {
                throw new Error('Failed to delete the user.');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };
  
    

    return (
        <Box sx={{ flexGrow: 1 }} py={5} height={300}>
            <Grid container direction="column" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6">Patient Account Deletion</Typography>
                <Button variant="contained" onClick={handleOpenModal}>
                    Delete
                </Button>
            </Grid>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="logout-modal-title"
                aria-describedby="logout-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'white',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" id="logout-modal-title" sx={{ marginBottom: '16px' }}>
                        Are you sure you want to delete your Account?
                    </Typography>
                    <Typography variant="body2" id="logout-modal-description" sx={{ color: 'gray' }}>
                        You can't retrieve your deleted information back once you confirm.
                    </Typography>
                    <Button onClick={handleLogout}>Yes</Button>
                    <Button onClick={handleCloseModal}>No</Button>
                    {/* <Button onClick={() => navigate('/login/patient')}>Logout</Button> */}
                </Box>
            </Modal>
        </Box>
    );
};

export default Patientmanagement;
