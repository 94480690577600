import React, { useState, useEffect } from "react";
import { Box, Button, Container } from "@mui/material";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { styled } from '@mui/material/styles';
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";
import Backbutton from "../../../components/Widgets/Backbutton";
import ViewBasic from "./ViewBasic";
import ViewConsultation from "./ViewConsultation";
import ViewFees from "./ViewFees";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { GetDocterSingleList } from "../../../Redux/actions/doctorAction";
const ViewDoctor = () => {
    let { id } = useParams();
    const dispatch = useDispatch()
    const { doctorSingleList } = useSelector(state => state.doct);


    const [Active, setActive] = useState("Basic_Details")
    const [stable, setStable] = useState("")
    useEffect(() => {
        dispatch(GetDocterSingleList(id))
    }, [])


    return (
        <> <Box>
            <Stack
                direction={"row"}
                spacing={1}
                style={{ display: "flex", alignItems: "center" }}
            >
                <Backbutton />
                <Typography
                    variant="h4"
                    component="h2"
                    style={{ fontSize: "25px", color: "#047AC3" }}
                >
                    View Doctor
                </Typography>
            </Stack>
        </Box>

            <Box display={"flex"} justifyContent="space-between" paddingTop={"30px"} alignItems={"center"} px={5}>
                <CustomButton
                    variant={"contained"}
                    active={Active === "Basic_Details" ? true : false}
                    text={"Basic Details"}
                    submit={() => setActive("Basic_Details")} />
                <Box
                    style={{
                        color: "#0A8EB6",
                        width: "30%",
                        borderBottom: " 2px dashed #0A8EB6",
                    }
                    } />

                <CustomButton
                    active={Active === "Consultation_Time" ? true : false}
                    variant={"contained"}
                    text={"Consultation Time"}
                    submit={() => setActive("Consultation_Time")} />
                <Box
                    style={{
                        color: "#0A8EB6",
                        width: "30%",
                        borderBottom: "2px dashed #0A8EB6",
                    }
                    } />
                <CustomButton
                    active={Active === "Fees" ? true : false}
                    variant={"contained"}
                    text={"Fees"}
                    submit={() => setActive("Fees")} />
            </Box>

            <Box marginTop={"25px"}>

                {Active === "Basic_Details" ?
                    <ViewBasic setActive={setActive} Active={Active} doctorSingleList={doctorSingleList} /> :
                    Active === "Consultation_Time" ?
                        <ViewConsultation setActive={setActive} doctorSingleList={doctorSingleList} /> :
                        Active === "Fees" ?
                            <ViewFees setActive={setActive} doctorSingleList={doctorSingleList} />
                            : ""}
            </Box>

        </>
    )
}

export default ViewDoctor