export const LOADING = 'LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const OTP_SUCCESS = 'OTP_SUCCESS'
export const OTP_FAILED = 'OTP_FAILED'
export const LOADER = 'LOADER'

export const AUTH_INPUT = 'AUTH_INPUT'
export const RESET_USER = 'RESET_USER'
export const FORGET_SUCCESS = "FORGET_SUCCESS"
export const FORGET_FAIL = "FORGET_FAIL"
export const RESET_ERROR = 'RESET_ERROR'
export const PASSWORD_UPDATE_SUCCES = "PASSWORD_UPDATE_SUCCES"
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL"



export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"
export const DEL_USER_SUCCESS = "DEL_USER_SUCCESS"
export const DEL_USER_FAIL = "DEL_USER_FAIL"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_FAIL = "EDIT_USER_FAIL"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL"
export const USER_STATUS_CHANGE_SUCCESS = "USER_STATUS_CHANGE_SUCCESS"
export const USER_STATUS_CHANGE_FAIL = "USER_STATUS_CHANGE_FAIL"
export const FILTER_USER_SUCCESS = "FILTER_USER_SUCCESS"
export const FILTER_USER_FAIL = "FILTER_USER_FAIL"