import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Divider, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from "../../../components/Widgets/CustomFormInput";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CustomImageUpload from "../../../components/Widgets/CustomImageUploade";
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";
import { editSlider, postGroups, postSlider } from "../../../Redux/actions/settingsAction";
import CustomSelectInput from "../../../components/Widgets/CustomSelect";
import CustomMultipleSelectInput from "../../../components/Widgets/CustomMultipleSelect";
import { GetDocterList } from "../../../Redux/actions/doctorAction";
const AddGroups = ({ handleClose, handleOpen, groupData }) => {


    const { doctorList } = useSelector(state => state.doct);
    const { loading, loader } = useSelector(state => state.auth);


    const dispatch = useDispatch()
    const [group, setGroup] = useState([])
    const [postDocArray, setPostDocArray] = useState([]);




    const schema = object().shape({

        name: yup.string().max(100).required("Name is required"),
        multipleDoctors: yup.array().required(" Doctors  field is required"),
    });





    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        resolver: yupResolver(schema),

        defaultValues: {


        }
    });




    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 150,
            },
        },
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: 260,
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        p: 2.5,
    };


    const onChangeMultiple = (event) => {

        const {
            target: { value },
        } = event;

        const values = event.target.value
        let find = doctorList?.filter((res, I) => event.target.value.includes(res._id))
        let data = find?.map((res) => ({
            name: `${res?.name}(${res?.department_details?.name})`,
            id: res?._id
        }
        ))
        setPostDocArray([...data])

        setValue('multipleDoctors', [...data])
        setGroup(
            values
        );



    }
    const clearClose = () => {
        reset();
        handleClose()
    }

    const SubmitForm = (data) => {
        let doc = [...postDocArray]
        let val = {
            name: data?.name,
            doctors: doc

        }
        dispatch(postGroups(val))

    }
    useEffect(() => {
        if (groupData) {
            setValue('name', groupData?.name)
            let map = groupData?.doctors?.map((res) => (
                res?.id
            ))
            setGroup([...map])
        }
    }, [groupData])



    useEffect(() => {
        dispatch(GetDocterList())
    }, [])


    return (
        <Modal
            open={handleOpen}
            onClose={clearClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack
                    direction={"row"}
                    spacing={5}
                    style={{
                        marginBottom: 5,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ color: "#047BC1" }}
                    >
                        {groupData ? "EDIT GROUP" : "ADD GROUP"}
                    </Typography>
                    <ClearIcon
                        style={{
                            cursor: "pointer",
                            color: "black"
                        }}
                        onClick={clearClose}
                    />
                </Stack>
                <Divider />
                <Box display={"flex"} gap={"5px"} flexDirection={"column"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomFormInput
                                height="40px"
                                fieldLabel="Group Name"
                                control={control}
                                error={errors.name}
                                fieldName="name"
                                type={"text"}
                                background="#FFFFFF"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomMultipleSelectInput
                                control={control}
                                error={errors.multipleDoctors}
                                fieldName="multipleDoctors"
                                fieldLabel="Add Additional Doctors"
                                background="#FFFFFF"
                                multiline
                                multiple={true}
                                height="40px"
                                size="15px"
                                defaultValue={""}
                                value={group}
                                MenuProps={MenuProps}
                                onChangeValue={onChangeMultiple}
                            >
                                <MenuItem value="" disabled >
                                    <em>Select Doctors</em>
                                </MenuItem>
                                {doctorList?.map((doc) => (
                                    <MenuItem key={doc?._id} value={doc?._id} >
                                        {doc?.name}({doc?.department_details?.name})
                                    </MenuItem>
                                ))}
                            </CustomMultipleSelectInput>
                        </Grid>
                    </Grid>


                    <Box display={"flex"} paddingTop={"10px"} justifyContent={"center"}>
                        <CustomButton text={loader ? "Loading..." : "Submit"} disabled={loader ? true : false} variant={"contained"} submit={handleSubmit(SubmitForm)} />
                    </Box>

                </Box>
            </Box>
        </Modal>
    )
}

export default AddGroups