import React from 'react'
import DataTable from 'react-data-table-component'

const DataTables = ({ columns, row, pagination,loading}) => {
  return (
    <DataTable
      data={row}
      columns={columns}

      striped={true}
      center={true}
      pagination={pagination}
      progressPending = {loading}
    />
  )
}

export default DataTables