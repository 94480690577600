export const CREATE_LEAVE_SUCCESS ="CREATE_LEAVE_SUCCESS"
export const CREATE_LEAVE_FAIL = 'CREATE_LEAVE_FAIL'

export const EDIT_LEAVE_SUCCESS = "EDIT_LEAVE_SUCCESS"
export const EDIT_LEAVE_FAIL = 'EDIT_LEAVE_FAIL'


export const GET_LEAVE_SUCCESS = 'GET_LEAVE_SUCCESS'
export const GET_LEAVE_FAIL='GET_LEAVE_SUCCESS'

export const REJECT_OR_APPROVE_SUCCESS ='REJECT_OR_APPROVE_SUCCESS'
export const REJECT_OR_APPROVE_FAIL='REJECT_OR_APPROVE_FAIL'