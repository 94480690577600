import { PROFILE_SUCCESS } from "../constants/profileConstants";
import { AUTH_INPUT, FORGET_FAIL, FORGET_SUCCESS, LOADER, LOADING, LOGIN_FAILED, LOGIN_SUCCESS, OTP_FAILED, OTP_SUCCESS, PASSWORD_UPDATE_FAIL, PASSWORD_UPDATE_SUCCES, RESET_ERROR } from "../constants/userConstants";


export const authReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                loginSuccess: null,
                error: null,
                emailSend: null,
                passwordUpdated: null,
                otpsuccess: null,
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case LOADER:
            return {
                ...state,
                loader: action.payload
            }

        case AUTH_INPUT:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }


        case LOGIN_SUCCESS:
            return {
                ...state,
                userData: action?.payload?.user,
                role: action?.payload?.role,
                loginSuccess: true,
                department: action?.payload?.departmentDetails,
                doctorDetails: action?.payload?.doctorDetails

            }



        case LOGIN_FAILED:
            return {
                ...state,
                error: action.payload
            }


        case OTP_SUCCESS:
            return {
                ...state,
                userData: action?.payload?.user,
                role: action?.payload?.role,
                otpsuccess: true,


            }

        case OTP_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case FORGET_SUCCESS:
            return {
                ...state,
                forgetList: action.payload,
                emailSend: true
            }
        case FORGET_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case PASSWORD_UPDATE_SUCCES:
            return {
                ...state,
                passwordUpdated: true
            }
        case PASSWORD_UPDATE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;

    }

}