import React, { useState, useEffect } from 'react'
import { Box, Button, Container, Divider, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from "../../components/Widgets/Backbutton";
import { Typography } from "@mui/material";
import CustomSelectInput from "../../components/Widgets/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from "../../components/Widgets/CustomFormInput";
import MenuItem from '@mui/material/MenuItem';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import CustomImageUpload from '../../components/Widgets/CustomImageUploade';
import { getDepartmentListByStatus, getDeptCategory } from '../../Redux/actions/departmentAction';
import { useDispatch, useSelector } from "react-redux";
import AddDepartment from './components/AddDepartment';
import CustomTimePicker from '../../components/Widgets/CustomTimePicker/CustomTimePicker';
import moment from 'moment';
import { createServiceList, editSingleListView, getSingleListView } from '../../Redux/actions/serviceAction';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'
import CustomCheckBox from '../../components/Widgets/CustomCheckBox';
import CustomMultipleSelectInput from '../../components/Widgets/CustomMultipleSelect';
const AddListing = () => {
    const { id } = useParams();
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { loading } = useSelector(state => state.auth);
    const { CategoryList, deptListBySatatus, deptCreated } = useSelector(state => state.department);
    const { createdServiceList, error, serviceSingleList, serviceListUpdated } = useSelector(state => state.service);

    const [dates, setDates] = useState({
        monday: {
            from: null,
            to: null
        },
        tuesday: {
            from: null,
            to: null
        },
        wednesday: {
            from: null,
            to: null
        },
        thursday: {
            from: null,
            to: null
        },
        friday: {
            from: null,
            to: null
        },
        saturday: {
            from: null,
            to: null
        },
        sunday: {
            from: null,
            to: null
        }
    })

    const [check, setCheck] = useState(true)
    const [images, setimages] = useState(null)
    const [open, setOpen] = useState(false);
    const [previewEdit, setPreviewEdit] = useState(null);
    const [selectDept, setSelectDept] = useState('')



    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const schema = object().shape({
        description: yup.string().max(100).required("Description is required").typeError("Description is required"),
        name: yup.string().max(50).required("Name is required"),
        price: yup.string().required('Price is required'),
        interval: yup.string().required('Interval is required'),
        department: yup.string().required('Department is required'),
        localImage: yup.boolean(),

        image: yup.mixed()
            .nullable()
            // .test('Fichier taille',
            //     'image resolution should be greater than 768 * 1000', (value) => !value || (value && value.size >= 768 * 1000))
            .when("localImage", {
                is: false,
                then: yup.mixed().required("Image Required")
            })
    });

    // const schema = yup.object().shape({
    //     description: yup.string().max(100).required("Description is required").typeError("Description is required"),
    //     name: yup.string().max(50).required("Name is required"),
    //     price: yup.string().required('Price is required'),
    //     interval: yup.string().required('Interval is required'),
    //     department: yup.string().required('Department is required'),
    //     localImage: yup.boolean(),

    //     image: yup.mixed()
    //         .nullable()
    //         .when("localImage", {
    //             is: false,
    //             then: yup.mixed().required("Image Required")
    //         }),
    //     // Add a custom validator for your dates
    //     dates: yup.object().test('dates-validator', 'At least one time field must be selected', (value) => {
    //         // Assuming that the dates are in the format {day: {from: Time, to: Time}}
    //         return Object.values(value).some(day => day.from !== null || day.to !== null);
    //     }).required()
    // });


    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            department: '',
            price: '',
            interval: '',
            description: '',
            localImage: false
        }
    });


    const setimagefile = (e) => {
        if (e.size > 500000) {
            toast.warning("Image Size Should be less than 500KB");
            setPreviewEdit(null)
            setimages(null)
        } else {
            setPreviewEdit(null)
            setimages(e)
        }

    }

    const handleCreateNewItem = (event) => {
        setSelectDept(event.target.value)
        setValue("department", event.target.value)
    };



    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (mode, day, value) => {
        dates[day][mode] = value
        setDates({
            ...dates
        })

    }



    const Submit = (data) => {



        let dateArray = [
            { day: 'monday', from: dates['monday'].from ? moment(dates['monday'].from).format("HH:mm") : null, to: dates['monday'].to ? moment(dates['monday'].to).format("HH:mm") : null },
            { day: 'tuesday', from: dates['tuesday'].from ? moment(dates['tuesday'].from).format("HH:mm") : null, to: dates['tuesday'].to ? moment(dates['tuesday'].to).format("HH:mm") : null },
            { day: 'wednesday', from: dates['wednesday'].from ? moment(dates['wednesday'].from).format("HH:mm") : null, to: dates['wednesday'].to ? moment(dates['wednesday'].to).format("HH:mm") : null },
            { day: 'thursday', from: dates['thursday'].from ? moment(dates['thursday'].from).format("HH:mm") : null, to: dates['thursday'].to ? moment(dates['thursday'].to).format("HH:mm") : null },
            { day: 'friday', from: dates['friday'].from ? moment(dates['friday'].from).format("HH:mm") : null, to: dates['friday'].to ? moment(dates['friday'].to).format("HH:mm") : null },
            { day: 'saturday', from: dates['saturday'].from ? moment(dates['saturday'].from).format("HH:mm") : null, to: dates['saturday'].to ? moment(dates['saturday'].to).format("HH:mm") : null },
            { day: 'sunday', from: dates['sunday'].from ? moment(dates['sunday'].from).format("HH:mm") : null, to: dates['sunday'].to ? moment(dates['sunday'].to).format("HH:mm") : null },
        ]

        let atLeastOneTime = Object.values(dates).some(date => date?.from !== null && date?.to !== null);

        if (!atLeastOneTime) {
            toast.error('Please provide at least one service time');
            return;
        }
        if (!id) {
            const formData = new FormData();
            formData.append('name', data?.name);
            formData.append('dateObject', JSON.stringify(dates));
            formData.append('date', JSON.stringify(dateArray));
            formData.append('department_id', data?.department);
            formData.append('price', data?.price);
            formData.append('interval', data?.interval);
            formData.append('image', data?.image);
            formData.append('description', data?.description);
            formData.append('groupable', check);
            dispatch(createServiceList(formData))

        } else {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('status', serviceSingleList?.status);
            formData.append('name', data?.name);
            formData.append('dateObject', JSON.stringify(dates));
            formData.append('date', JSON.stringify(dateArray));
            formData.append('department_id', data?.department);
            formData.append('price', data?.price);
            formData.append('interval', data?.interval);
            if (data?.image) {
                formData.append('image', data?.image);
            }
            formData.append('description', data?.description);
            formData.append('groupable', check);


            dispatch(editSingleListView(formData))
        }





    }



    //grouping function....................................
    const groupCheck = (e) => {
        const { checked } = e.target;


        if (checked) {
            setCheck(true)
        } else {
            setCheck(false)
        }



    }



    useEffect(() => {
        if (createdServiceList) {
            toast.success("Service List Created Successfully");
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }

        if (deptCreated) {
            toast.success("Department Created Successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
            dispatch(getDepartmentListByStatus())
        }

        if (serviceListUpdated) {
            toast.success("Service List updated Successfully");
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }

        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [createdServiceList, error, serviceListUpdated, deptCreated])


    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList.find((a) => a.name === "Services");
            if (find?._id) {
                dispatch(getDepartmentListByStatus(find._id))
            }
        }
    }, [CategoryList, deptCreated])


    useEffect(() => {


        dispatch(getDeptCategory())
        if (id) {
            dispatch(getSingleListView(id))
        }

    }, [id])


    useEffect(() => {

        if (id && serviceSingleList) {
            setValue("name", serviceSingleList?.name)
            setValue("department", serviceSingleList?.department_id)
            setValue("price", serviceSingleList?.price)
            setValue("description", serviceSingleList?.description)
            setValue("interval", serviceSingleList?.interval)
            setSelectDept(serviceSingleList?.department_id)
            setPreviewEdit(serviceSingleList?.image)
            if (serviceSingleList?.date) {
                setDates(serviceSingleList?.date)
            }
            setValue("localImage", serviceSingleList?.image ? true : false)
            setCheck(serviceSingleList?.groupable)
        }
    }, [serviceSingleList])

    return (
        <>
            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}

                    >
                        {!id ? "Add Listing" : "Edit Listing"}

                    </Typography>
                </Stack>
            </Box>
            <Grid container spacing={3} px={5}>
                <Grid item xs={12} md={4} lg={2.4}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Service Name"
                        control={control}
                        error={errors.name}
                        fieldName="name"
                        type={"text"}
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2.4}>

                    <CustomSelectInput
                        control={control}
                        error={errors.department}
                        fieldName="department"
                        fieldLabel="Service Department"
                        background="#e7f5f7"
                        height="40px"
                        size="16px"
                        defaultValue={""}
                        value={selectDept}
                        onChangeValue={handleCreateNewItem}

                    >
                        <MenuItem value="" disabled >
                            <em>Select Department</em>
                        </MenuItem>
                        {deptListBySatatus?.map((status) => (
                            <MenuItem key={status?.name} value={status?._id} >
                                {status?.name}
                            </MenuItem>
                        ))}

                    </CustomSelectInput>
                </Grid>
                {!id &&
                    <Grid item xs={12} md={4} lg={2.4} justifyContent={'center'} display={'flex'}>
                        <Box style={{ paddingTop: "24px" }}>
                            <CustomButton ComponentIcon={AddIcon} variant={"contained"} submit={handleOpen} />
                        </Box>
                    </Grid>}


                <Grid item xs={12} md={4} lg={2.4}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Price"
                        control={control}
                        error={errors.price}
                        fieldName="price"
                        type={"number"}
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2.4} >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Interval (Minutes)"
                        control={control}
                        error={errors.interval}
                        fieldName="interval"
                        type={"number"}
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={2} sm={3}>

                    <Box display={'flex'} gap={1} alignItems={'center'} height={'100%'}>
                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>Groupable</Typography>
                        <CustomCheckBox changeRole={(e) => groupCheck(e)} checked={check ? true : false} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <CustomFormInput
                        height="100px"
                        fieldLabel="Service Description"
                        control={control}
                        error={errors.description}
                        fieldName="description"
                        type={"text"}
                        maxrow={3}
                        multiline
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <CustomImageUpload
                        fieldLabel='Service Image'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type={"file"}
                        height="100px"
                        background="#e7f5f7"
                        id="contained-button-file"
                        preview={images}
                        previewEditimage={previewEdit}
                        onChangeValue={(file) => {
                            setimagefile(file)
                            setValue("image", file)
                        }}
                    />
                </Grid>

            </Grid>

            <Box style={{ paddingTop: "30px", paddingBottom: "20px" }} px={5}>
                <Typography variant="h5" component="h2" fontFamily={"Quicksand"} fontWeight={"bold"}>Service Time</Typography>
                <Divider />
            </Box>

            <Grid container spacing={2} px={5}>
                <Grid item xs={12} md={4}>
                    <Typography>{"Monday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            From={"From"}
                            To={"To"}
                            toValue={dates?.["monday"]?.to ? dates?.["monday"]?.to : null}
                            fromValue={dates?.["monday"]?.from ? dates?.["monday"]?.from : null}
                            onChanges={(value) => handleChange("from", "monday", value)}
                            onChangeTo={(value) => handleChange("to", "monday", value)}

                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Tuesday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Tuesday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates?.["tuesday"]?.to ? dates?.["tuesday"]?.to : null}
                            fromValue={dates?.["tuesday"]?.from ? dates?.["tuesday"]?.from : null}
                            onChanges={(value) => handleChange("from", "tuesday", value)}
                            onChangeTo={(value) => handleChange("to", "tuesday", value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Wednesday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Wednesday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates?.["wednesday"]?.to ? dates?.["wednesday"]?.to : null}
                            fromValue={dates?.["wednesday"]?.from ? dates?.["wednesday"]?.from : null}
                            onChanges={(value) => handleChange("from", "wednesday", value)}
                            onChangeTo={(value) => handleChange("to", "wednesday", value)}
                        />
                    </Box>


                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Thursday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Thursday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates?.["thursday"]?.to ? dates?.["thursday"]?.to : null}
                            fromValue={dates?.["thursday"]?.from ? dates?.["thursday"]?.from : null}
                            onChanges={(value) => handleChange("from", "thursday", value)}
                            onChangeTo={(value) => handleChange("to", "thursday", value)}
                        />
                    </Box>


                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Friday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Friday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates?.["friday"]?.to ? dates?.["friday"]?.to : null}
                            fromValue={dates?.["friday"]?.from ? dates?.["friday"]?.from : null}
                            onChanges={(value) => handleChange("from", "friday", value)}
                            onChangeTo={(value) => handleChange("to", "friday", value)}

                        />
                    </Box>


                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Saturday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Saturday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates?.["saturday"]?.to ? dates?.["saturday"]?.to : null}
                            fromValue={dates?.["saturday"]?.from ? dates?.["saturday"]?.from : null}
                            onChanges={(value) => handleChange("from", "saturday", value)}
                            onChangeTo={(value) => handleChange("to", "saturday", value)}
                        />
                    </Box>


                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Sunday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Sunday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates?.["sunday"]?.to ? dates?.["sunday"]?.to : null}
                            fromValue={dates?.["sunday"]?.from ? dates?.["sunday"]?.from : null}
                            onChanges={(value) => handleChange("from", "sunday", value)}
                            onChangeTo={(value) => handleChange("to", "sunday", value)}
                        />
                    </Box>


                </Grid>
            </Grid>


            <Box style={{ paddingTop: "50px", display: "flex", justifyContent: "center", paddingBottom: "50px" }}>
                <CustomButton submit={handleSubmit(Submit)} text={loading ? "Loading..." : "Submit"} variant={"contained"} disabled={loading ? true : false} />
            </Box>

            <AddDepartment handleClose={handleClose} handleOpen={open} CategoryList={CategoryList} />
        </>
    )
}

export default AddListing