import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Backbutton from "../../components/Widgets/Backbutton";
import CustomSelectInput from '../../components/Widgets/CustomSelect';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from '../../components/Widgets/CustomFormInput';
import CustomImageUpload from '../../components/Widgets/CustomImageUploade';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList, getDeptCategory } from '../../Redux/actions/departmentAction';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { createBlogs, EditBlogs, getSingleBlogs } from '../../Redux/actions/blogActions';
import { useNavigate, useParams } from 'react-router-dom';
import { set } from 'date-fns/esm';
const AddBlogs = () => {
    const Navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()

    const [category, setCategory] = useState(null)
    const [hide, setHide] = useState(true)
    const [types, setType] = useState(null)
    const [images, setimages] = useState(null)
    const [previewEdit, setPreviewEdit] = useState('');

    const { loading } = useSelector(state => state.auth);
    const { CategoryList, deptList } = useSelector(state => state.department);
    const { blogCreated, error, singleBlogList, blogedited } = useSelector(state => state.blog);
    const { profiledata, profileList, profileupdatedsuccess } = useSelector(state => state.profile);
    const role = JSON.parse(localStorage?.getItem("user"))






    const schema = object().shape({
        usertype: yup.string().required('User Type is Required'),
        // categorylocal: yup.boolean,
        // category: yup.string().when('categorylocal', { is: false, then: yup.mixed().required("Category is Required") }),
        subject: yup.string().required('Subject is Required'),
        localImage: yup.boolean(),
        image: yup.mixed()
            .nullable()
            //  .test('Fichier taille',
            //       'image resolution should be greater than 420 * 594', (value) => !value || (value && value.size >= 420 * 594))
            .when("localImage", {
                is: false,
                then: yup.mixed().required("Image  is Required")

            }),
        content: yup.string().required('Content is Required'),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            categorylocal: false,
            localImage: false
        },
        resolver: yupResolver(schema)
    });

    const ChangeType = (event) => {
        setType(event.target.value)

        let find = CategoryList?.find((a) => a?.name === event.target.value);

        dispatch(getDepartmentList(find?._id))
        if (event.target.value === 'all') {
            setHide(false)
            setCategory(null)
            setValue("localImage", event.target.value === 'all ' ? true : false)
        } else {
            setHide(true)
        }
        setValue('usertype', event.target.value)
    }

    const ChangeCategory = (e) => {
        setCategory(e.target.value)
        setValue('category', e.target.value)
    }


    const setimagefile = (e) => {
        if (e.size > 500000) {
            toast.warning("Image Size Should be less than 500KB");
            setPreviewEdit(null)
            setimages(null)
        } else {
            setPreviewEdit(null)
            setimages(e)
        }
    }

    useEffect(() => {
        if (profiledata) {
            setValue('created_by', profiledata?.name)
        }

    }, [profiledata])



    const SubmitForm = (DATA) => {

        if (id && singleBlogList) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('usertype', types);
            formData.append('category', category);
            formData.append('subject', DATA?.subject)
            formData.append('content', DATA?.content)
            formData.append('created_by', role?._id)
            if (images) {
                formData.append('attachment', images)
            }
            dispatch(EditBlogs(formData))

        } else {
            const formData = new FormData();
            formData.append('usertype', types);
            formData.append('category', category);
            formData.append('subject', DATA?.subject)
            formData.append('content', DATA?.content)
            formData.append('created_by', role?._id)
            formData.append('attachment', images)
            dispatch(createBlogs(formData))
        }

    }

    useEffect(() => {
        if (id && singleBlogList) {
            setValue('subject', singleBlogList?.subject)
            setValue('content', singleBlogList?.content)
            setPreviewEdit(singleBlogList?.attachment)
            setValue('usertype', singleBlogList?.usertype)
            setValue('category', singleBlogList?.category)
            setType(singleBlogList?.usertype)
            setCategory(singleBlogList?.category)
            setValue("localImage", singleBlogList?.attachment ? true : false)
            setValue('image', singleBlogList?.attachment)
            if (singleBlogList?.usertype) {
                let find = CategoryList?.find((a) => a?.name === singleBlogList?.usertype);
                dispatch(getDepartmentList(find?._id))
            }

        }
    }, [id, singleBlogList])




    useEffect(() => {
        dispatch(getDeptCategory())
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(getSingleBlogs(id))
        }
    }, [id])

    useEffect(() => {
        if (blogCreated) {
            toast.success(`Blog Created Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            Navigate(-1)
        }

        if (blogedited) {
            toast.success(`Blog Edited Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            Navigate(-1)
        }

        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, blogCreated, blogedited])

    return (
        <>
            <Box style={{ paddingBottom: "30px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        {id ? 'Edit Blog' : 'Add Blog'}
                    </Typography>
                </Stack>
            </Box>
            {/* <Grid container spacing={3} padding={2}>
                {category === "null" ? "" :
                    <Grid item xs={5} md={3} lg={3} >
                        <CustomSelectInput
                            control={control}
                            error={errors.usertype}
                            fieldName="usertype"
                            fieldLabel="User Type"
                            background="#ffff"
                            height="40px"
                            size="16px"
                            value={types}
                            onChangeValue={(value) => {
                                ChangeType(value)
                                setError("usertype", "")
                            }}
                        // options={categoryArray}
                        >
                            <MenuItem value="" disabled >
                                Select User Type
                            </MenuItem>
                            {[{ value: "doctors", label: "Doctors" }, { value: 'patients', label: "Patients" }, { value: "all", label: "All" }].map((status) => (
                                <MenuItem key={status.value} value={status.value} >
                                    {status.label}
                                </MenuItem>
                            ))}

                        </CustomSelectInput>
                    </Grid>}
                {hide  ?
                    <Grid item xs={5} md={3} lg={3} >
                        <CustomSelectInput
                            control={control}
                            error={errors.category}
                            fieldName="category"
                            fieldLabel="Category"
                            background="#ffff"
                            height="40px"
                            size="16px"
                            value={category}
                            onChangeValue={ChangeCategory}
                        // options={categoryArray}
                        >
                            <MenuItem value="" disabled >
                                Select Category
                            </MenuItem>
                            {deptList?.map((dept) => (
                                <MenuItem key={dept._id} value={dept?.name} >
                                    {dept?.name}
                                </MenuItem>
                            ))}

                        </CustomSelectInput>
                    </Grid> : ''}
                <Grid item xs={5} md={3} lg={3}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel='Subject'
                        control={control}
                        error={errors.subject}
                        fieldName="subject"
                        type={"text"}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={5} md={3} lg={3}>
                    <CustomFormInput
                        readOnly={true}
                        height="40px"
                        fieldLabel='Created By'
                        control={control}
                        error={errors.created_by}
                        fieldName="created_by"
                        type={"text"}
                        background="#e7f5f7"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={6} md={9} lg={9}>
                    <CustomFormInput
                        height="120px"
                        fieldLabel='Content'
                        control={control}
                        error={errors.content}
                        fieldName="content"
                        type={"text"}
                        background="#FFFFFF"
                        multiline
                        maxrow={4}
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={4} md={3} lg={3}>
                    <CustomImageUpload
                        fieldLabel='Upload Image'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type={"file"}
                        height="120px"
                        background="#ffff"
                        id="contained-button-file"
                        preview={images}
                        previewEditimage={previewEdit}
                        onChangeValue={(file) => {
                            setimagefile(file)
                            setValue("image", file)
                        }}
                    />
                </Grid>
            </Grid> */}
            <Grid container spacing={3} padding={2}>
                <Grid item xs={5} md={3} lg={3}>
                    <CustomSelectInput
                        control={control}
                        error={errors.usertype}
                        fieldName="usertype"
                        fieldLabel="User Type"
                        background="#ffff"
                        height="40px"
                        size="16px"
                        value={types}
                        onChangeValue={(value) => {
                            ChangeType(value);
                            setError("usertype", "");
                        }}
                    >
                        <MenuItem value="" disabled >
                            Select User Type
                        </MenuItem>
                        {[{ value: "Doctors", label: "Doctors" }, { value: 'patients', label: "Patients" }, { value: "all", label: "All" }].map((status) => (
                            <MenuItem key={status?.value} value={status?.value}>
                                {status?.label}
                            </MenuItem>
                        ))}
                    </CustomSelectInput>
                </Grid>

                {types !== "all" && (
                    <Grid item xs={5} md={3} lg={3}>
                        <CustomSelectInput
                            control={control}
                            error={errors.category}
                            fieldName="category"
                            fieldLabel="Category"
                            background="#ffff"
                            height="40px"
                            size="16px"
                            value={category}
                            onChangeValue={ChangeCategory}
                        >
                            <MenuItem value="" disabled >
                                Select Category
                            </MenuItem>
                            {deptList?.map((dept) => (
                                <MenuItem key={dept?._id} value={dept?.name}>
                                    {dept?.name}
                                </MenuItem>
                            ))}
                        </CustomSelectInput>
                    </Grid>
                )}


                <Grid item xs={5} md={3} lg={3}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel='Subject'
                        control={control}
                        error={errors.subject}
                        fieldName="subject"
                        type={"text"}
                        background="#FFFFFF"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={5} md={3} lg={3}>
                    <CustomFormInput
                        readOnly
                        height="40px"
                        placeholder={role ? role?.name : ''}
                        fieldLabel='Created By'
                        control={control}
                        error={errors.created_by}
                        fieldName="created_by"
                        type={"text"}
                        background="#e7f5f7"
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
            
                <Grid item xs={6} md={9} lg={9}>
                    <CustomFormInput
                        height="120px"
                        fieldLabel='Content'
                        control={control}
                        error={errors.content}
                        fieldName="content"
                        type={"text"}
                        background="#FFFFFF"
                        multiline
                        maxrow={4}
                        boxshadow="5px 5px 10px #00000005"
                    />
                </Grid>
                <Grid item xs={4} md={3} lg={3}>
                    <CustomImageUpload
                        fieldLabel='Upload Image'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type={"file"}
                        height="120px"
                        background="#ffff"
                        id="contained-button-file"
                        preview={images}
                        previewEditimage={previewEdit}
                        onChangeValue={(file) => {
                            setimagefile(file);
                            setValue("image", file);
                        }}
                    />
                </Grid>
            </Grid>

            <Box display={'flex'} justifyContent={'center'} marginTop={15}>
                <CustomButton variant={'contained'} text={loading ? 'Loading...' : 'Submit'} disabled={loading ? true : false} submit={handleSubmit(SubmitForm)} />
            </Box>
        </>
    )
}

export default AddBlogs