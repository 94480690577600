import { Box, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import DataTables from '../../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProcedureBookingList } from '../../../Redux/actions/procedureAction';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelBookingModal from '../../Service/components/BookingCancelModal';
import BookingResheduleModal from '../../Service/components/BookingResheduleModal';
import { RESET_ERROR } from '../../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import moment from 'moment';
import { Tooltip } from "@mui/material";
const ProcedureBookingTable = ({ datas, filterbooking }) => {
    const dispatch = useDispatch()

    const Navigate = useNavigate()
    const { procedureBookingList, procedureCanceled, error, procedureResheduled } = useSelector(state => state.procedure);
    const [open, setOpen] = useState(false);
    const [openCalender, setOpenCalender] = useState(false);
    const [_id, set_id] = useState(null)



    const handleOpen = (id) => {
        set_id(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        dispatch(getProcedureBookingList())
    };


    const handleOpenCalender = (id) => {

        set_id(id)
        setOpenCalender(true);
    };

    const handleCloseCalender = () => {
        setOpenCalender(false);
    };
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    useEffect(() => {
        if (procedureCanceled) {
            toast.success("Booking Cancelled successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
        }

        if (procedureResheduled) {
            toast.success("Booking Rescheduled successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleCloseCalender()

        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [procedureCanceled, procedureResheduled, error])

    var columns = [

        {
            name: <b>{Capitalize("BOOKING ID")}</b>,
            header: <b>{Capitalize("BOOKING ID")}</b>,
            selector: (row) => row?.booking_id,
            format: (row) => `#${row?.booking_id}`,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("CREATED ON")}</b>,
            header: <b>{Capitalize("CREATED ON")}</b>,
            selector: (row) => row?.created_at,
            format: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PATIENT ID")}</b>,
            header: <b>{Capitalize("PATIENT ID")}</b>,
            selector: (row) => `#${row?.patient?.[0]?.user_id}`,
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("PATIENT NAME")}</b>,
        //     header: <b>{Capitalize("PATIENT NAME")}</b>,
        //     selector: (row) => row?.patient?.[0]?.name,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("PATIENT NAME")}</b>,
            header: <b>{Capitalize("PATIENT NAME")}</b>,
            selector: (row) => (
                <Tooltip title={Capitalize(row?.patient?.[0]?.name)}>
                    <div style={{
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {row?.patient?.[0]?.name}
                    </div>
                </Tooltip>
            ),
            style: {
                textAlign: "center",
            },
        },

        // {
        //     name: <b>{Capitalize("PROCEDURE")}</b>,
        //     header: <b>{Capitalize("PROCEDURE")}</b>,
        //     selector: (row) => row?.procedure_id?.map((res)=> `${res?.name},` )
        //     ,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("PROCEDURE")}</b>,
            header: <b>{Capitalize("PROCEDURE")}</b>,
            selector: (row) => (
                <Tooltip title={row?.procedure_id?.map((res) => res?.name)?.join(', ')}>
                    <div style={{
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {row?.procedure_id?.map((res) => Capitalize(res?.name))?.join(', ')}
                    </div>
                </Tooltip>
            ),
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("REFERRED BY")}</b>,
            header: <b>{Capitalize("REFERRED BY")}</b>,
            selector: (row) => row?.refferd_by,
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("BOOKED ON")}</b>,
            header: <b>{Capitalize("BOOKED ON")}</b>,
            cell: (row) => moment(`${row?.date} ${row?.time}`, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY hh:mm A"),

            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PAYMENT STATUS")}</b>,
            header: <b>{Capitalize("PAYMENT STATUS")}</b>,
            cell: (row) => row?.payment_status,

            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("AMOUNT")}</b>,
            header: <b>{Capitalize("AMOUNT")}</b>,
            selector: (row) => row?.amount,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("STATUS")}</b>,
            header: <b>{Capitalize("STATUS")}</b>,
            selector: (row) => row?.status,
            style: {
                textAlign: "center",
                // color:row?.status === "sheduled" ? "#FF8000" : "#19B12C"
            },
        },
        {
            name: <b>ACTIONS</b>,
            cell: (row, index) => (
                <>
                    <Stack spacing={.3} direction="row">
                        <RemoveRedEyeIcon
                            onClick={() => Navigate(`/procedureBookingView/${row?._id}`)}
                            color="blue"
                            style={{
                                cursor: "pointer",
                                color: "#047ac1",
                            }}
                        />{" "}
                        {row?.status !== "cancelled" && <>
                            <BorderColorSharpIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() =>
                                Navigate(`/procedureBooking/${row?._id}`)} />
                            <CalendarMonthIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() => handleOpenCalender(row)} />
                            <CloseIcon style={{ color: "red", cursor: "pointer" }} onClick={() => handleOpen(row._id)} />
                        </>}
                    </Stack>
                </>
            ),
        },

    ];


    useEffect(() => {
        dispatch(getProcedureBookingList())
    }, [filterbooking])


    return (
        <><Paper>
            <Fragment>

                <DataTables
                    columns={columns}
                    row={datas}
                    pagination={true}
                />
            </Fragment>
        </Paper>
            <Box
                sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                {open && <CancelBookingModal handleClose={handleClose} handleOpen={open} id={_id} procedure={"procedure"} />}
                {openCalender && <BookingResheduleModal handleClose={handleCloseCalender} handleOpen={openCalender} store={_id} procedure={"procedure"} />}

            </Box>
        </>
    )
}

export default ProcedureBookingTable