import styled from "styled-components";

export const BUTTONIMG = styled.div`
background: transparent linear-gradient(139deg, #0E9DAB 0%, #047AC3 100%) 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 10px #00000054;
border-radius: 15px;
opacity: 1;
width:70px;
height:70px;
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;
img{
    width:40px;
    height:40px;
}

`;

export const DIV = styled.div`
width: 100vw;
height: 100vh;
background-image: url(${(props) => props.img});
display: flex;
justify-content: flex-end;

`