import React, { useState, useEffect } from "react";
import { Box, Button, Container } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from "../../components/Widgets/Backbutton";
import { Typography } from "@mui/material";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import ConsultationTime from "./ConsultationTime";
import BasicDetails from "./BasicDetails";
import Fees from "./Fees";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { GetDocterSingleList } from "../../Redux/actions/doctorAction";
const AddDoctor = () => {
    let { id } = useParams();
    const dispatch = useDispatch()

    const { doctorSingleList } = useSelector(state => state.doct);

    const [Active, setActive] = useState("Basic_Details")

    useEffect(() => {
        if (id) {
            dispatch(GetDocterSingleList(id))
        }
    }, [])


    return (
        <>
            <Box px={3}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        {id ? "EDIT DOCTOR" : "ADD DOCTOR"}
                    </Typography>
                </Stack>
            </Box>

            <Box display={"flex"} justifyContent="space-between" paddingTop={"30px"} alignItems={"center"} px={5}>
                <CustomButton
                    active={Active === "Basic_Details" ? true : false}
                    variant={"contained"}
                    text={"Basic Details"}
                //submit={() => setActive("Basic_Details")} 
                />
                <Box
                    style={{
                        color: "#0A8EB6",
                        width: "30%",
                        borderBottom: " 2px dashed #0A8EB6",
                    }
                    } />

                <CustomButton
                    active={Active === "Consultation_Time" ? true : false}
                    variant={"contained"}
                    text={"Consultation Time"}
                //submit={() => setActive("Consultation_Time")} 
                />
                <Box
                    style={{
                        color: "#0A8EB6",
                        width: "30%",
                        borderBottom: " 2px dashed #0A8EB6",
                    }
                    } />
                <CustomButton
                    active={Active === "Fees" ? true : false}
                    variant={"contained"}
                    text={"Fees"}
                //submit={() => setActive("Fees")} 
                />
            </Box>

            <Box marginTop={"25px"} px={5}>

                {Active === "Basic_Details" ?
                    <BasicDetails setActive={setActive} Active={Active} Resdata={doctorSingleList} /> :
                    Active === "Consultation_Time" ?
                        <ConsultationTime setActive={setActive} Resdata={doctorSingleList} /> :
                        Active === "Fees" ?
                            <Fees setActive={setActive} Resdata={doctorSingleList} />
                            : ""}
            </Box>

        </>
    )
}

export default AddDoctor