import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Context from "./index";
import { uniq } from 'lodash'



const getLocalRole = () => {
  try {
    const role = JSON.parse(localStorage.getItem("role"))
    if (role) {
      const roles = role.permissions;

      return roles;

      return null;
    }
  } catch (err) {
    return null;
  }
};

const RoleProvider = (props) => {
  const [availRoles, setAvailRoles] = useState([])
  const [role, setRole] = useState(getLocalRole());
  const [menus, setMenus] = useState([]);
  //const history = useNavigate();



  const getRoutes = () => {
    let routes = [];


    role?.map(ro => {
      if (ro?.create?.selected) {
        routes = [...routes, ...ro?.create?.paths]
      }

      if (ro?.edit?.selected) {
        routes = [...routes, ...ro?.edit?.paths]
      }

      if (ro?.view?.selected) {
        routes = [...routes, ...ro?.view?.paths]
      }
    })
    console.log({ routes });



    setAvailRoles(uniq(routes))
  }


  const getMenus = () => {
    let menus = [];
    role?.map(ro => {

      if (ro?.create?.selected) {
        menus.push(ro.title)
      }
      else if (ro?.edit?.selected) {
        menus.push(ro.title)
      }
      else if (ro?.view?.selected) {
        menus.push(ro.title)
      }
    })

    setMenus(menus)

  }




  return (
    <Context.Provider
      value={{
        ...props,
        role,
        availRoles,
        menus,
        setRole: setRole,
        getRoutes: getRoutes,
        getMenus
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default RoleProvider;

