import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Router from "./Router";
import store from "./Redux/store";
import { ToastContainer, toast } from 'react-toastify';
import UserProvider from "./helpers/User/userContext";
import RoleProvider from "./helpers/Role/roleContext";
const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(

	<Provider store={store} >
		<UserProvider>
			<RoleProvider>
			{/* <React.StrictMode> */}
				<BrowserRouter>
					<Router />
					<ToastContainer />
				</BrowserRouter>
			</RoleProvider>
			{/* </React.StrictMode> */}
		</UserProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
