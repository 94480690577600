import React from 'react'
import Box from '@mui/material/Box';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import CustomButton from '../CustomButton/CustomButton';
const CustomFilter = ({ valuefrom, valueto, handlechangefrom, handlechangeto, inputFormatF, inputFormatT, submit, clear }) => {


    return (
        <Box display={"flex"} flexDirection={"end"}>
            <Box
                sx={{
                    width: 230,
                    height: 220,
                    background: "transparent linear-gradient(110deg,#0e9dab 0%,#047ac3 100%) 0% 0% no-repeat padding-box",
                    position: "absolute",
                    left: "10",
                    zIndex: "100",
                    right: "35px",
                    top: "135px",
                    borderRadius: "20px"

                    // '&:hover': {
                    //     backgroundColor: 'primary.main',
                    //     opacity: [0.9, 0.8, 0.7],
                    // },
                }}
            >
                <Box display={"flex"} flexDirection={"column"} gap={"5px"} paddingTop={"10px"} justifyContent={"center"}>
                    <Grid container spacing={1} paddingLeft={"5px"} paddingRight={"5px"}>
                        <Grid item xs={12} >
                            <Typography sx={{
                                fontSize: {
                                    lg: 15,
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                                fontFamily: 'Quicksand',
                                fontWeight: "bold",
                                color: "white",
                                paddingLeft: "10px"
                            }}>From</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker

                                    inputFormat="DD/MM/YYYY"
                                    value={valuefrom}
                                    onChange={handlechangefrom}
                                    renderInput={(params) => <TextField {...params} />}
                                    InputProps={{
                                        style: { borderRadius: "20px", height: "40px", background: "white" }

                                    }}
                                />
                            </LocalizationProvider>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{
                                fontSize: {
                                    lg: 15,
                                    md: 14,
                                    sm: 13,
                                    xs: 12,

                                },
                                fontFamily: 'Quicksand',
                                fontWeight: "bold",
                                color: "white",
                                paddingLeft: "10px"
                            }}>To</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker

                                    inputFormat="DD/MM/YYYY"
                                    value={valueto}
                                    onChange={handlechangeto}
                                    renderInput={(params) => <TextField {...params} />}
                                    InputProps={{
                                        style: { borderRadius: "20px", height: "40px", background: "white" }

                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>


                </Box>

                <Box display={"flex"} justifyContent={"space-evenly"} paddingTop={"20px"} gap={"10px"}>
                    <CustomButton text={"Apply Filter"} variant={"contained"} submit={submit} />
                    <CustomButton text={"clear"} variant={"contained"} submit={clear} />
                </Box>
            </Box>

        </Box >

    )
}

export default CustomFilter