import React, { Fragment, useState, useEffect } from 'react'
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import DataTables from '../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import data from "../../components/common/data";
import CustomSearch from '../../components/Widgets/CustomSearch';
import Switch from '@mui/material/Switch';
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { Box, Pagination } from '@mui/material';
import Sliders from './Sliders';
import AddSlider from './SliderModal';
import BasicDetails from './BasicDetails';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import DoctorGroups from './DoctorGroups';
import AddDoctor from '../Doctor/AddDoctor';
import AddGroups from './GroupModal';
const Settings = () => {
  const { sliderCreated, error, sliderList, groupCreted } = useSelector(state => state.settings);

  const dispatch = useDispatch()

  const Navigate = useNavigate()

  const [active, setActive] = useState('BasicDetails')
  const [activeColor, setActiveColor] = useState('contained')
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);

  const NavigateToServiceAdd = (id) => {

    if (active === 'BasicDetails') {
      Navigate(`/home/procedureBooking/${id}`)
    } else if (active === 'ProcedureListing') {
      Navigate(`/home/procedureList/${id}`)
    } else {
      Navigate(`/home/doctorGroup`)
    }

  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenD = () => {
    setOpenD(true);
  };

  const handleCloseD = () => {
    setOpenD(false);
  };

  const AddData = () => {
    if (active === 'Sliders') {
      handleOpen()
    } else if (active === 'doctors') {
      handleOpenD()

    }

  }


  useEffect(() => {
    if (sliderCreated) {
      toast.success("Slider Created successfully");
      dispatch({
        type: RESET_ERROR
      })
      handleClose()
    }
    if (groupCreted) {
      toast.success("Group Created successfully");
      dispatch({
        type: RESET_ERROR
      })
      handleCloseD()
    }
    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR
      })
    }
  }, [sliderCreated, error, groupCreted])


  return (
    <>
      <Box
        sx={{
          width: "99%",
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { lg: "row", md: "column", xs: "column" },
          gap: { md: "10px", xs: "10px" },
          marginBottom: "40px"
        }}
      >
        <Stack spacing={2} direction="row">
          <CustomButton
            active={active === "BasicDetails" ? true : false ? active === "Sliders" : false}
            text={"Basic Details"}
            submit={() => setActive('BasicDetails')}
            variant={activeColor} />

          <CustomButton active={active === "Sliders" ? true : false ? active === "BasicDetails" : false}
            text={"Sliders"}
            submit={() => setActive('Sliders')}
            variant={activeColor} />
          <CustomButton active={active === "doctors" ? true : false}
            text={"Doctor Groups"}
            submit={() => setActive('doctors')}
            variant={activeColor} />
        </Stack>
        <Stack spacing={2.6} direction="row">
          {active === "Sliders" || active === 'doctors' ?
            <CustomButton
              submit={AddData}
              ComponentIcon={AddIcon}
              variant={"contained"}

            /> : ""}
        </Stack>
      </Box>
      <Box>
        {active === "Sliders" ? <Sliders />
          : active === 'BasicDetails' ?
            <BasicDetails /> :
            active === 'doctors' ? <DoctorGroups /> : ''}
        {open && <AddSlider handleClose={handleClose} handleOpen={open} />}
        {openD && <AddGroups handleClose={handleCloseD} handleOpen={openD} />}

      </Box>
    </>
  )
}

export default Settings