import styled, { css } from "styled-components";
export const BOX = styled.div`
.btn2{
    display:none;
  

    @media (max-width: 780px) {
        padding:10px;
        justify-content:center;
        display:inline-block;
    }
}          

.container{
    display:flex;
    margin-top:120px;
    @media (max-width: 780px) {
        flex-direction:column;
        padding:10px;
  margin-top:50px;
  margin-bottom:20px;
              }
.btn1{
    @media (max-width: 780px) {
       display:none;
              }

}
         

}
.mainprofile{
    @media (max-width: 780px) {
        display:flex;
        justify-content:center;
        align-items: center;
               }
}
.imageprofile{
    display: inline-block;
    margin-left: 55px;
    height: 230px;
    width: 230px;
    border-radius: 100%;
  
    text-align:center;
   
    .avatar{
        position:relative;
        width: 100%;
        height : 100%;
        object-fit: contain;
        .iconbutton{
         position:absolute;
         bottom:0;
        }
    }
}



`;
