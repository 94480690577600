import { authReducer } from './reducers/authReducer'
import thunk from 'redux-thunk';
import { combineReducers, applyMiddleware, compose, createStore } from 'redux';
import { ProfileReducer } from './reducers/profileReducer';
import { departmentReducer } from './reducers/departmentReducer';
import { docterStateReducer } from './reducers/AddDoctor';
import { doctorReducer } from './reducers/doctorReducer';
import { patientReducer } from './reducers/patientReducer';
import { serviceReducer } from './reducers/serviceReducer';
import { procedureReducer } from './reducers/procedureReducer';
import { settingsReducer } from './reducers/settingsReducer';
import { appointmentReducer } from './reducers/appointmentReducer';
import { userReducer } from './reducers/userResucer';
import { roleReducer } from './reducers/roleReducer';
import { blogReducer } from './reducers/blogReducers';
import { leaveReducer } from './reducers/leaveReducer';
import { notificationReducer } from './reducers/notificationReducer';
import {activeSidebarreducer} from './reducers/activeSidebarReducer'




const appReducer = combineReducers({
    auth: authReducer,
    profile: ProfileReducer,
    department:departmentReducer,
    doctState:docterStateReducer,
    doct:doctorReducer,
    patient:patientReducer,
    service:serviceReducer,
    procedure:procedureReducer,
    settings:settingsReducer,
    appointment:appointmentReducer,
    user:userReducer,
    role: roleReducer,
    blog:blogReducer,
    leave:leaveReducer,
    notifi:notificationReducer,
    activeSidebar:activeSidebarreducer
})



const reducer = (state, action) => {
    if (action.type === 'RESET_USER') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}





const middleware = [thunk];

const store = createStore(reducer, compose(applyMiddleware(...middleware)));

export default store;