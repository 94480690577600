import React, { useEffect } from 'react'
import * as UIToolkit from '@zoom/videosdk-ui-toolkit'

import { useSelector } from 'react-redux';
import customAxios from '../../../customAxios';
import { useDispatch } from 'react-redux';
import { ZOOM_DATA } from '../../../Redux/constants/profileConstants';
import { useParams } from 'react-router-dom';

const ZoomVideo = () => {
    const { zoomdata } = useSelector((state) => state?.profile);

    const dispatch = useDispatch();

    const { session, password, name, roletype } = useParams()



    const generateZoomToken = async () => {
        ;
        const role_type = parseInt(roletype)
        let data = {
            sessionName: session,
            password: password,
            role_type: role_type
        }

        let res = await customAxios.post(`doctor/zoomTokenNew`, data);

        let token = res?.data?.data

        var UIToolkitConfig = {
            videoSDKJWT: token,
            sessionName: session,
            userName: name,
            sessionPasscode: `${password}`,
            features: ['video', 'audio', 'settings', 'users', 'chat']
        }


        var UIToolkitConfig = JSON.stringify(UIToolkitConfig)

        let UIToolkit = document.createElement('app-uitoolkit')

        UIToolkit.setAttribute("uitoolkitconfig", UIToolkitConfig)

        document.getElementById('UIToolkit').append(UIToolkit)
    }


    useEffect(() => {

        if (session) {
            generateZoomToken()
        }
    }, [session])


    return (
        <div id='UIToolkit'></div>
    )
}

export default ZoomVideo