import React, { useState, useEffect } from "react";
import { Box, Button, Container, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from "../../components/Widgets/Backbutton";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import CustomFormInput from "../../components/Widgets/CustomFormInput";
import CustomSelectInput from "../../components/Widgets/CustomSelect";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import { styled } from '@mui/material/styles';
import customAxios from '../../customAxios';
import { useDispatch, useSelector } from "react-redux";
import { PostPatientSearch } from "../../Redux/actions/serviceAction";
import CustomSelectSearch from "../../components/Widgets/CustomSelectSearch/CustomSelectSearch";
import { getDepartmentList, getDeptCategory } from "../../Redux/actions/departmentAction";
import {
	createReportBooking, editAppointment, getAppointmentTime, getDeptByDocName, getSingleAppointment,
	postAppointment
} from "../../Redux/actions/appointmentAction";
import CustomCalender from "../../components/Widgets/CustomCalender";
import { toast } from 'react-toastify';
import moment from "moment/moment";
import { RESET_ERROR } from "../../Redux/constants/userConstants";
import { useNavigate, useParams } from 'react-router-dom';
import CustomMultipleSelectInput from "../../components/Widgets/CustomMultipleSelect";
import { GetDocterList } from "../../Redux/actions/doctorAction";
import { isArray, isEmpty } from "lodash";
import CustomCheckBox from "../../components/Widgets/CustomCheckBox";
import { getGroups } from "../../Redux/actions/settingsAction";
import { Attachment, Mode } from "@mui/icons-material";


const AddAppointment = () => {
	const { id } = useParams();

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [status, setstatus] = useState('')
	const { loading } = useSelector(state => state.auth);
	const { groupList } = useSelector(state => state.settings);
	const { patientSearchList } = useSelector(state => state.service);
	const { doctorList } = useSelector(state => state.doct);
	const { CategoryList, deptList } = useSelector(state => state.department);
	const [attachmentststus, setattachmentststus] = useState(false)
	const [pdfs, setPdfs] = useState([]);

	const [date, setdate] = useState('')
	const { doctorNameList,
		avabilityTime,
		error,
		appoim,
		appointmentCreated,
		sigleAppointmentList,
		appointmentEdited, appointmentReportCreated } = useSelector(state => state.appointment);


	const [check, setCheck] = useState(false)
	const [appointmentType, setAppointmentType] = useState('')
	const [isEditMode, setIsEditMode] = useState(false);
	useEffect(() => {
		setIsEditMode(Boolean(id));
	}, [id]);


	const schema = object().shape({

		...(isEditMode
			? {
				// Validation for fields in 'Edit' mode
				paymentstatus: yup.string().required("Payment Status is required"),
				appointmenttype: yup.string().required("Appointment Type is required"),
				// department: yup.string().required("Department is required"),
				// doctor: yup.string().required("Doctor is required"),
				remarks: yup.string().required("Remarks is required"),
			}
			:
			(appointmentType === 'Report' ? {
				appointmenttype: yup.string().required("Appointment Type is required"),

				department: yup.string().required("Department is required"),
				doctor: yup.string().required("Doctor is required"),
				paymentstatus: yup.string().required("Payment Status is required"),
				remarks: yup.string().required("Remarks is required"),

			}

				: {
					// Validation for fields in 'Add' mode
					user_id: yup.string().required("Patient ID is required"),
					name: yup.string().required("Patient name is required"),
					//mobile: yup.string().required("Mobile number is required"),
					mobile: yup.string()
						.required("Mobile Number is required")
						.matches(/^[0-9]{10}$/, "Mobile Number must be 10 digits long and contain only numeric characters"),
						
					dob: yup.string().required("Date of birth is required"),
					time: yup.string().required("Time is required"),
					gender: yup.string().required("Gender is required"),
					paymentstatus: yup.string().required("Payment Status is required"),
					appointmenttype: yup.string().required("Appointment Type is required"),
					department: yup.string().required("Department is required"),
					doctor: yup.string().required("Doctor is required"),
					remarks: yup.string().required("Remarks is required"),
					// ...(check === "individual" || check === "group"
					// 	? { multpleArray: yup.string().required("Additional doctors are required") }
					// 	: {}),


				})

		),

	});

	const {
		register,
		handleSubmit,
		setFieldValue,
		control,
		formState: { errors },
		reset,
		setValue,
		setError,
		getValues
	} = useForm({
		resolver: yupResolver(schema),
	});
	console.log({ errors });

	const [patientArray, setPatientArray] = useState([])
	const [inputValue, setInputValue] = useState('');
	const [data, setData] = useState('')
	// const [appointmentType, setAppointmentType] = useState('')
	const [department, setDepartment] = useState(null)
	const [dateTime, setDateTime] = useState(null)
	const [docName, setDocName] = useState(null)
	const [time, setTime] = useState(null)
	const [paymentMethod, setPaymentMethod] = useState(null)
	const [paymentStatus, setPaymentStatus] = useState(null)
	const [multpleArray, setMultipleArray] = React.useState([]);
	const [postDocArray, setPostDocArray] = React.useState([]);
	const [editDOC, setEditDoc] = useState([])
	const [mode, setMode] = useState(null)
	const [group, setgroup] = useState(null)
	const [patientNameError, setPatientNameError] = useState(false);
	const [pdf, setPdf] = useState(null)
	const [timeSlotError, setTimeSlotError] = useState('');
	const focus = localStorage.getItem('focus')

	//Ptient Search...................................................................


	const PatientSearch = (value, newvalue) => {
		setData(newvalue)

	}

	const PatientOnchangeInput = (event, newInputValue) => {

		setInputValue(newInputValue)

		const val = {
			key: inputValue
		}
		dispatch(PostPatientSearch(val))
	}


	//MULTI SELECT FEILD.................................................................
	const onChangeMultiple = (event) => {


		const {
			target: { value },
		} = event;

		const values = event.target.value

		setgroup(values)

		setMultipleArray(
			values
		);

		let find = doctorList?.filter((res, I) => event.target.value.includes(res?._id))


		let data = find?.map((res) => ({
			name: res?.name,
			id: res?._id,

		}

		)

		)
		setPostDocArray([...data])

	}
	const onChangePdfs = (e) => {
		const files = e.target.files;
		const newPdfs = [...pdfs];
		console.log(newPdfs);

		for (let i = 0; i < files.length; i++) {
			newPdfs.push(files[i]);
		}

		setPdfs(newPdfs);
		setattachmentststus(true);
	}

	const onChangePdf = (e) => {
		const selectedFile = e.target.files[0];

		if (selectedFile) {
			const fileSize = selectedFile.size / (1024 * 1024);

			if (fileSize > 2) {

				toast.error("File size is too large. Please select a file smaller than 2MB.");
				setPdf(selectedFile);
				setattachmentststus(true);
			} else {

				setPdf(selectedFile);
				setattachmentststus(true);
			}
		} else {

			setPdf(null);
			setattachmentststus(false);
		}
	};




	//DATE TIME ..................................................................
	const onChangeDateNtime = (value) => {
		setDateTime(value)
		setValue('date', moment(value).format('DD-MM-YYYY'))
		const val = {
			doctor_id: docName,
			date: moment(value).format('YYYY-MM-DD'),
			type: appointmentType
		}
		// if (appoim) {
		// 	setValue('date', null)
		// }
		dispatch(getAppointmentTime(val))
	}


	//Apointment onChange.......................................................

	const onChangeAppointmentType = (e) => {
		setValue("amount", "")
		setValue('date', null)
		setDateTime(null)
		setTime(null)
		setValue("paymentmethod", "")
		setPaymentMethod(null)
		setDocName(null)
		setValue("department", "");
		setDepartment(null)
		setValue('doctor', "")
		setValue("remarks", "")
		setValue("paymentstatus", "");
		setPaymentStatus(null)
		setValue('time', "")
		setValue("appointmenttype", e.target.value);
		setAppointmentType(e.target.value)
		if (docName) {
			let find = doctorNameList?.filter((find) => find?._id === docName)
			find?.map?.((res) => {
				if (e.target.value === "Op") {
					setValue("amount", res?.op_fee)

				} else if (e.target.value === "Online") {
					setValue("amount", res?.online_fee)

				} else if (e.target.value === "Report") {
					setValue("amount", res?.review_fee)
				}
			}
			)
		}
	}

	//Docter Name filter.......................................
	const onChangeDoctorName = (e) => {
		setDocName(e.target.value);
		setValue("doctor", e.target.value)
		let find = doctorNameList?.filter((find) => find?._id === e.target.value)
		find?.map?.((res) => {
			if (appointmentType === "Op") {
				setValue("amount", res?.op_fee)

			} else if (appointmentType === "Online") {
				setValue("amount", res?.online_fee)

			} else if (appointmentType === "Report") {
				setValue("amount", res?.review_fee)
			}
		}

		)
	}
	const onChangePaymentMethod = (e) => {
		setValue("paymentmethod", e.target.value)
		setPaymentMethod(e.target.value)
		if (e.target.value === "Cash") {
			setValue("transactionref", null)
		}
	}

	const onChangePaymentStatus = (e) => {
		setValue("paymentstatus", e.target.value)
		setPaymentStatus(e.target.value)
		if (e.target.value === "Pending") {
			setValue("paymentmethod", null)
			setValue("transactionref", null)
		}

	}


	const onChangeDepartment = (e) => {

		setValue('date', null)
		setDateTime(null)
		setTime(null)
		setDocName(null)
		setValue('doctor', "")
		setValue('time', "")
		setValue("department", e.target.value);
		setDepartment(e.target.value)
		let value = {
			department_id: e.target.value
		}

		dispatch(getDeptByDocName(value))




	}

	const onChangeTime = (e) => {
		setTime(e.target.value)
		setValue('time', e.target.value)
	}





	useEffect(() => {

	}, [id])
	//grouping function....................................
	const groupCheck = (e, mode) => {

		const { checked } = e.target;
		if (!checked) {
			setMode(null)
		}
		else {
			if (mode === "individual") {
				setMode("individual")
				dispatch(GetDocterList())
				setMultipleArray([])
			}
			else {
				setMode("group")
				dispatch(getGroups())
				setMultipleArray([])
			}
		}

		// if (check === mode) {
		// 	setCheck(null)
		// 	//setMultipleArray([])

		// }
		// else {
		// 	setCheck(mode)

		// 	if (checked && mode === 'individual') {

		// 		dispatch(GetDocterList())
		// 		checked = null

		// 	} else if (checked && mode === 'group') {

		// 		dispatch(getGroups())
		// 		checked = null
		// 	}

		// 	setMultipleArray([])

		// }

	}
	const removePdf = (indexToRemove) => {
		const updatedPdfs = pdfs?.filter((_, index) => index !== indexToRemove);
		setPdfs(updatedPdfs);
	};

	console.log(pdf);
	//submit form data..........................................
	const SUbmit = async (DATA) => {

		// if (!DATA.name) {
		// 	setPatientNameError(true);
		// 	return;
		// }
		console.log("in");


		const isFileSizeValid = pdfs.every(file => file.size <= 2 * 1024 * 1024);

		if (!isFileSizeValid) {
			console.log("hh");
			toast.error("File size is too large. Please select a file smaller than 2MB.");
			return;
		}



		console.log("uu");

		if (pdf && pdf?.size > 2 * 1024 * 1024) {
			console.log("gg");

			toast.error("File size is too large. Please select a file smaller than 2MB.");
			return;
		}



		let val = {
			doctor_id: (sigleAppointmentList ? sigleAppointmentList?.doctor_id : DATA.doctor)
		}
		const response = await customAxios.post(`/admin/doctors/status`, val);
		const doctorStatus = response?.data?.message;
		// Assuming the API returns status as 'active' or 'inactive'

		const status = response?.data?.status

		if (doctorStatus === 'Docotor is inactive') {
			toast.error("Doctor is inactive. Cannot submit.");

		}
		else if (doctorStatus === 'Docotor is active') {
			console.log("hh");

			if (id && sigleAppointmentList) {

				const formData = new FormData();
				formData.append('status', sigleAppointmentList?.status);
				formData.append('id', sigleAppointmentList?._id);
				formData.append('doctor_id', sigleAppointmentList?.doctor_id)
				formData.append('patient_id', sigleAppointmentList?.patient_id)
				formData.append('appointmenttype', DATA?.appointmenttype)
				formData.append("payment_status", DATA?.paymentstatus)
				formData.append('payment_mode', DATA?.paymentmethod)
				formData.append('transactionref', DATA?.transactionref)
				formData.append('remarks', DATA?.remarks)
				// if (appointmentType === "Report" && (pdfs?.length)) {
				// 	for (let i = 0; i < pdfs.length; i++) {
				// 		formData.append(`attachment[${i}]`, pdfs[i]);
				// 	}
				// }
				// if (attachmentststus === true && pdf) {
				// 	formData.append('attachment', pdf)
				// }
				if (appointmentType === "Report" && (pdfs?.length)) {
					console.log("report");
					for (let i = 0; i < pdfs.length; i++) {
						formData.append(`attachment[${i}]`, pdfs[i]);
					}
					// formData.append('attachment', pdf);
				}
				else if (attachmentststus === true && pdf) {
					formData.append('attachment', pdf)
				}


				else if (pdf === null) {
					formData.append("image", null)
				}

				if (mode === 'individual') {
					formData.append('doctors', JSON.stringify(postDocArray)) // Set it to null for individual mode
				} else if (mode === 'group') {
					formData.append('doctorsgroup', group); // Add the selected group ID for group mode
				}


				// if (postDocArray.length === 0) {
				// 	console.log("doctor truw");
				// 	formData.append('doctorsgroup', group);
				// 	formData.append('doctors', JSON.stringify(postDocArray))

				// } else {
				// 	console.log("hhhh");
				// 	formData.append('doctorsgroup', group);

				// }
				// if (check === 'group' && group === null) {
				// 	toast.error("doctor group is needed")
				// }
				// else if (check === 'individual' && inputValue === '') {
				// 	toast.error("doctor name is needed")
				// }
				// else {
				dispatch(editAppointment(formData))
				// }
			} else {



				const formData = new FormData();
				formData.append('name', data?.name);
				formData.append('doctor_id', DATA?.doctor)
				formData.append('patient_id', data?._id)
				formData.append('mobile', data?.mobile)
				formData.append('dob', data?.dob)
				formData.append('gender', data?.gender)
				formData.append("appointmenttype", appointmentType)
				formData.append('department', department)
				formData.append('amount_payable', DATA?.amount)
				formData.append('payment_status', DATA?.paymentstatus)
				formData.append('payment_mode', DATA?.paymentmethod)
				formData.append('transactionref', DATA?.transactionref)
				formData.append('remarks', DATA?.remarks)
				if (appointmentType === "Report" && (pdfs?.length)) {
					for (let i = 0; i < pdfs.length; i++) {
						formData.append(`attachment[${i}]`, pdfs[i]);
					}
				} else if (pdf) {
					formData.append('attachment', pdf);
				}



				if (mode === 'individual') {
					formData.append('doctors', JSON.stringify(postDocArray)) // Set it to null for individual mode
				} else if (mode === 'group') {
					formData.append('doctorsgroup', group); // Add the selected group ID for group mode
				}

				if (appointmentType !== "Report") {
					formData.append('date', moment(DATA?.date, "DD-MM-YYYY").format("YYYY-MM-DD"));
					formData.append('time', DATA?.time)
					// if (check === 'group' && group === null) {
					// 	toast.error("doctor group is needed")
					// }
					// else if (check === 'individual' && inputValue === null) {
					// 	toast.error("doctor name is needed")
					// }
					// else {
					dispatch(postAppointment(formData))
					// }
				} else {
					dispatch(createReportBooking(formData))

				}
			}
		}


	}
	useEffect(() => {

		let blob = null;

		if (sigleAppointmentList?.attachment !== null) {

			const attachmentData = JSON.stringify(sigleAppointmentList?.attachment[0]);
			const attachmentType = ".pdf,.png,.jpg,.jpeg";
			blob = new Blob([attachmentData], { type: attachmentType });
		}
		if (id) {
			setMultipleArray([])
			if (sigleAppointmentList) {

				setValue("appointmenttype", sigleAppointmentList?.appointmenttype)
				setValue("paymentmethod", sigleAppointmentList?.payment_mode)
				setAppointmentType(sigleAppointmentList?.appointmenttype)
				setPaymentMethod(sigleAppointmentList?.payment_mode)
				setValue("remarks", sigleAppointmentList?.remarks)
				setPaymentStatus(sigleAppointmentList?.payment_status)
				setValue("paymentstatus", sigleAppointmentList?.payment_status)
				setValue("transactionref", sigleAppointmentList?.transactionref)
				setValue("time", sigleAppointmentList?.time)
				setValue("doctor", sigleAppointmentList?.doctor_id)
				setValue("department", sigleAppointmentList?.department)
				setPdf(sigleAppointmentList?.attachment === null ? setPdf(null) : sigleAppointmentList?.attachment[0])
				setValue("attachment", blob, sigleAppointmentList?.attachment === null ? setPdf(null) : sigleAppointmentList?.attachment[0])
				console.log(typeof (pdf));

				;
				if (sigleAppointmentList?.doctors?.length > 0) {

					setMultipleArray(sigleAppointmentList?.doctors?.map((res) => res?.id))
					setMode('individual')
					setPostDocArray(sigleAppointmentList?.doctors)
					dispatch(GetDocterList())
				}
				else if (!sigleAppointmentList?.doctors && !sigleAppointmentList?.doctorsgroup
				) {

					setMultipleArray([])
				}
				else if (sigleAppointmentList?.doctorsgroup !== "null") {


					setMultipleArray(sigleAppointmentList?.doctorsgroup)
					setMode('group')
					setgroup(sigleAppointmentList?.doctorsgroup)
					dispatch(getGroups())
				}
				else if (sigleAppointmentList?.doctors?.length > 0) {


					setMultipleArray(sigleAppointmentList?.doctorsgroup)
					setMode('group')
					setgroup(sigleAppointmentList?.doctorsgroup)
					dispatch(getGroups())
				}


				else {

					// setMultipleArray(sigleAppointmentList?.doctorsgroup)
					// setMode('group')
					// dispatch(getGroups())
					setMode(null)
				}



				setEditDoc(sigleAppointmentList?.doctors)

				// setPostDocArray([...data])
			}

		}
	}, [sigleAppointmentList, id])

	//patient serach................................

	useEffect(() => {

		if (patientSearchList) {

			setPatientArray([...patientSearchList])
		}
		if (data) {
			setValue('name', data?.name)
			setValue('user_id', data?.user_id)
			setValue('gender', data?.gender)
			setValue('mobile', data?.mobile)
			setValue('dob', data?.dob)
		}

		if (inputValue.length === 0) {
			setValue('name', "")
			setValue('gender', "")
			setValue('mobile', "")
			setValue('dob', "")
			setValue('user_id', "")
		}
	}, [patientSearchList, data, inputValue])


	//get Category List..............................................
	useEffect(() => {
		if (CategoryList) {
			let find = CategoryList?.find((a) => a?.name === "Doctors");
			dispatch(getDepartmentList(find?._id))
		}
	}, [CategoryList])



	//status msg......................................................
	useEffect(() => {
		if (appointmentReportCreated) {
			toast.success(`Appointment Created Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			navigate(-1)
		}
		if (appointmentCreated) {
			toast.success(`Appointment Created Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			navigate(-1)
		}
		if (appointmentEdited) {
			toast.success(`Appointment Edited Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			navigate(-1)
		}
		if (error) {
			toast.error(`${error.message}`);
			dispatch({
				type: RESET_ERROR
			})
		}
		if (error && error.message === 'Time slot not available') {

			setTimeSlotError(true)
			setDateTime(null)
		}
	}, [error, appointmentCreated, appointmentEdited, appointmentReportCreated])

	useEffect(() => {
		if (id) {
			dispatch(getSingleAppointment(id))
		}
	}, [id])


	useEffect(() => {
		dispatch(getDeptCategory())
		if (id) {
			dispatch(GetDocterList())
		}
	}, [])

	return (
		<>
			<Box>
				<Stack
					direction={"row"}
					spacing={1}
					style={{ display: "flex", alignItems: "center" }}
				>
					<Backbutton />
					<Typography
						variant="h4"
						component="h2"
						style={{ fontSize: "25px", color: "#047AC3" }}
					>
						{id ? 'Edit Booking' : 'Add Booking'}
					</Typography>
				</Stack>
			</Box>

			<Grid container mt={5} px={5} spacing={2}>
				{id ? "" : <Grid item xs={12} md={4} lg={3}>
					<CustomSelectSearch
						control={control}
						// filterOptions={(options, { inputValue }) => options.filter(item => item.name.includes(inputValue) || item.phone.toString.includes(inputValue) )}
						error={errors.search_patient}
						fieldName="patient"
						fieldLabel="Search patient"
						background="#FFFFFF"
						height="40px"
						size="16px"
						// error={errors.name || patientNameError}
						options={patientArray}
						// open={inputValue.length > 2}
						getOptionLabel={({ name, user_id, mobile }) => `${name} ${user_id} ${mobile}`}
						onChangeValue={PatientSearch}
						inputValue={inputValue}
						placeholder='Search by Name,Mobile Number or MRN'
						onInputChange={
							PatientOnchangeInput

						}

					/>{patientNameError && (

						< Typography variant="body2" color="error">
							Patient's name is required.
						</Typography>
					)}
				</Grid>
				}
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomFormInput
							height="40px"
							background="#e7f5f7"
							fieldLabel="Patient ID "
							control={control}
							error={errors.user_id}
							fieldName="user_id"
							readOnly={true}
						/>
					</Grid>}
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomFormInput
							height="40px"
							background="#e7f5f7"
							fieldLabel="Name "
							control={control}
							error={errors.name}
							fieldName="name"
							readOnly={true}
						/>
					</Grid>}
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomFormInput
							height="40px"
							background="#e7f5f7"
							fieldLabel="Mobile Number"
							control={control}
							error={errors.mobile}
							fieldName="mobile"
							readOnly={true}
						/>
					</Grid>}
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomFormInput
							height="40px"
							background="#e7f5f7"
							fieldLabel="DOB "
							control={control}
							error={errors.dob}
							fieldName="dob"
							readOnly={true}
						/>
					</Grid>}
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomFormInput
							height="40px"
							background="#e7f5f7"
							fieldLabel="Gender "
							control={control}
							error={errors.gender}
							fieldName="gender"
							readOnly={true}
						/>
					</Grid>}
				<Grid item xs={12} md={4} lg={3}>
					<CustomSelectInput
						control={control}
						error={errors.appointmenttype}
						fieldName="appointmenttype"
						fieldLabel="Appointment Type"
						background="#FFFFFF"
						height="40px"
						size="15px"
						defaultValue={""}
						value={appointmentType}
						onChangeValue={(value) => {
							onChangeAppointmentType(value)
							setError('appointmenttype', '')
						}}
					>
						<MenuItem value="" disabled >
							<em>Appointment Type</em>
						</MenuItem>
						{['Op', 'Online', 'Report']?.map((doc) => (
							<MenuItem key={doc} value={doc} >
								{doc}
							</MenuItem>
						))}
					</CustomSelectInput>
				</Grid>
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomSelectInput
							control={control}
							error={errors.department}
							fieldName="department"
							fieldLabel="Department"
							background="#FFFFFF"
							height="40px"
							size="15px"
							defaultValue={""}
							value={department}
							onChangeValue={(value) => {
								onChangeDepartment(value)
								setError('department', '')
							}}
						>

							<MenuItem value="" disabled >
								<em>Select Department</em>
							</MenuItem>
							{/* {deptList?.map((doc) => (
								<MenuItem key={doc?._id} value={doc?._id} >
									{doc?.status === 'active' ? doc?.name : ""}
								</MenuItem>
							))} */}
							{deptList &&
								deptList
									.filter((doc) => doc.status === 'active')
									.map((doc) => (
										<MenuItem key={doc._id} value={doc._id}>
											{doc.name}
										</MenuItem>
									))}

						</CustomSelectInput>

					</Grid>}
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomSelectInput
							widthSize={'100%'}
							control={control}
							error={errors.doctor}
							fieldName="doctor"
							fieldLabel="Doctor"
							background="#FFFFFF"
							height="40px"
							size="15px"
							defaultValue={""}
							value={docName}
							onChangeValue={(value) => {
								onChangeDoctorName(value)
								setError('doctor', '')
							}}
						>
							<MenuItem value={[]} disabled >
								<em>Select Doctor</em>
							</MenuItem>
							{isArray(doctorNameList) && doctorNameList?.map((doc) => (
								<MenuItem key={doc?._id} value={doc?._id} >
									{doc?.name}
								</MenuItem>
							))}
						</CustomSelectInput>
					</Grid>}
				{id || appointmentType === "Report" ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomCalender
							minDateres={new Date()}
							height="40px"
							fieldLabel="Choose Date"
							control={control}
							error={errors.date}
							fieldName="date"
							type={"text"}
							disabled={(getValues("doctor") && getValues("appointmenttype")) ? false : true}
							readOnly={true}
							background="#FFFFFF"
							value={dateTime}
							onChangeValue={(value) => {
								onChangeDateNtime(value)
								setError('date', '')
							}}

						/>
						{timeSlotError && (
							<Typography variant="body2" color="error">
								choose date
							</Typography>
						)}
					</Grid>}
				{id || appointmentType === "Report" ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomSelectInput
							control={control}
							error={errors.time}
							fieldName="time"
							fieldLabel="Time"
							background="#FFFFFF"
							height="40px"
							size="15px"
							defaultValue={""}
							value={time}
							onChangeValue={(value) => {
								onChangeTime(value)
								setError('time', '')

							}}
						>
							<MenuItem value="" disabled >
								<em>Availability Time </em>
							</MenuItem>
							{avabilityTime?.time?.filter(tm => tm?.patient_id === null).map((doc) => {
								if (isEmpty(doc?.patient_id)) {
									return (
										<MenuItem key={doc?._id} value={doc?.time} >
											{moment(doc?.time, 'HH:mm').format('hh:mm A')}
										</MenuItem>
									)
								}
							})}
						</CustomSelectInput>
					</Grid>}
				{id ? "" :
					<Grid item xs={12} md={4} lg={3}>
						<CustomFormInput
							height="40px"
							background="#e7f5f7"
							fieldLabel="Amount "
							control={control}
							error={errors.amount}
							fieldName="amount"
							readOnly={true}
						/>
					</Grid>}
				<Grid item xs={12} md={4} lg={3}>
					<CustomSelectInput
						control={control}
						error={errors.paymentstatus}
						fieldName="paymentstatus"
						fieldLabel="Payment Status"
						background="#FFFFFF"
						height="40px"
						size="15px"
						defaultValue={""}
						value={paymentStatus}
						onChangeValue={onChangePaymentStatus}
					>
						<MenuItem value="" disabled >
							<em>Select  Payment Status</em>
						</MenuItem>
						{[
							{
								value: 'paid',
								name: 'Paid'
							},
							{
								value: 'pending',
								name: 'Pending'
							}
						]?.map((doc) => (
							<MenuItem key={doc?.value} value={doc?.value} >
								{doc?.name}
							</MenuItem>
						))}
					</CustomSelectInput>
				</Grid>

				{paymentStatus === 'paid' ?


					<Grid item xs={12} md={4} lg={3}>
						<CustomSelectInput
							control={control}
							error={errors.paymentmethod}
							fieldName="paymentmethod"
							fieldLabel="Payment Method"
							background="#FFFFFF"
							height="40px"
							size="15px"
							defaultValue={""}
							value={paymentMethod}
							onChangeValue={(value) => {
								onChangePaymentMethod(value)
								setError('paymentmethod', '')
							}

							}
						>
							<MenuItem value="" disabled >
								<em>Select Payment Method</em>
							</MenuItem>
							{[{ value: 'UPI', name: 'UPI' }, { value: 'card', name: 'Card' }, { value: 'cash', name: 'Cash' }]?.map((doc) => (
								<MenuItem key={doc?.value} value={doc?.value} >
									{doc?.name}
								</MenuItem>
							))}
						</CustomSelectInput>
					</Grid>
					: ''}
				{paymentStatus === 'Paid' && paymentMethod === 'Card' || paymentMethod === 'UPI' ?

					<Grid item xs={12} md={4} lg={3}>
						<CustomFormInput
							height="40px"
							background="#ffff"
							fieldLabel="transaction Ref"
							control={control}
							error={errors.transactionref}
							fieldName="transactionref"
							type="text"

						/>
					</Grid> : ''}

				{appointmentType === 'Online' ?
					<Grid item xs={12} md={4} lg={3}>
						<Typography fontFamily={'Quicksand'} fontWeight={'bold'} paddingLeft={1}>Add Doctor</Typography>
						<Box display={'flex'} justifyContent={'space-around'}>
							<Box display={'flex'} alignItems={'center'}>
								<Typography>Individual</Typography>
								<CustomCheckBox changeRole={(e) => groupCheck(e, 'individual')} checked={mode === 'individual' ? true : false} />

							</Box>
							<Box display={'flex'} alignItems={'center'}>
								<Typography>Group</Typography>
								<CustomCheckBox changeRole={(e) => groupCheck(e, 'group')} checked={mode === 'group' ? true : false} />
							</Box>
						</Box>
					</Grid> : ''}


				{(appointmentType === 'Online' && mode) ? (
					<Grid item xs={12} md={4} lg={3}>
						<CustomMultipleSelectInput
							control={control}
							multiple={mode === "individual" ? true : false}
							error={errors.multipleDoctors}
							fieldName="multipleDoctors"
							fieldLabel="Add Additional Doctors"
							background="#FFFFFF"
							multiline
							height="40px"
							mode
							size="15px"
							defaultValue={""}
							value={multpleArray}
							onChangeValue={onChangeMultiple}
						>
							<MenuItem value="">
								{mode === "group" ? "Select Group Doctors" : "Select Doctors"}
							</MenuItem>
							{mode === 'individual' ? (
								doctorList?.map((doc) => (
									<MenuItem key={doc?._id} value={doc?._id}>
										{doc?.name}
									</MenuItem>
								))
							) : (
								groupList?.filter(res => res?._id !== docName).map((group) => (

									<MenuItem value={group?._id} >{group?.name}
										{/* {group.doctors?.map((doctor) => (
											<MenuItem key={doctor.id} value={doctor.id}>
												({doctor.name})
											</MenuItem>
										))} */}

									</MenuItem>


								))
							)}
						</CustomMultipleSelectInput>
					</Grid>
				) : null}

				<Grid item xs={12} md={4} lg={3}>
					<CustomFormInput
						height="100px"
						background="#ffff"
						fieldLabel="Remarks"
						control={control}
						maxrow={4}
						// readOnly={id ? true : false}
						multiline
						error={errors.remarks}
						fieldName="remarks"
						type="text"
					/>
				</Grid>{appointmentType === "Report" ? <Grid item xs={12} md={4} lg={3}>
					{pdfs?.length > 0 && (
						<div>
							<h4>Uploaded Files:</h4>
							<ul>
								{pdfs.map((file, index) => (
									<li key={index}>
										{file?.name}{" "}
										<button onClick={() => removePdf(index)}>Remove</button>
									</li>
								))}
							</ul>
						</div>
					)}

					<input
						type="file"
						accept=".pdf,.png,.jpg,.jpeg"
						onChange={onChangePdfs}
						multiple
					/>



				</Grid> : <Grid item xs={12} md={4} lg={3}>
					<Typography fontSize={'18px'} paddingBottom={1}>Upload Files</Typography>

					{pdf ? (
						<div>

							<span>{pdf?.name}</span>
							<button onClick={() => setPdf(null)}>Remove</button>
						</div>
					) : (
						<input
							type="file"
							accept=".pdf,.png,.jpg,.jpeg"
							value={pdf}
							onChange={(e) => onChangePdf(e)}
						/>
					)}


				</Grid>}





			</Grid >
			<Box style={{ display: "flex", justifyContent: "center", paddingTop: "40px", paddingBottom: "40px" }}>
				<CustomButton variant={"contained"} text={loading ? 'Loading' : "Submit"} disabled={loading ? true : false} submit={handleSubmit(SUbmit)} />
			</Box>

		</>
	);
};

export default AddAppointment;
