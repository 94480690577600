import React, { Fragment, useState, useEffect, useId } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import CustomSearch from "../../components/Widgets/CustomSearch";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import data from "../../components/common/data";
import DataTables from "../../components/Widgets/dataTable";
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ClearIcon from "@mui/icons-material/Clear";
import Pagination from "@mui/material/Pagination";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filterAppointment, getAppointment } from "../../Redux/actions/appointmentAction";
import moment from "moment";
import CancelBookingModal from "../Service/components/BookingCancelModal";
import { RESET_ERROR } from "../../Redux/constants/userConstants";
import { toast } from 'react-toastify';
import AppointmentResheduleModal from "./ResheduleModal";
import { Filter } from "@mui/icons-material";
import CustomFilter from "../../components/Widgets/CustomFilter";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import { Tooltip } from "@mui/material";
const Appointment = () => {
	const dispatch = useDispatch()
	// appointmentList
	const { loading } = useSelector(state => state.auth);
	const { appointmentList, error, appointmentCancel, appointmentRescheduled } = useSelector(state => state.appointment);

	const navigate = useNavigate();
	const Capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const [datas, setDatas] = useState(null)
	const [searchValue, setSearchValue] = useState(null)
	const [open, setOpen] = useState(false);
	const [openRe, setOpenRe] = useState(false);
	const [active, setActive] = useState('Op')
	const [_id, setID] = useState(null)
	const [store, setStore] = useState(null)
	const [valuefrom, setvaluefrom] = useState("")
	const [valueto, setvalueto] = useState("")
	const [openFilter, setOpenFilter] = useState(false);
	const [debouncedText] = useDebounce(active, 500);
	const [filterKey, setFilterKey] = useState(null);


	useEffect(() => {
		if (appointmentList) {
			setDatas(appointmentList)
		}
	}, [appointmentList])


	const handleOpen = (id) => {
		setID(id)
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleOpenRe = (row) => {
		setStore(row)
		setOpenRe(true);
	};

	const handleCloseRe = () => {
		setOpenRe(false);
	};

	const handleOpenFilter = () => {
		setOpenFilter(true);
	};

	const handleCloseFilter = () => {
		if (valuefrom && valueto) {
			dispatch(getAppointment(active))
		}
		setvaluefrom(null)
		setvalueto(null)
		//setOpenFilter(false);
	};

	// const handleCloseFilter = () => {
	// 	clearFilter();
	// 	setOpenFilter(false);
	// };
	const clearSearch = () => {
		setSearchValue("");
	};


	const Submitfilter = () => {
		let val = {
			type: active,
			from_date: moment(valuefrom).format("DD-MM-YYYY"),
			to_date: moment(valueto).format("DD-MM-YYYY"),
		};


		if (valuefrom && valueto) {
			dispatch(filterAppointment(val));
			setOpenFilter(false);
		} else {
			toast.warning("from Date and to Date are required");
		}
	};


	const AddNavigate = () => {
		navigate(`/addAppointment`);
		localStorage.setItem("active", active)
	};


	const ViewNavigate = (id) => {
		navigate(`/viewAppointment/${id}`)
	}


	const EditNavigate = (id) => {
		navigate(`/editAppointment/${id}`)

	}

	var columns = [

		{
			name: <b>{Capitalize("BOOKING ID")}</b>,
			header: <b>{Capitalize("BOOKING ID")}</b>,
			Tooltip: <p>jBJbzjnk</p>,
			selector: (row) => `#${active.toUpperCase()}${row?.booking_id}`,

			width: 50,
			style: {
				textAlign: "center",
				Tooltip: "kslmkl"
			},
		},

		{
			name: <b>{Capitalize("PATIENT ID")}</b>,
			header: <b>{Capitalize("PATIENT ID")}</b>,
			selector: (row) => `${row?.patient?.user_id}`,
			width: 150,
			style: {
				textAlign: "center",
			},
		},

		// {
		// 	name: <b>{Capitalize("PATIENT NAME")}</b>,
		// 	header: <b>{Capitalize("PATIENT NAME")}</b>,
		// 	selector: (row) => row?.name,
		// 	minwidth: 150,
		// 	style: {
		// 		width: "200px",
		// 		textAlign: "center",
		// 		display: "flex",
		// 		justifyContent: "center"
		// 	},
		// },
		{
			name: <b>{Capitalize("PATIENT NAME")}</b>,
			header: <b>{Capitalize("PATIENT NAME")}</b>,
			selector: (row) => (
				<Tooltip title={row?.name}>
					<div style={{
						textAlign: "center",
						justifyContent: "center",
						overflow: "hidden",
						textOverflow: "ellipsis"
					}}>
						{row?.name}
					</div>
				</Tooltip>
			),
		},


		{
			name: <b>{Capitalize("MOBILE")}</b>,
			header: <b>{Capitalize("MOBILE")}</b>,
			selector: (row) => row?.mobile,
			minWidth: 150,
			style: {
				width: "200px",
				textAlign: "center",
			},
		},
		{
			name: <b>{Capitalize("DEPARTMENT")}</b>,
			header: <b>{Capitalize("DEPARTMENT")}</b>,
			selector: (row) => row?.department_details?.name,
			style: {
				textAlign: "center",
				display: "flex",
				justifyContent: "center"
			},
		},
		{
			name: <b>{Capitalize("DOCTOR")}</b>,
			header: <b>{Capitalize("DOCTOR")}</b>,
			selector: (row) => row?.doctor_details?.name,
			style: {
				textAlign: "center",
				display: "flex",
				justifyContent: "center"
			},
		},
		{
			name: <b>{Capitalize("BOOKED ON")}</b>,
			header: <b>{Capitalize("BOOKED ON")}</b>,
			selector: (row) => <div>{moment(`${row?.date} ${row?.time}`, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY hh:mm A")}</div>,
			// allowOverflow:true,
			minWidth: 250,
			style: {
				textAlign: "center",
			},
		},
		// {
		// 	name: <b>{Capitalize("AMOUNT")}</b>,
		// 	header: <b>{Capitalize("AMOUNT")}</b>,
		// 	selector: (row) => row?.amount_payable,
		// 	style: {
		// 		textAlign: "center",
		// 	},
		// },
		{
			name: <b>{Capitalize("AMOUNT")}</b>,
			header: <b>{Capitalize("AMOUNT")}</b>,
			selector: (row) => {
				if (row?.amount_payable) {
					return row?.amount_payable;
				} else if (row?.paymentResponse?.amount) {
					return row.paymentResponse.amount;
				}
			},
			style: {
				textAlign: "center",
			},
		},

		{
			name: <b>{Capitalize("PAYMENT STATUS")}</b>,
			header: <b>{Capitalize("PAYMENT STATUS")}</b>,
			selector: (row) => row?.payment_status,
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>{Capitalize("CREATED ON")}</b>,
			header: <b>{Capitalize("CREATED ON")}</b>,
			selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>{Capitalize("STATUS")}</b>,
			header: <b>{Capitalize("STATUS")}</b>,
			selector: (row) => row?.status,
			style: {
				textAlign: "center",
			},
		},
		{
			name: <b>ACTIONS</b>,
			id: "delete",
			accessor: (str) => "delete",
			cell: (row, index) => (
				<>


					<Stack spacing={0.1} direction="row">
						<RemoveRedEyeIcon
							color="blue"
							style={{
								cursor: "pointer",
								color: "#047ac1",


							}}
							onClick={() => ViewNavigate(row?._id)}
						/>{" "}
						{row?.status !== "cancelled" && row?.status !== "Completed" && <>
							{console.log(row?.status)}
							{active === "Report" ? "" :
								<>
									<BorderColorSharpIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() => EditNavigate(row._id)} />
									<CalendarMonthIcon
										onClick={() => handleOpenRe(row)}
										style={{
											cursor: "pointer",
											color: "#047ac1",

										}}
									/>
								</>
							}
							<ClearIcon
								onClick={() => handleOpen(row?._id)}
								style={{
									cursor: "pointer",
									color: "#FF0000",


								}}
							/>
						</>}
					</Stack>
				</>
			),
		},
	];
	if (active !== "Report") {
		columns.splice(1, 0, {
			name: <b>{Capitalize("TOKEN NUMBER")}</b>,
			header: <b>{Capitalize("TOKEN NUMBER")}</b>,
			selector: (row) => `${row?.token}`,
			width: 50,
			style: {
				textAlign: "center",
			},
		});
	}


	useEffect(() => {
		if (appointmentCancel) {
			toast.success(`Appointment Cancelled Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			setOpen(false)
			dispatch(getAppointment(active))
		}
		if (appointmentRescheduled) {
			toast.success(`Appointment Rescheduled Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			handleCloseRe()
		}
		// if (error) {
		// 	toast.error(`${error.message}`);
		// 	dispatch({
		// 		type: RESET_ERROR
		// 	})
		// }

	}, [error, appointmentCancel, appointmentRescheduled])

	let localFocus = localStorage.getItem('focus');

	useEffect(() => {
		if (localFocus) {
			setActive(localFocus)
		}
	}, [localFocus])


	useEffect(() => {
		dispatch(getAppointment(active))
	}, [debouncedText])


	const submitButton = (data) => {
		setActive(data)
	}
	const filterResults = (value) => {
		setSearchValue(value);
		let datas = appointmentList?.filter(
			(op) =>
				op?.name?.toLowerCase().includes(value.toLowerCase()) ||
				op?.department_details?.name?.toLowerCase().includes(value.toLowerCase()) ||
				op?.doctor_details?.name?.toLowerCase().includes(value.toLowerCase()) ||
				op?.status?.toLowerCase().includes(value.toLowerCase()) ||
				op?.mobile?.toString()?.toLowerCase().includes(value.toLowerCase()) ||
				`${op?.patient?.user_id}`.toLowerCase().includes(value.toLowerCase()) ||
				`#${op?.booking_id}`.toLowerCase().includes(value.toLowerCase())
		);
		setDatas(datas);
	};

	// if (loading) {
	// 	<span>Loading.....</span>
	// }
	return (
		<>
			<Box
				sx={{
					width: "99%",
					height: "60px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					flexDirection: { lg: "row", md: "column", xs: "column" },
					gap: { md: "10px", xs: "10px" },
					marginBottom: "40px",
				}}
			>
				<Stack spacing={2} direction="row">
					{/* <CustomButton variant={"contained"} text={"OP"} submit={() => {
						submitButton("Op")
						localStorage.setItem("focus", "Op")
					}} active={active === "Op" ? true : false} />
					<CustomButton variant={"contained"} text={"Online"} submit={() => {
						submitButton("Online")
						localStorage.setItem("focus", "Online")
					}} active={active === "Online" ? true : false} />
					<CustomButton variant={"contained"} text={"Report Review"} submit={() => {
						submitButton("Report")
						localStorage.setItem("focus", "Report")
					}} active={active === "Report" ? true : false} /> */}
					<CustomButton
						variant={"contained"}
						text={"OP"}
						submit={() => {
							submitButton("Op");
							clearSearch();
							localStorage.setItem("focus", "Op");
						}}
						active={active === "Op" ? true : false}
					/>
					<CustomButton
						variant={"contained"}
						text={"Online"}
						submit={() => {
							submitButton("Online");
							clearSearch();
							localStorage.setItem("focus", "Online");
						}}
						active={active === "Online" ? true : false}
					/>
					<CustomButton
						variant={"contained"}
						text={"Report Review"}
						submit={() => {
							submitButton("Report");
							clearSearch();
							localStorage.setItem("focus", "Report");
						}}
						active={active === "Report" ? true : false}
					/>
				</Stack>

				<Stack spacing={2.6} direction="row" style={{
					background: "#d8dee1",
					padding: "3px 3px 3px .1px",
					display: "flex",
					alignItems: "center",

				}} borderRadius={2}>
					<CustomSearch Icon={SearchIcon} variant={"contained"} value={searchValue} onSearch={(value) => filterResults(value)} placeholder="Booking ID,Patient Name,Mobile,Department..." />

					<CustomButton
						variant={"contained"}
						text={"Filter"}
						Icon={FilterListIcon}
						active={openFilter ? true : false}
						submit={() => setOpenFilter(!openFilter)}
					/>

					<CustomButton
						ComponentIcon={AddIcon}
						variant={"contained"}
						submit={AddNavigate}
					/>
				</Stack>

			</Box>
			{openFilter && <CustomFilter
				key={filterKey}
				handlechangefrom={(value) => setvaluefrom(value)}
				valuefrom={valuefrom}
				valueto={valueto}
				handlechangeto={(value) => setvalueto(value)}
				submit={Submitfilter}
				clear={handleCloseFilter}
			/>}
			<Paper>
				<Fragment>
					<DataTables
						responsive
						columns={columns}
						row={datas ? datas : []}
						pagination
					/>
				</Fragment>
			</Paper>
			<Box
				sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}
			>
				{open && <CancelBookingModal handleClose={handleClose} handleOpen={open} mode={"appointment"} id={_id} />}
				{openRe && <AppointmentResheduleModal handleCloseRe={handleCloseRe} handleOpenRe={openRe} store={store} />}
			</Box>
		</>
	);
};

export default Appointment;
