import axios from "../../customAxios"
import { CREATE_DOCTOR_FAIL, CREATE_DOCTOR_SUCCESS, DOCTOR_STATUS_FAIL, DOCTOR_STATUS_SUCCESS, EDIT_DOCTOR_FAIL, EDIT_DOCTOR_SUCCESS, GET_DOCTOR_FAIL, GET_DOCTOR_SINGLE_FAIL, GET_DOCTOR_SINGLE_SUCCESS, GET_DOCTOR_SUCCESS, DOCTOR_LEAVE_LIST_SUCCESS, DOCTOR_LEAVE_LIST_FAIL } from "../constants/doctorConstant";
import { LOADING } from "../constants/userConstants";

export const PostDocter = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/create-doctor`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_DOCTOR_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_DOCTOR_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const GetDocterList = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/doctors`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_DOCTOR_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_DOCTOR_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const GetDocterSingleList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/doctor/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_DOCTOR_SINGLE_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_DOCTOR_SINGLE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const DocterStatusChange = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/doctor/status`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch(GetDocterList())
            dispatch({
                type: DOCTOR_STATUS_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: DOCTOR_STATUS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
export const editDocterList = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/edit-doctor`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_DOCTOR_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: EDIT_DOCTOR_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
//Get all doctor Applied leave
export const getAllDoctorLeave = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`doctor/leave-list/${id}`)
        .then(async response => {
            dispatch({
                type: DOCTOR_LEAVE_LIST_SUCCESS,
                payload: response?.data?.data,

            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: DOCTOR_LEAVE_LIST_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}