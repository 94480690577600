import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import CustomTimePicker from '../../../components/Widgets/CustomTimePicker/CustomTimePicker';
import moment from 'moment';
const ViewConsultation = ({ doctorSingleList, setActive }) => {

    const [dates, setDates] = useState()

    useEffect(() => {
        if (doctorSingleList) {
            setDates(JSON.parse(doctorSingleList?.dateObject))
        }

    }, [doctorSingleList])



    return (
        <>

            <Grid container spacing={2} px={5}>
                <Grid item xs={12} md={2}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"
                    }}>Interval</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "10px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{doctorSingleList?.interval}</Typography>
                </Grid>
            </Grid>


            <Grid container spacing={2} px={5} mt={2}>
                <Grid item xs={12} md={4}>
                    <Typography>{"Monday"}</Typography>
                    {dates?.monday?.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    readOnly={true}
                                    Fieldlabel={"Monday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    disabled
                                />
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Tuesday"}</Typography>
                    {dates?.tuesday?.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    readOnly={true}
                                    // disable={disable}
                                    Fieldlabel={"Tuesday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    disabled
                                />
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Wednesday"}</Typography>
                    {dates?.wednesday?.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    // disable={disable}
                                    readOnly={true}
                                    Fieldlabel={"Wednesday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    disabled
                                />
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Thursday"}</Typography>
                    {dates?.thursday?.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    readOnly={true}
                                    // disable={disable}
                                    Fieldlabel={"Thursday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    disabled
                                />
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Friday"}</Typography>
                    {dates?.friday?.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    readOnly={true}
                                    // disable={disable}
                                    Fieldlabel={"Friday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    disabled
                                />
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Saturday"}</Typography>
                    {dates?.saturday?.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    readOnly={true}
                                    // disable={disable}
                                    Fieldlabel={"Saturday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    disabled
                                />
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Sunday"}</Typography>
                    {dates?.sunday?.map((day, index) => (
                        <Box style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                <CustomTimePicker
                                    readOnly={true}
                                    // disable={disable}
                                    Fieldlabel={"Sunday"}
                                    From={"From"}
                                    To={"To"}
                                    toValue={day?.to}
                                    fromValue={day?.from}
                                    disabled
                                />
                            </Box>
                        </Box>
                    ))}
                </Grid>
            </Grid>


        </>
    )
}

export default ViewConsultation