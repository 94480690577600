import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getSingleList } from '../../Redux/actions/patientAction';
import { Avatar, Box, Container, Divider, Grid, } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from '../../components/Widgets/Backbutton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import blood from '../../assets/images/blood.png'
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MaleIcon from '@mui/icons-material/Male';
import HeightIcon from '@mui/icons-material/Height';
import ScaleSharpIcon from '@mui/icons-material/ScaleSharp';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CustomSearch from '../../components/Widgets/CustomSearch';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
const PatientView = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const { patientSingleList } = useSelector(state => state.patient);
    console.log({ patientSingleList })


    const [datas, setDatas] = useState([])

    const searchPatient = (value) => {
        let datas = patientSingleList?.bookings?.
            filter(res => res?.doctor_details?.name.toLowerCase().includes(value.toLowerCase()))
        setDatas(datas)
    }



    useEffect(() => {
        if (patientSingleList) {
            setDatas(patientSingleList?.bookings)

        }
    }, [patientSingleList])


    useEffect(() => {
        dispatch(getSingleList(id))
    }, [])

    return (
        <>
            <Box
                px={2}
                sx={{
                    height: "60px",
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "5px", xs: "5px" },
                    marginBottom: "30px",
                }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >Patient ID : {patientSingleList?.user_id}
                    </Typography>
                </Stack>

                <Typography
                    variant="h4"
                    component="h2"
                    style={{ fontSize: "25px", color: "#047AC3" }}
                >Patient Name : {patientSingleList?.name}
                </Typography>

                <Typography
                    variant="h4"
                    component="h2"
                    style={{ fontSize: "25px", color: "#047AC3" }}
                >Mobile Number :{patientSingleList?.mobile}
                </Typography>
            </Box>
            <Box paddingX={10} >
                <Accordion style={{ borderRadius: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownCircleIcon style={{ color: "#047AC3", fontSize: '30px' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontFamily={'Quicksand'} fontSize={'20px'} fontWeight={'bold'}>
                            Patient Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box paddingY={2} px={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <EmailIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography variant='subtitle1' fontFamily={'Quicksand'} fontWeight={'bold'} display={'flex'}

                                            fontSize={'16px'} letterSpacing={'1px'}
                                        > {patientSingleList?.email}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <CalendarMonthIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}>{moment(patientSingleList?.dob, 'DD-MM-YYYY').format('DD/MM/YYYY')}
                                        </Typography>
                                    </Box>
                                </Grid>


                                <Grid item xs={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <MaleIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}> {patientSingleList?.gender}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <HeightIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}> {patientSingleList?.height}</Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <ScaleSharpIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}> {patientSingleList?.weight}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <BloodtypeIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}> {patientSingleList?.bloodgroup}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                        <LocationOnIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'} fontSize={'16px'} letterSpacing={'1px'}> {patientSingleList?.address}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Box
                    paddingX={2}
                    marginY={4}
                    style={{
                        background: '#ffff',
                        borderRadius: '20px',
                        height: '100px',
                        boxShadow: '1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Typography
                        fontFamily={'Quicksand'}
                        fontWeight={'bold'}
                        fontSize={'20px'}
                        letterSpacing={'1px'}
                    >Patient History</Typography>
                    <Box>
                        <Stack spacing={2.6} direction="row" style={{
                            background: "#d8dee1",
                            padding: "3px 3px 3px .1px",
                            display: "flex",
                            alignItems: "center",

                        }} borderRadius={2}>
                            <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={(value) => searchPatient(value)} />

                        </Stack>
                    </Box>
                </Box>
                <Grid container spacing={3}>


                    <Box
                        paddingX={2}
                        marginY={4}
                        style={{
                            background: '#F5FAFD',
                            borderRadius: '20px',
                            width: '1009%',
                            margin: '43px 3px 0 26px',
                            height: '836px',
                            boxShadow: '1',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            overflow: 'auto',
                        }}
                    >
                        {datas?.map((res) =>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                                    <Box display={'flex'} flexDirection={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        width={'100px'}>
                                        <CalendarMonthIcon style={{ color: "#047AC3", fontSize: '30px' }} />
                                        <Typography>{moment(res?.date).format('DD/MM/YYYY')}</Typography>
                                        <Typography>{res?.time}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={10} lg={11} xl={11}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                justifyContent={'center'}
                                                alignItems={'center'}>
                                                <Avatar variant="square" src={`${patientSingleList?.basepath}${res?.doctor_details?.image}`}
                                                    style={{ width: '90px', height: '70px', borderRadius: '10px' }}
                                                ></Avatar>
                                                <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>
                                                    {res?.doctor_details?.name}
                                                </Typography>

                                                <Typography fontFamily={'Quicksand'} fontSize={'14px'} letterSpacing={1}>
                                                    {res?.doctor_details?.departments}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={1}>
                                            <Box >
                                                <Typography paddingX={1}
                                                    fontFamily={'Quicksand'}
                                                    fontSize={'16px'}
                                                    fontWeight={'bold'}
                                                >Remarks</Typography>
                                                <Typography
                                                    paddingX={1}
                                                    paddingY={1}
                                                    style={{
                                                        background: '#E4E4E4',
                                                        height: '100px',
                                                        width: '120px',
                                                        borderRadius: '20px',
                                                        paddingLeft: '10px',
                                                        overflowY: "auto"
                                                    }}>
                                                    {res?.remarks}

                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={1}>
                                            <Box>
                                                <Typography paddingX={1} fontFamily={'Quicksand'}
                                                    fontSize={'16px'}
                                                    fontWeight={'bold'}>Procedure & Service</Typography>
                                                <Box
                                                    style={{
                                                        background: '#E4E4E4',
                                                        height: '100px',
                                                        width: '120px',
                                                        borderRadius: '20px',
                                                        paddingLeft: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexWrap: 'wrap',
                                                        overflowY: "auto"
                                                    }}
                                                    paddingX={1}
                                                    paddingY={1}>
                                                    <Typography
                                                        paddingX={1}
                                                        fontFamily={'Quicksand'}
                                                        fontSize={'14px'}
                                                        fontWeight={'bold'}
                                                        style={{ color: "#047AC3" }}>Procedure</Typography>

                                                    <Box paddingX={1} >
                                                        <Typography fontFamily={'Quicksand'} fontSize={'14px'} fontWeight={'bold'}>
                                                            {res?.procedure_booking?.map(res => res?.procedure_id?.map(res => `${res?.name},`))}</Typography>
                                                    </Box>
                                                    <Typography
                                                        paddingX={1}
                                                        fontFamily={'Quicksand'}
                                                        fontSize={'14px'}
                                                        fontWeight={'bold'}
                                                        style={{ color: "#047AC3" }}>Service</Typography>


                                                    <Box paddingX={1} >
                                                        <Typography
                                                            fontFamily={'Quicksand'}
                                                            fontSize={'14px'}
                                                            fontWeight={'bold'}>
                                                            {res?.service_booking?.map(res => res?.service_id
                                                                ?.map(res => `${res?.name},`))}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={1}>
                                            <Box>
                                                <Typography paddingX={1} fontFamily={'Quicksand'} fontSize={'16px'} fontWeight={'bold'}>
                                                    Reports
                                                </Typography>
                                                <Typography
                                                    paddingX={1}
                                                    paddingY={1}
                                                    style={{
                                                        background: '#E4E4E4',
                                                        height: '100px',
                                                        width: '120px',
                                                        borderRadius: '20px',
                                                        paddingLeft: '10px',
                                                        overflowY: "auto"
                                                    }}
                                                >
                                                    <Box display={'flex'} flexDirection={'column'}>
                                                        <Typography
                                                            paddingX={1}
                                                            fontFamily={'Quicksand'}
                                                            fontSize={'14px'}
                                                            fontWeight={'bold'}
                                                            style={{ color: "#047AC3" }}
                                                        >
                                                            Service
                                                        </Typography>
                                                        {res?.service_reports?.map((report, index) => (
                                                            <div key={index}>
                                                                <a
                                                                    style={{ paddingLeft: "10px", fontSize: '14px' }}
                                                                    href={`${patientSingleList?.basepath}${report?.attachment}`}
                                                                    target="_blank"
                                                                >

                                                                    {report?.attachment_name}
                                                                </a>
                                                            </div>
                                                        ))}
                                                        <Typography
                                                            paddingX={1}
                                                            fontFamily={'Quicksand'}
                                                            fontSize={'14px'}
                                                            fontWeight={'bold'}
                                                            style={{ color: "#047AC3" }}
                                                        >
                                                            Procedure
                                                        </Typography>
                                                        {res?.procedure_reports?.map((report, index) => (
                                                            <div key={index}>
                                                                <a
                                                                    style={{ paddingLeft: "10px", fontSize: '14px' }}
                                                                    href={`${patientSingleList?.basepath}${report?.attachment}`}
                                                                    target="_blank"
                                                                >
                                                                    {report?.attachment_name}
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        </Grid>




                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                    </Box>
                </Grid >

            </Box >
        </>
    )
}

export default PatientView