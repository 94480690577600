import Button from '@mui/material/Button';
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Divider, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import CustomCalender from '../../../components/Widgets/CustomCalender';
import CustomSelectInput from '../../../components/Widgets/CustomSelect';
import { getAppointmentTime, resheduleAppointment } from '../../../Redux/actions/appointmentAction';

const RecheduleModal = ({ handleClose, handleOpen, item }) => {
    const { avabilityTime, error } = useSelector(state => state.appointment);
    const dispatch = useDispatch()




    const schema = object().shape({
        date: yup.string().max(100).required("Date is required"),
        time: yup.string().max(100).required("Time is required"),
    });

    const [dateTime, setDateTime] = useState(null)
    const [time, setTime] = useState(null)


    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),

    });

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        p: 2.5,
    };

    const clearClose = () => {
        handleClose()
    }

    const onChangeTime = (e) => {
        setTime(e.target.value)
        setValue('time', e.target.value)
    }

    const onChangeDateNtime = (value) => {
        setDateTime(value)
        setValue("date", moment(value).format('DD-MM-YYYY'))
        const val = {
            doctor_id: item?.doctor_id,
            date: moment(value).format('YYYY-MM-DD'),
            type: item?.appointmenttype

        }
        dispatch(getAppointmentTime(val))

    }

    const SubmitDate = (data) => {
        let val = {
            booking_id: item?._id,
            date: data?.date,
            time: data?.time,
            appointmenttype: item?.appointmenttype
        }
        dispatch(resheduleAppointment(val))


    }


    return (
        <Modal
            open={handleOpen}
            onClose={clearClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack
                    direction={"row"}
                    spacing={5}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ color: "#047BC1" }}
                    >
                        Reschedule
                    </Typography>
                    <ClearIcon
                        style={{
                            cursor: "pointer",
                            color: "black"
                        }}
                        onClick={() => clearClose()}
                    />
                </Stack>
                <Divider />
                <Box display={"flex"} gap={"10px"} flexDirection={"column"} paddingTop={"20px"}>
                    <CustomCalender
                        minDateres={moment()}
                        height="35px"
                        fieldLabel="Choose Date"
                        control={control}
                        error={errors.date}
                        fieldName="date"
                        type={"text"}
                        readOnly={true}
                        background="#FFFFFF"
                        value={dateTime}
                        onChangeValue={onChangeDateNtime}
                    />
                    <CustomSelectInput
                        control={control}
                        error={errors.time}
                        fieldName="time"
                        fieldLabel="Time"
                        background="#FFFFFF"
                        height="40px"
                        size="16px"
                        defaultValue={""}
                        onChangeValue={onChangeTime}
                        value={time}

                    >
                        <MenuItem value="" disabled >
                            <em>Select Time</em>
                        </MenuItem>
                        {avabilityTime?.time?.filter(t => t?.patient_id === null).map((doc) => (
                            <MenuItem key={doc?._id} value={doc?.time} >
                                {moment(doc?.time, 'h:mm').format('h:mm A')}
                            </MenuItem>
                        ))}
                    </CustomSelectInput>
                    <Box display={"flex"} paddingTop={"10px"} justifyContent={"center"}>
                        <CustomButton text={"Submit"} variant={"contained"} submit={handleSubmit(SubmitDate)} />
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default RecheduleModal