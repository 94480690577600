import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import LoginLayout from "../components/Layout/LoginLayout";
import HomeLayout from "../components/Layout/HomeLayout";
import { PrivateRoute } from "./PrivateRoutes";
import Appointment from "../Screens/Appoinment";
import AddAppointment from "../Screens/Appoinment/AddAppointment";
import Profile from "../Screens/profile";
import Department from "../Screens/Department";
import { useDispatch, useSelector } from "react-redux";
import Docter from "../Screens/Doctor";
import AddDoctor from "../Screens/Doctor/AddDoctor";
import Patients from "../Screens/Patient";
import AddPatient from "../Screens/Patient/AddPatient";
import ViewDoctor from "../Screens/Doctor/DoctorView/ViewDoctor";
import EditPatient from "../Screens/Patient/EditPatient";
import PatientView from "../Screens/Patient/PatientView";
import Service from "../Screens/Service";
import AddBooking from "../Screens/Service/AddBooking";
import AddListing from "../Screens/Service/AddListing";
import ServiceViewListing from "../Screens/Service/ViewListing";
import BookingView from "../Screens/Service/BookingView";
import Procedure from "../Screens/Procedure";
import AddProcedureList from "../Screens/Procedure/ProcedureList/AddProcedure";
import ProcedureBooking from "../Screens/Procedure/ProcedureBooking/ProcedureBooking";
import Settings from "../Screens/Settings";
import Sliders from "../Screens/Settings/Sliders";
import ProcedureListView from "../Screens/Procedure/ProcedureList/ProcedureListView";
import ProcedureBookingView from "../Screens/Procedure/ProcedureBooking/ProcedureBookingView";
import Roles from "../Screens/Roles";
import AddRole from "../Screens/Roles/AddRole";
import ViewAppointment from "../Screens/Appoinment/ViewAppointment";
import Users from "../Screens/Users";
import RoleContext from "../helpers/Role";
import EditRole from "../Screens/Roles/EditRole";
import ErrorPage from "./ErrorPage";
import NotFound from "./NotFound";
import ForgetPassword from "../Screens/auth/ForgetPassword";
import LoginPageTwo from '../Screens/authentication/loginPageTwo'
import Login from "../Screens/authentication/login";
import LoginPatient from "../Screens/Authdoc/Logindetails";
import LoginAdmin from '../Screens/auth/login'
import Forget from "../Screens/auth/forget";
import Blogs from "../Screens/Blogs";
import AddBlogs from "../Screens/Blogs/AddBlogs";
import EditGroup from '../Screens/Settings/EditGroup'
import ViewBlog from '../Screens/Blogs/ViewBlog'
import LeaveManagement from "../Screens/LeaveManagement";
import AddLeaveManagement from "../Screens/LeaveManagement/AddLeaveManagement";
import RecheduleLeave from "../Screens/LeaveManagement/RecheduleLeave";
import { requestForToken, onMessageListener } from '../config/firebase';
import DoctorGroups from "../Screens/Settings/DoctorGroups";
import { toast } from "react-toastify";
import DocBlog from "../Screens/DoctorWeb/Blogs";
import DrBlogview from "../Screens/DoctorWeb/Blogs/DrBlogview";
import DrSettings from "../Screens/DoctorWeb/Settings";
import DrLeaveManagement from "../Screens/DoctorWeb/LeaveManagement";
import DrTimeManagement from "../Screens/DoctorWeb/TimeManagement";
import DrReportReview from "../Screens/DoctorWeb/Report Review";
import DrOnline from "../Screens/DoctorWeb/Online";
import DrMyConsultation from "../Screens/DoctorWeb/MyConsultation";
import DrEditMyConsultation from "../Screens/DoctorWeb/MyConsultation/EditMyConsultation";
import DrPatienthistory from "../Screens/DoctorWeb/MyConsultation/Patienthistory";
import ViewModal from "../Screens/DoctorWeb/MyConsultation/ViewModal";
import customAxios from "../customAxios";
import { PROFILE_SUCCESS } from "../Redux/constants/profileConstants";
import ZoomVideo from "../Screens/DoctorWeb/MyConsultation/ZoomVideo";
import Patientmanagement from '../Screens/Patientweb/PatientDelete/delete'
import { useNavigate } from "react-router-dom";
import Loginpatientdetails from "../Screens/Authdoc/loginpatient";
import { getDoctorNotification, getNotification } from "../Redux/actions/notificationAction";
import { setActiveSidebar } from '../Redux/reducers/activeSidebarReducer'
import { NOTY_TYPESS } from "../Redux/constants/notificationConstant";
import { getSettings } from "../Redux/actions/settingsAction";


const Router = () => {
    const dispatch = useDispatch()
    const context = useContext(RoleContext)
    const [userRoutes, setUserRoutes] = useState(null)
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState(false);
    let roles = JSON.parse(localStorage?.getItem("role"))
    let user = JSON.parse(localStorage?.getItem("user"))
    const [text, settext] = useState(null);
    const { notificationList, notificationUpdated, count } = useSelector(state => state.notifi);
    const { profiledata } = useSelector(state => state.profile);
    const navigate = useNavigate();

    const Msg = ({ closeToast, notification, onClick }) => (
        <div onClick={onClick}>
            <p>{notification?.title}</p>
            <p>{notification?.body}</p>
        </div>
    );



    const AppointmentDetails = async (bookingid) => {
        let id = bookingid;


        const response = await customAxios.get(`/doctor/consultationdetail/${bookingid}`);
        console.log({ response: response?.data?.data }, 'RESPONSE IN BOOKING')
        navigate(`/docViewModal/${id}`, { state: { consultation: response?.data?.data } });
    }


    const handleNotificationClick = (payload) => {
        if (payload) {
            const { types, appointmenttype, booking_id, zoomDetails } = payload;

            let path = '';
            let text = '';
            dispatch({
                type: NOTY_TYPESS,
                payload: appointmenttype

            })
            if (roles && roles?.name === "admin") {
                if (types === 'procedure') {
                    navigate(`/procedureBookingView/${booking_id}`);


                } else if (types === 'service') {
                    navigate(`/bookingView/${booking_id}`);

                } else if (types === 'Op') {
                    navigate(`/viewAppointment/${booking_id}`);

                } else if (types === 'Online') {
                    navigate(`/viewAppointment/${booking_id}`);
                } else if (types === 'Report') {
                    navigate(`/viewAppointment/${booking_id}`);
                } else if (appointmenttype === 'leave') {
                    navigate(`/leaveManagement`);
                }
            } else {

                if (types === 'leave') {

                    navigate(`/docleavemanagement`);
                } else if (appointmenttype === 'service') {

                    AppointmentDetails(booking_id)

                } else if (appointmenttype === 'procedure') {
                    AppointmentDetails(booking_id)

                } else if (appointmenttype === 'Op') {

                    AppointmentDetails(booking_id)
                    setActiveSidebar('/consultations')

                }
                else if (appointmenttype === "video consultation") {

                    // Parse the zoomDetails JSON string


                    const zoomDetailsObj = JSON.parse(zoomDetails);
                    const sessionName = zoomDetailsObj?.sessionName;
                    const password = zoomDetailsObj?.password;


                    const roletype = 0;
                    startZoomVideoCall(sessionName, password, user?.name, roletype);
                }

                else if (appointmenttype === 'Online') {

                    AppointmentDetails(booking_id)
                    setActiveSidebar('/docOnline')


                } else if (types === 'Report') {

                    AppointmentDetails(booking_id)
                    setActiveSidebar('/docReportReview')

                }
            }

            // navigate(path);

        }
    };



    useEffect(() => {
        const onMessageReceived = (payload) => {
            console.log('Message received:', payload);
            const title = payload?.notification?.title;
            const body = payload?.notification?.body;

            toast(
                <Msg
                    notification={{ title, body }}
                    closeToast={toast.dismiss}
                    onClick={() => handleNotificationClick(payload?.data)}
                />,
                {
                    toastId: 'customId',
                }
            );

            // Call requestPermission when a notification is received
            requestPermission();
        };

        onMessageListener()
            .then((payload) => {
                onMessageReceived(payload);
                console.log("listener");

            })

            .catch((err) => console.log('failed: ', err));
    }, [isTokenFound]);




    async function requestPermission() {
        let token = requestForToken();
        console.log('Token requested:', token);


        onMessageListener()
            .then((payload) => {
                console.log('Message received:', payload);

                const title = payload?.notification?.title;
                const body = payload?.notification?.body;
                setTokenFound(payload)
                toast(
                    <Msg

                        notification={{ title, body }}
                        closeToast={toast.dismiss}
                        onClick={() => handleNotificationClick(payload?.data)}
                    />,
                    {
                        toastId: 'customId',
                    }
                );
            })
            .catch((err) => console.log('failed: ', err));
    }


    useEffect(() => {
      if(user?.role === "admin"){
        dispatch(getSettings())
      }
    }, [user?._id])
    

    useEffect(() => {
        requestPermission();
        if (roles?.name === 'doctor') {
            const id = profiledata?._id;
            console.log(context?.user?.role === 'doctor');
            if (id) {
                dispatch(getDoctorNotification(id));
            }
        } else {
            console.log("ll");
            dispatch(getNotification());
        }
        console.log(count);
    }, [isTokenFound]);


    useEffect(() => {
        context?.getRoutes()
        console.log(context);
    }, [context?.role])

    const routeArray = [
        { path: '/', element: <Appointment />, index: true },
        { path: 'addAppointment', element: <AddAppointment /> },
        { path: 'editappointment/:id', element: <AddAppointment /> },
        { path: 'profile', element: <Profile /> },
        { path: 'department', element: <Department /> },
        { path: 'doctor', element: <Docter /> },
        { path: 'AddDoctor', element: <AddDoctor /> },
        { path: 'editDoctor/:id', element: <AddDoctor /> },
        { path: 'patients', element: <Patients /> },
        { path: 'Addpatients', element: <AddPatient /> },
        { path: 'EditPatient/:id', element: <EditPatient /> },
        { path: 'PatientView/:id', element: <PatientView /> },
        { path: 'DoctorDetailPage/:id', element: <ViewDoctor /> },
        { path: 'addBooking', element: <AddBooking /> },
        { path: 'editBooking/:id', element: <AddBooking /> },
        { path: 'addListing', element: <AddListing /> },
        { path: 'editListing/:id', element: <AddListing /> },
        { path: 'Service', element: <Service /> },
        { path: 'service', element: <Service /> },
        { path: 'serviceViewListing/:id', element: <ServiceViewListing /> },
        { path: 'bookingView/:id', element: <BookingView /> },
        { path: 'procedure', element: <Procedure /> },
        { path: 'procedureList/:id', element: <AddProcedureList /> },
        { path: 'procedureList', element: <AddProcedureList /> },
        { path: 'ProcedureListView/:id', element: <ProcedureListView /> },
        { path: 'procedureBooking/:id', element: <ProcedureBooking /> },
        { path: 'procedureBooking', element: <ProcedureBooking /> },
        { path: 'settings', element: <Settings /> },
        { path: 'Sliders', element: <Sliders /> },
        { path: 'procedureBookingView/:id', element: <ProcedureBookingView /> },
        { path: 'roles', element: <Roles /> },
        { path: 'addroles', element: <AddRole /> },
        { path: 'editrole/:id', element: <EditRole /> },
        { path: 'viewAppointment/:id', element: <ViewAppointment /> },
        { path: 'user', element: <Users /> },
        { path: 'blogs', element: <Blogs /> },
        { path: 'addBlogs', element: <AddBlogs /> },
        { path: 'editBlogs/:id', element: <AddBlogs /> },
        { path: 'editgroup/:id', element: <EditGroup /> },
        { path: 'viewBlog/:id', element: <ViewBlog /> },
        { path: 'leaveManagement', element: <LeaveManagement /> },
        { path: 'addLeave', element: <AddLeaveManagement /> },
        { path: 'rescheduleLeave', element: <RecheduleLeave /> },
        { path: 'doctorGroup', element: <DoctorGroups /> },
        { path: 'docBlog', element: <DocBlog /> },
        { path: 'docBlogview/:id', element: <DrBlogview /> },
        { path: 'docSettings', element: <DrSettings /> },
        { path: 'docleavemanagement', element: <DrLeaveManagement /> },
        { path: 'docTimeManagement', element: <DrTimeManagement /> },
        { path: 'docReportReview', element: <DrReportReview /> },
        { path: 'docOnline', element: <DrOnline /> },
        { path: 'consultations', element: <DrMyConsultation /> },
        { path: 'docEditMyConsultation/:id', element: <DrEditMyConsultation /> },
        { path: 'docViewModal/:id', element: <ViewModal /> },
        { path: 'docPatientHistory/:patient_id', element: <DrPatienthistory /> },
        // { path: 'patientaccounts', element: <Patientmanagement /> },
        // { path: 'onlineVideo', element: <ZoomVideo /> },

    ]

    useEffect(() => {
        let role = JSON.parse(localStorage?.getItem("role"))
        let user = JSON.parse(localStorage?.getItem("user"))
        if (role) {
            if (role?.slug === "admin" || user?.role === "admin" || user?.slug === "doctor" || user?.role === "doctor") {
                setUserRoutes(routeArray)
                if (user && user?.role === "doctor") {

                    const userid = user?._id
                    //get User Profile
                    customAxios.get(`doctor/get-doctor/${userid}`).then(response => {
                        dispatch({
                            type: PROFILE_SUCCESS,
                            payload: response?.data?.data
                        })
                    })
                }
            }
            else {
                let user = JSON.parse(localStorage?.getItem("user"))
                // let tocken = (localStorage.getItem("token"))
                // if (user && user?.role === "doctor") {

                //     const userid = user?._id
                //     //get User Profile
                //     customAxios.get(`doctor/get-doctor/${userid}`).then(response => {
                //         dispatch({
                //             type: PROFILE_SUCCESS,
                //             payload: response?.data?.data
                //         })
                //     })
                // }


                let usersRoutes = routeArray.filter(rou => context?.availRoles?.includes(rou?.path))

                setUserRoutes(usersRoutes)
                console.log("context?.availRoles:", context?.availRoles);
                console.log("routeArray:", routeArray);
                console.log("usersRoutes:", usersRoutes);
            }
        }



    }, [context?.availRoles])


    const startZoomVideoCall = async (session, password, name, roletype) => {
        var win = window.open(`/onlineVideo/${session}/${password}/${name}/${roletype}`, '_blank');
        win.focus();
    }

    return (
        <Routes>

            <Route path="/login" element={<LoginLayout />} >
                <Route index element={<LoginAdmin />} />
            </Route>
            <Route path="/dr" element={<LoginLayout />}>
                <Route index element={<Login />} />
            </Route>
            <Route path="/login/patient" element={<LoginLayout />}>
                <Route index element={<LoginPatient />} />
            </Route>
            <Route path="/forgetpass" element={<LoginLayout />} >
                <Route index element={<Forget />} />
            </Route>
            <Route path="/otp-login" element={<LoginLayout />}>

                <Route index element={<LoginPageTwo />} />

            </Route>
            <Route path="/patientaccounts" element={<Patientmanagement />} />

            <Route path="/otp-patientlogin" element={<LoginLayout />}>

                <Route index element={<Loginpatientdetails />} />

            </Route>
            <Route path="/onlineVideo/:session/:password/:name/:roletype" element={<ZoomVideo />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path={`/`} element={<PrivateRoute><HomeLayout />
            </PrivateRoute>} errorElement={<ErrorPage />}>
                {userRoutes?.map((rou, index) => (
                    <Route key={index} index={rou?.index ? true : false} path={rou?.index ? null : rou?.path} element={rou?.element} />
                ))}
                <Route
                    path="/docPatientHistory/:patient_id" element={<PrivateRoute> <DrPatienthistory /> </PrivateRoute>}
                />
                <Route path="docEditMyConsultation/:id"
                    element={<DrEditMyConsultation />}
                />
                <Route path="/docViewModal/:id"
                    element={<ViewModal />}
                />
                <Route path="*" element={<NotFound />} />

            </Route>

        </Routes>
    );
};

export default Router;
