import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Divider, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from "../../../components/Widgets/CustomFormInput";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
// import { RESET_ERROR } from "../../../Redux/constants/settingsConstants"
import CustomImageUpload from "../../../components/Widgets/CustomImageUploade";
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";
import { editSlider, postSlider } from "../../../Redux/actions/settingsAction";
import { toast } from 'react-toastify';
const AddSlider = ({ handleClose, handleOpen, sliderData }) => {
  const dispatch = useDispatch()
  const [images, setimages] = useState(null)
  const [previewEdit, setPreviewEdit] = useState(null);
  const { error } = useSelector(state => state.settings)
  const { loading } = useSelector(state => state.auth);
  const schema = object().shape({
    //order: yup.string().max(100).required("Order is required"),
    order: yup
      .number()
      .typeError("Order must be a number")
      .required("Order is required"),
    name: yup.string().max(50).required("Name is required"),
    localImage: yup.boolean(),
    image: yup.mixed()
      .nullable()
      // .test('Fichier taille',
      //     'image resolution should be greater than 768 * 1000', (value) => !value || (value && value.size >= 768 * 1000))
      .when("localImage", {
        is: false,
        then: yup.mixed().required("Image  is Required")

      })

  });


  const setimagefile = (e) => {
    if (e.size > 500000) {
      toast.warning("Image Size Should be less than 500KB");
      setPreviewEdit(null)
      setimages(null)
    } else {
      setPreviewEdit(null)
      setimages(e)
    }

  }

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {

      localImage: false
    }
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "none",
    p: 2.5,
  };


  const submit = (data) => {
    // dispatch({ type: RESET_ERROR });
    if (sliderData) {
      const formData = new FormData();
      formData.append('id', sliderData?._id)
      formData.append('status', sliderData?.status)
      formData.append('name', data?.name)
      formData.append('order', data?.order)
      if (images !== null) {
        formData.append('image', data?.image)
      }

      dispatch(editSlider(formData))

    } else {
      const formData = new FormData();
      formData.append('name', data?.name)
      formData.append('order', data?.order)
      formData.append('image', data?.image)
      dispatch(postSlider(formData))

    }
    try {
      // Make API call here...
      if (sliderData) {
        // ... Edit slider logic ...
      } else {
        // ... Post slider logic ...
      }
    } catch (error) {
      toast.error(error?.message); // Display error message using toast
    }
  };



  useEffect(() => {
    if (sliderData) {
      setValue('name', sliderData?.name)
      setValue('order', sliderData?.order)
      setPreviewEdit(sliderData?.image)
      setValue("localImage", sliderData?.image ? true : false)
    }
  }, [sliderData, handleOpen])

  const clearClose = () => {
    reset();
    setimages(null)
    setPreviewEdit(null)
    setValue('name', "")
    setValue('order', "")
    handleClose()
  }

  return (
    <Modal
      open={handleOpen}
      onClose={clearClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          spacing={5}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ color: "#047BC1" }}
          >
            {sliderData ? "EDIT SLIDER" : "ADD SLIDER"}
          </Typography>
          <ClearIcon
            style={{
              cursor: "pointer",
              color: "black"
            }}
            onClick={() => clearClose()}
          />
        </Stack>
        <Divider />
        <Box display={"flex"} gap={"5px"} flexDirection={"column"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormInput
                height="40px"
                fieldLabel="Name"
                control={control}
                error={errors.name}
                fieldName="name"
                type={"text"}
                background="#FFFFFF"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomFormInput
                height="40px"
                fieldLabel="Order"
                control={control}
                error={errors.order}
                fieldName="order"
                type={"text"}
                background="#FFFFFF"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomImageUpload
              fieldLabel='Image'
              control={control}
              error={errors.image}
              fieldName='image'
              type={"file"}
              height="100px"
              background="#e7f5f7"
              id="contained-button-file"
              preview={images}
              previewEditimage={previewEdit}
              onChangeValue={(file) => {
                setimagefile(file)
                setValue("image", file)
              }}
            />
          </Grid>
          <Box display={"flex"} paddingTop={"10px"} justifyContent={"center"}>
            <CustomButton text={loading ? "Loading..." : "Submit"} disabled={loading ? true : false} variant={"contained"} submit={handleSubmit(submit)} />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddSlider