import { Box, Container } from '@mui/system'
import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { Avatar, Grid, IconButton, Typography } from '@mui/material';
import CustomFormInput from '../../components/Widgets/CustomFormInput';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import image from "../../assets/images/hlogoLogin.png"
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { editSettings, getSettings, postSettings } from '../../Redux/actions/settingsAction';
import { IMGEURL } from '../../config/constants';
const BasicDetails = () => {
    let dispatch = useDispatch()
    const { settingsList, settingCreated, error, settingUpdated } = useSelector(state => state.settings);


    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = object().shape({
        address: yup.string().max(100).required("address is required"),
        name: yup.string().max(50).required("Name field is required"),
        mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Mobile Number is required"),
        email: yup.string().email().required("Email is Required"),

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema)
    });
    const [Image, setImage] = useState(null)
    const [previewEdit, setPreviewEdit] = useState(null);

    // let profile = URL.createObjectURL(Image)
    const role = JSON.parse(localStorage.getItem("user"))

    const [imagePreview, setImagePreview] = useState(null);
    const Submit = (data) => {
        if (Image && Image?.size > 2 * 1024 * 1024) {
            console.log("gg");

            toast.error("File size is too large. Please select a file smaller than 2MB.");
            return;
        }

        if (settingsList) {
            const formData = new FormData();
            formData.append("name", data?.name)
            formData.append("id", settingsList?._id)
            formData.append("status", settingsList?.status)
            formData.append("email", data?.email)
            formData.append("mobile", data?.mobile)
            formData.append("address", data?.address)
            if (Image !== null) {
                formData.append("logo", Image)
            }
            dispatch(editSettings(formData))
        } else {
            const formData = new FormData();
            formData.append("name", data?.name)
            formData.append("email", data?.email)
            formData.append("mobile", data?.mobile)
            formData.append("address", data?.address)
            formData.append("logo", Image)
            dispatch(postSettings(formData))
        }
    }

    const onchangeImage = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const imageUrl = URL.createObjectURL(selectedImage);
            setImage(selectedImage);
            setImagePreview(imageUrl);
        }
    }

    useEffect(() => {
        if (settingCreated) {
            toast.success("Setting Created successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        if (settingUpdated) {
            toast.success("Setting Updated successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [settingCreated, error, settingUpdated])

    useEffect(() => {
        if (settingsList) {
            console.log(settingsList?.logo);
            setValue("name", settingsList?.name)
            setValue("address", settingsList?.address)
            setValue("mobile", settingsList?.mobile)
            setValue("email", settingsList?.email)
            setPreviewEdit(settingsList?.logo)
            setImagePreview(settingsList?.logo)
            // setPreviewEdit(settingsList?.logo)
        }
    }, [settingsList])

    useEffect(() => {
        dispatch(getSettings())
    }, [])

    return (
        <Container>
            <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                <Box position={"relative"} width={"200px"} height={"200px"}>
                    <Avatar
                        src={imagePreview}
                        style={{ width: "200px", height: "200px", objectFit: "contain" }}
                    ></Avatar>
                    <Box
                        display={"flex"}
                        position={"absolute"}
                        zIndex={"100"}
                        justifyContent={"center"}
                        bottom={"0px"}
                        width={"200px"}
                    >
                        <IconButton color="primary" aria-label="upload picture" component="label" className='iconbutton'>
                            <input
                                type="file"
                                hidden
                                accept=".png,.jpg,.jpeg"
                                onChange={(e) => onchangeImage(e)}
                            />
                            <PhotoCamera className='photoicon' />
                        </IconButton>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Typography fontFamily={"QuickSand"} fontWeight={"bold"}>
                            Logo
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4} xl={4}>
                            <CustomFormInput
                                height="40px"
                                background="#FFFFFF"
                                fieldLabel="Hospital Name"
                                control={control}
                                error={errors.name}
                                fieldName="name"
                                type={"text"}
                            />

                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <CustomFormInput
                                height="40px"
                                fieldLabel="Contact Number"
                                control={control}
                                error={errors.mobile}
                                fieldName="mobile"
                                type={"text"}
                                background="#FFFFFF"
                            />

                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <CustomFormInput
                                height="40px"
                                fieldLabel="Email Address"
                                control={control}
                                error={errors.email}
                                fieldName="email"
                                type={"text"}
                                background="#FFFFFF"
                            />

                        </Grid>
                        <Grid item xs={6} md={4} xl={12}>
                            <CustomFormInput
                                height="40px"
                                fieldLabel="Address"
                                control={control}
                                error={errors.address}
                                fieldName="address"
                                type={"text"}
                                background="#FFFFFF"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"} paddingTop={"80px"}>
                <CustomButton text={"UPDATE"} variant={"contained"} submit={handleSubmit(Submit)} />
            </Box>
        </Container >
    )
}

export default BasicDetails