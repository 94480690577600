import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Avatar, Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from '../../../components/Widgets/Backbutton';
import { useDispatch, useSelector } from "react-redux";
import CustomTimePicker from '../../../components/Widgets/CustomTimePicker/CustomTimePicker';
import { getSingleProcedure } from '../../../Redux/actions/procedureAction';


const ProcedureListView = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const { procedureSingleList } = useSelector(state => state.procedure);
    const { loading } = useSelector(state => state.auth);


  
 
    useEffect(() => {
        if (id) {
            dispatch(getSingleProcedure(id))
        }
    }, [id])

if(loading){
    return <div>Loading...</div>
}

    return (
        <>
            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                      {`${'Procedure ID:'}${procedureSingleList?.procedure_id}`}
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={2} px={5}>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Interval (Minutes)</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{procedureSingleList?.interval}</Typography>
                </Grid>


                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Procedure Department</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{procedureSingleList?.department?.[0]?.name}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Procedure Name</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{procedureSingleList?.name}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Price</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{procedureSingleList?.price}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Procedure Description</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "100px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{procedureSingleList?.description}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 14,
                            sm: 13,
                            xs: 12,

                        },
                    }}>Procedure Image</Typography>
                    {procedureSingleList?.image &&
                        <Avatar style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            width: "100%",
                            height: "150px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} src={procedureSingleList?.image} ></Avatar>}
                </Grid>
                <Box style={{ paddingTop: "20px", paddingBottom: "20px", width: "100%" }}>
                    <Typography variant="h5" component="h2">Procedure Time</Typography>
                    <Divider />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Monday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true} 
                                toValue={procedureSingleList?.date?.monday?.to}
                                fromValue={procedureSingleList?.date?.monday?.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Tuesday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true} 
                                toValue={procedureSingleList?.date?.tuesday?.to}
                                fromValue={procedureSingleList?.date?.tuesday?.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Wednesday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true} 
                                toValue={procedureSingleList?.date?.wednesday?.to}
                                fromValue={procedureSingleList?.date?.wednesday?.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Thursday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true} 
                                toValue={procedureSingleList?.date?.thursday?.to}
                                fromValue={procedureSingleList?.date?.thursday?.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Friday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true} 
                                toValue={procedureSingleList?.date?.friday?.to}
                                fromValue={procedureSingleList?.date?.friday?.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Saturday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true} 
                                toValue={procedureSingleList?.date?.saturday?.to}
                                fromValue={procedureSingleList?.date?.saturday?.from}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Sunday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true} 
                                toValue={procedureSingleList?.date?.sunday?.to}
                                fromValue={procedureSingleList?.date?.sunday?.from}
                            />

                        </Box>
                    </Grid>

                </Grid>
            </Grid>

        </>
    )
}

export default ProcedureListView