
import React, { useState, useEffect } from "react";
import { Box, Button, Container } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from "../../components/Widgets/Backbutton";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import CustomFormInput from "../../components/Widgets/CustomFormInput";
import CustomSelectInput from "../../components/Widgets/CustomSelect";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import MenuItem from '@mui/material/MenuItem';
import CustomCalender from "../../components/Widgets/CustomCalender";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { createPatient } from "../../Redux/actions/patientAction";
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { getDepartmentListByStatus, getDeptCategory } from "../../Redux/actions/departmentAction";
import CustomMultipleSelectInput from "../../components/Widgets/CustomMultipleSelect";
import AddIcon from '@mui/icons-material/Add';
import AddDepartmentModal from "../Procedure/AddDepartmentModal/AddDepartmentModal";
const AddPatient = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()


  const { CategoryList, deptListBySatatus } = useSelector(state => state.department);
  const { patientCreated, error, patientSingleList } = useSelector(state => state.patient);
  const { deptCreated } = useSelector(state => state.department)
  const { loading } = useSelector(state => state.auth)


  const schema = object().shape({
    //name: yup.string().max(50).required("Name is required"), name: yup.string().max(50)
    name: yup.string().max(50)
      .matches(/^[A-Za-z\s]*$/, "Name can only contain alphabets")
      .required("Name is required"),
    mobile: yup.string()
      .min(10)
      .required('A phone number is required'),
    dob: yup.string().required("DOB is Required"),
    address: yup.string().required("Address is Required"),
    email: yup.string().email().required("Email is Required"),
    gender: yup.string().required("gender is Required"),
    height: yup.number()
      .typeError("Height must be a number")
      .required("Height is required"),
    weight: yup.number()
      .typeError("Weight must be a number")
      .required("Weight is required"),
    bloodgroup: yup.string().required("blood Group is Required"),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      role: "patient",
      category: null
    },
    resolver: yupResolver(schema)
  });
  const [open, setOpen] = useState(false);
  const [Date, setDate] = React.useState((null));
  const [valuegender, setvaluegender] = useState("");
  const [valuebloodgroup, setvaluebloodgroup] = useState("");
  const [category, setCategory] = useState('')
  const [multpleArray, setMultipleArray] = React.useState([]);
  const [postArray, setPostArray] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChangeDate = (newValue) => {

    let date = moment(newValue).format('DD-MM-YYYY')
    setValue("dob", date)
    setDate(newValue);
  };

  const handlechangegender = (event) => {
    setvaluegender(event.target.value)
    setValue("gender", event.target.value)

  }
  const handlechanBloodgroup = (event) => {
    setvaluebloodgroup(event.target.value)
    setValue("bloodgroup", event.target.value)

  }

  const handleChangeCategory = (e) => {
    setCategory(e.target.value)
  }

  const submit = (data) => {
    dispatch(createPatient(data))
  }


  const onChangeMultiple = (event) => {
    const {
      target: { value },
    } = event;

    const values = event.target.value

    let find = deptListBySatatus?.filter((res, I) => event.target.value.includes(res._id))
    let data = find?.map((res) => ({
      id: res?._id,
      name: res?.name

    }))
    if (data) {
      setValue('category', data)
    }

    setPostArray(data)
    setMultipleArray(
      values
    );

  }

  useEffect(() => {
    if (patientCreated) {
      toast.success("Patient Created Successfully");
      dispatch({
        type: RESET_ERROR
      })
      reset()
      navigate(-1)
    }
    if (deptCreated) {
      toast.success("Department Created Successfully");
      dispatch({
        type: RESET_ERROR
      })
      handleClose()
      // dispatch(getDepartmentListByStatus())
      setOpen(false)
    }

    if (error) {
      toast.error(`${error?.message}`);
      dispatch({
        type: RESET_ERROR
      })

    }

  }, [patientCreated, error, deptCreated])

  useEffect(() => {
    if (CategoryList) {
      let find = CategoryList?.find((a) => a?.name === "patients");
      dispatch(getDepartmentListByStatus(find?._id))
    }
  }, [CategoryList, deptCreated])


  useEffect(() => {
    dispatch(getDeptCategory())
  }, [])


  return (
    <>
      <Box style={{ paddingBottom: "30px" }}>
        <Stack
          direction={"row"}
          spacing={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Backbutton />
          <Typography
            variant="h4"
            component="h2"
            style={{ fontSize: "25px", color: "#047AC3" }}
          >Add Patient
          </Typography>
        </Stack>
      </Box>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} md={4} lg={3} >
          <CustomFormInput
            height="40px"
            fieldLabel="Patient Name "
            control={control}
            error={errors.name}
            fieldName="name"
            type={"text"}
            background="#FFFFFF"
            boxshadow="5px 5px 10px #00000005"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomFormInput
            height="40px"
            fieldLabel="Mobile Number "
            control={control}
            error={errors.mobile}
            fieldName="mobile"
            type={"text"}
            background="#FFFFFF"
            boxshadow="5px 5px 10px #00000005"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomFormInput
            height="40px"
            fieldLabel="Email Address"
            control={control}
            error={errors.email}
            fieldName="email"
            type={"text"}
            background="#FFFFFF"
            boxshadow="5px 5px 10px #00000005"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomCalender
            control={control}
            error={errors.dob}
            fieldName="dob"
            fieldLabel="DOB"
            value={Date}
            onChangeValue={handleChangeDate}
            inputFormat={"DD-MM-YYYY"}
            background="#e7f5f7"
            height="40px"
          />

        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomSelectInput
            control={control}
            error={errors.gender}
            fieldName="gender"
            fieldLabel="Gender"
            background="#e7f5f7"
            height="40px"
            size="16px"
            value={valuegender}
            onChangeValue={handlechangegender}
          // options={categoryArray}
          >
            <MenuItem value="" disabled >
              Select Gender
            </MenuItem>
            {["Male", "Female", "Others"].map((status) => (
              <MenuItem key={status?.name} value={status} >
                {status}
              </MenuItem>
            ))}

          </CustomSelectInput>
        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomFormInput
            height="40px"
            fieldLabel="Height "
            control={control}
            error={errors.height}
            fieldName="height"
            type={"text"}
            background="#FFFFFF"
            boxshadow="5px 5px 10px #00000005"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomFormInput
            height="40px"
            fieldLabel="Weight"
            control={control}
            error={errors.weight}
            fieldName="weight"
            type={"text"}
            background="#FFFFFF"
            boxshadow="5px 5px 10px #00000005"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomSelectInput
            control={control}
            error={errors.bloodgroup}
            fieldName="bloodgroup"
            fieldLabel="Blood Group"
            background="#e7f5f7"
            height="40px"
            size="16px"
            defaultValue={""}
            value={valuebloodgroup}
            onChangeValue={handlechanBloodgroup}
          // options={categoryArray}
          >
            <MenuItem value="" disabled >
              Select Blood Group
            </MenuItem>
            {["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"].map((status) => (
              <MenuItem key={status} value={status} >
                {status}
              </MenuItem>
            ))}

          </CustomSelectInput>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <CustomFormInput
            height="40px"
            fieldLabel="Address "
            control={control}
            error={errors.address}
            fieldName="address"
            type={"text"}
            background="#FFFFFF"
            boxshadow="5px 5px 10px #00000005"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} >
          <CustomMultipleSelectInput
            multiple={true}
            control={control}
            error={errors.category}
            fieldName="category"
            fieldLabel="Select Category"
            background="#FFFFFF"
            multiline
            height="40px"
            size="15px"
            // multiple={groupable ? true : false}
            defaultValue={""}
            value={multpleArray}
            onChangeValue={onChangeMultiple}
          >
            <MenuItem value="" disabled >
              <em>Select  Category</em>
            </MenuItem>
            {deptListBySatatus?.map((cat) => (
              <MenuItem key={cat?._id} value={cat?._id} >
                {cat?.name}
              </MenuItem>
            ))}
          </CustomMultipleSelectInput>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Box style={{ paddingTop: "24px" }}>
            <CustomButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleOpen()} />
          </Box>
        </Grid>

      </Grid>
      <Box style={{ display: "flex", justifyContent: "center", paddingTop: "100px" }}>
        <CustomButton variant={"contained"} text={loading ? "Loading..." : "Submit"} disabled={loading ? true : false} submit={handleSubmit(submit)} />
      </Box>

      <AddDepartmentModal handleClose={handleClose} handleOpen={open} CategoryList={CategoryList} mode={'patients'} />
    </>
  )
}

export default AddPatient