import { POST_DOCTOR_RESCHEDULE_LIST_FAIL, POST_DOCTOR_RESCHEDULE_LIST_SUCCESS, POST_DOCTOR_RESCHEDULE_LIST_FAIL_DOCTOR, POST_DOCTOR_RESCHEDULE_LIST_SUCCESS_DOCTOR } from "../constants/doctorConstant";
import { CREATE_LEAVE_FAIL, CREATE_LEAVE_SUCCESS, GET_LEAVE_FAIL, GET_LEAVE_SUCCESS, REJECT_OR_APPROVE_FAIL, REJECT_OR_APPROVE_SUCCESS } from "../constants/leaveConstant";
import { RESET_ERROR } from "../constants/userConstants";

export const leaveReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                leaveCreated: null,
                ApproveRejectStatus: null

            }
        case CREATE_LEAVE_SUCCESS:
            return {
                ...state,
                leaveCreated: true
            }
        case CREATE_LEAVE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_LEAVE_SUCCESS:
            return {
                ...state,
                leaveList: action.payload
            }
        case GET_LEAVE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case POST_DOCTOR_RESCHEDULE_LIST_SUCCESS:
            return {
                ...state,
                reschedulelist: action.payload
            }
        case POST_DOCTOR_RESCHEDULE_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case POST_DOCTOR_RESCHEDULE_LIST_SUCCESS_DOCTOR:
            return {
                ...state,
                reschedulelist: action.payload
            }
        case POST_DOCTOR_RESCHEDULE_LIST_FAIL_DOCTOR:
            return {
                ...state,
                error: action.payload
            }


        case REJECT_OR_APPROVE_SUCCESS:
            return {
                ...state,
                ApproveRejectStatus: true
            }
        case REJECT_OR_APPROVE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;

    }

}