import { BASIC_DETAIL_SUCCESS, CONSULTATION_FEE_SUCCESS, CONSULTATION_TIME_SUCCESS, DOCTOR_INPUT, RESET_DOCTOR } from "../../constants/AddDoctorConstant";

const initialState = {
  basicInfo: null,
  cosultationTime:[]
};

export const docterStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case BASIC_DETAIL_SUCCESS:
      return {
        ...state,
        basicInfo: action.payload
      };

    case CONSULTATION_TIME_SUCCESS:
      return {
        ...state,
        cosultationTime:  action.payload
      }

    case DOCTOR_INPUT:
      return{
        ...state,
        [action.payload.prop] : action.payload.value
      }

    case CONSULTATION_FEE_SUCCESS:
      return {
        ...state,
        consultationFee: action.payload
      }

    case RESET_DOCTOR:
      return{
        state: null
      }

    
    default:
      return state;
  }
};