import React, { useState, useEffect, useContext } from "react";
import {
	LogoContainer,
	LogoutContainer,
	NavlinkContainer,
	SidebarContainer,
} from "./style";
import logo from "../../assets/images/hlogo.png";
import power from "../../assets/images/power-button-svgrepo-com.png";
import { Sidebardata, Sidebardataforadmin, doctorsideBar, adminData, Sidebardoctor } from "./data";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import RoleContext from "../../helpers/Role";
import { Box, Button, Typography, appBarClasses } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import customAxios from "../../customAxios";
import { setActiveSidebar } from '../../Redux/reducers/activeSidebarReducer'

const Sidebar = () => {


	let location = useLocation();
	console.log({ location });
	const appt = localStorage.getItem("router_type")

	const [sideMenu, setSideMenu] = useState([])
	const [open, setOpen] = React.useState(false);
	const [text, settext] = useState(null);


	const handleClickOpen = () => {
		setOpen(true);
	};


	const { notificationList, types, router_types } = useSelector(state => state.notifi);
	const handleClose = () => {
		setOpen(false);
	};

	const { status } = useSelector(state => state.activeSidebar);
	const context = useContext(RoleContext)

	useEffect(() => {
		context?.getMenus()
	}, [context?.role])

	useEffect(() => {

		const role = JSON.parse(localStorage?.getItem("role"))
		const user = JSON.parse(localStorage?.getItem("user"))

		if (role?.slug === "admin" || user?.role === "admin") {

			setSideMenu(Sidebardataforadmin)
		}
		else if (role?.slug === "doctor" || user?.role === "doctor") {

			setSideMenu(Sidebardoctor)
		}
		else {
			console.log(context.menus);

			let usersMenus = Sidebardata?.filter(side => context?.menus?.includes(side?.roleTitle))
			console.log({ usersMenus }, 'menu')



			setSideMenu(usersMenus)
		}

	}, [context?.menus])



	const navigate = useNavigate();
	const [isActive, setActive] = useState(status);

	const toggleClass = (index) => {
		setActive(index);
	};

	useEffect(() => {
		setActive(location.pathname);
	}, [location]);


	const click = (index) => {
		console.log({ index }, 'indexconsole')
		navigate(index);
		setActive(index);
		setActiveSidebar(index);
	}



	// const click = (index) => {
	// 	console.log({index},'indexconsole')
	// 	navigate(index);
	// 	setActive(index);
	// 	setActiveSidebar(index);

	// };

	const LogOut = async () => {
		const role = JSON.parse(localStorage?.getItem("role"))
		let user = JSON.parse(localStorage?.getItem("user"))
		let tocken = localStorage?.getItem("webtocken")
		let data = {
			id: user?._id,
			token: tocken
		}

		if (role?.name?.toLowerCase() === "doctor") {
			navigate(`/dr`)

		}
		else {
			navigate(`/login`);
		}
		await customAxios.post(`auth/removewebToken`, data)
		localStorage.clear();
		handleClose();
	}




	//*******************SIDEBAR ONCLICK EFFECT************************** */

	// useEffect(() => {
	// 	if (location) {
	// 		if (location.pathname === "/") {
	// 			setActive("/")
	// 		} else if (location.pathname === "/profile") {
	// 			setActive(null)
	// 		} else if (location.pathname === "/department") {
	// 			setActive("/department")
	// 		} else if (location.pathname === "/doctor" || location.pathname === "/AddDoctor") {
	// 			setActive("/doctor")
	// 		} else if (location.pathname === "/patients") {
	// 			setActive("/patients")
	// 		} else if (location.pathname === "/service" || location.pathname === "/addBooking") {
	// 			setActive("/service")
	// 		} else if (location.pathname === "/procedure" ||
	// 			location.pathname === "/procedureBooking"
	// 			|| location.pathname === "/procedureList") {
	// 			setActive("/procedure")
	// 		} else if (location.pathname === "/settings") {
	// 			setActive("/settings")
	// 		} else if (location.pathname === "/roles") {
	// 			setActive("/roles")
	// 		} else if (location.pathname === "/user") {
	// 			setActive("/user")
	// 		} else if (location.pathname === "/blogs") {
	// 			setActive("/blogs")
	// 		}
	// 		else if (location.pathname === "/leaveManagement") {
	// 			setActive("/leaveManagement")
	// 		}
	// 		else if (location.pathname === "/docBlog") {
	// 			setActive("/docBlog")
	// 		}
	// 	}
	// }, [location])
	useEffect(() => {
		const role = JSON.parse(localStorage?.getItem("role"))

		console.log(appt);

		if (location && role?.slug !== "doctor") {


			console.log({ role }, 'ROLE')
			if (location?.pathname === "/" || location?.pathname?.startsWith("/viewAppointment/") || location?.pathname.startsWith("/docViewModal/")) {
				setActive("/")
			} else if (location?.pathname === "/profile") {
				setActive(null)
			} else if (location?.pathname === "/department") {
				setActive("/department")
			} else if (location?.pathname === "/doctor" || location?.pathname === "/AddDoctor") {
				setActive("/doctor")
			} else if (location?.pathname === "/patients") {
				setActive("/patients")
			} else if (location?.pathname === "/service" || location?.pathname === "/addBooking" || location?.pathname?.startsWith("/bookingView/")) {
				setActive("/service")
			} else if (location?.pathname === "/procedure" || location?.pathname?.startsWith("/procedureBookingView/") ||
				location?.pathname === "/procedureBooking"
				|| location?.pathname === "/procedureList") {
				setActive("/procedure")

			} else if (location?.pathname === "/settings") {
				setActive("/settings")
			} else if (location?.pathname === "/roles") {
				setActive("/roles")
			} else if (location?.pathname === "/user") {
				setActive("/user")
			} else if (location?.pathname === "/blogs") {
				setActive("/blogs")
			}
			else if (location?.pathname === "/leaveManagement") {
				setActive("/leaveManagement")
			}
			else if (location?.pathname === "/docBlog") {
				setActive("/docBlog")
			}

		}
		else {
			if (location?.pathname?.startsWith("/docViewModal/") && types === "Op") {
				setActive("/consultations")

			}
			else if (location?.pathname?.startsWith("/docViewModal/") && types === "Online") {
				setActive("/docOnline")

			}
			else if (location?.pathname?.startsWith("/docViewModal/") && types === "Report") {
				setActive("/docReportReview")

			}
			else if (location?.pathname?.startsWith("/docViewModal/") && types === "service") {
				setActive("/docReportReview")

			}
			else if (location?.pathname?.startsWith("/docViewModal/") && types === "procedure") {
				setActive("/docReportReview")

			}
			else if (location?.pathname?.startsWith("/docleavemanagement") && types === "leave") {
				setActive("/docleavemanagement")

			}
			if (location?.pathname?.startsWith("/docViewModal/") && router_types === "Op") {
				setActive("/consultations")

			}
			else if (location?.pathname?.startsWith("/docViewModal/") && router_types === "Online") {
				setActive("/docOnline")

			}

			else if (location?.pathname?.startsWith("/docleavemanagement") && router_types === "leave") {
				setActive("/docleavemanagement")

			}
			else if (location?.pathname?.startsWith("/docViewModal/") && router_types === "service") {
				setActive("/docReportReview")

			}
			else if (location?.pathname?.startsWith("/docViewModal/") && router_types === "procedure") {
				setActive("/docReportReview")
			}
			// else {
			// 	setActive("/consultations")

			// }
		}

	}, [location])


	console.log({ sideMenu });
	return (
		<SidebarContainer>
			<LogoContainer>
				<div className="sub">
					<img src={logo} alt="logo" style={{ width: 60, height: 60, paddingTop: "9px" }}></img>
				</div>
			</LogoContainer>


			<NavlinkContainer>
				<Box style={{ height: "65vh" }}>
					<ul>
						{sideMenu?.map((val, index) => {
							return (
								<li
									key={index}
									onClick={() => click(val?.link)}


									style={{ background: val?.link === isActive ? "white" : "", padding: val?.link === isActive ? "5px 5px 5px 5px" : "", borderRadius: val.link === isActive ? "10px" : "" }}
								>
									<Typography textAlign={"center"}>{val?.title} </Typography>
								</li>
							);
						})}
					</ul>
				</Box>
				<LogoutContainer >
					<Box py={1} className="power" >
						<Button onClick={() => setOpen(true)}>
							<img src={power} alt="power" height={50}></img>
						</Button>

					</Box>
				</LogoutContainer>
			</NavlinkContainer>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to log out?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Disagree</Button>
					<Button onClick={LogOut} autoFocus>
						Agree
					</Button>
				</DialogActions>
			</Dialog>
		</SidebarContainer>
	);
};

export default Sidebar;
