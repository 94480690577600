import { Box, Pagination, Paper, Stack } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import CustomButton from '../../components/Widgets/CustomButton/CustomButton'
import CustomSearch from '../../components/Widgets/CustomSearch'
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import data from '../../components/common/data';
import EditIcon from "@mui/icons-material/Edit";
import DataTables from '../../components/Widgets/dataTable';
import CustomSwitch from '../../components/Widgets/CustomSwitch/CustomSwitch';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from '../../Redux/actions/roleAction';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import moment from 'moment';

const Roles = () => {
    const dispatch = useDispatch()
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const { roleList } = useSelector(state => state.role)

    const [datas, setDatas] = useState([])
    const [switchChecked, setSwitchChecked] = useState(null);
    const navigate = useNavigate()
    const [row, setRow] = useState(null)
    const [checked, setChecked] = useState(null)
    const [openDialog, setOpenDialog] = useState(false);


    useEffect(() => {
        if (roleList) {
            setDatas(roleList)
        }
    }, [roleList])

    const searchRoles = (value) => {


        let datas = roleList?.filter(role => role?.name.toLowerCase().includes(value.toLowerCase()))

        setDatas(datas)
    }

    const NavigateToaddpage = () => {
        navigate("/addRoles")
    }

    useEffect(() => {
        dispatch(getAllRoles())
    }, [])

    const editRole = (id) => {
        navigate(`/editrole/${id}`)
    }
    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };

    // const ChangeStatus = (checked, row) => {
    //     setSwitchChecked(checked)
    //     setRow(row)
    //     setChecked(checked)
    //     handleClickOpen()
    // }
    var columns = [
        {
            name: <b>{Capitalize("ROLE NAME")}</b>,
            header: <b>{Capitalize("ROLE NAME")}</b>,
            selector: (row) => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("CREATED ON")}</b>,
            header: <b>{Capitalize("CREATED ON")}</b>,
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>ACTIONS</b>,
            cell: (row, index) => (
                <>
                    <Stack spacing={1} direction="row">

                        <BorderColorSharpIcon onClick={() => editRole(row?._id)} style={{ color: "#047ac1", cursor: "pointer" }} />{" "}
                    </Stack>
                </>
            ),
        },
        // {
        //     name: <b>STATUS</b>,
        //     cell: (row, index) => (
        //         <>
        //             <Stack spacing={1} direction="row">

        //                 <CustomSwitch
        //                     checked={row.status === "active" ? true : false}
        //                  onClick={(e) => ChangeStatus(e.target.checked, row)}

        //                 />
        //             </Stack>
        //         </>
        //     ),
        // }
    ];


    return (
        <>
            <Box
                sx={{
                    width: "99%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "10px", xs: "10px" },
                    marginBottom: "40px",
                }}
            >
                <Stack spacing={2.6} direction="row" style={{
                    background: "#d8dee1",
                    padding: "3px 3px 3px .1px",
                    display: "flex",
                    alignItems: "center",

                }} borderRadius={2}>
                    <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={searchRoles} />

                    <CustomButton
                        submit={NavigateToaddpage}
                        ComponentIcon={AddIcon}
                        variant={"contained"}

                    />
                </Stack>
            </Box>
            <Box>
                <Paper>
                    <Fragment>
                        <DataTables columns={columns} row={datas} pagination />
                    </Fragment>
                </Paper>
            </Box>
        </>
    )
}

export default Roles