

import { CREATE_PATIENT_SUCCESS, CRETAE_PATIENT_FAIL, EDIT_PATIENTS_FAIL, EDIT_PATIENTS_SUCCESS, FILTER_PATIENT_FAIL, FILTER_PATIENT_SUCCESS, GET_ALL_PATIENTS_SUCCESS, GET_ALL_PATIENT_FAIL, GET_SINGLE_PATIENT_FAIL, GET_SINGLE_PATIENT_SUCCESS } from "../constants/patientConstant";
import { LOADING, RESET_ERROR } from "../constants/userConstants";

export const patientReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                patientCreated: null,
                patientedited: null,

            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case GET_ALL_PATIENTS_SUCCESS:
            return {
                ...state,
                patientList: action.payload
            }

        case GET_ALL_PATIENT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_PATIENT_SUCCESS:
            return {
                ...state,
                // patientList: state?.patientList ? [...state.patientList, action.payload] : action.payload,
                patientCreated: true
            }

        case CRETAE_PATIENT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case EDIT_PATIENTS_SUCCESS:
            return {
                ...state,
                patientList: state?.patientList.map(Patient => Patient._id === action.payload._id ? action.payload : Patient),
                patientedited: true
            }

        case EDIT_PATIENTS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_SINGLE_PATIENT_SUCCESS:
            return {
                ...state,
                patientSingleList: action.payload
            }
        case GET_SINGLE_PATIENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case FILTER_PATIENT_SUCCESS:
            return {
                ...state,
                patientList: action.payload
            }
        case FILTER_PATIENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }

}