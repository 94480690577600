
import { Box, Pagination, Typography } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import DataTables from '../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import data from '../../components/common/data';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomSearch from '../../components/Widgets/CustomSearch';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import CustomCheckBox from '../../components/Widgets/CustomCheckBox';
import CustomSwitch from '../../components/Widgets/CustomSwitch/CustomSwitch';
import RecheduleModal from './ResheduleModal/RecheduleModal';
import { postApproveReject, postResheduleList, postResheduleListbasedondoctor } from '../../Redux/actions/leaveAction';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import CancelBookingModal from '../Service/components/BookingCancelModal';
import Backbutton from '../../components/Widgets/Backbutton';
import { reject } from 'lodash';

const RecheduleLeave = () => {
    const location = useLocation()
    const { appointmentRescheduled, appointmentCancel } = useSelector(state => state.appointment);
    const { reschedulelist, error, ApproveRejectStatus } = useSelector(state => state.leave);
    const [checkedT, setChecked] = useState(false)
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const [store, setStore] = useState(null)
    const [open, setOpen] = useState(false);
    const [openCancel, setOpenCancel] = useState(false)
    const [mode, setMode] = useState(null)
    const [dataRes, setDataRes] = useState([])

    const [_id, setID] = useState(null)

    const handleOpen = (row) => {
        setStore(row)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenCancel = (id) => {
        setID(id)
        setOpenCancel(true);
    };
    const handleCloseCancel = () => {
        setOpenCancel(false);
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    var columns = [
        {
            name: <b>{Capitalize("DATE & TIME")}</b>,
            header: <b>{Capitalize("DATE & TIME")}</b>,
            selector: (row) => <span>{moment(row?.date).format('DD-MM-YYYY')} {row?.appointmenttype === "Report" ? "" : moment(row.time, 'h:mm').format('h:mm A')}</span>,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("BOOKING ID")}</b>,
            header: <b>{Capitalize("BOOKING ID")}</b>,
            selector: (row) => row?.booking_id,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PATIENT ID")}</b>,
            header: <b>{Capitalize("PATIENT ID")}</b>,
            selector: (row) => row?.patient?.user_id,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PATIENT NAME")}</b>,
            header: <b>{Capitalize("PATIENT NAME")}</b>,
            selector: (row) => row?.name
            ,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PHONE NUMBER")}</b>,
            header: <b>{Capitalize("PHONE NUMBER")}</b>,
            selector: (row) => row?.mobile,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("APPOINTMENT TYPE")}</b>,
            header: <b>{Capitalize("APPOINTMENT TYPE")}</b>,
            selector: (row) => row?.appointmenttype
            ,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>ACTIONS</b>,
            cell: (row, index) => (
                <>
                    <Stack spacing={2} direction="row">
                        <CalendarMonthIcon
                            onClick={() => handleOpen(row)}
                            color="blue"
                            style={{
                                cursor: "pointer",
                                color: "#047ac1",
                            }}
                        />{" "}
                        <CloseIcon
                            onClick={() => handleOpenCancel(row?._id)}
                            style={{
                                cursor: "pointer",
                                color: "red",
                            }}
                        />
                    </Stack>
                </>
            ),
        },

    ];


    useEffect(() => {
        if (reschedulelist) {
            setDataRes(reschedulelist)
        }
    }, [reschedulelist])


    useEffect(() => {
        if (appointmentCancel) {
            toast.success(`Appointment cancelled successfully`);
            dispatch({
                type: RESET_ERROR
            })
            setOpenCancel(false)
            let value = {
                doctor_id: location?.state?.item?.doctor_id,
                date: moment(location?.state?.item?.leave_days, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                type: location?.state?.item?.type
            }
            dispatch(postResheduleListbasedondoctor(value))
        }

        if (appointmentRescheduled) {
            toast.success(`Appointment Rescheduled Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            let value = {
                date: moment(location?.state?.item?.leave_days, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                type: location?.state?.item?.type,
                doctor_id: location?.state?.item?.doctor_id,
            }
            dispatch(postResheduleListbasedondoctor(value))
            handleClose()
        }
        if (ApproveRejectStatus) {
            { mode === 'approve' ? toast.success(`Leave Approved Successfully`) : toast.success(`Leave Rejected Successfully`) }
            dispatch({
                type: RESET_ERROR
            })
            Navigate(-1)
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, appointmentRescheduled, appointmentCancel, ApproveRejectStatus])

    useEffect(() => {
        if (location) {
            let value = {
                date: moment(location?.state?.item?.leave_days, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                type: location?.state?.item?.type,
                doctor_id: location?.state?.item?.doctor_id
            }
            dispatch(postResheduleListbasedondoctor(value))
        }
    }, [location])

    const RejectorApprove = (e, mode) => {
        setMode(mode)
        const { checked } = e.target;

        if (mode === 'approve' && checked) {
            let approve = {
                id: location?.state?.item?._id,
                status: 'approved'
            }
            dispatch(postApproveReject(approve))
        }
        else if (mode === 'warning') {
            toast.warning(`Please Cancel or Reschedule Current Appointments`)

        }



        if (mode === 'rejected' && checked) {
            let rejected = {
                id: location?.state?.item?._id,
                status: 'rejected'
            }
            dispatch(postApproveReject(rejected))
        }
    }

    const searchResheduleList = (value) => {
        let datas = reschedulelist?.filter(res => res?.name?.toLowerCase().includes(value.toLowerCase()) || res?.appointmenttype?.toLowerCase().includes(value.toLowerCase()) || res?.mobile?.toLowerCase().includes(value.toLowerCase()))
        setDataRes(datas)
    }


    return (
        <>
            <Box
                sx={{
                    width: "99%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "10px", xs: "10px" },
                    marginBottom: "40px",
                }}
            >
                <Box >
                    <Stack
                        direction={"row"}
                        spacing={1}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <Backbutton />
                        <Typography
                            variant="h4"
                            component="h2"
                            style={{ fontSize: "25px", color: "#047AC3" }}
                        >
                            Reschedule
                        </Typography>
                    </Stack>
                </Box>
                <Stack spacing={2.6} direction="row" style={{
                    background: "#d8dee1",
                    padding: "3px 3px 3px .1px",
                    display: "flex",
                    alignItems: "center",

                }} borderRadius={2}>
                    <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={(value) => searchResheduleList(value)} />
                </Stack>

            </Box>
            <Paper>
                <Fragment>

                    <DataTables
                        columns={columns}
                        row={dataRes}
                        pagination
                    />
                </Fragment>
            </Paper>
            <Box
                sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}


            </Box>

            <Box display={'flex'} justifyContent={'flex-end'} gap={3} marginTop={2} marginBottom={2}>

                <Box
                    display={'flex'}

                    justifyContent={'space-between'}
                    borderRadius={2}
                    paddingLeft={'10px'}
                    alignItems={'center'}
                    gap={5}
                    style={{ background: "white" }}>
                    <Typography
                        letterSpacing={'1px'}
                        fontWeight={'bold'}
                        fontFamily={'Quicksand'}
                        color={'green'}>Approve</Typography>
                    {reschedulelist?.length === 0 ?
                        <CustomSwitch changeRole={(e) => RejectorApprove(e, 'approve')} /> :
                        <CustomSwitch checked={false} changeRole={(e) => RejectorApprove(e, 'warning')} />
                    }


                </Box>
                <Box >
                    <Box
                        display={'flex'}
                        justifyContent={'space-around'}
                        borderRadius={2}
                        paddingLeft={'10px'}
                        alignItems={'center'}
                        gap={5}
                        style={{ background: "white" }}>
                        <Typography
                            letterSpacing={'1px'}
                            fontWeight={'bold'}
                            fontFamily={'Quicksand'}
                            color={'red'}>Reject</Typography>
                        <CustomSwitch changeRole={(e) => RejectorApprove(e, 'rejected')} />
                    </Box>
                </Box>
            </Box>
            {open && <RecheduleModal handleClose={handleClose} handleOpen={open} item={store} />}
            {openCancel && <CancelBookingModal handleClose={handleCloseCancel} handleOpen={openCancel} mode={"leave"} id={_id} />}
        </>

    )
}

export default RecheduleLeave