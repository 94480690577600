import React, { useState } from "react";
import { Box, FormGroup, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const CustomMultipleSelectInput = ({
  fieldName,
  control,
  fieldLabel,
  error,
  children,
  max, mode,
  multiple,
  selectvalue,
  onChangeValue,
  options,
  background,
  height,
  size,
  value,
  label,
  defaultValue,
  readOnly
}) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: { lg: 250, md: 200, xs: '100%' },
      },
    },
  };

  return (
    <>
      <FormGroup>
        <Typography
          paddingLeft={1}
          sx={{
            fontSize: {
              lg: size,
              md: 14,
              sm: 13,
              xs: 12,
            },
            fontFamily: 'Quicksand',
            fontWeight: "bold"
          }}>{fieldLabel}</Typography>
        <Controller
          name={fieldName}
          control={control}

          render={({ field: { onBlur, onChange } }) => (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              readOnly={readOnly}
              required={mode === "individual" || "group" ? true : false}

              IconComponent={() => (

                <Box
                  sx={{
                    cursor: 'pointer'
                  }}
                  onClick={handleOpen}
                  width={60}
                  height={40}
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  bgcolor="#047AC3"
                  color={"#fff"}
                  borderRadius={"10px"}>
                  <KeyboardArrowDownSharpIcon style={{ fontSize: 30, fontWeight: 'bold' }} />
                </Box>
              )}
              sx={{

                "& .MuiSvgIcon-root": {
                  color: "white",

                  width: '32px',
                  borderRadius: '5px',
                  background: '#047AC3',
                },
                width: { lg: '100%', md: '100%', sm: '100%' },
              }}
              displayEmpty
              onChange={onChangeValue}
              value={value}

              multiple={multiple}
              //   input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}

              style={{
                borderRadius: "10px",
                background: background,
                height: height,


              }}

            >
              {children}
            </Select>
          )}
        />

        {error && (
          <p
            role="alert"
            style={{
              color: "red",
              display: "flex",
              flexDirection: "start",
              paddingLeft: "10px",
              fontSize: "12px",
            }}
          >
            {error?.message}
          </p>
        )}
      </FormGroup>
    </>
  );
};

export default CustomMultipleSelectInput;
