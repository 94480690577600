export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS'
export const CREATE_BLOG_FAIL = 'CREATE_BLOG_FAIL'

export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS'
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL'

export const GET_SINGLE_BLOG_SUCCESS = 'GET_SINGLE_BLOG_SUCCESS'
export const GET_SINGLE_BLOG_FAIL = 'GET_SINGLE_BLOG_FAIL'


export const EDIT_BLOG_SUCCESS = 'EDIT_BLOG_SUCCESS'
export const EDIT_BLOG_FAIL = 'EDIT_BLOG_FAIL'

export const DEL_BLOG_SUCCESS = 'DEL_BLOG_SUCCESS'
export const DEL_BLOG_FAIL = 'DEL_BLOG_FAIL'