import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from '../../../components/Widgets/CustomFormInput';
import { Avatar, Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const DrProfile = () => {

    const schema = object().shape({

    });
    const { profiledata } = useSelector(state => state?.profile);
    console.log({profiledata});
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        setValue("name", profiledata?.name)

        setValue("mobile", profiledata?.mobile)
        setValue("email", profiledata?.email)
        setValue("designation", profiledata?.designation)
        setValue("doctor_id", profiledata?.doctor_id)
        setValue("department", profiledata?.department_name?.charAt(0).toUpperCase() + profiledata?.department_name?.slice(1))

    },)
    const [Image, setImage] = useState(null)
    return (
        <>
            <Box mt={10} padding={5} borderRadius={5}>

                <Box display={"flex"} flex={1} flexDirection="column ">
                    {/* full grid */}
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Avatar src={profiledata?.image ? profiledata?.image : ''} style={{ width: "200px", height: "200px", objectFit: "contain" }}>
                                </Avatar>
                                {/* <Box display={"flex"} position={"absolute"} zIndex={"100"} justifyContent={"center"} bottom={"0px"} width={"200px"} >
                                    <IconButton color="primary" aria-label="upload picture" component="label" className='iconbutton'>
                                        <input hidden name="myImage" type="file" onChange={(e) => setImage(e.target.files[0])} />
                                        <PhotoCamera className='photoicon' />
                                    </IconButton>

                                </Box> */}
                            </Box >
                            {/* <Stack justifyContent={'center'} mt={3} gap={2}>
                                    {/* <Typography>Doctor ID:{profiledata?._id}</Typography> */}
                            {/* <Typography>Department:{profiledata?.department_name.charAt(0).toUpperCase() + profiledata?.department_name.slice(1)}</Typography> */}
                            {/* </Stack> */}
                        </Grid >
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                            <Grid container spacing={1}>
                                {/* <Grid item xs container direction="column" spacing={2} backgroundColor="brown"> */}
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <CustomFormInput
                                        fieldLabel="Name"
                                        control={control}
                                        error={errors.name}
                                        fieldName="name"
                                        readOnly={true}

                                        defaultValue={profiledata?.name}
                                        // placeholder={profiledata?.name}
                                        height="40px"
                                        background="#FFFFFF"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <CustomFormInput
                                        fieldLabel="Contact Number "
                                        control={control}
                                        error={errors.mobile}
                                        readOnly={true}
                                        fieldName="mobile"
                                        type={"number"}

                                        height="40px"
                                        background="#FFFFFF"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <CustomFormInput
                                        fieldLabel="Email Address"
                                        control={control}
                                        error={errors.email}
                                        fieldName="email"

                                        height="40px"
                                        readOnly={true}
                                        background="#FFFFFF"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <CustomFormInput
                                        fieldLabel="Designation"
                                        control={control}
                                        error={errors.designation}
                                        readOnly={true}
                                        fieldName="designation"

                                        height="40px"
                                        background="#FFFFFF"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <CustomFormInput
                                        fieldLabel="DoctorID"
                                        control={control}
                                        error={errors.doctor_id}
                                        readOnly={true}
                                        fieldName="doctor_id"

                                        height="40px"
                                        background="#FFFFFF"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <CustomFormInput
                                        fieldLabel="Department"
                                        control={control}
                                        error={errors.department}
                                        readOnly={true}
                                        fieldName="department"

                                        height="40px"
                                        background="#FFFFFF"
                                    />
                                </Grid>
                                {/* </Grid> */}
                            </Grid>

                        </Grid>
                    </Grid >
                </Box >
            </Box >
        </>
    )
}

export default DrProfile