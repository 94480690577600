import { isNull } from "lodash"
import axios from "../../customAxios"
import { FILTER_PROCEDURE_SUCCESS } from "../constants/procedureConstant"
import { CANCEL_SERVICE_BOOKING_FAIL, CANCEL_SERVICE_BOOKING_SUCCESS, CREATE_SERVICE_BOOKING_FAIL, CREATE_SERVICE_BOOKING_SUCCESS, CREATE_SERVICE_LISTING_FAIL, CREATE_SERVICE_LISTING_SUCCESS, CREATE_SERVICE_SEARCH_BOOKING_FAIL, CREATE_SERVICE_SEARCH_BOOKING_SUCCESS, CREATE_SERVICE_TIME_FAIL, CREATE_SERVICE_TIME_SUCCESS, EDIT_SERVICE_BOOKING_FAIL, EDIT_SERVICE_BOOKING_SUCCESS, EDIT_SERVICE_LISTING_FAIL, EDIT_SERVICE_LISTING_SUCCESS, FILTER_SERVICE_BOOKING_FAIL, FILTER_SERVICE_BOOKING_SUCCESS, FILTER_SERVICE_FAIL, FILTER_SERVICE_SUCCESS, GET_BOOKING_LIST_FAIL, GET_BOOKING_LIST_SUCCESS, GET_BOOKING_VIEW_FAIL, GET_BOOKING_VIEW_SUCCESS, GET_SERVICE_BY_DEPT_SERVICE_FAIL, GET_SERVICE_BY_DEPT_SERVICE_SUCCESS, GET_SERVICE_LISTING_FAIL, GET_SERVICE_LISTING_SUCCESS, GET_SINGLE_LIST_FAIL, GET_SINGLE_LIST_SUCCESS, POST_REFERAL_BOOKING_FAIL, POST_REFERAL_BOOKING_SUCCESS, REFERAL_SERVICE_FAIL, REFERAL_SERVICE_SUCCESS, RESHEDULE_SERVICE_BOOKING_FAIL, RESHEDULE_SERVICE_BOOKING_SUCCESS, SERVICE_REPORT_FAIL, SERVICE_REPORT_SUCCESS, UPDATE_SERVICE_STATUS_FAIL, UPDATE_SERVICE_STATUS_SUCCESS } from "../constants/serviceConstant"


import {
    LOADING
} from "../constants/userConstants"
import moment from "moment"

export const createServiceList = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/create`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_SERVICE_LISTING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: CREATE_SERVICE_LISTING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getServiceList = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/service`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SERVICE_LISTING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SERVICE_LISTING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getSingleListView = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/service/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SINGLE_LIST_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SINGLE_LIST_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}



export const editSingleListView = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/edit`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_SERVICE_LISTING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: EDIT_SERVICE_LISTING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

//******************************SURVICE BOOKING*********************************** */

export const PostPatientSearch = (data) => async (dispatch) => {
    // dispatch({
    //     type: LOADING,
    //     payload: true
    // })
    await axios.post(`/admin/search-patients-booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_SERVICE_SEARCH_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: CREATE_SERVICE_SEARCH_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getServiceByDeptService = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/search-booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SERVICE_BY_DEPT_SERVICE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_SERVICE_BY_DEPT_SERVICE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const createServiceBooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_SERVICE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: CREATE_SERVICE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const postServiceTime = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/service-time`, data)
        .then(async response => {
            let data = response.data.data?.time;
            let procedureAvailableTimes = [];

            let date = moment(response?.data?.data?.date, "YYYY-MM-DD");
            console.log(date);
            console.log(data);
            if (moment().isSame(date, 'day')) {
                data?.map(time => {
                    if (moment() < moment(time?.time, "hh:mm A") && isNull(time?.patient_id)) {
                        procedureAvailableTimes.push(time)
                    }
                })
            }
            else {
                data?.map(time => {
                    if (isNull(time?.patient_id)) {
                        procedureAvailableTimes.push(time)
                    }
                })
            }
            let datas = {
                ...response.data.data,
                time: procedureAvailableTimes
            }

            dispatch({
                type: CREATE_SERVICE_TIME_SUCCESS,
                payload: datas
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: CREATE_SERVICE_TIME_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getBookingList = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/service/booking/list`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_BOOKING_LIST_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_BOOKING_LIST_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getBookingListView = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/service/booking/show/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_BOOKING_VIEW_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: GET_BOOKING_VIEW_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const Editbooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/schedule`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_SERVICE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: EDIT_SERVICE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const Cancelbooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/booking/cancel`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CANCEL_SERVICE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CANCEL_SERVICE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const Reshedulebooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/booking/reschedule`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: RESHEDULE_SERVICE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: RESHEDULE_SERVICE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const updateServiceStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/service/status`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch(getServiceList())
            dispatch({
                type: UPDATE_SERVICE_STATUS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_SERVICE_STATUS_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}




export const filterServiceBooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/service/booking/filter`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: FILTER_SERVICE_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: FILTER_SERVICE_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const filterServiceListing = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/procedure/filter`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: FILTER_SERVICE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: FILTER_SERVICE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

//procedure booking by selected procedure and pdf..........................

export const postServiceReport = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/service/addservicereport`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: SERVICE_REPORT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: SERVICE_REPORT_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

//****************************************REFERAL*********************************************** */
export const getReferal = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/service/referrel-list/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: REFERAL_SERVICE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: REFERAL_SERVICE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
//****************************************REFERAL*********************************************** */
export const postReferalbooking = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/service/booking`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: POST_REFERAL_BOOKING_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: POST_REFERAL_BOOKING_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
