import React from 'react'

const NotFound = () => {
  return (
    <div>
        <h4>Error</h4>
      <p>Page Not Found! or You are not authorized to view contents of this page</p>
    </div>
  )
}

export default NotFound
