import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import CustomSearch from "../../components/Widgets/CustomSearch";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import data from "../../components/common/data";
import DataTables from "../../components/Widgets/dataTable";
import { Paper, getLinearProgressUtilityClass } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import DepartmentAdd from "./DepartmentAdd";
import { useDispatch, useSelector } from "react-redux";
import {
    filterDepartment,
    getDepartmenDetailPage,
    getDepartmentList,
    getDeptCategory,
    updateDepartment
} from "../../Redux/actions/departmentAction";
import CustomSwitch from "../../components/Widgets/CustomSwitch/CustomSwitch";
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import DeptDetailedPage from "./DeptDetailedPage";
import moment from "moment";
import CustomFilter from "../../components/Widgets/CustomFilter";
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext } from "react";
import RoleContext from "../../helpers/Role";
const Department = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { CategoryList, deptList, deptCreated, error, deptSingleList, DeptEdited } = useSelector(state => state.department)
    const context = useContext(RoleContext)

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const [open, setOpen] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [Active, setActive] = useState(null);
    const [variants, setvariants] = useState("contained")
    const [DeptEdit, setDeptEdit] = useState(null)
    const [title, settitle] = useState(null)
    const [switchChecked, setSwitchChecked] = useState(null);
    const [datas, setDatas] = useState([])
    const [valuefrom, setvaluefrom] = useState("")
    const [valueto, setvalueto] = useState("")
    const [openFilter, setOpenFilter] = useState(false);
    const [cat_id, setCat_id] = useState(null)
    const [openDialog, setOpenDialog] = useState(false);
    const [checked, setChecked] = useState(null)
    const [row, setRow] = useState(null)
    const [filterKey, setfilterKey] = useState(null)
    const user = JSON.parse(localStorage.getItem("user"))


    const handleOpenFilter = () => {
        setOpenFilter(true);
    };
    const departmentsEntry = context?.role?.find(item => item?.title === 'Departments');
    let departmentsCreateSelected = false;
    let departmentsedit = false;
    let departmentsview = false
    if (user?.role === "admin") {
        const departmentsCreateSelected = true;
        const departmentsedit = true;
        const departmentsview = true
    }
    else {
        departmentsCreateSelected = departmentsEntry?.create?.selected;
        departmentsedit = departmentsEntry?.edit?.selected;
        departmentsview = departmentsEntry?.view?.selected
    }

    const handleCloseFilter = () => {
        if (valuefrom && valueto) {
            if (CategoryList) {
                let find = CategoryList.find((a) => a?.name === "Doctors");
                dispatch(getDepartmentList(Active === null ? find?._id : Active))
            }
        }
        setvaluefrom("")
        setvalueto("")
        setOpenFilter(false);
    };

    const Submitfilter = () => {
        let val = {
            category_id: Active === null ? cat_id : Active,
            from_date: moment(valuefrom).format("DD-MM-YYYY"),
            to_date: moment(valueto).format("DD-MM-YYYY")
        }
        if (valuefrom && valueto) {
            dispatch(filterDepartment(val))
            setOpenFilter(false);
        } else {
            toast.warning(`from Date and to Date is required`);
        }
    }


    useEffect(() => {
        if (deptList) {
            setDatas(deptList)
        }
    }, [deptList])



    const handleOpen = () => {
        setOpen(true);
    };
    const clearFilter = () => {
        setvaluefrom("");
        setvalueto("");
        setfilterKey(prevKey => prevKey + 1);
    };

    const handleClose = () => {
        setDeptEdit(null)
        setOpen(false);
    };


    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialoge = (mode) => {
        if (mode === 'yes') {
            const formData = new FormData();
            formData.append('id', row?._id);
            formData.append('status', checked === true ? "active" : "inactive");
            formData.append('name', row?.name);
            formData.append('category_id', row?.category_id);
            formData.append('description', row?.description === "null" ? "" : row?.description)
            dispatch(updateDepartment(formData))

        };
        setOpenDialog(false);
    }


    const handleOpenView = () => {
        setOpenView(true);
    };

    const handleCloseView = () => {
        setOpenView(false);
    };

    const AddDepartment = () => {
        settitle("ADD DEPARTMENT")
        setOpen(true)
    }

    const DetailpageView = (id) => {
        dispatch(getDepartmenDetailPage(id))
        handleOpenView()
    }

    const EditDepartment = (data) => {
        setDeptEdit(data)
        settitle("EDIT DEPARTMENT")
        handleOpen()
    }

    const ChangeStatus = (checked, row) => {
        setSwitchChecked(checked)
        setRow(row)
        setChecked(checked)
        handleClickOpen()
    }



    var columns = [
        {
            name: <b>{Capitalize("DEPARTMENT ID")}</b>,
            header: <b>{Capitalize("DEPARTMENT ID")}</b>,
            selector: (row) => row?.department_id,
            style: {
                textAlign: "center",

            },
        },
        {
            name: <b>{Capitalize("CREATED DATE & TIME")}</b>,
            header: <b>{Capitalize("CREATED DATE & TIME")}</b>,
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY hh:mm A"),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("DEPARTMENT NAME")}</b>,
            header: <b>{Capitalize("DEPARTMENT NAME")}</b>,
            selector: (row) => row?.name,
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>ACTIONS</b>,
            cell: (row, index) => (
                <>
                    <Stack spacing={2} direction="row">
                        {user?.role === 'admin' ? (
                            <RemoveRedEyeIcon
                                onClick={() => DetailpageView(row?._id)}
                                color="blue"
                                style={{
                                    cursor: "pointer",
                                    color: "#047ac1",
                                }}
                            />
                        ) : (
                            departmentsview && (
                                <RemoveRedEyeIcon
                                    onClick={() => DetailpageView(row?._id)}
                                    color="blue"
                                    style={{
                                        cursor: "pointer",
                                        color: "#047ac1",
                                    }}
                                />
                            )
                        )}

                        {user?.role === 'admin' ? (
                            <>
                                {row?.status === 'active' && ( // Check if row status is active
                                    <BorderColorSharpIcon
                                        style={{ color: "#047ac1", cursor: "pointer" }}
                                        onClick={() => EditDepartment(row)}
                                    />
                                )}
                            </>
                        ) : (
                            departmentsedit && (
                                <>
                                    {row?.status === 'active' && ( // Check if row status is active
                                        <BorderColorSharpIcon
                                            style={{ color: "#047ac1", cursor: "pointer" }}
                                            onClick={() => EditDepartment(row)}
                                        />
                                    )}
                                </>
                            )
                        )}
                    </Stack>
                </>
            ),
        },
        {
            name: <b>STATUS</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (
                <>
                    <Stack spacing={1} direction="row">
                        <CustomSwitch
                            checked={row?.status === "active" ? true : false}
                            onClick={(e) => ChangeStatus(e.target.checked, row)}
                        />
                    </Stack>
                </>
            ),
        },
    ];

    const submitDeptButton = (id) => {
        setActive(id);
        dispatch(getDepartmentList(id))
    }

    useEffect(() => {
        if (deptCreated) {
            toast.success("Department Created Successfully");
            dispatch({
                type: RESET_ERROR
            })

            handleClose()

        }
        if (DeptEdited) {
            {
                switchChecked === null ?
                    toast.success("Department updated Successfully")
                    : toast.success("Department Status Changed")
            }
            dispatch({
                type: RESET_ERROR
            })
            setSwitchChecked(null)
            handleClose()

        }
        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })

        }

    }, [deptCreated, error, DeptEdited])



    useEffect(() => {
        dispatch(getDeptCategory())
    }, [])


    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList?.find((a) => a?.name === "Doctors");
            setCat_id(find?._id)
            setActive(find?._id);
            dispatch(getDepartmentList(find?._id))
        }
    }, [CategoryList])

    const searchDepartments = (value) => {
        let datas = deptList?.filter(dept => dept?.department_id?.toLowerCase().includes(value.toLowerCase()) || dept?.name?.toLowerCase().includes(value.toLowerCase()))
        setDatas(datas)
    }



    return (
        <>
            <Box
                sx={{
                    width: "99%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "10px", xs: "10px" },
                    marginBottom: "40px",
                }}
            >
                <Stack spacing={2} direction="row">
                    {CategoryList?.map((cat) => (
                        <CustomButton active={Active === cat?._id ? true : Active ? false : CategoryList[0]?._id === cat?._id} variant={variants} text={cat?.name} uid={Active} submit={() =>
                            submitDeptButton(cat?._id)} />
                    ))}
                </Stack>

                <Stack spacing={2.6} direction="row" style={{
                    background: "#d8dee1",
                    padding: "3px 3px 3px .1px",
                    display: "flex",
                    alignItems: "center",

                }} borderRadius={2}>
                    <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={(value) => searchDepartments(value)} />
                    <CustomButton
                        variant={"contained"}
                        text={"Filter"}
                        active={openFilter ? true : false}
                        Icon={FilterListIcon}
                        submit={() => setOpenFilter(!openFilter)}
                    />
                    {(departmentsCreateSelected || user?.role === "admin") ? <CustomButton
                        ComponentIcon={AddIcon}
                        variant={"contained"}
                        submit={() => AddDepartment()}
                    /> : ""}

                </Stack>
            </Box>
            {openFilter && <CustomFilter
                key={filterKey}
                handlechangefrom={(value) => setvaluefrom(value)}
                valuefrom={valuefrom}
                valueto={valueto}
                handlechangeto={(value) => setvalueto(value)}
                submit={Submitfilter}
                //clear={handleCloseFilter}
                clear={clearFilter}
            />}
            <Paper>
                <Fragment>
                    <DataTables
                        columns={columns}
                        row={datas}
                        pagination={true} />
                </Fragment>
            </Paper>
            <Box
                sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}
            >
                {open && <DepartmentAdd handleClose={handleClose} handleOpen={open} CategoryList={CategoryList}
                    DeptEditdata={DeptEdit} title={title} active={Active} />}
                <DeptDetailedPage handleClose={handleCloseView} handleOpen={openView} data={deptSingleList} />

            </Box>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialoge}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Are you sure ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialoge}>No</Button>
                    <Button onClick={() => handleCloseDialoge('yes')} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Department