import { Box, Pagination, Paper } from '@mui/material'
import React, { useState, useEffect, Fragment } from 'react'
import Stack from "@mui/material/Stack";
import CustomButton from "../../components/Widgets/CustomButton/CustomButton";
import CustomSearch from "../../components/Widgets/CustomSearch";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddUserModal from './addUserModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import data from '../../components/common/data';
import DataTables from '../../components/Widgets/dataTable';
import CustomSwitch from '../../components/Widgets/CustomSwitch/CustomSwitch';
import DeleteIcon from '@mui/icons-material/Delete';
import { delUser, filterUser, getUser, statusChangeUser } from '../../Redux/actions/userActions';
import moment from "moment";
import CustomFilter from '../../components/Widgets/CustomFilter';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext } from "react";
import RoleContext from "../../helpers/Role";
const Users = () => {
    const dispatch = useDispatch()
    const { userCreated, error, userList, userDeleted, userEdited, statusChnage } = useSelector(state => state.user);
    const user = JSON.parse(localStorage.getItem("user"))
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState(null)
    const [datas, setDatas] = useState([])
    const [valuefrom, setvaluefrom] = useState("")
    const [valueto, setvalueto] = useState("")
    const [openFilter, setOpenFilter] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [checked, setChecked] = useState(null)
    const [row, setRow] = useState(null)

    const context = useContext(RoleContext)


    const departmentsEntry = context?.role?.find(item => item?.title === 'Users');
    let departmentsedit = false;
    let usercreate = false;

    if (user?.role === "admin") {
        let departmentsedit = true;
        let usercreate = true;

    }
    else {
        departmentsedit = departmentsEntry?.edit?.selected;
        usercreate = departmentsEntry?.create?.selected;

    }


    // const departmentsEntry = context?.role?.find(item => item.title === 'Users');
    // const departmentsedit = departmentsEntry?.edit?.selected;
    // const usercreate = departmentsEntry?.create?.selected;

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };
    const [filterKey, setFilterKey] = useState(0);


    const clearFilter = () => {
        setvaluefrom("");
        setvalueto("");
        setFilterKey(prevKey => prevKey + 1);
    };

    const handleCloseFilter = () => {
        clearFilter();
        setOpenFilter(false);
    };

    const Submitfilter = () => {
        let val = {
            from_date: moment(valuefrom).format("DD-MM-YYYY"),
            to_date: moment(valueto).format("DD-MM-YYYY")
        }
        if (valuefrom && valueto) {
            dispatch(filterUser(val))
            setOpenFilter(false);
        } else {
            toast.warning(`from Date and to Date is required`);
        }
    }


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setUserData(null)
    };

    useEffect(() => {
        if (userList) {
            setDatas(userList)
        }
    }, [userList])


    const searchUser = (value) => {
        let datas = userList?.filter(user => user.user_id.toString().toLowerCase().includes(value?.toLowerCase()) || user?.mobile?.toString().toLowerCase().includes(value?.toLowerCase()) || user?.name.toLowerCase().includes(value?.toLowerCase()))

        setDatas(datas)
    }


    // const handleDelete = (id) => {
    //     if (window.confirm("Are you sure you wish to delete this item?")) {
    //         dispatch(delUser(id))
    //     }
    // }

    const onClickEdit = (row) => {
        setUserData(row)
        setOpen(true);
    }


    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    var columns = [
        {
            name: <b>{Capitalize("USER ID")}</b>,
            header: <b>{Capitalize("USER ID")}</b>,
            selector: (row) => row?.user_id,
            style: {
                textAlign: "center",

            },
        },
        {
            name: <b>{Capitalize("NAME")}</b>,
            header: <b>{Capitalize("NAME")}</b>,
            selector: (row) => row?.name,

            style: {
                textAlign: "center",

            },
        },

        {
            name: <b>{Capitalize("DESIGNATION")}</b>,
            header: <b>{Capitalize("DESIGNATION")}</b>,
            selector: (row) => row?.designation,
            style: {
                textAlign: "center",

            },
        },
        {
            name: <b>{Capitalize("MOBILE NUMBER")}</b>,
            header: <b>{Capitalize("MOBILE NUMBER")}</b>,
            selector: (row) => row?.mobile,
            style: {
                textAlign: "center",

            },
        },
        ,
        {
            name: <b>{Capitalize("EMAIL ADDRESS")}</b>,
            header: <b>{Capitalize("EMAIL ADDRESS")}</b>,
            selector: (row) => row?.email,
            style: {
                textAlign: "center",

            },
        },
        {
            name: <b>{Capitalize("CREATED ON")}</b>,
            header: <b>{Capitalize("CREATED ON")}</b>,
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("ROLE")}</b>,
            header: <b>{Capitalize("ROLE")}</b>,
            selector: (row) => row?.role,
            style: {
                textAlign: "center",

            },
        },
        {
            name: <b>Actions</b>,
            id: "del",
            accessor: (str) => "del",
            cell: (row, index) => (
                <>
                    <Stack spacing={2} direction="row">
                        {user?.role === 'admin' ? (
                            <>
                                {row.status === 'active' && ( // Check if row status is active
                                    <BorderColorSharpIcon
                                        style={{ color: "#047ac1", cursor: "pointer" }}
                                        onClick={() => onClickEdit(row)}
                                    />
                                )}
                            </>
                        ) : (
                            departmentsedit && (
                                <>
                                    {row.status === 'active' && ( // Check if row status is active
                                        <BorderColorSharpIcon
                                            style={{ color: "#047ac1", cursor: "pointer" }}
                                            onClick={() => onClickEdit(row)}
                                        />
                                    )}
                                </>
                            )
                        )}

                    </Stack>
                </>
            ),
        },
        {
            name: <b>Status</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (
                <>
                    <Stack spacing={1} direction="row">
                        <CustomSwitch checked={row?.status === "active" ? true : false}
                            onClick={(e) => ChangeStatus(e.target.checked, row)}
                        />
                    </Stack>
                </>
            ),
        },
    ];





    useEffect(() => {
        if (userDeleted) {
            toast.success("User Deleted successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
        }
        if (userCreated) {
            toast.success("User Created successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
        }
        if (userEdited) {
            toast.success("User Edited successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
        }

        if (statusChnage) {
            toast.success("Status Changed successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [userCreated, error, userDeleted, userEdited, statusChnage])


    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialoge = (mode) => {
        if (mode === 'yes') {
            let val = {
                id: row._id,
                name: row?.name,
                designation: row?.designation,
                mobile: row?.mobile,
                email: row?.email,
                role: row?.role,
                status: checked === true ? "active" : "inactive"
            }
            dispatch(statusChangeUser(val))
        };
        setOpenDialog(false);
    }



    const ChangeStatus = (checked, row) => {

        setRow(row)
        setChecked(checked)
        handleClickOpen()


    }

    useEffect(() => {
        dispatch(getUser())
    }, [])

    return (
        <>
            <Box
                sx={{
                    width: "99%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "10px", xs: "10px" },
                    marginBottom: "40px",
                }}
            >
                <Stack spacing={2.6} direction="row" style={{
                    background: "#d8dee1",
                    padding: "3px 3px 3px .1px",
                    display: "flex",
                    alignItems: "center",

                }} borderRadius={2}>
                    <CustomSearch Icon={SearchIcon} variant={"contained"} onSearch={searchUser} />
                    <CustomButton
                        variant={"contained"}
                        text={"Filter"}
                        active={openFilter ? true : false}
                        Icon={FilterListIcon}
                        submit={() => setOpenFilter(!openFilter)}
                    />
                    {(usercreate || user?.role === "admin") ? (
                        <CustomButton
                            submit={handleOpen}
                            ComponentIcon={AddIcon}
                            variant={"contained"}
                        />
                    ) : null}
                </Stack>
            </Box>
            <Box>
                {openFilter && <CustomFilter
                    key={filterKey}
                    handlechangefrom={(value) => setvaluefrom(value)}
                    valuefrom={valuefrom}
                    valueto={valueto}
                    handlechangeto={(value) => setvalueto(value)}
                    submit={Submitfilter}
                    //clear={handleCloseFilter}
                    clear={clearFilter}
                />}
                <Paper>
                    <Fragment>
                        <DataTables
                            columns={columns}
                            row={datas}
                            pagination
                        />
                    </Fragment>
                </Paper>
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialoge}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Are you sure ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialoge}>No</Button>
                    <Button onClick={() => handleCloseDialoge('yes')} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {open && <AddUserModal handleClose={() => {
                handleClose()
                setUserData(null)
            }} handleOpen={open} userData={userData} />}
        </>
    )
}

export default Users