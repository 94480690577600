import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import Grid from '@mui/material/Grid';
import CustomTimePicker from '../../../components/Widgets/CustomTimePicker/CustomTimePicker';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomFormInput from '../../../components/Widgets/CustomFormInput';
import { Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomIconButton from '../../../components/Widgets/CustomIconButton';
import { useParams } from 'react-router-dom';
import customAxios from '../../../customAxios';
import { toast } from 'react-toastify';
function DrTimeManagement({ setActive, Resdata }) {
  let { id } = useParams();
  const [DoctorDetails, setDoctorDetails] = useState(null)
  const { profiledata } = useSelector(state => state?.profile);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()

  const [dates, setDates] = useState({
    monday: [
      { from: null, to: null },
    ],
    tuesday: [
      { from: null, to: null }
    ],
    wednesday: [
      { from: null, to: null }
    ],
    thursday: [
      { from: null, to: null }
    ],
    friday: [
      { from: null, to: null }
    ],
    saturday: [
      { from: null, to: null }
    ],
    sunday: [
      { from: null, to: null }
    ]
  })
  const { cosultationTime } = useSelector(state => state?.doctState);


  const schema = object().shape({
    interval: yup.string().required("Interval field is required"),
  });


  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      interval: cosultationTime?.interval
    }
  });
  useEffect(() => {
    if (cosultationTime?.time) (
      setDates(cosultationTime?.time)
    )
  }, [cosultationTime])

  useEffect(() => {
    if (Resdata?.dateObject) {
      setDates(JSON.parse(Resdata?.dateObject))
      setValue("interval", Resdata?.interval)
    }

  }, [Resdata])

  
  useEffect(() => {
    async function getTimeManagement() {
      try {
        if (profiledata?.user_id) {
          let res = await customAxios.get(`/doctor/get-doctor/${profiledata?.user_id}`);
          if (res?.status === 201) {
            let data = res?.data?.data;
            setDoctorDetails(data);
            setDates(JSON.parse(data?.dateObject));
            setValue("interval", data?.interval);
          } else {
            throw new Error("Network error");
          }
        }
      } catch (error) {
        toast.error(error?.message || error);
      }
    }
  
    getTimeManagement();
  }, [profiledata?.user_id]); 
  
  const submit = async (data) => {

    try {
      setLoading(true);
      let days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      let datess = [];
      days?.map(da => {
        dates[da]?.map(ti => {
          datess?.push({ day: da, from: ti.from ? moment(ti.from).format("HH:mm") : null, to: ti.to ? moment(ti.to).format("HH:mm") : null })
        })
      })

      let value = {
        interval: data?.interval,
        id: profiledata?._id,
        dateObject: JSON.stringify(dates),
        time: datess
      }
      const res = await customAxios.post("/doctor/addtimes", value);
      if (res?.status === 201) {
        toast.success("Updated successfully");
      } else {
        throw new Error("Failed to update");
      }

    } catch (error) {
      toast.error(error?.message || error)
    } finally {
      setLoading(false);
    }
  };


  const handleChange = (day, mode, index, value) => {
    dates[day][index][mode] = value;
    setDates({
      ...dates
    })

  }


  const handleAdd = (day) => {
    dates[day].push({ from: null, to: null })
    setDates({
      ...dates
    })
  }


  const handleRemove = (day, index) => {
    let filterdatas = dates[day].filter((da, i) => i !== index)
    dates[day] = filterdatas
    setDates({
      ...dates
    })
  }

  return (
    <>
      <Box mt={5}>
        <Box px={5} pt={2}>
          <Typography fontSize={24} fontFamily={'Quicksand'} color={'#000'} fontWeight={'bold'} letterSpacing={1}>Interval (Minutes)</Typography>
        </Box>
        <Grid container spacing={2} px={5}>
          <Grid item xs={12} md={2}>
            <CustomFormInput
              height="40px"
              fieldLabel=""
              control={control}
              error={errors.interval}
              fieldName="interval"
              type={"text"}
              background="#FFFFFF"
              defaultValue={cosultationTime?.interval}
            />

          </Grid>
        </Grid>
      </Box>
      <Box px={5} py={2} mt={2}>
        <Typography fontSize={24} fontFamily={'Quicksand'} color={'#000'} fontWeight={'bold'} letterSpacing={1}>Consultation Type</Typography>
      </Box>
      <Grid container spacing={3} px={5}>

        <Grid item xs={12} md={4}>
          <Typography>{"Monday"}</Typography>
          {dates.monday.map((day, index) => (
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CustomTimePicker
                  // disable={disable}
                  Fieldlabel={"Monday"}
                  From={"From"}
                  To={"To"}
                  toValue={day?.to}
                  fromValue={day?.from}
                  onChanges={(value) => handleChange("monday", "from", index, value)}
                  onChangeTo={(value) => handleChange("monday", "to", index, value)}
                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('monday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("monday", index)} />}
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{"Tuesday"}</Typography>
          {dates?.tuesday?.map((day, index) => (
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CustomTimePicker
                  // disable={disable}
                  Fieldlabel={"Tuesday"}
                  From={"From"}
                  To={"To"}
                  toValue={day?.to}
                  fromValue={day?.from}
                  onChanges={(value) => handleChange("tuesday", "from", index, value)}
                  onChangeTo={(value) => handleChange("tuesday", "to", index, value)}

                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('tuesday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("tuesday", index)} />}
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{"Wednesday"}</Typography>
          {dates?.wednesday?.map((day, index) => (
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CustomTimePicker
                  // disable={disable}
                  Fieldlabel={"Wednesday"}
                  From={"From"}
                  To={"To"}
                  toValue={day?.to}
                  fromValue={day?.from}
                  onChanges={(value) => handleChange("wednesday", "from", index, value)}
                  onChangeTo={(value) => handleChange("wednesday", "to", index, value)}

                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('wednesday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("wednesday", index)} />}
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{"Thursday"}</Typography>
          {dates?.thursday?.map((day, index) => (
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CustomTimePicker
                  // disable={disable}
                  Fieldlabel={"Thursday"}
                  From={"From"}
                  To={"To"}
                  toValue={day?.to}
                  fromValue={day?.from}
                  onChanges={(value) => handleChange("thursday", "from", index, value)}
                  onChangeTo={(value) => handleChange("thursday", "to", index, value)}

                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('thursday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("thursday", index)} />}
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{"Friday"}</Typography>
          {dates?.friday?.map((day, index) => (
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CustomTimePicker
                  // disable={disable}
                  Fieldlabel={"Friday"}
                  From={"From"}
                  To={"To"}
                  toValue={day?.to}
                  fromValue={day?.from}
                  onChanges={(value) => handleChange("friday", "from", index, value)}
                  onChangeTo={(value) => handleChange("friday", "to", index, value)}

                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('friday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("friday", index)} />}
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{"Saturday"}</Typography>
          {dates?.saturday?.map((day, index) => (
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CustomTimePicker
                  // disable={disable}
                  Fieldlabel={"Saturday"}
                  From={"From"}
                  To={"To"}
                  toValue={day?.to}
                  fromValue={day?.from}
                  onChanges={(value) => handleChange("saturday", "from", index, value)}
                  onChangeTo={(value) => handleChange("saturday", "to", index, value)}

                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('saturday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("saturday", index)} />}
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{"Sunday"}</Typography>
          {dates?.sunday?.map((day, index) => (
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CustomTimePicker
                  // disable={disable}
                  Fieldlabel={"Sunday"}
                  From={"From"}
                  To={"To"}
                  toValue={day?.to}
                  fromValue={day?.from}
                  onChanges={(value) => handleChange("sunday", "from", index, value)}
                  onChangeTo={(value) => handleChange("sunday", "to", index, value)}

                />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {index === 0 ? <CustomIconButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleAdd('sunday')} /> : <CustomIconButton ComponentIcon={DeleteIcon} variant={"contained"} submit={() => handleRemove("sunday", index)} />}
              </Box>
            </Box>
          ))}
        </Grid>


      </Grid>
      {/* <Box py={5} display={'flex'} justifyContent={'center'}>
        <CustomButton variant={"contained"} text={"Update"} submit={handleSubmit(submit)} />
      </Box> */}
      <Box py={5} display={'flex'} justifyContent={'center'}>
        <CustomButton
          variant={"contained"}
          text={loading ? "Loading ..." : "Update"}
          disabled={loading ? true : false}
          submit={handleSubmit(submit)}
        />
      </Box>

    </>
  )
}

export default DrTimeManagement