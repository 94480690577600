import { CREATE_USER_FAIL, CREATE_USER_SUCCESS, DEL_USER_FAIL, DEL_USER_SUCCESS, EDIT_USER_FAIL, EDIT_USER_SUCCESS, FILTER_USER_FAIL, FILTER_USER_SUCCESS, GET_USER_FAIL, GET_USER_SUCCESS, RESET_ERROR, USER_STATUS_CHANGE_FAIL, USER_STATUS_CHANGE_SUCCESS } from "../constants/userConstants";


export const userReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                userCreated: null,
                error: null,
                userDeleted: null,
                userEdited: null,
                statusChnage: null
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                userList: action.payload
            }
        case GET_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_USER_SUCCESS:
            return {
                ...state,
                userList: state?.userList ? [...state?.userList, action.payload] : action.payload,
                userCreated: true,
            }

        case CREATE_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DEL_USER_SUCCESS:
            return {
                ...state,
                userList: state?.userList.filter(unit => unit._id !== action.payload),
                userDeleted: true
            }
        case DEL_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                userList: state?.userList.map(user => user._id === action.payload._id ? action.payload : user),
                userEdited: true
            }
        case EDIT_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case USER_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                userList: state?.userList.map(status => status._id === action.payload._id ? action.payload : status),
                statusChnage: true
            }
        case USER_STATUS_CHANGE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case FILTER_USER_SUCCESS:
            return{
                ...state,
                userList: action.payload

            }    
        case FILTER_USER_FAIL:
            return{
                ...state,
                error:action.payload
            }    


        default:
            return state;

    }

}