import React, { useState, useEffect } from 'react'
import { Box, Grid, Pagination, Paper, Stack, Typography } from '@mui/material'

import CustomButton from '../../components/Widgets/CustomButton/CustomButton'
import CustomSearch from '../../components/Widgets/CustomSearch'
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import data from '../../components/common/data';
import EditIcon from "@mui/icons-material/Edit";
import DataTables from '../../components/Widgets/dataTable';
import CustomSwitch from '../../components/Widgets/CustomSwitch/CustomSwitch';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Backbutton from '../../components/Widgets/Backbutton';
import { Container } from '@mui/system';
import CustomFormInput from '../../components/Widgets/CustomFormInput';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import CustomCheckBox from '../../components/Widgets/CustomCheckBox';
import { getSinglerole, saveNewRole, updateNewRole } from '../../Redux/actions/roleAction';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../Redux/constants/userConstants';

let roles = [
    {
        id: 0,
        title: 'Appointment',
        create: {
            paths: ['/', 'addAppointment'],
            selected: false
        },
        edit: {
            paths: ['editappointment/:id'],
            selected: false
        },
        view: {
            paths: ['viewAppointment/:id', '/'],
            selected: false
        },
        selected: false
    },
    {
        id: 1,
        title: 'Procedures',
        create: {
            paths: ['procedure', 'procedureList', 'procedureBooking', 'procedureBookingView/:id', 'ProcedureListView/:id'],
            selected: false
        },
        edit: {
            paths: ['procedureBooking/:id', 'procedureList/:id'],
            selected: false
        },
        view: {
            paths: ['procedure', 'procedureBookingView/:id', 'ProcedureListView/:id'],
            selected: false
        },
        selected: false
    },
    {
        id: 2,
        title: 'Services',
        create: {
            paths: ['service', 'addBooking', 'addListing'],
            selected: false
        },
        edit: {
            paths: ['editBooking/:id', 'editListing/:id'],
            selected: false
        },
        view: {
            paths: ['service', 'bookingView/:id', 'serviceViewListing/:id'],
            selected: false
        },
        selected: false
    },
    {
        id: 3,
        title: 'Departments',
        create: {
            paths: ['department'],
            selected: false
        },
        edit: {
            paths: ['department'],
            selected: false
        },
        view: {
            paths: ['department'],
            selected: false
        },
        selected: false
    },
    {
        id: 4,
        title: 'Doctors',
        create: {
            paths: ['doctor', 'AddDoctor'],
            selected: false
        },
        edit: {
            paths: ['editDoctor/:id'],
            selected: false
        },
        view: {
            paths: ['doctor', 'DoctorDetailPage/:id'],
            selected: false
        },
        selected: false
    },
    {
        id: 5,
        title: 'Patients',
        create: {
            paths: ['patients', 'Addpatients'],
            selected: false
        },
        edit: {
            paths: ['patients', 'EditPatient/:id'],
            selected: false
        },
        view: {
            paths: ['patients', 'PatientView/:id'],
            selected: false
        },
        selected: false
    },
    {
        id: 6,
        title: 'Users',
        create: {
            paths: ['user'],
            selected: false
        },
        edit: {
            paths: ['user'],
            selected: false
        },
        view: {
            paths: ['user'],
            selected: false
        },
        selected: false
    },
    // {
    //     id: 7,
    //     title: 'Settings',
    //     create: {
    //         paths: ['settings'],
    //         selected: false
    //     },
    //     edit: {
    //         paths: ['settings'],
    //         selected: false
    //     },
    //     view: {
    //         paths: ['settings'],
    //         selected: false
    //     },
    //     selected: false
    // },
    {
        id: 15,
        title: 'Blogs',
        create: {
            paths: ['blogs', 'addBlogs', 'viewBlog/:id'],
            selected: false
        },
        edit: {
            paths: ['blogs', 'viewBlog/:id', 'editBlogs/:id'],
            selected: false
        },
        view: {
            paths: ['blogs', 'viewBlog/:id'],
            selected: false
        },
        selected: false
    },
    {
        id: 8,
        title: 'Doctor OP Consultations',
        create: {
            paths: ['consultations'],
            selected: false
        },
        edit: {
            paths: ['consultations'],
            selected: false
        },
        view: {
            paths: ['consultations'],
            selected: false
        },
        selected: false
    },
    {
        id: 9,
        title: 'Doctor Online Consultations',
        create: {
            paths: ['docOnline', 'docPatientHistory', 'onlineVideo'],
            selected: false
        },
        edit: {
            paths: ['docOnline', 'docPatientHistory', 'onlineVideo'],
            selected: false
        },
        view: {
            paths: ['docOnline', 'docPatientHistory', 'onlineVideo'],
            selected: false
        },
        selected: false
    },
    {
        id: 10,
        title: 'Doctor Report Review Consultations',
        create: {
            paths: ['docReportReview'],
            selected: false
        },
        edit: {
            paths: ['docReportReview'],
            selected: false
        },
        view: {
            paths: ['docReportReview'],
            selected: false
        },
        selected: false
    },
    {
        id: 11,
        title: 'Doctor Time Management',
        create: {
            paths: ['docTimeManagement'],
            selected: false
        },
        edit: {
            paths: ['docTimeManagement'],
            selected: false
        },
        view: {
            paths: ['docTimeManagement'],
            selected: false
        },
        selected: false
    },
    {
        id: 12,
        title: 'Doctor Leave Management',
        create: {
            paths: ['docleavemanagement'],
            selected: false
        },
        edit: {
            paths: ['docleavemanagement'],
            selected: false
        },
        view: {
            paths: ['docleavemanagement'],
            selected: false
        },
        selected: false
    },
    {
        id: 13,
        title: 'Doctor Settings',
        create: {
            paths: ['docSettings'],
            selected: false
        },
        edit: {
            paths: ['docSettings'],
            selected: false
        },
        view: {
            paths: ['docSettings'],
            selected: false
        },
        selected: false
    },
    {
        id: 14,
        title: 'Doctor Blogs',
        create: {
            paths: ['docBlog'],
            selected: false
        },
        edit: {
            paths: ['docBlog'],
            selected: false
        },
        view: {
            paths: ['docBlog', 'docBlogview/:id'],
            selected: false
        },
        selected: false
    },
]



const EditRole = () => {

    const { id } = useParams()
    const [role, setRole] = useState(null)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { loading } = useSelector(state => state.auth)
    const { roleSuccess, error, roleList, roleEdited } = useSelector(state => state.role)
    const schema = object().shape({
        name: yup.string().required("Role name is required"),
    });


    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues: { role: "patient" },
        resolver: yupResolver(schema)
    });



    useEffect(() => {
        if (id) {
            let role = roleList?.find(ro => ro?._id === id)
            if (role) {
                setRole(role)
                setValue("name", role?.name)

                let newRole = roles?.map(ro => {
                    let findRole = role?.permissions?.find(fi => fi?.id === ro?.id)
                    ro.create.selected = findRole?.create?.selected
                    ro.edit.selected = findRole?.edit?.selected
                    ro.view.selected = findRole?.view?.selected
                    ro.selected = findRole?.selected
                    return ro
                })
                setRoleDetails(newRole)
            }

        }

    }, [id])



    useEffect(() => {
        if (roleSuccess) {
            toast.success("Role Created Successfully")
            navigate(-1)
            dispatch({
                type: RESET_ERROR
            })
        }

        if (roleEdited) {
            toast.success("Role Edited Successfully")
            navigate(-1)
            dispatch({
                type: RESET_ERROR
            })

        }

        if (error) {
            toast.error(error)
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [roleSuccess, error, roleEdited])


    const [rolesDetails, setRoleDetails] = useState(roles)


    const saveRole = (data) => {




        if (id) {
            let datas = {
                id: id,
                permissions: rolesDetails,
            }
            dispatch(updateNewRole(datas))


        } else {
            let datas = {
                name: data.name,
                permissions: rolesDetails
            }

            dispatch(saveNewRole(datas))

        }

    }




    const updateSelection = (index, mode, checked) => {
        let roles = rolesDetails;
        if (mode === "create") {
            roles[index].create.selected = checked
            setRoleDetails([...rolesDetails])
            roles[index].selected = roles[index].create.selected && roles[index].edit.selected && roles[index].view.selected;
        }
        else if (mode === "edit") {
            roles[index].edit.selected = checked
            setRoleDetails([...rolesDetails])
            roles[index].selected = roles[index].create.selected && roles[index].edit.selected && roles[index].view.selected;
        }
        else if (mode === "view") {
            roles[index].view.selected = checked
            setRoleDetails([...rolesDetails])
            roles[index].selected = roles[index].create.selected && roles[index].edit.selected && roles[index].view.selected;
        }
        else if (mode === "all") {
            roles[index].create.selected = checked
            roles[index].edit.selected = checked
            roles[index].view.selected = checked
            roles[index].selected = checked
            setRoleDetails([...rolesDetails])
        }
    }



    return (
        <>
            <Box
                sx={{
                    width: "99%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: { lg: "row", md: "column", xs: "column" },
                    gap: { md: "10px", xs: "10px" },
                    marginBottom: "40px",
                }}
            >
                <Box >
                    <Stack
                        direction={"row"}
                        spacing={1}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <Backbutton />
                        <Typography
                            variant="h4"
                            component="h2"
                            style={{ fontSize: "25px", color: "#047AC3" }}
                        >Edit Roles
                        </Typography>
                    </Stack>
                </Box>
                {/* <Stack spacing={2.6} direction="row" style={{
                    background: "#d8dee1",
                    padding: "3px 3px 3px .1px",
                    display: "flex",
                    alignItems: "center",

                }} borderRadius={2}>
                    <CustomSearch Icon={SearchIcon} variant={"contained"} />
                    <CustomButton
                        variant={"contained"}
                        text={"Filter"}
                        Icon={FilterListIcon}
                    />
                    <CustomButton
                        submit={"NavigateToaddpage"}
                        ComponentIcon={AddIcon}
                        variant={"contained"}

                    />
                </Stack> */}

            </Box>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={5} md={3} >
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Role Name "
                            control={control}
                            defaultValues={role?.name}
                            error={errors.name}
                            fieldName="name"
                            type={"text"}
                            background="#FFFFFF"
                            boxshadow="5px 5px 10px #00000005"
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
                <Box paddingTop={"30px"}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Menu</TableCell>
                                    <TableCell align="right">Create</TableCell>
                                    <TableCell align="right">Edit</TableCell>
                                    <TableCell align="right">View</TableCell>
                                    <TableCell align="right">Full Control</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rolesDetails?.map((role, index) => (
                                    <TableRow
                                        key={`${index}${role?.id}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="column">
                                            {role?.title}
                                        </TableCell>
                                        <TableCell align="right"><CustomCheckBox changeRole={(e) => updateSelection(index, 'create', e.target.checked)} checked={role?.create?.selected} /></TableCell>
                                        <TableCell align="right"><CustomCheckBox changeRole={(e) => updateSelection(index, 'edit', e.target.checked)} checked={role?.edit?.selected} /></TableCell>
                                        <TableCell align="right"><CustomCheckBox changeRole={(e) => updateSelection(index, 'view', e.target.checked)} checked={role?.view?.selected} /></TableCell>
                                        <TableCell align="right">
                                            <Box display={"flex"} justifyContent={"flex-end"}>
                                                <CustomSwitch checked={role?.selected} changeRole={(e) => updateSelection(index, 'all', e.target.checked)} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", paddingTop: "70px", paddingBottom: "30px" }}>
                    <CustomButton variant={"contained"} text={"Submit"} submit={handleSubmit(saveRole)} />
                </Box>
            </Container>
        </>
    )
}

export default EditRole