import React, { useState, useEffect } from 'react'
import { Box, Container, Divider, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from "../../../components/Widgets/Backbutton";
import { Typography } from "@mui/material";
import CustomSelectInput from "../../../components/Widgets/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from "../../../components/Widgets/CustomFormInput";
import MenuItem from '@mui/material/MenuItem';
import CustomButton from '../../../components/Widgets/CustomButton/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import CustomImageUpload from '../../../components/Widgets/CustomImageUploade';
import { useDispatch, useSelector } from "react-redux";
import CustomTimePicker from '../../../components/Widgets/CustomTimePicker/CustomTimePicker';
import moment from 'moment';
import { RESET_ERROR } from '../../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'
import AddDepartment from '../../Service/components/AddDepartment';
import { getDepartmentListByStatus, getDeptCategory } from '../../../Redux/actions/departmentAction';
import AddDepartmentModal from '../AddDepartmentModal/AddDepartmentModal';
import { editProcedure, getSingleProcedure, postProcedure } from '../../../Redux/actions/procedureAction';
import CustomCheckBox from '../../../components/Widgets/CustomCheckBox';


const AddProcedureList = () => {
    const { id } = useParams();

    const dispatch = useDispatch()
    const naviget = useNavigate()

    const [images, setimages] = useState(null)
    const [open, setOpen] = useState(false);
    const [previewEdit, setPreviewEdit] = useState(null);
    const [selectDept, setSelectDept] = useState('')
    const [check, setCheck] = useState(true)
    const { CategoryList, deptListBySatatus, deptCreated } = useSelector(state => state.department);
    const { loading } = useSelector(state => state.auth)
    const { procedureCreated, error, procedureList, procedureSingleList, procedureEdited } = useSelector(state => state.procedure);



    //***********************DATE STATE**************************** */
    const [dates, setDates] = useState({
        monday: {
            from: null,
            to: null
        },
        tuesday: {
            from: null,
            to: null
        },
        wednesday: {
            from: null,
            to: null
        },
        thursday: {
            from: null,
            to: null
        },
        friday: {
            from: null,
            to: null
        },
        saturday: {
            from: null,
            to: null
        },
        sunday: {
            from: null,
            to: null
        }
    })

    const [dayTime, setdayTime] = useState([])

    console.log(error);
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const schema = object().shape({
        description: yup.string().max(100).required("Description is required"),
        name: yup.string().max(50).required("Name is required"),
        price: yup.string().required('Price is required'),
        interval: yup.string().required('Interval is required'),
        department: yup.string().required('Department is required'),
        localImage: yup.boolean(),
        image: yup.mixed()
            .nullable()
            // .test('Fichier taille',
            //     'image resolution should be greater than 768 * 1000', (value) => !value || (value && value.size >= 768 * 1000))
            .when("localImage", {
                is: false,
                then: yup.mixed().required("Image Required")
            })
    });




    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            department: '',
            price: '',
            interval: '',
            description: '',
            localImage: false
        }
    });

    const setimagefile = (e) => {
        if (e.size > 500000) {
            toast.warning("Image Size Should be less than 500KB");
            setPreviewEdit(null)
            setimages(null)
        } else {
            setPreviewEdit(null)
            setimages(e)
        }

    }



    const handleCreateNewItem = (event) => {
        setSelectDept(event.target.value)
        setValue("department", event.target.value)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (mode, day, value) => {

        dates[day][mode] = value
        setDates({
            ...dates
        })

    }


    //grouping function....................................
    const groupCheck = (e) => {
        const { checked } = e.target;
        if (checked) {
            setCheck(true)
        } else {
            setCheck(false)
        }



    }


    const Submit = (data) => {
        let dateArray = [
            { day: 'monday', from: dates['monday'].from ? moment(dates['monday'].from).format("HH:mm") : null, to: dates['monday'].to ? moment(dates['monday'].to).format("HH:mm") : null },
            { day: 'tuesday', from: dates['tuesday'].from ? moment(dates['tuesday'].from).format("HH:mm") : null, to: dates['tuesday'].to ? moment(dates['tuesday'].to).format("HH:mm") : null },
            { day: 'wednesday', from: dates['wednesday'].from ? moment(dates['wednesday'].from).format("HH:mm") : null, to: dates['wednesday'].to ? moment(dates['wednesday'].to).format("HH:mm") : null },
            { day: 'thursday', from: dates['thursday'].from ? moment(dates['thursday'].from).format("HH:mm") : null, to: dates['thursday'].to ? moment(dates['thursday'].to).format("HH:mm") : null },
            { day: 'friday', from: dates['friday'].from ? moment(dates['friday'].from).format("HH:mm") : null, to: dates['friday'].to ? moment(dates['friday'].to).format("HH:mm") : null },
            { day: 'saturday', from: dates['saturday'].from ? moment(dates['saturday'].from).format("HH:mm") : null, to: dates['saturday'].to ? moment(dates['saturday'].to).format("HH:mm") : null },
            { day: 'sunday', from: dates['sunday'].from ? moment(dates['sunday'].from).format("HH:mm") : null, to: dates['sunday'].to ? moment(dates['sunday'].to).format("HH:mm") : null },
        ]
        let atLeastOneTime = Object.values(dates).some(date => date.from !== null && date.to !== null);

        if (!atLeastOneTime) {
            toast.error('Please provide at least one procedure time');
            return;
        }
        if (id) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('dateObject', JSON.stringify(dates));
            formData.append('date', JSON.stringify(dateArray));
            formData.append('status', procedureSingleList?.status);
            formData.append('name', data?.name);
            formData.append('department_id', data?.department);
            formData.append('price', data?.price);
            formData.append('interval', data?.interval);
            if (data?.image) {
                formData.append('image', data?.image);
            }
            formData.append('description', data?.description);
            formData.append('groupable', check);
            dispatch(editProcedure(formData))
        } else {
            const formData = new FormData();
            formData.append('name', data?.name);
            formData.append('dateObject', JSON.stringify(dates));
            formData.append('date', JSON.stringify(dateArray));
            formData.append('department_id', data?.department);
            formData.append('price', data?.price);
            formData.append('interval', data?.interval);
            formData.append('image', data?.image);
            formData.append('description', data?.description);
            formData.append('groupable', check);
            dispatch(postProcedure(formData))
        }

    }


    useEffect(() => {
        if (id) {
            if (procedureSingleList) {
                setValue("name", procedureSingleList?.name)
                setValue("department", procedureSingleList?.department_id)
                setValue("price", procedureSingleList?.price)
                setValue("description", procedureSingleList?.description)
                setValue("interval", procedureSingleList?.interval)
                setSelectDept(procedureSingleList?.department_id)
                setPreviewEdit(procedureSingleList?.image)
                setDates({
                    monday: {
                        from: procedureSingleList?.date?.monday?.from,
                        to: procedureSingleList?.date?.monday?.to
                    },
                    tuesday: {
                        from: procedureSingleList?.date?.tuesday?.from,
                        to: procedureSingleList?.date?.tuesday?.to
                    },
                    wednesday: {
                        from: procedureSingleList?.date?.wednesday?.from,
                        to: procedureSingleList?.date?.wednesday?.to
                    },
                    thursday: {
                        from: procedureSingleList?.date?.thursday?.from,
                        to: procedureSingleList?.date?.thursday?.to
                    },
                    friday: {
                        from: procedureSingleList?.date?.friday?.from,
                        to: procedureSingleList?.date?.friday?.to
                    },
                    saturday: {
                        from: procedureSingleList?.date?.saturday?.from,
                        to: procedureSingleList?.date?.saturday?.to
                    },
                    sunday: {
                        from: procedureSingleList?.date?.sunday?.from,
                        to: procedureSingleList?.date?.sunday?.to
                    }
                });
                setValue("localImage", procedureSingleList?.image ? true : false)
                setCheck(procedureSingleList?.groupable)

            }

        }
    }, [procedureSingleList])



    useEffect(() => {
        if (procedureCreated) {
            toast.success("Procedure List Created Successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
            naviget(-1)
        }
        if (deptCreated) {
            toast.success("Department Created Successfully");
            dispatch({
                type: RESET_ERROR
            })
            handleClose()

            // dispatch(getDepartmentListByStatus());
            const categoryIds = CategoryList.map(category => category._id);
            dispatch(getDepartmentListByStatus(categoryIds));

            //setOpen(false);
        }

        if (procedureEdited) {
            toast.success("Procedure Updated Successfully");
            dispatch({
                type: RESET_ERROR
            })
            naviget(-1)
        }


        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [procedureCreated, error, procedureEdited, deptCreated])

    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList.find((a) => a.name === "Procedures");
            if (find?._id) {
                dispatch(getDepartmentListByStatus(find._id))
            }
        }
    }, [CategoryList, deptCreated])



    useEffect(() => {
        if (id) {
            setValue("name", "")
            setValue("department", "")
            setValue("price", "")
            setValue("description", "")
            setValue("interval", "")
            setSelectDept("")
            setPreviewEdit("")
            setdayTime("")
        }
        dispatch(getDeptCategory())
        if (id) {

            dispatch(getSingleProcedure(id))
        }
    }, [])

    return (
        <>
            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        {!id ? "Add Procedure" : "Edit Procedure"}

                    </Typography>
                </Stack>
            </Box>


            <Grid container spacing={2} px={5}>
                <Grid item xs={12} md={4} lg={2.4} >
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Procedure Name"
                        control={control}
                        error={errors.name}
                        fieldName="name"
                        type={"text"}
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2.4}>

                    <CustomSelectInput
                        control={control}
                        error={errors.department}
                        fieldName="department"
                        fieldLabel="Department"
                        background="#e7f5f7"
                        height="40px"
                        size="16px"
                        defaultValue={""}
                        value={selectDept}
                        onChangeValue={handleCreateNewItem}

                    >
                        <MenuItem value="" disabled >
                            <em>Select Department</em>
                        </MenuItem>
                        {deptListBySatatus?.map((status) => (
                            <MenuItem key={status?.name} value={status?._id} >
                                {status?.name}
                            </MenuItem>
                        ))}

                    </CustomSelectInput>
                </Grid>



                {!id && <Grid item xs={12} md={4} lg={2.4} display={'flex'} justifyContent={'center'}>
                    <Box style={{ paddingTop: "24px" }}>
                        <CustomButton ComponentIcon={AddIcon} variant={"contained"} submit={() => handleOpen()} />
                    </Box>
                </Grid>}
                <Grid item xs={12} md={4} lg={2.4}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Price"
                        control={control}
                        error={errors.price}
                        fieldName="price"
                        type={"number"}
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2.4}>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Interval (Minutes)"
                        control={control}
                        error={errors.interval}
                        fieldName="interval"
                        type={"number"}
                        background="#FFFFFF"
                    />
                </Grid>
                <Grid item xs={12} md={2}>

                    <Box display={'flex'} gap={1} alignItems={'center'} height={'100%'}>
                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>Groupable</Typography>
                        <CustomCheckBox changeRole={(e) => groupCheck(e)} checked={check ? true : false} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomFormInput
                        height="100px"
                        fieldLabel="Procedure Description"
                        control={control}
                        error={errors.description}
                        fieldName="description"
                        type={"text"}
                        background="#FFFFFF"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomImageUpload
                        fieldLabel='Procedure Image'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type={"file"}
                        height="150px"
                        background="#e7f5f7"
                        id="contained-button-file"
                        preview={images}
                        previewEditimage={previewEdit}
                        onChangeValue={(file) => {
                            setimagefile(file)
                            setValue("image", file)
                        }}
                    />
                </Grid>

            </Grid>

            <Box style={{ paddingTop: "30px", paddingBottom: "20px" }} px={2}>
                <Typography variant="h5" component="h2" fontFamily={"Quicksand"} fontWeight={"bold"}>Procedure Time</Typography>
                <Divider />
            </Box>

            <Grid container spacing={2} py={2} px={5}>
                <Grid item xs={12} md={4}>
                    <Typography>{"Monday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            From={"From"}
                            To={"To"}
                            toValue={dates["monday"]?.to}
                            fromValue={dates["monday"]?.from}
                            onChanges={(value) => handleChange("from", "monday", value)}
                            onChangeTo={(value) => handleChange("to", "monday", value)}

                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Tuesday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Tuesday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates["tuesday"]?.to}
                            fromValue={dates["tuesday"]?.from}
                            onChanges={(value) => handleChange("from", "tuesday", value)}
                            onChangeTo={(value) => handleChange("to", "tuesday", value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Wednesday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Wednesday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates["wednesday"]?.to}
                            fromValue={dates["wednesday"]?.from}
                            onChanges={(value) => handleChange("from", "wednesday", value)}
                            onChangeTo={(value) => handleChange("to", "wednesday", value)}
                        />
                    </Box>


                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Thursday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Thursday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates["thursday"]?.to}
                            fromValue={dates["thursday"]?.from}
                            onChanges={(value) => handleChange("from", "thursday", value)}
                            onChangeTo={(value) => handleChange("to", "thursday", value)}
                        />
                    </Box>

                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Friday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Friday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates["friday"]?.to}
                            fromValue={dates["friday"]?.from}
                            onChanges={(value) => handleChange("from", "friday", value)}
                            onChangeTo={(value) => handleChange("to", "friday", value)}
                        />
                    </Box>


                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Saturday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Saturday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates["saturday"]?.to}
                            fromValue={dates["saturday"]?.from}
                            onChanges={(value) => handleChange("from", "saturday", value)}
                            onChangeTo={(value) => handleChange("to", "saturday", value)}
                        />
                    </Box>


                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography>{"Sunday"}</Typography>
                    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <CustomTimePicker
                            //   disable={disable}
                            Fieldlabel={"Sunday"}
                            From={"From"}
                            To={"To"}
                            toValue={dates["sunday"]?.to}
                            fromValue={dates["sunday"]?.from}
                            onChanges={(value) => handleChange("from", "sunday", value)}
                            onChangeTo={(value) => handleChange("to", "sunday", value)}
                        />
                    </Box>
                </Grid>
            </Grid>


            <AddDepartmentModal handleClose={handleClose} handleOpen={open} CategoryList={CategoryList} mode={'Procedures'} />
            <Box style={{ paddingTop: "50px", display: "flex", justifyContent: "center", paddingBottom: "50px" }}>
                <CustomButton text={loading ? "Loading" : "Submit"} disabled={loading ? true : false} variant={"contained"} submit={handleSubmit(Submit)} />
            </Box>


        </>
    )
}

export default AddProcedureList