import styled from "styled-components";

export const DIV = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${(props) => props.img});
  display: flex;
  justify-content: flex-end;

`;
