import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Box, Container } from '@mui/system'
import CustomFormInput from '../../components/Widgets/CustomFormInput'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomImageUpload from '../../components/Widgets/CustomImageUploade';
import CustomSelectInput from '../../components/Widgets/CustomSelect';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentList, getDepartmentListByStatus, getDeptCategory } from '../../Redux/actions/departmentAction';
import MenuItem from '@mui/material/MenuItem';
import { Basic_Info } from '../../Redux/actions/AddDocter';
import { useLocation, useParams } from 'react-router-dom';
import { BASIC_DETAIL_SUCCESS } from '../../Redux/constants/AddDoctorConstant';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
const BasicDetails = ({ setActive, Active, Resdata }) => {
    let { id } = useParams();
    const dispatch = useDispatch()

    const { basicInfo } = useSelector(state => state.doctState);
    const { doctorSingleList } = useSelector(state => state.doct);
    const { CategoryList, deptListBySatatus } = useSelector(state => state.department);

    const [images, setimages] = useState(basicInfo?.image)
    const [selectDept, setSelectDept] = useState(basicInfo?.department ? basicInfo?.department : Resdata ? Resdata?.department : '')
    const [previewEdit, setPreviewEdit] = useState(null);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const schema = object().shape({
        designation: yup
            .string()
            .max(100)
            // .matches(/^[a-zA-Z0-9\s]*$/, "Designation should not contain special characters.")
            .required("Designation is required"),
        name: yup
            .string()
            .max(50, "Name must be less than 50 characters")
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, 'Name can only contain Latin letters.')
            .required('Name is Required'),
        department: yup.string().required("Department is Required"),
        specialization: yup
            .string()
            .required("Specialization is Required"),
        qualifications: yup
            .string()
            .required("Qualifications is Required"),
        expertise: yup
            .string()
            .required("Area of Expertise is Required"),
        email: yup.string().email().required("Email is Required"),
        mobile: yup.string()
            //.test('notStartWith0', 'Mobile number should not start with 0', value => !value.startsWith('0'))
            .matches(phoneRegExp, 'Phone number is not valid')
            .min(10)
            .max(10, 'Mobile number must be 10 digits')
            .required('A phone number is required'),
        localImage: yup.boolean(),
        image: yup.mixed()
            .nullable()
            .when("localImage", {
                is: false,
                then: yup.mixed().required("Image is Required")
            })
    });


    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError,
    } = useForm({
        defaultValues: {
            ...basicInfo,
            localImage: false
        },
        resolver: yupResolver(schema)
    });


    const handleCreateNewItem = (event) => {
        setSelectDept(event.target.value)
        setValue("department", event.target.value)
    };


    const SubmitBasic = (data) => {
        dispatch({
            type: BASIC_DETAIL_SUCCESS,
            payload: data
        })
        setActive("Consultation_Time")
    }

    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList?.find((a) => a?.name === "Doctors");

            dispatch(getDepartmentListByStatus(find?._id))
        }
    }, [CategoryList])

    const setimagefile = (e) => {
        if (e.size > 500000) {
            toast.warning("Image Size Should be less than 500KB");
            setPreviewEdit(null)
            setimages(null)
        } else {
            setPreviewEdit(null)
            setimages(e)
        }

    }

    useEffect(() => {
        if (id && !basicInfo) {

            setValue("designation", doctorSingleList?.designation)
            setValue("name", doctorSingleList?.name)
            setValue("email", doctorSingleList?.email)
            setValue("qualifications", doctorSingleList?.qualifications)
            setValue("expertise", doctorSingleList?.expertise)
            setValue("mobile", doctorSingleList?.mobile)
            setValue("specialization", doctorSingleList?.specialization)
            setValue("department", doctorSingleList?.department)
            setSelectDept(doctorSingleList?.department)
            setPreviewEdit(`${doctorSingleList?.basepath}${doctorSingleList?.image}`)
            setValue("localImage", `${doctorSingleList?.basepath}${doctorSingleList?.image}` ? true : false)
        }
        if (basicInfo && isEmpty(basicInfo.image)) {
            setimages(basicInfo?.image)
            setSelectDept(basicInfo?.department)
        }

        if (doctorSingleList) {
            setPreviewEdit(`${doctorSingleList?.basepath}${doctorSingleList?.image}`)
            setValue("localImage", `${doctorSingleList?.basepath}${doctorSingleList?.image}` ? true : false)
        }

    }, [id, doctorSingleList, basicInfo])

    useEffect(() => {
        if (deptListBySatatus) {
            setSelectDept(doctorSingleList?.department)
            setValue("department", doctorSingleList?.department)
        }

        if (deptListBySatatus && basicInfo?.department) {
            setValue("department", basicInfo?.department)
            setSelectDept(basicInfo?.department)
        }
    }, [deptListBySatatus, basicInfo])




    useEffect(() => {
        dispatch(getDeptCategory())
    }, [])

    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <CustomSelectInput
                            control={control}
                            error={errors.department}
                            fieldName="department"
                            fieldLabel="Department"
                            background="#FFFFFF"
                            height="40px"
                            size="16px"
                            value={selectDept}
                            onChangeValue={(value) => {
                                handleCreateNewItem(value)
                                setError('department', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Department</em>
                            </MenuItem>
                            {deptListBySatatus?.map((doc) => (
                                <MenuItem key={doc?.name} value={doc?._id} >
                                    {doc?.name}
                                </MenuItem>
                            ))}
                        </CustomSelectInput>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Name "
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            type={"text"}
                            background="#FFFFFF"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Email Address"
                            control={control}
                            error={errors.email}
                            fieldName="email"
                            type={"email"}
                            background="#FFFFFF"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Phone Number"
                            control={control}
                            error={errors.mobile}
                            fieldName="mobile"
                            type={"text"}
                            background="#FFFFFF"
                        />
                    </Grid>


                    <Grid item xs={12} md={3}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Designation"
                            control={control}
                            error={errors.designation}
                            fieldName="designation"
                            type={"text"}
                            background="#FFFFFF"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Specialization"
                            control={control}
                            error={errors.specialization}
                            fieldName="specialization"
                            type={"text"}
                            background="#FFFFFF"
                        />
                    </Grid>

                    <Grid item xs={12} md={3} >
                        <CustomImageUpload
                            fieldLabel='Upload Image'
                            control={control}
                            error={errors.image}
                            fieldName='image'
                            type={"file"}
                            height="150px"
                            background="#FFFFFF"
                            id="contained-button-file"
                            previewEditimage={previewEdit}
                            preview={images}
                            onChangeValue={(file) => {
                                setimagefile(file)
                                setValue("image", file)
                                setError('image', '')
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <CustomFormInput
                            maxrow={2}
                            multiline
                            height="60px"
                            fieldLabel="Qualifications"
                            control={control}
                            error={errors.qualifications}
                            fieldName="qualifications"
                            type={"text"}
                            background="#FFFFFF"
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <CustomFormInput
                            height="100px"
                            fieldLabel="Area of Expertise"
                            control={control}
                            error={errors.expertise}
                            fieldName="expertise"
                            type={"text"}
                            multiline={"multiline"}
                            background="#FFFFFF"
                            maxrow={4}
                        />

                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box display={"flex"} justifyContent={'flex-end'} paddingTop={"25px"}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} >
                                <CustomButton variant={"contained"} text={"Next"} submit={handleSubmit(SubmitBasic)} />
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
            </Box>



        </>
    )
}

export default BasicDetails