import { useNavigate } from "react-router-dom"
import axios from "../../customAxios"
import UserContext from "../../helpers/User"
import { DEL_SLIDER_FAIL } from "../constants/settingsConstants"
import {
    CREATE_USER_FAIL,
    CREATE_USER_SUCCESS,
    DEL_USER_FAIL,
    DEL_USER_SUCCESS,
    EDIT_USER_FAIL,
    EDIT_USER_SUCCESS,
    FILTER_USER_FAIL,
    FILTER_USER_SUCCESS,
    FORGET_FAIL,
    FORGET_SUCCESS,
    GET_ROLES_FAIL,
    GET_ROLES_SUCCESS,
    GET_USER_FAIL,
    GET_USER_SUCCESS,
    LOADING, LOGIN_FAILED, LOGIN_SUCCESS, OTP_FAILED, OTP_SUCCESS, PASSWORD_UPDATE_FAIL, PASSWORD_UPDATE_SUCCES, USER_STATUS_CHANGE_FAIL, USER_STATUS_CHANGE_SUCCESS
} from "../constants/userConstants"
import { PROFILE_SUCCESS } from "../constants/profileConstants"
import { getSettings } from "./settingsAction"

export const adminloginUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`auth/login`, data)
        .then(async response => {

            let data = response.data;

            await localStorage.setItem("user", JSON.stringify(data?.user));
            await localStorage.setItem("token", response.data.access_token);
            await localStorage.setItem("role", JSON.stringify(response.data.role));
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

            dispatch(getSettings())


        }).catch(async error => {
            dispatch({
                type: LOGIN_FAILED,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
export const loginUser = (mobile, data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    // const mobile = localStorage.getItem("mobile")
    const value = { ...data, mobile };
    await axios.post(`auth/doctor/login`, value)
        .then(async response => {

            let data = response.data;

            await localStorage.setItem("user", JSON.stringify(data?.user));
            await localStorage.setItem("token", response.data.access_token);
            await localStorage.setItem("role", JSON.stringify(response.data.role));
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data
            });

            dispatch({
                type: PROFILE_SUCCESS,
                payload: data?.doctorDetails
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: LOGIN_FAILED,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
export const loginUsers = (mobile, data) => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })
    // const mobile = localStorage.getItem("mobile")
    const value = { ...data, mobile };
    await axios.post(`auth/patient/login`, value)

        .then(async response => {

            let data = response.data;

            await localStorage.setItem("user", JSON.stringify(data?.user));
            await localStorage.setItem("token", response.data.access_token);
            await localStorage.setItem("role", JSON.stringify(response.data.role));
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data
            });

            dispatch({
                type: PROFILE_SUCCESS,
                payload: data?.patientDetails
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: LOGIN_FAILED,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
export const loginPhone = (data) => async (dispatch) => {


    localStorage.setItem("mobile", data.mobile)


    dispatch({
        type: LOADING,
        payload: true
    })


    // localStorage.setItem("mobile", data.mobile)

    await axios.post(`auth/loginotp`, data)

        .then(async response => {

            let data = response.data;

            console.log({ data }, 'hai')

            localStorage.setItem("id", data?.id)
            dispatch({
                type: OTP_SUCCESS,
                payload: data.message,


            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: OTP_FAILED,
                payload: error
            })


            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const forgetpasswordUpdate = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/auth/forgotpasswordupdate`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: PASSWORD_UPDATE_SUCCES,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: PASSWORD_UPDATE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}



export const forgetPassword = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/auth/forgotpassword`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: FORGET_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: FORGET_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const createUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-user`, data)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_USER_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/users`, data)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: GET_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_USER_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const delUser = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/delete-user/${id}`)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: DEL_USER_SUCCESS,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: DEL_USER_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const editUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-user`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: EDIT_USER_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const statusChangeUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-user`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: USER_STATUS_CHANGE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: USER_STATUS_CHANGE_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const filterUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/user/filter`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: FILTER_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: FILTER_USER_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}





