import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Avatar, Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Backbutton from '../../components/Widgets/Backbutton';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { getSingleListView } from '../../Redux/actions/serviceAction';
import CustomTimePicker from '../../components/Widgets/CustomTimePicker/CustomTimePicker';

const ServiceViewListing = () => {
    const dispatch = useDispatch()
    const { serviceSingleList } = useSelector(state => state.service);
    const { loading } = useSelector(state => state.auth);

    const { id } = useParams();



    useEffect(() => {
        dispatch(getSingleListView(id))
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }
    return (
        <>
            <Box style={{ paddingBottom: "50px" }}>
                <Stack
                    direction={"row"}
                    spacing={1}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Backbutton />
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{ fontSize: "25px", color: "#047AC3" }}
                    >
                        Service ID: {serviceSingleList?.service_id}
                    </Typography>
                </Stack>
            </Box>

            <Grid container spacing={2} px={5}>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,
                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Interval</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{serviceSingleList?.interval}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Service Department</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{serviceSingleList?.department?.map((res) => (
                            <span>{res?.name}</span>
                        ))}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Service Name</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{serviceSingleList?.name}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Price</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "40px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{serviceSingleList?.price}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 16,
                            sm: 14,
                            xs: 14,

                        },
                        fontFamily: "Quicksand",
                        fontWeight: "bold",
                        letterSpacing: "1px"

                    }}>Service Description</Typography>
                    <Typography
                        style={{
                            marginTop: "3px",
                            borderRadius: "10px",
                            height: "100px",
                            background: "#e7f5f7",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "5px"
                        }} sx={{
                            fontSize: {
                                md: 14,
                                sm: 13,
                                xs: 12,

                            },
                        }}>{serviceSingleList?.description}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{
                        fontSize: {
                            md: 14,
                            sm: 13,
                            xs: 12,

                        },
                    }}>Service Image</Typography>
                    <Avatar style={{
                        marginTop: "3px",
                        borderRadius: "10px",
                        width: "100%",
                        height: "150px",
                        background: "#e7f5f7",
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px"
                    }} src={serviceSingleList?.image} ></Avatar>
                </Grid>
                <Box style={{ paddingTop: "20px", paddingBottom: "20px", width: "100%" }}>
                    <Typography variant="h5" component="h2">Service Time</Typography>
                    <Divider />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Monday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true}
                                toValue={serviceSingleList?.date.monday.to}
                                fromValue={serviceSingleList?.date.monday.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Tuesday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true}
                                toValue={serviceSingleList?.date?.tuesday?.to}
                                fromValue={serviceSingleList?.date?.tuesday?.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Wednesday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true}
                                toValue={serviceSingleList?.date.wednesday.to}
                                fromValue={serviceSingleList?.date.wednesday.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Thursday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true}
                                toValue={serviceSingleList?.date?.thursday?.to}
                                fromValue={serviceSingleList?.date?.thursday?.from}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Friday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true}
                                toValue={serviceSingleList?.date?.friday?.to}
                                fromValue={serviceSingleList?.date?.friday?.from}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Saturday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true}
                                toValue={serviceSingleList?.date?.saturday?.to}
                                fromValue={serviceSingleList?.date?.saturday?.from}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography>{"Sunday"}</Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <CustomTimePicker
                                From={"From"}
                                To={"To"}
                                disabled={true}
                                readOnly={true}
                                toValue={serviceSingleList?.date?.sunday?.to}
                                fromValue={serviceSingleList?.date?.sunday?.from}
                            />

                        </Box>
                    </Grid>
                    {/* {serviceSingleList?.date.map((day) => (
                            <Grid item xs={12} md={4}>
                                <Typography>{day?.day}</Typography>
                                <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <CustomTimePicker
                                        From={"From"}
                                        To={"To"}
                                        toValue={moment(day?.to, 'h:mm a').format()}
                                        fromValue={moment(day?.from, 'h:mm a').format()}
                                    />

                                </Box>
                            </Grid>

                        ))} */}

                </Grid>
            </Grid>
        </>
    )
}

export default ServiceViewListing