import { Box, Pagination } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react'
import DataTables from '../../../components/Widgets/dataTable';
import { Paper } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import data from '../../../components/common/data';
import { getProcedure, updateprocedureListStatus } from '../../../Redux/actions/procedureAction';
import CustomSwitch from '../../../components/Widgets/CustomSwitch/CustomSwitch';
import { RESET_ERROR } from '../../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import moment from 'moment';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import CustomCheckBox from '../../../components/Widgets/CustomCheckBox';
const ProcedureListingTable = ({ datas, filterListing }) => {
    const dispatch = useDispatch()

    const Navigate = useNavigate()
    const { procedureList, procedureStatus, error } = useSelector(state => state.procedure);
    const [switchChecked, setSwitchChecked] = useState(false);


    const NavigateToView = (id) => {
        Navigate(`/ProcedureListView/${id}`)
    }
    const NavigateToEdit = (id) => {
        Navigate(`/procedureList/${id}`)
    }

    const ChangeStatus = (checked, row) => {
        let val = {
            id: row,
            status: checked === true ? "active" : "inactive"
        }

        // dispatch(getProcedure())
        dispatch(updateprocedureListStatus(val))
    }


    useEffect(() => {
        if (procedureStatus) {
            toast.success("Procedure Status Changed successfully");
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [procedureStatus, error])

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    var columns = [
        {
            name: <b>{Capitalize("PROCEDURE ID")}</b>,
            header: <b>{Capitalize("PROCEDURE ID")}</b>,
            selector: (row) => row?.procedure_id,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("CREATED ON")}</b>,
            header: <b>{Capitalize("CREATED ON")}</b>,
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY"),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PROCEDURE DEPARTMENT")}</b>,
            header: <b>{Capitalize("PROCEDURE DEPARTMENT")}</b>,
            selector: (row) => row?.department[0]?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("NAME")}</b>,
            header: <b>{Capitalize("NAME")}</b>,
            selector: (row) => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("PRICE")}</b>,
            header: <b>{Capitalize("PRICEE")}</b>,
            selector: (row) => row?.price,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("GROUPABLE")}</b>,
            header: <b>{Capitalize("GROUPABLE")}</b>,
            cell: (row) => (
                <>
                    <CustomCheckBox checked={row?.groupable ? true : false} />
                </>
            )
        },

        {
            name: <b>ACTIONS</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (
                <>
                    <Stack spacing={2} direction="row">
                        <RemoveRedEyeIcon
                            onClick={() => NavigateToView(row._id)}
                            color="blue"
                            style={{
                                cursor: "pointer",
                                color: "#047ac1",
                            }}
                        />{" "}
                        {row?.status == "active" && <BorderColorSharpIcon style={{ color: "#047ac1", cursor: "pointer" }} onClick={() => NavigateToEdit(row?._id)} />}

                    </Stack>
                </>
            ),
        },
        {
            name: <b>STATUS</b>,
            id: "status",
            accessor: (str) => "status",
            cell: (row, index) => (
                <>
                    <CustomSwitch checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)}
                    />

                </>
            ),
        },
    ];


    useEffect(() => {
        dispatch(getProcedure())
    }, [filterListing])

    return (
        <><Paper>
            <Fragment>

                <DataTables
                    columns={columns}
                    row={datas}
                    pagination
                />
            </Fragment>
        </Paper>
            <Box
                sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}


            </Box></>
    )
}

export default ProcedureListingTable