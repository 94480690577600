import { CREATE_BLOG_FAIL, CREATE_BLOG_SUCCESS, DEL_BLOG_SUCCESS, DEL_BLOG_FAIL, EDIT_BLOG_FAIL, EDIT_BLOG_SUCCESS, GET_BLOG_FAIL, GET_BLOG_SUCCESS, GET_SINGLE_BLOG_FAIL, GET_SINGLE_BLOG_SUCCESS } from "../constants/blogConstants";
import { RESET_ERROR } from "../constants/userConstants";

export const blogReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                blogCreated: null,
                blogedited: null,
                blogDeleted: null
            }
        case CREATE_BLOG_SUCCESS:
            return {
                ...state,
                blogCreated: true,
            }

        case CREATE_BLOG_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_BLOG_SUCCESS:
            return {
                ...state,
                blogList: action.payload
            }
        case GET_BLOG_FAIL:
            return {
                ...state,
                error: action.payload

            }
        case GET_SINGLE_BLOG_SUCCESS:
            return {
                ...state,
                singleBlogList: action.payload
            }
        case GET_SINGLE_BLOG_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case EDIT_BLOG_SUCCESS:
            return {
                ...state,
                blogedited: true
            }
        case EDIT_BLOG_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DEL_BLOG_SUCCESS:
            return {
                ...state,
                blogList: state?.blogList.filter(grp => grp._id !== action.payload),
                blogDeleted: true
            }
        case DEL_BLOG_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;

    }

}