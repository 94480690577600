import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Divider, Grid } from "@mui/material";
import CustomButton from "../../../components/Widgets/CustomButton/CustomButton";
import CustomSelectInput from "../../../components/Widgets/CustomSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomFormInput from "../../../components/Widgets/CustomFormInput";
import CustomImageUpload from "../../../components/Widgets/CustomImageUploade";
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import { postDepartment } from "../../../Redux/actions/departmentAction";

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
const AddDepartment = ({ handleClose, handleOpen, CategoryList, DeptEditdata, title }) => {
    const dispatch = useDispatch()
    const [selectvalue, setselectValue] = useState(null);
    const [images, setimages] = useState(null)
    const [previewEdit, setPreviewEdit] = useState(null);



    const { loading } = useSelector(state => state.auth)


    const schema = object().shape({
        description: yup.string().max(100).required("Description is required"),
        name: yup.string().max(50).required("Name is required"),
        image: yup.mixed().required('image is required')

    });


    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema)
    });

    const setimagefile = (e) => {
        if (e.size > 500000) {
            toast.warning("Image Size Should be less than 500KB");
            setPreviewEdit(null)
            setimages(null)
        } else {
            setPreviewEdit(null)
            setimages(e)
        }
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        p: 2.5,
    };


    const submit = (data) => {
        const formData = new FormData();
        formData.append('name', data?.name);
        formData.append('category_id', selectvalue?._id);
        formData.append('image', data?.image);
        formData.append('description', data?.description)
        dispatch(postDepartment(formData))

    }

    const clearclose = () => {
        setimages(null)
        reset()
        setPreviewEdit(null)
        handleClose()
    }



    useEffect(() => {
        if (CategoryList) {
            let find = CategoryList?.find((a) => a?.name === "Services");
            setselectValue(find)
        }
    }, [CategoryList, handleOpen])


    return (
        <>
            <Modal
                open={handleOpen}
                onClose={clearclose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack
                        direction={"row"}
                        spacing={5}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            style={{ color: "#047BC1" }}
                        >
                            {"Add Department"}
                        </Typography>
                        <ClearIcon
                            style={{
                                cursor: "pointer",
                                color: "black"
                            }}
                            onClick={() => clearclose()}
                        />
                    </Stack>

                    <Divider />
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "20px",
                            marginTop: "40px",
                        }}
                    >
                        <Container>
                            <Box style={{ display: "flex", justifyContent: "space-around", gap: "5px" }}>
                                <Box >
                                    <Grid container spacing={2} height={160}>
                                        <Grid xs={4} md={10}>
                                            <Box style={{ marginTop: "10px" }}>
                                                <Typography sx={{
                                                    fontSize: {
                                                        md: 14,
                                                        sm: 13,
                                                        xs: 12,

                                                    },
                                                }}>Department</Typography>
                                                <Typography

                                                    style={{
                                                        marginTop: "3px",
                                                        borderRadius: "10px",
                                                        height: "40px",
                                                        background: "#e7f5f7",
                                                        color: "black",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        paddingLeft: "5px"
                                                    }} sx={{
                                                        fontSize: {
                                                            md: 14,
                                                            sm: 13,
                                                            xs: 12,

                                                        },
                                                    }}>{selectvalue?.name}</Typography>
                                            </Box>


                                        </Grid>
                                        <Grid xs={4} md={10}>
                                            <CustomFormInput
                                                height="40px"
                                                fieldLabel="Department Name "
                                                control={control}
                                                error={errors.name}
                                                fieldName="name"
                                                type={"Department_Name"}
                                                background="#e7f5f7"
                                                placeholder={"Department Name"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container spacing={1}>
                                        <Grid xs={12}>
                                            <CustomImageUpload
                                                fieldLabel='Department Image'
                                                control={control}
                                                error={errors.image}
                                                fieldName='image'
                                                type={"file"}
                                                height="100px"
                                                background="#e7f5f7"
                                                id="contained-button-file"
                                                preview={images}
                                                previewEditimage={previewEdit}
                                                onChangeValue={(file) => {
                                                    setimagefile(file)
                                                    setValue("image", file)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                    <Box>
                        <Grid container >
                            <Grid xs={12}>
                                <CustomFormInput
                                    fieldLabel="description"
                                    control={control}
                                    error={errors.description}
                                    fieldName="description"
                                    type={"textarea"}
                                    placeholder={"Description"}
                                    height="120px"
                                    multiline={"multiline"}
                                    background="#e7f5f7"
                                    maxrow={4}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        marginTop={5}>
                        <CustomButton
                            text={loading ? 'Loading...' : "submit"}
                            disabled={loading ? true : false}
                            variant={"contained"}
                            submit={handleSubmit(submit)} />
                    </Box>

                </Box>
            </Modal>

        </>
    )
}

export default AddDepartment