import axios from "../../customAxios"
import {
    CREATE_DEPT_FAILED,
    CREATE_DEPT_SUCCESS,
    FILTER_DEPT_FAIL,
    FILTER_DEPT_SUCCESS,
    GET_CATEGORY_FAILED,
    GET_CATEGORY_SUCCESS,
    GET_DEPT_DETAIL_FAIL,
    GET_DEPT_DETAIL_SUCCESS,
    GET_DEPT_FAIL,
    GET_DEPT_SUCCESS,
    GET_LIST_STATUS_FAIL,
    GET_LIST_STATUS_SUCCESS,
    UPDATE_DEPT_FAIL,
    UPDATE_DEPT_SUCCESS,
} from "../constants/departmentConstants";
import { LOADING } from "../constants/userConstants";


export const getDeptCategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/department/category`, data)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: GET_CATEGORY_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_CATEGORY_FAILED,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const postDepartment = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/department/create`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_DEPT_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_DEPT_FAILED,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getDepartmentList = (data) => async (dispatch) => {

    let val = {
        category_id: data
    }
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/departments`, val)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: GET_DEPT_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_DEPT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getDepartmenDetailPage = (id) => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/department/${id}`)
        .then(async response => {

            let data = response.data.data;
            dispatch({
                type: GET_DEPT_DETAIL_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_DEPT_DETAIL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

    export const updateDepartment = (data) => async (dispatch) => {

        dispatch({
            type: LOADING,
            payload: true
        })

        await axios.post(`admin/department/edit?id`, data)
            .then(async response => {

                let data = response.data.data;
                dispatch({
                    type: UPDATE_DEPT_SUCCESS,
                    payload: data
                });

                dispatch({
                    type: LOADING,
                    payload: false
                })

            }).catch(async error => {
                dispatch({
                    type: UPDATE_DEPT_FAIL,
                    payload: error
                })

                dispatch({
                    type: LOADING,
                    payload: false
                })
            })
    }




    export const getDepartmentListByStatus = (data) => async (dispatch) => {

        let val = {
            category_id: data
        }
        dispatch({
            type: LOADING,
            payload: true
        })
    
        await axios.post(`admin/departments/list`, val)
            .then(async response => {
    
                let data = response.data.data;
                dispatch({
                    type: GET_LIST_STATUS_SUCCESS,
                    payload: data
                });
    
                dispatch({
                    type: LOADING,
                    payload: false
                })
    
            }).catch(async error => {
                dispatch({
                    type: GET_LIST_STATUS_FAIL,
                    payload: error
                })
    
                dispatch({
                    type: LOADING,
                    payload: false
                })
            })
    }


    
    export const filterDepartment = (data) => async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        })
    
        await axios.post(`admin/department/filter`, data)
            .then(async response => {
                let data = response.data.data;
                dispatch({
                    type: FILTER_DEPT_SUCCESS,
                    payload: data
                });
    
                dispatch({
                    type: LOADING,
                    payload: false
                })
    
            }).catch(async error => {
                dispatch({
                    type:FILTER_DEPT_FAIL,
                    payload: error
                })
    
                dispatch({
                    type: LOADING,
                    payload: false
                })
            })
    }