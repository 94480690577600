import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { borderRadius, Container } from '@mui/system';
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import CustomFormInput from '../../components/Widgets/CustomFormInput';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import Image from "../../assets/images/1.jpg"
import { BOX } from './style';
import { Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from '../../Redux/actions/userActions';
import { getProfile, updateProfile } from '../../Redux/actions/profileAction';
import { RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import UserContext from '../../helpers/User';



const Profile = () => {
    let dispatch = useDispatch()

    const context = useContext(UserContext)
    const { userData, loading } = useSelector(state => state.auth);
    const { profiledata, error, profileList, profileupdatedsuccess } = useSelector(state => state.profile);
    const [Image, setImage] = useState(null)


    const schema = object().shape({
        name: yup.string().required('Name is required'),
        mobile: yup.string().required('Mobile is required').typeError("Mobile number is required"),
        email: yup.string().email().required('Email is required'),
    });

    const passschema = object().shape({
        password: yup.string().required('Password is required'),
        passwordConfirmation: yup.string().test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value
        })
    });

    // const {
    //     handleSubmit,
    //     control,
    //     formState: { errors },
    //     setValue,
    // } = useForm({
    //     resolver: yupResolver(schema),
    // });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            name: profiledata?.name || "", // Set default name value from profiledata
            email: profiledata?.email || "", // Set default email value from profiledata
            mobile: profiledata?.mobile || "", // Set default mobile value from profiledata
            designation: profiledata?.designation || "", // Set default designation value from profiledata
        },
        resolver: yupResolver(schema),
    });
    
    
    const {
        handleSubmit: handlePasswordSubmit,
        control: passControl,
        formState: { errors: passErrors },
        reset
    } = useForm({
        resolver: yupResolver(passschema),
        defaultValues: {
            password: '',
            passwordConfirmation: ''
        }
    });




    const [previewEdit, setPreviewEdit] = useState(null);

    const submitForm = (data) => {
        let formData = new FormData();
        formData.append('id', context.user?.user?._id);
        formData.append('name', data?.name);
        formData.append('email', data?.email);
        formData.append('mobile', data?.mobile);
        if (Image) {
            formData.append('image', Image);

        }
        formData.append('designation', data?.designation);
        dispatch(updateProfile(formData));

    }


    useEffect(() => {
        if (context?.user?.user?._id) {
            dispatch(getProfile(context?.user?._id))
        }

    }, [context?.user])


    useEffect(() => {
  
        if (profiledata) {
            setValue("name", profiledata?.name || "");
            setValue("email", profiledata?.email || "");
            setValue("designation", profiledata?.designation || "");
            setValue("mobile", profiledata?.mobile || "");
        }
    }, [profiledata, setValue]);
    


    useEffect(() => {
        if (profileupdatedsuccess) {
            reset()
            toast.success("Profile Updated successfully");
            dispatch({
                type: RESET_ERROR
            })

        }
        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })

        }

    }, [profileupdatedsuccess, error])

    const submitPassword = (data) => {
        let formData = new FormData();
        formData.append("name", profiledata?.name)
        formData.append("email", profiledata?.email)
        formData.append('id', context.user?.user?._id);
        formData.append('password', data?.password);
        dispatch(updateProfile(formData));
    }


    return (
        <>
            <Box mt={10} boxShadow={1} padding={5} borderRadius={5}>
                <h4>PROFILE</h4>
                <Box display={"flex"} flex={1} flexDirection="row" >
                    <Box flex={2}>
                        <Box className='mainprofile'>
                            <Box position={"relative"} width={"200px"} height={"200px"}>
                                <Avatar src={Image ? URL.createObjectURL(Image) : profiledata?.image} style={{ width: "200px", height: "200px", objectFit: "contain" }}>
                                </Avatar>
                                <Box display={"flex"} position={"absolute"} zIndex={"100"} justifyContent={"center"} bottom={"0px"} width={"200px"} >
                                    <IconButton color="primary" aria-label="upload picture" component="label" className='iconbutton'>
                                        <input hidden name="myImage" type="file" onChange={(e) => setImage(e.target.files[0])} />
                                        <PhotoCamera className='photoicon' />
                                    </IconButton>

                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box flex={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} md={6} xl={4}>
                                <CustomFormInput
                                    fieldLabel="Name"
                                    control={control}
                                    error={errors.name}
                                    fieldName="name"
                                    placeholder={"Name"}
                                    height="40px"
                                    background="#FFFFFF"
                                />
                            </Grid>
                        
                            <Grid item xs={6} md={6} xl={4}>
                                <CustomFormInput
                                    fieldLabel="Contact Number "
                                    control={control}
                                    error={errors.mobile}
                                    fieldName="mobile"
                                    type={"number"}
                                    placeholder={"Contact Number"}
                                    height="40px"
                                    background="#FFFFFF"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} xl={4}>
                                <CustomFormInput
                                    fieldLabel="Email Address"
                                    control={control}
                                    error={errors.email}
                                    fieldName="email"
                                    placeholder={"Email Address"}
                                    height="40px"
                                    background="#FFFFFF"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} xl={4}>
                                <CustomFormInput
                                    fieldLabel="Designation"
                                    control={control}
                                    error={errors.designation}
                                    fieldName="designation"
                                    placeholder={"Designation"}
                                    height="40px"
                                    background="#FFFFFF"
                                />
                            </Grid>

                        </Grid>
                        <Box style={{ display: "flex", paddingTop: "50px" }}>

                            <CustomButton
                                text={loading ? "Loading " : "Update"}
                                variant={"contained"}
                                submit={handleSubmit(submitForm)}
                                heights={50}
                                widths={150}
                                fontSizes={20}
                            />

                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box mt={10} boxShadow={1} padding={5} borderRadius={5} mb={10}>
                <h4>CHANGE PASSWORD</h4>
                <Box flex={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6} xl={4}>
                            <CustomFormInput
                                fieldLabel="Password"
                                control={passControl}
                                error={passErrors.password}
                                fieldName="password"
                                placeholder={"Password"}
                                height="40px"
                                background="#FFFFFF"
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={6} md={6} xl={4}>
                            <CustomFormInput
                                fieldLabel="Confirm Password"
                                control={passControl}
                                error={passErrors.passwordConfirmation}
                                fieldName="passwordConfirmation"
                                type={"password"}
                                placeholder={"Confirm Password"}
                                height="40px"
                                background="#FFFFFF"
                            />
                        </Grid>
                        <Grid item xs={6} md={6} xl={4} mt={2}>
                            <CustomButton
                                text={loading ? "Loading " : "Update"}
                                variant={"contained"}
                                submit={handlePasswordSubmit(submitPassword)}
                                heights={50}
                                widths={150}
                                fontSizes={20}
                            />
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </>
    )



}

export default Profile