import { Grid, Switch, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useEffect } from 'react'
import CustomFormInput from '../../components/Widgets/CustomFormInput'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from '../../components/Widgets/CustomButton/CustomButton';
import { Fees_info } from '../../Redux/actions/AddDocter';
import { LOADING, RESET_ERROR } from '../../Redux/constants/userConstants';
import { toast } from 'react-toastify';
import { editDocterList, PostDocter } from '../../Redux/actions/doctorAction';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCTOR_INPUT, RESET_DOCTOR } from '../../Redux/constants/AddDoctorConstant';
import moment from 'moment';
const Fees = ({ setActive, Resdata }) => {
    const navigate = useNavigate()
    let { id } = useParams();
    const dispatch = useDispatch()
    const { cosultationTime, basicInfo, feesDatas } = useSelector(state => state.doctState);
    const { loading } = useSelector(state => state.auth);
    const { docterCreated, error, docterEdited } = useSelector(state => state.doct);

    const schema = object().shape({
        op_fee: yup.number().positive("OP Consultation Fee must be a positive number").required("OP Consultation Fee is required").typeError('Should be a Number'),
        online_fee: yup.number().positive("Online Consultation Fee must be a positive number").required("Online Consultation Fee is required").typeError('Should be a Number'),
        review_fee: yup.number().positive("Report Review Fee must be a positive number").required("Report Review Fee is required").typeError('Should be a Number'),
        review_day: yup.string().max(50).required("Reviewed Within (Days) Fee is required"),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...feesDatas
        }
    });

    const SubmitForm = (data) => {
        //dispatch(Fees_info(data))
        let days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        let dates = [];
        days.map(da => {
            cosultationTime?.time[da].map(ti => {
                dates.push({ day: da, from: ti?.from ? moment(ti?.from).format("HH:mm") : null, to: ti?.to ? moment(ti?.to).format("HH:mm") : null })
            })
        })

        if (id) {
            const formData = new FormData();
            formData.append('id', Resdata?._id);
            formData.append('status', Resdata?.status);
            formData.append('name', basicInfo?.name);
            formData.append('email', basicInfo?.email);
            formData.append('mobile', basicInfo?.mobile);
            formData.append('department', basicInfo?.department);
            formData.append('designation', basicInfo?.designation);
            formData.append('specialization', basicInfo?.specialization);
            formData.append('qualifications', basicInfo?.qualifications);
            formData.append('expertise', basicInfo?.expertise);
            if (basicInfo?.image) {
                formData.append('image', basicInfo?.image);
            }
            formData.append('interval', parseInt(cosultationTime?.interval));
            formData.append('op_fee', JSON.stringify(data?.op_fee));
            formData.append('online_fee', JSON.stringify(data?.online_fee));
            formData.append('review_fee', JSON.stringify(data?.review_fee));
            formData.append('review_day', data?.review_day);
            formData.append('dateObject', JSON.stringify(cosultationTime?.time));
            formData.append('time', JSON.stringify(dates));
            dispatch(editDocterList(formData))

        } else {
            const formData = new FormData();
            formData.append('name', basicInfo?.name);
            formData.append('email', basicInfo?.email);
            formData.append('mobile', basicInfo?.mobile);
            formData.append('department', basicInfo?.department);
            formData.append('designation', basicInfo?.designation);
            formData.append('specialization', basicInfo?.specialization);
            formData.append('qualifications', basicInfo?.qualifications);
            formData.append('expertise', basicInfo?.expertise);
            formData.append('image', basicInfo?.image);
            formData.append('interval', parseInt(cosultationTime?.interval));
            formData.append('op_fee', JSON.stringify(data?.op_fee));
            formData.append('online_fee', JSON.stringify(data?.online_fee));
            formData.append('review_fee', JSON.stringify(data?.review_fee));
            formData.append('review_day', data?.review_day);
            formData.append('dateObject', JSON.stringify(cosultationTime?.time));
            formData.append('time', JSON.stringify(dates));
            dispatch(PostDocter(formData))

        }
    }


    useEffect(() => {

        if (id && Resdata) {
            setValue("op_fee", Resdata?.op_fee)
            setValue("online_fee", Resdata?.online_fee)
            setValue("review_fee", Resdata?.review_fee)
            setValue("review_day", Resdata?.review_day)
        }



    }, [])


    useEffect(() => {
        if (docterCreated) {
            toast.success("Doctor Created successfully");
            dispatch({
                type: RESET_ERROR
            })
            dispatch({
                type: RESET_DOCTOR
            })
            navigate(-1)
        }
        if (docterEdited) {
            toast.success("Doctor Edited successfully");
            dispatch({
                type: RESET_ERROR
            })
            dispatch({
                type: RESET_DOCTOR
            })
            navigate(-1)
        }

        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })

        }
    }, [docterCreated, error, docterEdited])


    const handlePrevious = () => {
        const data = getValues();
        dispatch({
            type: DOCTOR_INPUT,
            payload: {
                prop: 'feesDatas',
                value: data
            }
        })
        setActive("Consultation_Time")
    }


    return (
        <>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} md={3}>
                    <Typography>OP Consultation</Typography>
                    {/* <Switch></Switch> */}
                    <Box mt={1}>
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Fees"
                            control={control}
                            error={errors.op_fee}
                            fieldName="op_fee"
                            type="phone"

                            background="#FFFFFF"
                        />
                    </Box>

                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography>Online Consultation</Typography>
                    <Box mt={1}>
                        {/* <Switch></Switch> */}
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Fees"
                            control={control}
                            error={errors.online_fee}
                            fieldName="online_fee"
                            type={"phone"}
                            background="#FFFFFF"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography>Report Review</Typography>
                    <Box mt={1} >
                        {/* <Switch></Switch> */}
                        <CustomFormInput
                            height="40px"
                            fieldLabel="Fees"
                            control={control}
                            error={errors.review_fee}
                            fieldName="review_fee"
                            type="phone"

                            background="#FFFFFF"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box mt={3.5}></Box>
                    <CustomFormInput
                        height="40px"
                        fieldLabel="Reviewed Within (Days)"
                        control={control}
                        error={errors.review_day}
                        fieldName="review_day"
                        type={"text"}
                        background="#FFFFFF"
                    />
                </Grid>
            </Grid>

            <Box paddingTop={"100px"} display={"flex"} justifyContent={"flex-end"} gap={"20px"}>
                <CustomButton variant={"contained"} text={"Previous"} submit={handlePrevious} />
                <CustomButton variant={"contained"} text={loading ? "Loading..." : "Confirm"} disabled={loading ? true : false} submit={handleSubmit(SubmitForm)} />
            </Box>

        </>
    )
}

export default Fees