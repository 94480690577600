import React, { useContext, useEffect } from "react";
import Navbar from "../../../Screens/Header";
import Sidebar from "../../../Screens/sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { DIV } from "./style";
const HomeLayout = () => {


  return (
    <DIV>
      <Sidebar />
      <div className="nav">
        <Navbar />
        <div className="outlet">
          <Outlet />
        </div>
      </div>
    </DIV>
  );
};

export default HomeLayout;
