
const initialState = '/consultations'


const SET_ACTIVE_SIDEBAR = "SET_ACTIVE_SIDEBAR";

export const activeSidebarreducer = (state = {status : initialState }, action) => {
    switch (action.type) {

        case SET_ACTIVE_SIDEBAR:
            return {
                ...state,
                status: action.payload
            }

       
        default:
            return state;
    }
}

export const setActiveSidebar = (value) => ({

    type: 'SET_ACTIVE_SIDEBAR',
    payload: value,
  },
  console.log({value},'indexactive'));